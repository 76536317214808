import React from 'react'

import { Col, Input, Row, Table } from 'reactstrap'

// GraphQL
import { graphql, withApollo } from '@apollo/client/react/hoc'
import { faLevelUpAlt, faPlus } from '@fortawesome/free-solid-svg-icons'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { getLicenseOwners } from './license-owners.graphql'

import { DD_MM_YYYY__HH_mm } from '../../../helper/helper-functions'
// additional components
import Loading from '../../../helper/loading/loading.jsx'
import DeleteLicenseOwnerModal from '../../../modules/modal/deleteLicenseOwnerModal.jsx'
import Modal from '../../../modules/modal/modal.jsx'
import UpdateLicenseOwnerModal from '../../../modules/modal/updateLicenseOwnerModal.jsx'
import FormCreate from './create.jsx'
import VolumeUnitAccountManagement from './volumeUnitAccountManagement'

class LicenseOwners extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      search_query: '',
      modal: false,
      delete_id: null,
      text: {
        header: '',
        body: '',
        confirm: '',
        cancel: ''
      }
    }
  }

  close = () => this.setState({ modal: false })

  change = event => this.setState({ [event.target.id]: event.target.value })

  searchLicenseOwner = () => {
    const license_owners = this.props.data.license_owners
    const result = []
    if (this.state.search_query.length !== 0) {
      for (let i = 0; i < license_owners.length; i++) {
        if (
          license_owners[i].name &&
          license_owners[i].name.toLowerCase().includes(this.state.search_query.toLowerCase())
        ) {
          const { sublicense_owners, ...license_owner } = license_owners[i]
          result.push(license_owner)
          continue
        }
        const { sublicense_owners } = license_owners[i]
        for (let i = 0; i < sublicense_owners.length; i++) {
          if (
            sublicense_owners[i].name &&
            sublicense_owners[i].name.toLowerCase().includes(this.state.search_query.toLowerCase())
          ) {
            result.push(sublicense_owners[i])
          }
        }
      }
      return result
    }
    return license_owners
  }

  render = () => {
    const {
      client,
      locale,
      history,
      data: { loading, error }
    } = this.props
    const { search_query } = this.state
    const license_owners = this.searchLicenseOwner()

    if (loading) return <Loading />
    if (error) return <p>Error</p>

    return (
      <div className="page-wrapper">
        <Row className="page-header">
          <Col sm="6">
            <h1>{locale.license_owner.singular}</h1>
          </Col>
          <Col sm="3" className="text-right">
            <Input
              id="search_query"
              placeholder="Lizenznehmer durchsuchen..."
              value={search_query}
              onChange={this.change}
            />
          </Col>
          <Col sm="3" className="text-right">
            <Modal
              children={<FormCreate locale={locale} client={client} history={history} />}
              toggleModalText={locale.add_license_owner}
              button_type={{ icon_only: false, icon_type: faPlus, color: 'primary-light', classes: 'btn-labeled' }}
              modal_size={'lg'}
            />
          </Col>
        </Row>
        <hr className="seperator" />
        <Table striped responsive bordered>
          <thead className="thead-light">
            <tr>
              <th>{locale.label}</th>
              <th>{locale.volumeUnits.plural}</th>
              <th>{locale.facilities}</th>
              <th>{locale.users}</th>
              <th>{locale.created_at}</th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {license_owners.map(license_owner => (
              <LicenseOwner
                key={license_owner.id}
                license_owner={license_owner}
                client={client}
                locale={locale}
                is_sublicense_owner={false}
              />
            ))}
          </tbody>
        </Table>
      </div>
    )
  }
}

const LicenseOwner = ({ license_owner, locale, client, is_sublicense_owner }) => (
  <React.Fragment>
    <tr>
      <td>
        {is_sublicense_owner ? (
          <FontAwesomeIcon className="text-info icon-prepend" icon={faLevelUpAlt} fixedWidth rotation={90} />
        ) : (
          ``
        )}
        <UpdateLicenseOwnerModal
          id={license_owner.id}
          license_owner={license_owner}
          client={client}
          locale={locale}
          is_sublicense_owner={is_sublicense_owner}
        />
      </td>
      <td>
        <VolumeUnitAccountManagement
          balance={license_owner.volumeUnitAccount.balance}
          accountId={license_owner.volumeUnitAccount.id}
          ownerName={license_owner.name}
        />
      </td>
      <td>{license_owner?.facility_versions_count || '0'}</td>
      <td>{license_owner?.user_count || '0'}</td>
      <td>{DD_MM_YYYY__HH_mm(license_owner?.created_at)}</td>
      <td className="text-center">
        {license_owner.destroyable ? (
          <DeleteLicenseOwnerModal id={license_owner.id} client={client} name={license_owner?.name} locale={locale} />
        ) : null}
      </td>
    </tr>
    {license_owner.sublicense_owners
      ? license_owner.sublicense_owners.map(sublicense_owner => (
          <LicenseOwner
            key={sublicense_owner.id}
            license_owner={sublicense_owner}
            client={client}
            locale={locale}
            is_sublicense_owner={true}
          />
        ))
      : null}
  </React.Fragment>
)

export default withApollo(
  graphql(getLicenseOwners, {
    options: () => ({
      notifyOnNetworkStatusChange: true
    })
  })(LicenseOwners)
)
