import React from 'react'

import { Col, Container, FormGroup, Row } from 'reactstrap'

import { withApollo } from '@apollo/client/react/hoc'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'

// additional components
import { deleteImage, getFacility } from './../facility.graphql'

import { ProcessingButton } from '../../../modules/buttons/processing-button'

const Delete = ({ client, id, toggleModal, uuid, locale }) => {
  return (
    <React.Fragment>
      <DeleteForm toggleModal={toggleModal} client={client} image_uuid={id} facility_uuid={uuid} locale={locale} />
    </React.Fragment>
  )
}

class DeleteForm extends React.Component {
  submit = event => {
    event.preventDefault()
    event.stopPropagation()
    this.setState({ processing: true })
    this.props.client
      .mutate({
        mutation: deleteImage,
        variables: {
          uuid: this.props.image_uuid
        },
        refetchQueries: [
          {
            query: getFacility,
            variables: {
              uuid: this.props.facility_uuid,
              version_number: ''
            }
          }
        ],
        awaitRefetchQueries: true
      })
      .then(result => {
        this.cancel()
      })
  }
  cancel = () => this.props.toggleModal()
  render = () => {
    const { locale } = this.props
    const { cancel, submit } = this

    return (
      <FormGroup className="form-action">
        <Container>
          <Row>
            <Col sm="5">
              <a className="link-btn" onClick={cancel} href="#">
                {locale.cancel}
              </a>
            </Col>
            <Col sm="7" className="text-right">
              <ProcessingButton
                icon={faTrashAlt}
                removebutton
                label={locale.delete}
                onClick={submit}
                processing={this.state?.processing || false}
              />
            </Col>
          </Row>
        </Container>
      </FormGroup>
    )
  }
}

export default withApollo(Delete)
