import React from 'react'

import { Col, Input, Row, Table } from 'reactstrap'

// GraphQL
import { graphql, withApollo } from '@apollo/client/react/hoc'
// Icons
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import { getRoles } from './roles.graphql'

import { DD_MM_YYYY__HH_mm } from '../../../helper/helper-functions'
import Loading from '../../../helper/loading/loading.jsx'
import DeleteRoleModal from '../../../modules/modal/deleteRoleModal.jsx'
import Modal from '../../../modules/modal/modal.jsx'
import UpdateRoleModal from '../../../modules/modal/updateRoleModal.jsx'
// additional components
import FormCreate from './create.jsx'

class Roles extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      search_query: '',
      modal: false,
      delete_id: null,
      text: {
        header: '',
        body: '',
        confirm: '',
        cancel: ''
      }
    }
  }

  close = () => this.setState({ modal: false })
  open = event => {
    this.setState({
      modal: true,
      delete_id: event.target.id,
      text: {
        header: `${event.target.name} ${this.props.locale.confirm_delete_header}`,
        body: this.props.locale.confirm_delete_role_description,
        confirm: 'Löschen',
        cancel: 'Abbrechen'
      }
    })
  }

  change = event => this.setState({ [event.target.id]: event.target.value })

  search = () => {
    const roles = this.props.data.roles
    const result = []
    if (this.state.search_query.length !== 0) {
      for (let i = 0; i < roles.length; i++) {
        if (roles[i].name && roles[i].name.toLowerCase().includes(this.state.search_query.toLowerCase())) {
          result.push(roles[i])
        }
      }
      return result
    }
    return roles
  }

  render = () => {
    const {
      locale,
      client,
      history,
      data: { loading, error }
    } = this.props
    const { search_query } = this.state
    const roles = this.search()

    if (loading) return <Loading />
    if (error) return <p>Error</p>

    return (
      <div className="page-wrapper">
        <Row className="page-header">
          <Col sm="6">
            <h1>{locale.role.plural}</h1>
          </Col>
          <Col sm="3" className="text-right">
            <Input
              id="search_query"
              placeholder="Rechte-Gruppen durchsuchen..."
              value={search_query}
              onChange={this.change}
            />
          </Col>
          <Col sm="3" className="text-right">
            <Modal
              children={<FormCreate locale={locale} client={client} history={history} />}
              toggleModalText={locale.add_role}
              button_type={{ icon_only: false, icon_type: faPlus, color: 'primary-light', classes: 'btn-labeled' }}
              modal_size={'lg'}
            />
          </Col>
        </Row>
        <hr className="seperator" />
        <Table striped responsive bordered>
          <thead className="thead-light">
            <tr>
              <th>{locale.label}</th>
              <th>{locale.related_users}</th>
              <th>{locale.created_at}</th>
              <th>{locale.last_modified}</th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {roles.map(role => (
              <Role key={role.id} role={role} client={client} locale={locale} open={this.open} />
            ))}
          </tbody>
        </Table>
      </div>
    )
  }
}

const Role = ({ client, locale, history, role }) => (
  <tr>
    <td>
      <UpdateRoleModal role={role} client={client} history={history} locale={locale} />
    </td>
    <td>{role?.related_users_count || '0'}</td>
    <td>{DD_MM_YYYY__HH_mm(role?.created_at)}</td>
    <td>{DD_MM_YYYY__HH_mm(role?.updated_at)}</td>
    <td className="text-center">
      {role.destroyable && (
        <DeleteRoleModal id={role.id} client={client} name={role?.name} history={history} locale={locale} />
      )}
    </td>
  </tr>
)
export default withApollo(
  graphql(getRoles, {
    options: () => ({
      notifyOnNetworkStatusChange: true
    })
  })(Roles)
)
