import { withRouter } from 'react-router'
import { Col, Row, Table } from 'reactstrap'

import { useQuery } from '@apollo/client'

import Loading from 'components/helper/loading/loading'
import Pagination from 'components/modules/pagination/pagination'
import { withLocale } from 'locale'

import { getAssetDownloads } from './asset_downloads.graphql'

import DownloadEntry from './download_entry'

const AssetDownloads = ({ locale, history, match }) => {
  const { loading, error, data } = useQuery(getAssetDownloads)

  if (loading)
    return (
      <div className="mt-5">
        <Loading />
      </div>
    )
  if (error) return <div className="mt-5">{`There was an error fetching the data: ${error}`}</div>

  const itemsPerPage = 500
  const currentPage = parseInt(match.params.page) || 1
  const pageIndex = currentPage - 1

  const pageItems = data.asset_downloads.slice(pageIndex * itemsPerPage, currentPage * itemsPerPage)

  const setPage = n => history.push(match.path.replace(':page?', n))

  return (
    <div className="page-wrapper">
      <Row className="page-header">
        <Col>
          <h1>{locale.plural}</h1>
        </Col>
      </Row>
      <hr className="seperator" />
      <Pagination
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        totalCount={data.asset_downloads.length}
        setPage={setPage}
      />
      <Table striped responsive bordered>
        <thead className="thead-light">
          <tr>
            <th />
            <th>{locale.combinationKey}</th>
            <th>{locale.url}</th>
            <th>{locale.updateUrl}</th>
          </tr>
        </thead>
        <tbody>
          {pageItems.map(download => (
            <DownloadEntry key={data.asset_downloads.indexOf(download)} data={download} />
          ))}
        </tbody>
      </Table>
      <Pagination
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        totalCount={data.asset_downloads.length}
        setPage={setPage}
      />
    </div>
  )
}

export default withRouter(withLocale(AssetDownloads, { key: 'asset_download' }))
