export default {
  fields: {
    fields: {
      merge(existing, incoming) {
        return incoming
      }
    },
    variables: {
      merge(existing, incoming) {
        return incoming
      }
    }
  }
}
