// additional components
import { Component } from 'react'

import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { Alert, Col, Row, Table } from 'reactstrap'

import { Query } from '@apollo/client/react/components'
// GraphQL
import { withApollo } from '@apollo/client/react/hoc'

import { arrayMoveImmutable as arrayMove } from 'array-move'
import { LegacySelect as Select } from 'components/modules/selects/react-select'
import DragHandle from 'components/modules/sortable/DragHandle'

import { getSections, reorderSection } from './sections.graphql'

import Loading from '../../../helper/loading/loading.jsx'
import CreateSectionModal from '../../../modules/modal/createSectionModal.jsx'
import DeleteSectionModal from '../../../modules/modal/deleteSectionModal.jsx'
import UpdateSectionModal from '../../../modules/modal/updateSectionModal.jsx'

const Sections = props => (
  <Query query={getSections} variables={{ form_version_id: props.match.params.id }}>
    {({ loading, error, data }) => {
      if (loading || !data) {
        return (
          <div className="page-wrapper">
            <Row className="page-header">
              <Col sm="12">
                <h1>Standard-Teilbereiche</h1>
              </Col>
            </Row>
            <hr className="seperator" />
            <div className="box">
              <Alert
                color="info"
                children={`Bitte wählen Sie einen Standard-Teilbereich aus, den Sie bearbeiten wollen. Die Zuordnung zu Angebotstypen erfolgt in deren Konfiguration.`}
              />
              <Row>
                <Col sm="2" className="text-right">
                  <label htmlFor="currentVersion" className="control-label ">
                    Version
                  </label>
                </Col>
                <Col sm="3">
                  <Select
                    isClearable={false}
                    id="current_version"
                    name="current_version"
                    onChange={() => null}
                    options={[]}
                    placeholder={'...wird geladen'}
                    value={props.match.params.id || ''}
                  />
                </Col>
                <Col sm={{ size: '5', offset: '2' }}>
                  <CreateSectionModal id={props.match.params.id || ''} {...props} />
                </Col>
              </Row>
            </div>
            <br />
            <br />
            <Loading />
          </div>
        )
      } else {
        !props.match.params.id &&
          props.history.push(
            `/form-kit/sections/version/${
              data.form_versions.filter(({ current }) => current === true).reduce(_ => _).id
            }`
          )

        return (
          <div className="page-wrapper">
            <Row className="page-header">
              <Col sm="12">
                <h1>Standard-Teilbereiche</h1>
              </Col>
            </Row>
            <hr className="seperator" />
            <div className="box">
              <Alert
                color="info"
                children={`Bitte wählen Sie einen Standard-Teilbereich aus, den Sie bearbeiten wollen. Die Zuordnung zu Angebotstypen erfolgt in deren Konfiguration.`}
              />
              <Row>
                <Col sm="2" className="text-right">
                  <label htmlFor="currentVersion" className="control-label ">
                    Version
                  </label>
                </Col>
                <Col sm="3">
                  <Select
                    isClearable={false}
                    id="current_version"
                    name="current_version"
                    value={props.match.params.id}
                    onChange={event => props.history.push(`/form-kit/sections/version/${event.value}`)}
                    options={data.form_versions.map(version => ({
                      value: version.id,
                      id: 'current_version',
                      label: version.name
                    }))}
                  />
                </Col>
                <Col sm={{ size: '5', offset: '2' }}>
                  <CreateSectionModal id={props.match.params.id} locale={props.locale} />
                </Col>
              </Row>
            </div>
            <br />
            <SortableComponent
              locale={props.locale}
              id={props.match.params.id}
              items={data.sections}
              versions={data.form_versions}
              match={props.match}
              client={props.client}
            />
          </div>
        )
      }
    }}
  </Query>
)

const SortableItem = SortableElement(
  ({
    client,
    locale,
    item: {
      id,
      destroyable,
      position,
      name_de,
      linked_facility_kinds_count,
      standard_forms_count,
      additional_forms_count
    }
  }) => (
    <tr>
      <td>
        <DragHandle />
      </td>
      <td>
        <strong>{name_de}</strong>
      </td>
      <td>{linked_facility_kinds_count}</td>
      <td>{standard_forms_count}</td>
      <td>{additional_forms_count}</td>
      <td className="text-center">
        <UpdateSectionModal id={id} locale={locale} />
        {destroyable && <DeleteSectionModal id={id} client={client} locale={locale} name={name_de} />}
      </td>
    </tr>
  )
)

const SortableList = SortableContainer(({ client, locale, items }) => (
  <tbody>
    {items.map((item, index) => (
      <SortableItem client={client} key={item.id} index={index} item={item} locale={locale} />
    ))}
  </tbody>
))

class SortableComponent extends Component {
  state = {
    items: this.props.items
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const variables = {
      id: this.props.items[oldIndex].id,
      position: this.props.items[newIndex].position
    }

    this.setState({ items: arrayMove(this.state.items, oldIndex, newIndex) }, () =>
      this.props.client.mutate({
        mutation: reorderSection,
        variables,
        refetchQueries: [
          {
            query: getSections,
            variables: {
              form_version_id: this.props.id
            }
          }
        ]
      })
    )
  }

  render = () => {
    return (
      <Table striped responsive bordered style={{ tableLayout: 'fixed' }}>
        <thead className="thead-light">
          <tr>
            <th style={{ width: '5%' }}></th>
            <th style={{ width: '40%' }}>{this.props.locale.label}</th>
            <th style={{ width: '15%' }}>{this.props.locale.linked_facility_kinds_count}</th>
            <th style={{ width: '15%' }}>{this.props.locale.standard_forms_count}</th>
            <th style={{ width: '15%' }}>{this.props.locale.additional_forms_count}</th>
            <th style={{ width: '10%' }}> </th>
          </tr>
        </thead>
        <SortableList
          client={this.props.client}
          items={this.props.items}
          locale={this.props.locale}
          lockAxis="y"
          lockOffset="0%"
          lockToContainerEdges
          match={this.props.match}
          onSortEnd={this.onSortEnd}
          useDragHandle
          versions={this.props.form_versions}
          helperClass="sortable-helper"
        />
      </Table>
    )
  }
}

export default withApollo(Sections)
