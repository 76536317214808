import { useState } from 'react'

import { Card, Col, Row } from 'reactstrap'

import { TokenAuth } from 'helpers/token-auth'
import { withLocale } from 'locale'
import { omit } from 'lodash'
import PropTypes from 'prop-types'

import { FacilitiesFilter } from './facilitiesFilter'
import FilteredFacilitiesFromApi from './facilitiesTable/filteredFacilitiesFromApi'
import { FileExporter } from './fileExporter'
import SearchQueryInput from './searchQueryInput/searchQueryInput'
import { SortingSwitcher, allowedSortingDirections, allowedSortingOptions } from './sortingSwitcher'

const FacilitiesTableWithFilter = ({ locale }) => {
  const [queryVariables, setQueryVariables] = useState({
    sortBy: allowedSortingOptions.UPDATED_AT,
    sortDirection: allowedSortingDirections.DESCENDING,
    queryString: '',
    licenseOwnerIds: [],
    collectorIds: [],
    regionIds: [],
    trainingStatus: null,
    certificationEndDateRange: null,
    workflowStates: [],
    certificationTypes: []
  })

  const setSortingOptions = ({ type, direction }) => {
    setQueryVariables({ ...queryVariables, sortBy: type, sortDirection: direction })
  }

  const setQueryString = queryString => {
    setQueryVariables({ ...queryVariables, queryString })
  }

  const setCurrentSearchFilters = newFilters => {
    setQueryVariables({ ...queryVariables, ...newFilters })
  }

  // HACK: old logic, but permissions handling is currently not really designed for a better frontend architecture.
  // TODO: can we inject this somehow to not depend on it that much?
  const showCsvDownloader = TokenAuth.getPermissions().export_facilities_as_csv

  return (
    <Card className="p-5">
      <Row className="page-header mt-1">
        <Col xl="auto" className="mb-3 mb-xl-0 mr-auto">
          <h2>{locale.heading}</h2>
        </Col>
        <Col xl="auto" className="mb-3 mb-xl-0 ml-auto">
          <Row className="my-0 flex-xl-nowrap">
            {showCsvDownloader && (
              <Col lg="5" xl="auto" className="mb-3 mb-xl-0">
                <FileExporter
                  sortBy={queryVariables.sortBy}
                  sortDirection={queryVariables.sortDirection}
                  queryString={queryVariables.queryString}
                  licenseOwnerIds={queryVariables.licenseOwnerIds}
                  collectorIds={queryVariables.collectorIds}
                  regionIds={queryVariables.regionIds}
                  trainingStatus={queryVariables.trainingStatus}
                  certificationEndDateRange={queryVariables.certificationEndDateRange}
                  workflowStates={queryVariables.workflowStates}
                  certificationTypes={queryVariables.certificationTypes}
                />
              </Col>
            )}
            <Col lg="7" xl="auto" className="flex-grow-1 flex-xl-grow-0 flex-xl-shrink-1 mb-3 mb-xl-0">
              <SearchQueryInput searchQuery={queryVariables.queryString} setSearchQuery={setQueryString} />
            </Col>
            <Col sm="auto" xl="auto" className="flex-grow-1 flex-xl-grow-0 flex-xl-shrink-1 mb-3 mb-xl-0">
              <SortingSwitcher
                type={queryVariables.sortBy}
                direction={queryVariables.sortDirection}
                setSortingOptions={setSortingOptions}
              />
            </Col>
            <Col sm="auto" className="flex-shrink-1">
              <FacilitiesFilter
                currentSearchFilters={omit(queryVariables, ['sortBy', 'sortDirection', 'queryString'])}
                setCurrentSearchFilters={setCurrentSearchFilters}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <hr className="seperator seperator-small border-gray" />
      <FilteredFacilitiesFromApi
        perPage={10}
        sortBy={queryVariables.sortBy}
        sortDirection={queryVariables.sortDirection}
        queryString={queryVariables.queryString}
        licenseOwnerIds={queryVariables.licenseOwnerIds}
        collectorIds={queryVariables.collectorIds}
        regionIds={queryVariables.regionIds}
        trainingStatus={queryVariables.trainingStatus}
        certificationEndDateRange={queryVariables.certificationEndDateRange}
        workflowStates={queryVariables.workflowStates}
        certificationTypes={queryVariables.certificationTypes}
      />
    </Card>
  )
}

FacilitiesTableWithFilter.propTypes = {
  locale: PropTypes.object.isRequired
}

export default withLocale(FacilitiesTableWithFilter, { key: 'facilitiesDashboard' })
