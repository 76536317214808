import { Card, CardBody, Container, FormGroup, Row } from 'reactstrap'

import PropTypes from 'prop-types'

const FormGroupContainer = ({ children, card = false }) => {
  const content = <Row>{children}</Row>

  return (
    <FormGroup>
      <Container>
        {card ? (
          <Card body>
            <CardBody>{content}</CardBody>
          </Card>
        ) : (
          content
        )}
      </Container>
    </FormGroup>
  )
}

FormGroupContainer.propTypes = {
  children: PropTypes.node.isRequired,
  card: PropTypes.bool
}

export { FormGroupContainer }
