import { Button, Col, Container, FormGroup, Row } from 'reactstrap'

import { faSave } from '@fortawesome/free-solid-svg-icons'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ValidatedInput from '../../../modules/inputs/validated-input'
// additional components
import RadioSwitch from '../../../modules/radio-switch/radio-switch.jsx'

const FormGroups = ({
  permissions,
  change,
  locale,
  role,
  submit,
  changeCheckbox,
  isCreate,
  changeName,
  toggleModal,
  variables,
  errors,
  onChange,
  changeable
}) => {
  const role_dsft = [
    {
      id: 'role.dsft',
      key: 'dsft',
      label: locale.dsft_permission,
      value: isCreate ? false : variables.role.dsft,
      checked: isCreate ? false : variables.role.dsft
    }
  ]

  return (
    <fieldset disabled={!changeable}>
      {/* <Alert color="warning">Diese Rechte-Gruppe kann nicht bearbeitet werden.</Alert> */}
      <FormGroup>
        <Container>
          <Row>
            <ValidatedInput
              id={'role.name'}
              variables={variables}
              errors={errors}
              locale={locale}
              onChange={onChange}
            />
          </Row>
        </Container>
      </FormGroup>
      <FormGroup>
        <Container>
          <Row>
            <Col sm={{ size: 6 }} lg={{ size: 4, offset: 2 }}>
              <RadioSwitch items={role_dsft} change={onChange} />
            </Col>
          </Row>
        </Container>
      </FormGroup>
      <FormGroup>
        <Container>
          <Row>
            {[...permissions]
              .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
              .map((permission, index) => (
                <Col key={index} sm={{ size: 6 }} lg={index % 2 === 0 ? { size: 4, offset: 2 } : { size: 4 }}>
                  <Permission key={index} permission={permission} change={onChange} isCreate={isCreate} />
                </Col>
              ))}
          </Row>
        </Container>
      </FormGroup>
      <FormGroup className="form-action">
        <Container>
          <Row>
            <Col sm="2">
              <a className="link-btn" onClick={toggleModal} href="#">
                {locale.cancel}
              </a>
            </Col>
            <Col sm={{ size: '4', offset: '6' }} className="text-right">
              <Button className="btn-labeled" color="primary-light" onClick={submit}>
                <FontAwesomeIcon className="icon-prepend" icon={faSave} />
                <span>{locale.save}</span>
              </Button>
            </Col>
          </Row>
        </Container>
      </FormGroup>
    </fieldset>
  )
}

const Permission = ({ permission, change, isCreate }) => {
  let permission_options = []
  let value = isCreate ? false : permission.permitted
  let checked = isCreate ? false : permission.permitted
  permission_options.push({
    key: permission.key,
    label: permission.name,
    hint: permission.description,
    value,
    checked
  })

  return <RadioSwitch key={permission.key} items={permission_options} change={change} />
}

export default FormGroups
