import { Component } from 'react'

import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './_loading.scss'

class Loading extends Component {
  render() {
    return (
      <div className="text-center">
        <FontAwesomeIcon
          icon={faSpinner}
          className={`text-primary${this.props.inline ? ' icon-prepend' : ''}`}
          size={this?.props?.size || '4x'}
          pulse
        />
        {this.props.inline ? (
          <span>{this?.props?.loadingText || `wird geladen...`}</span>
        ) : (
          <p>{this?.props?.loadingText || `wird geladen...`}</p>
        )}
      </div>
    )
  }
}

export default Loading
