import db from '../../../indexed-db/db'
import { deleteDownload } from './sync'

let storesToClear = [
  'Facilities',
  'SyncCreateFormDatum',
  'SyncUpdateFormDatum',
  'SyncDeleteFormDatum',
  'SyncCreateSectionDatum',
  'SyncUpdateSectionDatum',
  'SyncDeleteSectionDatum',
  'SyncReorderSectionDatum',
  'SyncCreateImage',
  'SyncUpdateImage',
  'SyncDeleteImage',
  'SyncReorderImage'
]

const removeOffline = async (facility, client) => {
  let promises = []

  if (!facility.download) {
    let offline_facility = await db.get('Facilities', facility.uuid)
    if (!offline_facility) return
    facility.download = offline_facility.download
  }

  storesToClear.forEach(store => promises.push(db.remove(store, facility.uuid)))

  return Promise.all(promises).then(() => deleteDownload(facility.download, facility.uuid, client))
}

export default removeOffline
