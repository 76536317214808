import { Fade } from 'reactstrap'

import Loading from 'components/helper/loading/loading'
import PropTypes from 'prop-types'

const LoadingOverlay = ({ loading }) => {
  return (
    <Fade in={loading} timeout={50} unmountOnExit>
      <div className="facilitiesTable__LoadingContainer">
        <Loading size="5x" />
      </div>
    </Fade>
  )
}

LoadingOverlay.propTypes = {
  loading: PropTypes.bool.isRequired
}

export default LoadingOverlay
