// additional components
import React from 'react'

import { Alert } from 'reactstrap'

// GraphQL
import { graphql, withApollo } from '@apollo/client/react/hoc'

import update from 'immutability-helper'

import { getSection, getSections, updateSection } from './sections.graphql'

import Loading from '../../../helper/loading/loading.jsx'
import FormGroups from './form_groups'

const SectionUpdate = ({ locale, history, client, toggleModal, data: { loading, error, section } }) => {
  if (loading) return <Loading />
  if (error) return <p>Error</p>

  return (
    <SectionUpdateForm
      client={client}
      history={history}
      locale={locale}
      possibleForms={section.possible_standard_forms}
      section={section}
      toggleModal={toggleModal}
    />
  )
}

class SectionUpdateForm extends React.Component {
  UNSAFE_componentWillMount = () => {
    const {
      id,
      form_version_id,
      name_de,
      name_en,
      missing_message_de,
      missing_message_en,
      additional_forms,
      standard_forms
    } = this.props.section
    let standard_form_ids = []
    let additional_form_ids = []
    standard_forms.map(form => standard_form_ids.push(form.id))
    additional_forms.map(form => additional_form_ids.push(form.id))
    this.setState({
      error: false,
      error_msg: '',
      variables: {
        id,
        section: {
          form_version_id,
          name_de,
          name_en,
          missing_message_de,
          missing_message_en,
          standard_form_ids,
          additional_form_ids
        }
      }
    })
  }

  changeStandard = id_list => {
    this.setState(
      update(this.state, {
        variables: { section: { standard_form_ids: { $set: id_list.split(',') } } }
      })
    )
  }

  changeAdditional = id_list => {
    this.setState(
      update(this.state, {
        variables: { section: { additional_form_ids: { $set: id_list.split(',') } } }
      })
    )
  }

  change = event => {
    this.setState(
      update(this.state, {
        variables: { section: { [event.target.id]: { $set: event.target.value } } }
      })
    )
  }

  gqlValidationError = error =>
    this.setState({
      error_msg: error.graphQLErrors[0].message,
      error: true
    })

  submit = () => {
    const { variables } = this.state
    const { client } = this.props
    const { gqlValidationError } = this

    client
      .mutate({
        mutation: updateSection,
        variables,
        refetchQueries: [
          {
            query: getSections,
            variables: {
              form_version_id: variables.section.form_version_id
            }
          }
        ]
      })
      .then(() => this.props.toggleModal())
      .catch(error => gqlValidationError(error))
  }

  render = () => {
    const { possibleForms, toggleModal, locale } = this.props
    const { section } = this.state.variables
    const { change, changeAdditional, changeStandard, submit } = this

    return (
      <React.Fragment>
        {this.state.error ? <Alert color="danger">{this.state.error_msg}</Alert> : ''}
        <FormGroups
          change={change}
          changeAdditional={changeAdditional}
          changeStandard={changeStandard}
          locale={locale}
          possibleForms={possibleForms}
          section={section}
          submit={submit}
          toggleModal={toggleModal}
        />
      </React.Fragment>
    )
  }
}

export default withApollo(
  graphql(getSection, {
    options: props => ({
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      variables: {
        id: props.id
      }
    })
  })(SectionUpdate)
)
