export default {
  keyFields: ['uuid'],
  fields: {
    images: {
      merge(existing, incoming) {
        return incoming
      }
    },
    form: {
      merge(existing, incoming) {
        return incoming
      }
    }
  }
}
