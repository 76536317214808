import React, { Component, Fragment } from 'react'

import RichTextEditor from 'react-rte'
import { Alert } from 'reactstrap'

import { withApollo } from '@apollo/client/react/hoc'

import update from 'immutability-helper'

import { getFacility } from '../../facility.graphql'
import { updateBaseData } from './information.graphql'

import { FormGroupContainerActions } from '../partials'

const TouristicData = props => (
  <div className="box">
    <Form {...props} />
  </div>
)

class Form extends Component {
  constructor(props) {
    super(props)
    const { base_data } = this.props.facility

    this.state = {
      processing: false,
      variables: {
        base_data: {
          overview_de: RichTextEditor.createValueFromString(base_data?.overview?.de || '', 'html'),
          overview_en: RichTextEditor.createValueFromString(base_data?.overview?.en || '', 'html'),
          accessibility_at_a_glance_de: RichTextEditor.createValueFromString(
            base_data?.accessibility_at_a_glance?.de || '',
            'html'
          ),
          accessibility_at_a_glance_en: RichTextEditor.createValueFromString(
            base_data?.accessibility_at_a_glance?.en || '',
            'html'
          )
        }
      }
    }
  }

  onError = ({ graphQLErrors }) =>
    this.setState({
      processing: false,
      error_msg: graphQLErrors[0].message,
      errors: graphQLErrors[0].errors
    })

  onSuccess = () =>
    this.setState(
      {
        processing: false,
        error_msg: '',
        errors: {}
      },
      this.props.hideEditBoxRight()
    )

  submit = () => {
    this.setState({ processing: true })
    const {
      props: {
        client,
        facility: { uuid }
      },
      state: {
        variables: { base_data }
      }
    } = this

    client
      .mutate({
        mutation: updateBaseData,
        variables: {
          uuid,
          base_data: {
            overview_de: base_data.overview_de.toString('html'),
            overview_en: base_data.overview_en.toString('html'),
            accessibility_at_a_glance_de: base_data.accessibility_at_a_glance_de.toString('html'),
            accessibility_at_a_glance_en: base_data.accessibility_at_a_glance_en.toString('html')
          }
        },
        refetchQueries: [
          {
            query: getFacility,
            variables: {
              uuid,
              version_number: this.props.match.params.version_number || ''
            }
          }
        ],
        awaitRefetchQueries: true
      })
      .then(() => this.onSuccess())
      .catch(error => this.onError(error))
  }

  onChange = (id, value) =>
    this.setState(
      update(this.state, {
        variables: {
          base_data: {
            [id]: {
              $set: value
            }
          }
        }
      })
    )

  render = () => {
    const {
      onChange,
      submit,
      props: { locale, hideEditBoxRight },
      state: { errors, error_msg, variables, processing }
    } = this
    const confidential_base_data_keys = [
      'overview_de',
      'overview_en',
      'accessibility_at_a_glance_de',
      'accessibility_at_a_glance_en'
    ]
    const actions_props = { locale, hideEditBoxRight, processing, submit }

    return (
      <React.Fragment>
        {error_msg && <Alert color="danger">{error_msg}</Alert>}
        {confidential_base_data_keys.map((confidential_base_data_key, i) => (
          <RTE
            key={i}
            id={confidential_base_data_key}
            locale={locale}
            onChange={onChange}
            value={variables.base_data[confidential_base_data_key]}
          />
        ))}
        <FormGroupContainerActions {...actions_props} />
      </React.Fragment>
    )
  }
}

class RTE extends Component {
  onChange = value => this.props.onChange(this.props.id, value)
  toolbarConfig = () => ({
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
      { label: 'Fett', style: 'BOLD' },
      { label: 'Kursiv', style: 'ITALIC' },
      { label: 'Durchgestrichen', style: 'STRIKETHROUGH' },
      { label: 'Unterstrichen', style: 'UNDERLINE' }
    ],
    BLOCK_TYPE_DROPDOWN: [
      { label: 'Text', style: 'unstyled' },
      { label: 'Überschrift H1', style: 'header-one' },
      { label: 'Überschrift H2', style: 'header-two' },
      { label: 'Überschrift H3', style: 'header-three' },
      { label: 'Überschrift H4', style: 'header-four' },
      { label: 'Überschrift H5', style: 'header-five' }
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: 'Liste (unsortiert)', style: 'unordered-list-item' },
      { label: 'Liste (sortiert)', style: 'ordered-list-item' },
      { label: 'Blockzitat', style: 'blockquote' }
    ]
  })

  render = () => {
    const {
      onChange,
      toolbarConfig,
      props: { locale, value, id }
    } = this
    return (
      <Fragment>
        <h5>{locale.base_data[id]}</h5>
        <RichTextEditor onChange={onChange} toolbarConfig={toolbarConfig()} value={value} />
        <br />
      </Fragment>
    )
  }
}

export default withApollo(TouristicData)
