import React from 'react'

import { Col, Container, Form, FormGroup, Row } from 'reactstrap'

import { withApollo } from '@apollo/client/react/hoc'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'

import { deepNestInObjectsWithKeys } from 'helpers/object'
import update from 'immutability-helper'

import { deletePartnerGatewayUser, getPartnerGatewayUsers } from './partner_gateway_users.graphql'

import { ProcessingButton } from '../../../modules/buttons/processing-button'

class FormCreate extends React.Component {
  constructor(props) {
    super(props)

    const defaultState = {
      processing: false
    }

    this.state = defaultState
  }

  onChange = event => {
    const fragments = event.target.id.split('.')
    const variables = deepNestInObjectsWithKeys(event.target.value, ...fragments, '$set')

    this.setState(update(this.state, { variables }))
  }

  onError = ({ graphQLErrors }) =>
    this.setState({
      processing: false,
      error_msg: graphQLErrors[0].message,
      errors: graphQLErrors[0].errors
    })

  submit = () => {
    this.setState({ processing: true })
    let { variables } = this.state

    this.props.client
      .mutate({
        mutation: deletePartnerGatewayUser,
        variables: {
          id: this.props.partner_gateway_user.id
        },
        refetchQueries: [
          {
            query: getPartnerGatewayUsers
          }
        ]
      })
      .then(result =>
        this.setState({
          processing: false,
          error_msg: ''
        })
      )
      .then(_ => this.props.toggleModal())
      .catch(error => this.onError(error))
  }

  render() {
    const { locale, toggleModal, partner_gateway_user } = this.props
    const { processing } = this.state
    const { submit } = this
    return (
      <Form>
        <FormGroup>
          <p>
            Möchten Sie{' '}
            <span className={'text-danger'}>
              <strong>{partner_gateway_user.name}</strong>
            </span>{' '}
            wirklich entfernen?
          </p>
        </FormGroup>

        <FormGroup className="form-action">
          <Container>
            <Row>
              <Col sm="5">
                <a className="link-btn" onClick={toggleModal} href="#">
                  {locale.cancel}
                </a>
              </Col>
              <Col sm="7" className="text-right">
                <ProcessingButton
                  onClick={submit}
                  label={'löschen'}
                  icon={faTrashAlt}
                  removebutton={true}
                  processing={processing}
                />
              </Col>
            </Row>
          </Container>
        </FormGroup>
      </Form>
    )
  }
}

export default withApollo(FormCreate)
