/*
 * migration from IDB version 3 to 4
 *
 * add field change_provider to facility.permissions
 * add field create_provider to facility.permissions
 * add field update_provider to facility.permissions
 *
 * add field email to each entry of facility.possible_collectors
 * add field email to each entry of facility.possible_contact_users
 * add field email to each entry of facility.possible_license_owner_users
 * add field email to each entry of facility.possible_dsft_users
 *
 * add field provider to facility
 * add field possible_providers to facility
 */

const migrate = facility => ({
  ...facility,
  permissions: {
    change_provider: false,
    create_provider: false,
    update_provider: false,
    ...facility.permissions
  },
  possible_collectors: addEmailToUsers(facility.possible_collectors),
  possible_contact_users: addEmailToUsers(facility.possible_contact_users),
  possible_license_owner_users: addEmailToUsers(facility.possible_license_owner_users),
  possible_dsft_users: addEmailToUsers(facility.possible_dsft_users),
  provider: null,
  possible_providers: []
})

const addEmailToUsers = users =>
  users.map(user => ({
    email: '',
    ...user
  }))

export default migrate
