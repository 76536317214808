import { openDB } from 'idb'

import { migrateStoreEntry } from './migrate'

/**
 * IndexDB API
 * use this custom IDB API to update, delete or get data from the IDB with JS Promises
 *
 *  usage e.g.
 *
 *  let db = new IndexedDB_API();
 *
 *  //get a Facility by its uuid
 *  db.get('Facilities', facility_uuid).then(facility => doSomeThingWith(facility))
 *
 *  or
 *
 *  //get all Facilities
 *  db.getAll('Facilities').then(facilities => doSomeThingWith(facilities))
 *
 */

export default class IndexedDB_API {
  constructor() {
    this.VERSION = __IDB_VERSION__ // bump version to upgrade IDB on installed devices; use the current timestamp (`date +%Y%m%d%H%M%S`) for the version to correctly merge migrations; write a migration to update existing data
    this.IDB_NAME = 'offline'
    let stores = [
      'Facilities', // Facilities that are offline
      'Forms', // Facilities that are offline
      'SyncCreateFormDatum', // new FormDatum is stored here
      'SyncUpdateFormDatum', // edits on a FormDatum
      'SyncDeleteFormDatum', // deleted FormData
      'SyncCreateSectionDatum', // new SectionDatum
      'SyncUpdateSectionDatum', // edits on a SectionDatum
      'SyncDeleteSectionDatum', // deleted SectionData
      'SyncReorderSectionDatum', // reordered SectionData
      'SyncCreateImage', // created Images
      'SyncUpdateImage', // updated Images
      'SyncDeleteImage', // deleted Images
      'SyncReorderImage' // reordered Images
    ]
    this.stores = stores

    this.dbPromise = openDB(this.IDB_NAME, this.VERSION, {
      upgrade(upgradeDB, oldVersion, newVersion, transaction) {
        if (oldVersion && newVersion != oldVersion) {
          const findOrCreateStore = (name, transaction, upgradeDB) => {
            try {
              return transaction.objectStore(name)
            } catch (error) {
              if (error.name == 'NotFoundError') {
                console.log('creating idb objectstore:', name)
                return upgradeDB.createObjectStore(name)
              } else {
                throw error
              }
            }
          }

          let migrationStore = findOrCreateStore('_migrations', transaction, upgradeDB)

          stores.forEach(name => {
            console.log('updating store', name)
            let store = findOrCreateStore(name, transaction, upgradeDB)

            store.getAllKeys().then(keys =>
              keys.forEach(async (key, index) => {
                const existingMigrations = await migrationStore.getAllKeys()

                return store.get(key).then(
                  migrateStoreEntry({
                    store: {
                      name,
                      objectStore: store,
                      migrationStore,
                      existingMigrations
                    },
                    key,
                    index
                  })
                )
              })
            )
          })
        } else {
          stores.map(store => {
            console.log('creating idb objectstore:', store)
            upgradeDB.createObjectStore(store)
          })
        }
      }
    })
  }

  //Generic Functions to access indexedDB Data via Promise Transactions

  async get(store, key) {
    return (await this.dbPromise).get(store, key)
  }

  async getAll(store) {
    return (await this.dbPromise).getAll(store)
  }

  async set(store, key, val) {
    return (await this.dbPromise).put(store, val, key)
  }

  async remove(store, key) {
    return (await this.dbPromise).delete(store, key)
  }

  async clear(store) {
    return (await this.dbPromise).clear(store)
  }

  async keys(store) {
    return (await this.dbPromise).getAllKeys(store)
  }
}
