import { Fragment, useState } from 'react'

import { withRouter } from 'react-router-dom'
import RichTextEditor from 'react-rte'
import { Alert, Col, Form } from 'reactstrap'

import { ProcessingButton } from 'components/modules/buttons/processing-button'
import ValidatedInput from 'components/modules/inputs/validated-input'
import { FormGroupContainer } from 'components/views/facility/information/partials'
import { withLocale } from 'locale'

import { editVariable, getVariables } from '../../short_report_variables.graphql'

import CertificateConfigurationForm from './certificateConfigurationForm'
import CertificateStatesForm from './certificateStatesForm'
import FacilityKindSelection from './facilityKindSelection'
import TextTemplateForm from './textTemplateForm'

const stripEmptyHtml = value => ['<p><br></p>', '\n'].reduce((val, substr) => val?.replace(substr, ''), value || '')

const VariableForm = ({ facility_short_report_variable, locale, client, history, query, form_id }) => {
  const [processing, setProcessing] = useState(false)
  const [successful, setSuccessful] = useState(false)

  const [variable, setVariable] = useState({
    ...facility_short_report_variable,
    textTemplate: RichTextEditor.createValueFromString(facility_short_report_variable.textTemplate || '', 'html'),
    form_id: form_id || facility_short_report_variable?.form?.id,
    facilityKindIds: facility_short_report_variable?.facilityKinds?.map(({ id }) => id) || []
  })

  const [errors, setErrors] = useState({
    lastQueryVariables: variable
  })

  const [errorMessage, setErrorMessage] = useState(null)

  const onChange = e => {
    const newVariable = {
      ...variable,
      [e.target.id]: e.target.value
    }

    setVariable(newVariable)
  }

  const changeFacilityKindIds = listOfNewIds => {
    setVariable({
      ...variable,
      facilityKindIds: listOfNewIds
    })
  }

  const updateCertificateConfiguration = ({ guestGroupKey, level, enabled }) => {
    const certificateConfigurations = variable.certificateConfigurations.map(config => {
      if (config.certificateGuestGroup.key === guestGroupKey && config.level === level) {
        return {
          ...config,
          enabled
        }
      }

      return config
    })

    setVariable({
      ...variable,
      certificateConfigurations
    })
  }

  const save = e => {
    e.preventDefault()
    setProcessing(true)
    setSuccessful(false)
    client
      .mutate({
        mutation: query,
        variables: {
          variable: {
            ...variable,
            id: undefined,
            kind: undefined,
            position: undefined,
            textTemplate: stripEmptyHtml(variable.textTemplate?.toString('html')),
            facilityKinds: undefined,
            certificateConfigurations: variable.certificateConfigurations.map(
              ({ __typename, certificateGuestGroup, ...keepAttrs }) => ({
                certificateGuestGroupKey: certificateGuestGroup.key,
                ...keepAttrs
              })
            ),
            __typename: undefined
          },
          id: variable.id
        },
        refetchQueries: [
          { query: getVariables },
          variable.id ? { query: editVariable, variables: { id: variable.id } } : null
        ].filter(el => el !== null)
      })
      .then(({ data }) => {
        if (!variable.id) {
          history.push(`/form-kit/short-report-variables/${data.create_facility_short_report_variable.id}`)
        } else {
          setErrors({})
          setErrorMessage(null)
          setProcessing(false)
          setSuccessful(true)
        }
      })
      .catch(({ graphQLErrors }) => {
        if (graphQLErrors && graphQLErrors[0]) {
          if (graphQLErrors[0].errors) {
            setErrors({
              ...graphQLErrors[0].errors?.variable,
              lastQueryVariables: variable
            })
          }

          setErrorMessage(graphQLErrors[0].message)
        }
        setProcessing(false)
      })
  }

  const inputProps = { variables: variable, locale: locale.short_report.variable, errors, onChange }

  return (
    <Fragment>
      <Form onSubmit={save}>
        <fieldset disabled={processing}>
          <FormGroupContainer>
            <Col sm={12}>
              <SubmitStateMessage error={errorMessage} {...{ successful, setSuccessful }} />
            </Col>
          </FormGroupContainer>

          <FormGroupContainer card>
            <Col sm={12}>
              <h3 className="mb-5">{locale.short_report.variable.sections.common}</h3>
            </Col>
            <ValidatedInput id="name" {...inputProps} />
            <ValidatedInput id="description" type="textarea" {...inputProps} />
          </FormGroupContainer>

          <FormGroupContainer card>
            <Col sm={12}>
              <h3 className="mb-5">{locale.short_report.variable.sections.facilityKinds}</h3>
            </Col>
            <Col sm={12}>
              <Alert color="danger" isOpen={errors?.facility_kind_ids !== undefined}>
                {errors.facility_kind_ids}
              </Alert>
              <FacilityKindSelection
                selectedFacilityKindIds={variable.facilityKindIds}
                {...{ changeFacilityKindIds }}
              />
            </Col>
          </FormGroupContainer>

          <FormGroupContainer card>
            <Col sm={12}>
              <h3 className="mb-5">{locale.short_report.variable.sections.certificateConfig}</h3>
            </Col>
            <CertificateStatesForm missing={variable.certificateStateMissing} onChange={onChange} />
            <Col xs={12}>
              <hr />
            </Col>
            <Col xs={12}>
              <CertificateConfigurationForm
                certificateConfigurations={variable.certificateConfigurations}
                {...{ updateCertificateConfiguration }}
              />
            </Col>
          </FormGroupContainer>

          <FormGroupContainer card>
            <Col sm={12}>
              <h3>{locale.short_report.variable.sections.textTemplate}</h3>
            </Col>
            <TextTemplateForm
              value={variable.textTemplate}
              error={errors?.text_template}
              // constructing synthetic event to piggyback off of the existing onChange handler
              onChange={value => onChange({ target: { id: 'textTemplate', value } })}
            />
          </FormGroupContainer>

          <FormGroupContainer>
            <Col sm={12} className="mb-3">
              <SubmitStateMessage error={errorMessage} {...{ successful, setSuccessful }} />
            </Col>
            <Col sm={12} className="text-right">
              <ProcessingButton processing={processing} type="submit" onClick={save} label={locale.save} />
            </Col>
          </FormGroupContainer>
        </fieldset>
      </Form>
    </Fragment>
  )
}

const SubmitStateMessage = withLocale(
  ({ error, successful, setSuccessful, locale }) => {
    const toggle = error ? undefined : () => setSuccessful(false)
    const color = error ? 'danger' : 'success'

    return (
      <Alert color={color} isOpen={error || successful} {...{ toggle }}>
        {error || locale.updated_successfully}
      </Alert>
    )
  },
  { key: 'short_report.variable.actions' }
)

export default withLocale(withRouter(VariableForm))
