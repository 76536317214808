import {
  createFacilityImage,
  deleteImage,
  reorderImages,
  reorderSectionDatum,
  updateImage
} from '../../../views/facility/facility.graphql'
import {
  createFormDatum,
  delete_form_datum,
  updateFormDatum
} from '../../../views/facility/form-datum/form-datum.graphql'
import { createFormDatumImage } from '../../../views/facility/form-datum/images/images.graphql'
import { createSectionDatumImage } from '../../../views/facility/sections/images/images.graphql'
import {
  createSectionDatum,
  delete_section_datum,
  updateSectionDatum
} from '../../../views/facility/sections/sections.graphql'

import createImageResolver from './create_image_resolver'

/**
 * sync configuration defining stores and queries to be used
 * sync queries will be executed in the order of the array
 *
 * @param {string} store -
 * @param {(object|function)} mutation - The mutation Object to be used for sync; a function will be resolved with one object from the sync store
 * @param {function} variableResolver - Resolver function which should return the variables object for the sync query. Receives one sync object
 */
const config = [
  { store: 'SyncCreateSectionDatum', mutation: createSectionDatum },
  { store: 'SyncUpdateSectionDatum', mutation: updateSectionDatum },
  { store: 'SyncReorderSectionDatum', mutation: reorderSectionDatum },
  { store: 'SyncCreateFormDatum', mutation: createFormDatum },
  { store: 'SyncUpdateFormDatum', mutation: updateFormDatum },
  {
    store: 'SyncCreateImage',
    mutation: syncObject =>
      ({
        createFacilityImage,
        createSectionDatumImage,
        createFormDatumImage
      }[syncObject.operationName]),
    variableResolver: createImageResolver
  },
  { store: 'SyncUpdateImage', mutation: updateImage },
  { store: 'SyncReorderImage', mutation: reorderImages },
  { store: 'SyncDeleteImage', mutation: deleteImage },
  { store: 'SyncDeleteFormDatum', mutation: delete_form_datum },
  { store: 'SyncDeleteSectionDatum', mutation: delete_section_datum }
]

export default config
