import gql from 'graphql-tag'

export const query = gql`
  query dsft_users($id: ID!) {
    license_owner(id: $id) {
      id
      possible_default_dsft_users {
        full_name
        id
      }
    }
  }
`
