import PropTypes from 'prop-types'

import GuestGroupLevelChooser from './certificateConfigurationForm/guestGroupLevelChooser'

// HACK: TODO: these should probably be constants defined in a fitting global context/scope
const guestGroupOrder = ['walking', 'wheelchair', 'partially_deaf', 'deaf', 'partially_visual', 'visual', 'mental']

const CertificateConfigurationForm = ({ certificateConfigurations, updateCertificateConfiguration }) => {
  const guestGroupConfigs = Object.groupBy(
    certificateConfigurations,
    ({ certificateGuestGroup }) => certificateGuestGroup.key
  )

  return guestGroupOrder
    .map(guestGroupKey => (
      <GuestGroupLevelChooser
        key={guestGroupKey}
        guestGroupConfigs={guestGroupConfigs[guestGroupKey]}
        {...{ guestGroupKey, updateCertificateConfiguration }}
      />
    ))
    .filter(Boolean)
}

CertificateConfigurationForm.propTypes = {
  certificateConfigurations: PropTypes.arrayOf(
    PropTypes.shape({
      certificateGuestGroup: PropTypes.shape({
        key: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired
      }).isRequired,
      level: PropTypes.string.isRequired,
      enabled: PropTypes.bool.isRequired
    })
  ).isRequired,
  updateCertificateConfiguration: PropTypes.func.isRequired
}

export default CertificateConfigurationForm
