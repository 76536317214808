import React, { Fragment } from 'react'

import Geocode from 'react-geocode'
import { Alert, Col, Label } from 'reactstrap'

import { withApollo } from '@apollo/client/react/hoc'

import { deepNestInObjectsWithKeys } from 'helpers/object'
import update from 'immutability-helper'

import { getFacility } from '../../facility.graphql'
import { updateAddress } from './information.graphql'

import { getPropertyFromObject } from '../../../../helper/helper-functions'
import { ProcessingButton } from '../../../../modules/buttons/processing-button'
import GoogleMap from '../../../../modules/google-map/google-map'
import ValidatedInformationInput from '../../../../modules/inputs/validated-information-input'
import CountrySelect from '../../../../modules/selects/countries'
import DynamicSelect from '../../../../modules/selects/dynamicSelect'
import StateSelect from '../../../../modules/selects/states'
import { FormGroupContainer, FormGroupContainerActions } from '../partials'

Geocode.setApiKey('AIzaSyD5xGA7_TeoOOX-W-NSPfDD_gXKeuXmV3c')

const AddressForm = props => (
  <div className="box">
    <Form {...props} />
  </div>
)

class Form extends React.Component {
  constructor(props) {
    super(props)

    const { base_data } = this.props.facility
    this.state = {
      processing: false,
      geocoding: false,
      variables: {
        uuid: this.props.facility.uuid,
        base_data: {
          region_ids: base_data?.regions?.map(region => region.id) || [],
          widget_filter_ids: base_data?.widget_filters?.map(widget_filter => widget_filter.id) || [],
          address: {
            city: base_data?.address?.city || '',
            country_code: base_data?.address?.country?.key || 'DE',
            state: base_data?.address?.state?.key || '',
            street: base_data?.address?.street || '',
            street_addition: base_data?.address?.street_addition || '',
            lat: base_data?.address?.lat || '',
            lon: base_data?.address?.lon || '',
            zipcode: base_data?.address?.zipcode || ''
          }
        }
      }
    }
  }

  onChangeRegions = region_ids => {
    this.setState(
      update(this.state, {
        variables: {
          base_data: {
            region_ids: {
              $set: region_ids.split(',')
            }
          }
        }
      })
    )
  }

  onChangeWidgetFilters = widget_filter_ids => {
    this.setState(
      update(this.state, {
        variables: {
          base_data: {
            widget_filter_ids: {
              $set: widget_filter_ids.split(',')
            }
          }
        }
      })
    )
  }

  onChange = event => {
    return new Promise(resolve => {
      const fragments = event.target.id.split('.')

      const variables = deepNestInObjectsWithKeys(event.target.value, ...fragments, '$set')

      this.setState(update(this.state, { variables }), resolve)
    })
  }

  addressChange = e =>
    this.onChange(e).then(() => {
      let newState = this.state
      newState.variables.base_data.address.lon = ''
      newState.variables.base_data.address.lat = ''
      this.setState(newState)
    })

  geocode = () =>
    new Promise(resolve => {
      this.setState({ geocoding: true })
      let {
        variables: {
          base_data: { address }
        }
      } = this.state
      let addressString = [address.street, address.street_addition, address.city, address.zipcode, address.country_code]
        .filter(n => n)
        .join()

      Geocode.fromAddress(addressString)
        .then(response => {
          let newState = this.state
          if (response.results.length) {
            let { lat, lng: lon } = response.results[0].geometry.location
            newState.variables.base_data.address.lon = lon
            newState.variables.base_data.address.lat = lat
          } else {
            newState.variables.base_data.address.lon = ''
            newState.variables.base_data.address.lat = ''
          }
          newState.geocoding = false
          this.setState(newState, resolve)
        })
        .catch(resolve)
    })

  onError = ({ graphQLErrors }) =>
    this.setState({
      processing: false,
      error_msg: graphQLErrors[0].message,
      errors: graphQLErrors[0].errors
    })

  onSuccess = () =>
    this.setState(
      {
        processing: false,
        error_msg: '',
        errors: {}
      },
      this.props.hideEditBoxRight()
    )

  submit = () => {
    this.setState({ processing: true })
    const { client } = this.props
    const { variables } = this.state
    let geocode =
      !variables.base_data.address.lat && !variables.base_data.address.lon && variables.base_data.address.street
    let promise = geocode ? this.geocode() : Promise.resolve()

    promise.then(() => {
      variables.base_data.address.lat = parseFloat(variables.base_data.address.lat) || null
      variables.base_data.address.lon = parseFloat(variables.base_data.address.lon) || null

      client
        .mutate({
          mutation: updateAddress,
          variables,
          refetchQueries: [
            {
              query: getFacility,
              variables: {
                uuid: this.props.facility.uuid,
                version_number: this.props.match.params.version_number || ''
              }
            }
          ],
          awaitRefetchQueries: true
        })
        .then(() => this.onSuccess())
        .catch(error => this.onError(error))
    })
  }

  render = () => {
    const {
      onChange,
      submit,
      onChangeRegions,
      onChangeWidgetFilters,
      addressChange,
      geocode,
      props: { locale, hideEditBoxRight },
      state: { variables, errors, error_msg, processing, geocoding }
    } = this

    const input_props = { errors, variables, locale, onChange }
    const actions_props = { locale, hideEditBoxRight, processing, submit }
    return (
      <React.Fragment>
        {error_msg && <Alert color="danger">{error_msg}</Alert>}
        <FormGroupContainer
          children={
            <ValidatedInformationInput id={'base_data.address.street'} {...input_props} onChange={addressChange} />
          }
        />
        <FormGroupContainer
          children={
            <ValidatedInformationInput
              id={'base_data.address.street_addition'}
              {...input_props}
              onChange={addressChange}
            />
          }
        />
        <FormGroupContainer
          children={
            <ValidatedInformationInput id={'base_data.address.zipcode'} {...input_props} onChange={addressChange} />
          }
        />
        <FormGroupContainer
          children={<ValidatedInformationInput id={'base_data.address.city'} {...input_props} />}
          onChange={addressChange}
        />

        <FormGroupContainer
          children={
            <Fragment>
              <Col lg="5" className="text-lg-right">
                <Label className="control-label" for={'base_data.region_ids'}>
                  {getPropertyFromObject(locale, 'base_data.region_ids')}
                </Label>
              </Col>
              <Col lg="7">
                <DynamicSelect
                  simpleValue
                  isClearable
                  isMulti
                  id={'base_data.region_ids'}
                  variables={variables}
                  errors={errors}
                  locale={locale}
                  onChange={onChangeRegions}
                  options={this.props.facility.base_data.possible_regions.map(possible_region => ({
                    value: possible_region.id,
                    label: possible_region.name_de
                  }))}
                />
              </Col>
            </Fragment>
          }
        />

        <FormGroupContainer
          children={
            <Fragment>
              <Col lg="5" className="text-lg-right">
                <Label className="control-label" for={'base_data.widget_filter_ids'}>
                  {getPropertyFromObject(locale, 'base_data.widget_filter_ids')}
                </Label>
              </Col>
              <Col lg="7">
                <DynamicSelect
                  simpleValue
                  isClearable
                  isMulti
                  id={'base_data.widget_filter_ids'}
                  variables={variables}
                  errors={errors}
                  locale={locale}
                  onChange={onChangeWidgetFilters}
                  options={this.props.facility.base_data.possible_widget_filters.map(possible_widget_filter => ({
                    value: possible_widget_filter.id,
                    label: possible_widget_filter.name_de
                  }))}
                />
              </Col>
            </Fragment>
          }
        />

        <FormGroupContainer
          children={
            <Fragment>
              <Col lg="5" className="text-lg-right">
                <Label className="control-label" for={'base_data.address.country_code'}>
                  {getPropertyFromObject(locale, 'base_data.address.country_code')}
                </Label>
              </Col>
              <Col lg="7">
                <CountrySelect
                  id={'base_data.address.country_code'}
                  variables={variables}
                  errors={errors}
                  locale={locale}
                  onChange={addressChange}
                />
              </Col>
            </Fragment>
          }
        />
        <FormGroupContainer
          children={
            <Fragment>
              <Col lg="5" className="text-lg-right">
                <Label className="control-label" for={'base_data.address.state'}>
                  {getPropertyFromObject(locale, 'base_data.address.state')}
                </Label>
              </Col>
              <Col lg="7">
                <StateSelect
                  id={'base_data.address.state'}
                  variables={variables}
                  errors={errors}
                  locale={locale}
                  onChange={addressChange}
                  current_country={variables?.base_data?.address?.country_code || 'DE'}
                />
              </Col>
            </Fragment>
          }
        />

        <FormGroupContainer children={<ValidatedInformationInput id={'base_data.address.lat'} {...input_props} />} />
        <FormGroupContainer children={<ValidatedInformationInput id={'base_data.address.lon'} {...input_props} />} />
        <FormGroupContainer>
          <Col lg={{ size: 7, offset: 5 }}>
            <ProcessingButton
              processing={geocoding}
              onClick={geocode}
              label={locale.base_data.address.geocode}
              no_icon
            />
          </Col>
        </FormGroupContainer>
        {variables.base_data.address.lon && variables.base_data.address.lat && (
          <FormGroupContainer>
            <Col lg={{ size: 7, offset: 5 }}>
              <div className="w-100 address-map-container">
                <GoogleMap lat={variables.base_data.address.lat} lon={variables.base_data.address.lon} />
              </div>
            </Col>
          </FormGroupContainer>
        )}
        <FormGroupContainerActions {...actions_props} />
      </React.Fragment>
    )
  }
}

export default withApollo(AddressForm)
