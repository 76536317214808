import React from 'react'

import { Alert } from 'reactstrap'

// GraphQL
import { graphql, withApollo } from '@apollo/client/react/hoc'

import { deepNestInObjectsWithKeys } from 'helpers/object'
import update from 'immutability-helper'

import { getUser, getUsers, updateUser } from './users.graphql'

import Loading from '../../../helper/loading/loading.jsx'
// additional components
import FormGroups from './form'

const UserUpdate = ({ locale, history, client, toggleModal, data: { loading, error, user } }) => {
  if (loading) return <Loading />
  if (error) return <p>Error</p>
  return (
    <UserForm user={user} client={client} id={user.id} history={history} locale={locale} toggleModal={toggleModal} />
  )
}

class UserForm extends React.Component {
  constructor(props) {
    super(props)
    const { user, id } = this.props
    this.state = {
      variables: {
        user: {
          address: {
            street: user?.address?.street || '',
            street_addition: user?.address?.street_addition || '',
            zipcode: user?.address?.zipcode || '',
            city: user?.address?.city || '',
            state: user?.address?.state?.key || '',
            country_code: user?.address?.country?.key || 'DE'
          },
          notes: user?.notes || '',
          info: {
            more_info: user?.info?.more_info || ''
          },
          phone: user?.phone || '',
          collects_for_license_owner_ids: user?.collects_for_license_owners
            .map(license_owner => license_owner.id)
            .join(','),
          email: user?.email || '',
          email_notifications: user?.email_notifications || false,
          first_name: user?.first_name || '',
          last_name: user?.last_name || '',
          salutation: user?.salutation?.key || 'mr',
          collector_id: user?.collector_id || '',
          institution: user?.institution || '',
          license_owner_id: user?.license_owner?.id || '',
          role_id: user?.role?.id,
          password: user?.password || '',
          password_confirmation: user?.password_confirmation || '',
          collector: user?.collector || false,
          status: user?.status?.key || 'not_certified'
        },
        id: user.id
      }
    }
  }

  gqlValidationError = error =>
    this.setState({
      error_msg: error.graphQLErrors[0].message,
      error: true
    })

  changeNotification = () =>
    this.setState(
      update(this.state, {
        variables: {
          user: {
            email_notifications: {
              $set: !this.state.variables.user.email_notifications
            }
          }
        }
      })
    )

  changeCollector = () =>
    this.setState(
      update(this.state, {
        variables: {
          user: {
            collector: {
              $set: !this.state.variables.user.collector
            }
          }
        }
      })
    )

  changeAddress = event => {
    this.setState(
      update(this.state, {
        variables: {
          user: {
            address: {
              [event.target.id]: {
                $set: event.target.value
              }
            }
          }
        }
      })
    )
  }
  changeInfo = event => {
    this.setState(
      update(this.state, {
        variables: {
          user: {
            info: {
              [event.target.id]: {
                $set: event.target.value
              }
            }
          }
        }
      })
    )
  }

  change = event => {
    this.setState(
      update(this.state, {
        variables: {
          user: {
            [event.target.id]: {
              $set: event.target.value
            }
          }
        }
      })
    )
  }
  changeSalutation = salutation => {
    this.setState(
      update(this.state, {
        variables: {
          user: {
            salutation: {
              $set: salutation.value
            }
          }
        }
      })
    )
  }
  changeLicenseOwner = license_owner => {
    this.setState(
      update(this.state, {
        variables: {
          user: {
            ['license_owner_id']: {
              $set: license_owner.value
            }
          }
        }
      })
    )
  }
  changeState = state => {
    this.setState(
      update(this.state, {
        variables: {
          user: {
            address: {
              state: {
                $set: state.value
              }
            }
          }
        }
      })
    )
  }
  changeStatus = status => {
    this.setState(
      update(this.state, {
        variables: {
          user: {
            status: {
              $set: status.value
            }
          }
        }
      })
    )
  }
  changeCountry = country => {
    this.setState(
      update(this.state, {
        variables: {
          user: {
            address: {
              country_code: {
                $set: country.value
              }
            }
          }
        }
      })
    )
  }
  changeLicenseOwnerToCollectFor = id_string => {
    this.setState(
      update(this.state, {
        variables: {
          user: {
            collects_for_license_owner_ids: {
              $set: id_string
            }
          }
        }
      })
    )
  }
  changeRole = role => {
    this.setState(
      update(this.state, {
        variables: {
          user: {
            ['role_id']: {
              $set: role.value
            }
          }
        }
      })
    )
  }

  submit = () => {
    let { variables } = this.state

    //api expects array but select is giving us stringlist
    if (!Array.isArray(variables.user.collects_for_license_owner_ids)) {
      variables.user.collects_for_license_owner_ids = variables.user.collects_for_license_owner_ids
        .split(',')
        .filter(item => item !== '')
    }

    //no collector
    if (!variables.user.collector) {
      variables.user.collector_id = ''
      variables.user.status = 'not_certified'
      variables.user.collects_for_license_owner_ids = []
    }

    this.props.client
      .mutate({
        mutation: updateUser,
        variables,
        refetchQueries: [
          {
            query: getUsers
          }
        ]
      })
      .then(() => this.props.toggleModal())
      .catch(error => this.onError(error))
  }

  onChange = event => {
    const fragments = event.target.id.split('.')
    const variables = deepNestInObjectsWithKeys(event.target.value, ...fragments, '$set')

    this.setState(update(this.state, { variables }))
  }

  onError = ({ graphQLErrors }) =>
    this.setState({
      error_msg: graphQLErrors[0].message,
      errors: graphQLErrors[0].errors
    })

  render = () => (
    <React.Fragment>
      {this.state.errors && <Alert color="danger">{this.state.error_msg}</Alert>}
      <FormGroups
        change={this.change}
        changeAddress={this.changeAddress}
        changeCollector={this.changeCollector}
        changeCountry={this.changeCountry}
        changeInfo={this.changeInfo}
        changeLicenseOwner={this.changeLicenseOwner}
        changeLicenseOwnerToCollectFor={this.changeLicenseOwnerToCollectFor}
        changeNotification={this.changeNotification}
        changeRole={this.changeRole}
        changeSalutation={this.changeSalutation}
        changeState={this.changeState}
        changeStatus={this.changeStatus}
        is_create={false}
        locale={this.props.locale}
        submit={this.submit}
        toggleModal={this.props.toggleModal}
        user={this.state.variables.user}
        possible_options={this.props.user.possible_license_owners_to_collect_for}
        errors={this.state.errors}
        variables={this.state.variables}
        onChange={this.onChange}
      />
    </React.Fragment>
  )
}

export default withApollo(
  graphql(getUser, {
    options: ({ id }) => ({
      notifyOnNetworkStatusChange: true,
      variables: {
        id
      }
    })
  })(UserUpdate)
)
