import React from 'react'

import { Col, FormFeedback, Input, InputGroup, InputGroupAddon, Label, Row } from 'reactstrap'

import { HelpTooltip } from '../help-tooltip'
import Requirements from './Requirements'

const Float = ({
  requirements_with_status,
  depth,
  help_text,
  help_image,
  field_number,
  uuid,
  changeFieldValue,
  parent_field_id,
  name,
  enabled,
  processing,
  value_de,
  value_en,
  field_id,
  unit_de,
  embedded,
  i,
  errors
}) => (
  <React.Fragment>
    <Row
      className={
        depth > 0
          ? `mt-0 mb-0${enabled ? ' ' : ' hidden '}parent-${parent_field_id || 'none'}`
          : `${enabled ? '' : 'hidden '}parent-${parent_field_id || 'none'}`
      }
    >
      <Col
        sm={{ size: 6 - depth, offset: depth }}
        lg={{ size: 4, offset: depth }}
        className={depth > 0 ? 'pt-3 pb-3-lg pl-4 border-left border-secondary' : 'pl-4'}
      >
        <Label
          className={`control-label align-items-center justify-content-start ${errors?.value_de ? 'text-danger' : ''}`}
          for={uuid}
        >
          {field_number && <span className="text-primary-light">{field_number}&nbsp;&mdash;&nbsp;</span>}
          {name}
          <HelpTooltip help_text={help_text} help_image={help_image} uuid={uuid} />
        </Label>
        <Requirements requirements_with_status={requirements_with_status} />
      </Col>
      <Col
        sm={{ size: 6, offset: depth }}
        lg={{ size: 4, offset: 0 }}
        className={depth > 0 ? 'pb-3 pt-3-lg pl-4 border-left-md-only border-secondary' : ''}
      >
        <InputGroup>
          <Input
            disabled={processing}
            type="text"
            inputMode="decimal"
            onChange={e =>
              changeFieldValue({
                value_de: e.target.value,
                uuid,
                field_id,
                embedded,
                i,
                event: e
              })
            }
            id={uuid}
            placeholder="Kein Wert eingetragen"
            value={value_de.replace(/,/g, '.')}
            invalid={!!errors?.value_de}
          />
          {!!unit_de && <InputGroupAddon addonType="append">{unit_de}</InputGroupAddon>}
        </InputGroup>
      </Col>
      {errors?.value_de && (
        <Col sm={{ size: 6, offset: depth }} lg={{ size: 4, offset: 4 + depth }}>
          <FormFeedback valid={false} className="d-block">
            {errors?.value_de}
          </FormFeedback>
        </Col>
      )}
    </Row>
  </React.Fragment>
)

export default Float
