import { useRef } from 'react'

import { Button, Popover, PopoverBody, PopoverHeader } from 'reactstrap'

import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { withLocale } from 'locale'
import PropTypes from 'prop-types'

import ActiveFilterBadge from './activeFilterBadge'

const FilterDropdown = ({ isOpen, toggle, activeFilterCount, children, locale }) => {
  const buttonRef = useRef()

  const toggleWhenNotInteractingWithInsides = event => {
    if (
      // TODO: maybe register the specific container elements via a ref/hook/callback/…, instead of statically defining CSS classes
      !event.target.closest('.facilitiesDashboard__facilitiesFilterDropdown') && // don't close if interacting with the dropdown itself
      !event.target.closest('.mantine-Popover-dropdown') && // don't close if interacting with portaled mantine dropdown
      !event.target.closest('.react-select-portal') && // don't close if interacting with portaled react-select dropdown
      !event.target.closest('[role="option"]') && // don't close if interacting with portaled react-select option (unfortunately the best selector, when selected options get hidden)
      // NOTE: for some reason, clicking on a multiselect, holding the button,
      // dragging it over the opened select dropdown and releasing it over an
      // option registers as a click on the body, thus triggering the toggle and
      // closing the dropdown. Maybe it has to do with dragging the mouse
      // between different html elements leading to the event target bubbling up
      // to the next common parent? This mostly happens, when quickly changing
      // different filter options and the browser auto-scrolls because of the
      // opening select.
      event.target !== document.body
    ) {
      toggle()
    }
  }

  return (
    <>
      <Button
        color="link"
        onClick={toggle}
        className="text-nowrap position-relative text-primary-light"
        innerRef={buttonRef}
        id="foobarbaz"
      >
        <FontAwesomeIcon icon={faFilter} className="icon-prepend" />
        {locale.filter}
        <ActiveFilterBadge activeFilterCount={activeFilterCount} />
      </Button>
      <Popover
        placement="bottom-end"
        trigger="legacy" // NOTE: allows to click outside to close, but stay open on inside click;
        isOpen={isOpen}
        toggle={toggleWhenNotInteractingWithInsides}
        target="foobarbaz"
        flip={false}
        popperClassName="shadow facilitiesDashboard__facilitiesFilterDropdown"
      >
        <PopoverHeader>{locale.filter}</PopoverHeader>
        <PopoverBody className="p-4">{children}</PopoverBody>
      </Popover>
    </>
  )
}

FilterDropdown.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  activeFilterCount: PropTypes.number.isRequired,
  children: PropTypes.node,
  locale: PropTypes.object.isRequired
}

export default withLocale(FilterDropdown, { key: 'facilitiesDashboard.filters' })
