import { Button, ButtonGroup } from 'reactstrap'

import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { withLocale } from 'locale/index'

const SignageSwitch = ({ positive, setPositive, locale }) => (
  <ButtonGroup size="lg" className="w-100">
    <Button color={positive ? 'primary' : 'danger'} onClick={_ => setPositive(false)} outline={positive}>
      <FontAwesomeIcon icon={faMinus} />
      <span className="sr-only">{locale.subtract}</span>
    </Button>
    <Button color={positive ? 'success' : 'primary'} onClick={_ => setPositive(true)} outline={!positive}>
      <FontAwesomeIcon icon={faPlus} />
      <span className="sr-only">{locale.add}</span>
    </Button>
  </ButtonGroup>
)

export default withLocale(SignageSwitch, { key: 'licenseOwners.volumeUnitAccount.createTransaction' })
