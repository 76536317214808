import gql from 'graphql-tag'

export const versionsQuery = gql`
  {
    form_versions {
      id
      fields_count
      forms_count
      comment
      name
      current
    }
  }
`
export const versionQuery = gql`
  query($id: ID!) {
    form_version(id: $id) {
      id
      fields_count
      forms_count
      comment
      name
      current
    }
  }
`

export const versionCreateMutation = gql`
  mutation($base_version_id: ID, $form_version: FormVersionInput!) {
    create_form_version(base_version_id: $base_version_id, form_version: $form_version) {
      id
    }
  }
`

export const versionUpdateMutation = gql`
  mutation($id: ID!, $form_version: FormVersionInput!) {
    update_form_version(id: $id, form_version: $form_version) {
      id
    }
  }
`

export const makeCurrentVersionMutation = gql`
  mutation($id: ID!) {
    make_current_form_version(id: $id) {
      message
      success
    }
  }
`

export const versionDeleteMutation = gql`
  mutation($id: ID!) {
    delete_form_version(id: $id) {
      success
      message
    }
  }
`
