import React from 'react'

import { Badge } from 'reactstrap'

const Requirements = ({ requirements_with_status = [] }) =>
  requirements_with_status.length === 1 && (
    <React.Fragment>
      <Badge pill color="light">
        Anforderung
      </Badge>
      <Badge color={requirement_color(requirements_with_status[0].calculated_result_key)}>
        {requirements_with_status[0].requirement.resulting_string}
      </Badge>
      <br />
      {requirements_with_status[0].alternatives.length >= 1 && (
        <React.Fragment>
          Alternativen{' '}
          {requirements_with_status[0].alternatives.map((alternative, i) => (
            <Badge key={i} color={requirement_color(alternative.calculated_result_key)}>
              {alternative.requirement.resulting_string}
            </Badge>
          ))}
        </React.Fragment>
      )}
    </React.Fragment>
  )

const requirement_color = key => {
  if (key === 'failed') return 'danger'
  if (key === 'missing') return 'warning'
  if (key === 'passed') return 'success'
  return 'primary'
}

export default Requirements
