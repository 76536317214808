import { useMediaQuery } from 'react-responsive'

import { MonthRangePicker } from 'components/ui/genericComponents/monthRangePicker'
import { formatISO } from 'date-fns'
import 'dayjs/locale/de'
import { withLocale } from 'locale'
import PropTypes from 'prop-types'

const CertificationEndDateRangeInput = ({ certificationEndDateRange, setCertificationEndDateRange, locale }) => {
  const value = certificationEndDateRange
    ? { start: new Date(certificationEndDateRange.start), end: new Date(certificationEndDateRange.end) }
    : null

  const onChange = ({ start, end }) => {
    setCertificationEndDateRange({
      start: start ? formatISO(start, { representation: 'date' }) : null,
      end: end ? formatISO(end, { representation: 'date' }) : null
    })
  }

  const numberOfColumns = useMediaQuery({ query: '(max-width: 576px)' }) ? 1 : 2 // NOTE: bootstraps `sm` breakpoint

  return (
    <>
      <label>{locale.label}</label>
      {/* TODO: getting the actual first and last date from the API and visualizing the available range would be super nice */}
      <MonthRangePicker
        numberOfColumns={numberOfColumns}
        placeholder={locale.placeholder}
        value={value}
        onChange={onChange}
        strictRange
      />
    </>
  )
}

CertificationEndDateRangeInput.propTypes = {
  certificationEndDateRange: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string
  }),
  setCertificationEndDateRange: PropTypes.func.isRequired
}

export default withLocale(CertificationEndDateRangeInput, {
  key: 'facilitiesDashboard.filters.certificationEndDateRange'
})
