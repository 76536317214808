import { InputWrapper } from 'components/modules/form'
import RadioButtons from 'components/modules/radio-switch/radio-buttons'
import { withLocale } from 'locale'
import PropTypes from 'prop-types'

const CertificateStatesForm = ({ locale, missing, onChange }) => {
  const layout = {
    label: { xs: 4, sm: 4, md: 3, className: 'mb-3' },
    input: { xs: 8, sm: 8, md: 3, className: 'mb-3' }
  }
  return (
    <>
      <InputWrapper label={locale.certificateMissing} layout={layout}>
        <RadioButtons
          variables={{ facilityShortReportVariableRenderOnCertificateMissing: missing }}
          id="facilityShortReportVariableRenderOnCertificateMissing"
          onChange={({ target: { value } }) => onChange({ target: { id: 'certificateStateMissing', value } })}
        />
      </InputWrapper>
    </>
  )
}

CertificateStatesForm.propTypes = {
  locale: PropTypes.object.isRequired,
  missing: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
}

export default withLocale(CertificateStatesForm, { key: 'short_report.variable' })
