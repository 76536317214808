import React from 'react'

import { Button, Col, Container, FormGroup, Label, Modal, ModalBody, ModalHeader, Row, Tooltip } from 'reactstrap'

import { withApollo } from '@apollo/client/react/hoc'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { LegacySelect as Select } from 'components/modules/selects/react-select'
import { deepNestInObjectsWithKeys } from 'helpers/object'
import update from 'immutability-helper'

import { getFacility } from '../facility.graphql'
import { createTraining } from './training.graphql'

import generateUUID from '../../../../helpers/guid'
import { ProcessingButton } from '../../../modules/buttons/processing-button'
import ValidatedInput from '../../../modules/inputs/validated-input'

import '../../../modules/modal/_modal.scss'

class Create extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      modal: false,
      processing: false,
      create_new_participant: true,
      tooltip_uuid: generateUUID(),
      variables: {
        facility_uuid: this.props.facility.uuid,
        participant_uuid: '',
        training_participant: {
          salutation: 'mr',
          first_name: '',
          last_name: ''
        },
        training_participation: {
          kind: 'online',
          comment: '',
          seminar_number: '',
          passed_at: ''
        }
      }
    }
  }

  clear = () =>
    this.setState({
      modal: false,
      processing: false,
      create_new_participant: true,
      tooltip_uuid: generateUUID(),
      variables: {
        facility_uuid: this.props.facility.uuid,
        participant_uuid: '',
        training_participant: {
          salutation: 'mr',
          first_name: '',
          last_name: ''
        },
        training_participation: {
          kind: 'online',
          comment: '',
          seminar_number: '',
          passed_at: ''
        }
      }
    })

  toggle = () => this.setState({ modal: !this.state.modal })
  toggleTooltip = () => this.setState({ tooltip_open: !this.state.tooltip_open })

  changeSalutation = salutation =>
    this.setState(
      update(this.state, {
        variables: {
          training_participant: {
            salutation: {
              $set: salutation.value
            }
          }
        }
      })
    )

  changeKind = kind =>
    this.setState(
      update(this.state, {
        variables: {
          training_participation: {
            kind: {
              $set: kind.value
            }
          }
        }
      })
    )

  changeParticipantUUID = uuid => {
    uuid.value !== ''
      ? this.setState(
          update(this.state, {
            variables: {
              training_participant: {
                salutation: {
                  $set: ''
                },
                first_name: {
                  $set: ''
                },
                last_name: {
                  $set: ''
                }
              },
              participant_uuid: {
                $set: uuid.value
              }
            }
          })
        )
      : this.setState(
          update(this.state, {
            variables: {
              training_participant: {
                salutation: {
                  $set: 'mr'
                },
                first_name: {
                  $set: ''
                },
                last_name: {
                  $set: ''
                }
              },
              participant_uuid: {
                $set: uuid.value
              }
            }
          })
        )
  }

  options = () => {
    let options = [{ value: '', label: 'Neuen Schulungsabsolventen erstellen' }]
    this.props.facility.training_participants.map(training_participant =>
      options.push({
        value: training_participant.uuid,
        label: `${training_participant.first_name} ${training_participant.last_name}`
      })
    )
    return options
  }

  onChange = event => {
    const fragments = event.target.id.split('.')
    const variables = deepNestInObjectsWithKeys(event.target.value, ...fragments, '$set')

    this.setState(update(this.state, { variables }))
  }

  save = () => {
    this.setState({ processing: true })
    this.props.client
      .mutate({
        mutation: createTraining,
        variables: this.state.variables,
        refetchQueries: [
          {
            query: getFacility,
            variables: {
              uuid: this.state.variables.facility_uuid,
              version_number: this.props.match.params.version_number || ''
            }
          }
        ],
        awaitRefetchQueries: true
      })
      .then(response => this.clear())
      .catch(error => this.onError(error))
  }

  onError = ({ graphQLErrors }) =>
    this.setState({
      processing: false,
      error_msg: graphQLErrors[0].message,
      errors: graphQLErrors[0].errors
    })
  render = () => {
    const {
      state: { tooltip_uuid, tooltip_open, modal, errors, variables, processing },
      props: { className, locale, uuid },
      save,
      toggle,
      toggleTooltip,
      onChange,
      changeSalutation,
      changeKind,
      changeParticipantUUID
    } = this
    return (
      <React.Fragment>
        <Button
          size={`sm`}
          className="btn-transparent btn-icon-only btn"
          color={'link'}
          onClick={e => {
            toggle()
            e.stopPropagation()
          }}
          id={`tooltip-modal_${tooltip_uuid}`}
        >
          <FontAwesomeIcon icon={faEdit} />
        </Button>
        <Tooltip
          delay={500}
          placement="top"
          isOpen={tooltip_open}
          target={`tooltip-modal_${tooltip_uuid}`}
          toggle={toggleTooltip}
        >
          {modal ? locale.close : locale.edit}
        </Tooltip>
        <Modal size={'lg'} centered isOpen={modal} toggle={toggle} className={className}>
          <ModalHeader>Neue Schulungsinformation anlegen</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Container>
                <Row>
                  <Col sm="4" className="text-right">
                    <Label className="control-label" for="name_de">
                      Schulungsabsolvent
                    </Label>
                  </Col>
                  <Col sm="4">
                    <Select
                      isClearable={false}
                      name="training_participant"
                      placeholder="Schulungsabsolvent auswählen"
                      value={variables.participant_uuid}
                      onChange={changeParticipantUUID}
                      options={this.options()}
                    />
                  </Col>
                </Row>
                {!variables.participant_uuid && (
                  <NewParticipantForm
                    changeSalutation={changeSalutation}
                    variables={variables}
                    locale={locale}
                    errors={errors}
                    change={onChange}
                    possible_salutations={[
                      { value: 'mr', label: 'Herr' },
                      { value: 'mrs', label: 'Frau' }
                    ]}
                  />
                )}

                <Row>
                  <ValidatedInput
                    input_sm={'4'}
                    label_sm={'4'}
                    onChange={onChange}
                    id={'training_participation.comment'}
                    errors={errors}
                    variables={variables}
                    locale={locale}
                  />
                </Row>
                <Row>
                  <Col sm="4" className="text-right">
                    <Label className="control-label" for="type">
                      Typ
                    </Label>
                  </Col>
                  <Col sm="4">
                    <Select
                      isClearable={false}
                      onChange={changeKind}
                      options={[
                        { value: 'collector', label: 'DSFT-Erheberschulung' },
                        { value: 'online', label: 'Online-Schulung Reisen für Alle' },
                        { value: 'presence', label: 'DSFT-Präsenzseminar Reisen für Alle' },
                        { value: 'other', label: 'Sonstige Schulung' }
                      ]}
                      value={variables.training_participation.kind}
                      placeholder="auswählen..."
                    />
                  </Col>
                </Row>
                <Row>
                  <ValidatedInput
                    input_sm={'4'}
                    label_sm={'4'}
                    onChange={onChange}
                    id={'training_participation.seminar_number'}
                    errors={errors}
                    variables={variables}
                    locale={locale}
                  />
                </Row>
                <Row>
                  <ValidatedInput
                    max={'2050-01-01'}
                    pattern={'[0-9]{4}-[0-9]{2}-[0-9]{2}'}
                    type={'date'}
                    input_sm={'4'}
                    label_sm={'4'}
                    onChange={onChange}
                    id={'training_participation.passed_at'}
                    errors={errors}
                    variables={variables}
                    locale={locale}
                  />
                </Row>
              </Container>
            </FormGroup>

            <FormGroup className="form-action">
              <Container>
                <Row>
                  <Col sm="5">
                    <a className="link-btn" onClick={toggle} href="#">
                      abbrechen
                    </a>
                  </Col>
                  <Col sm="7" className="text-right">
                    <ProcessingButton onClick={save} label={'speichern'} processing={processing} />
                  </Col>
                </Row>
              </Container>
            </FormGroup>
          </ModalBody>
        </Modal>
      </React.Fragment>
    )
  }
}

const NewParticipantForm = ({ possible_salutations, change, changeSalutation, locale, errors, variables }) => (
  <React.Fragment>
    <Row>
      <Col sm="4" className="text-right">
        <Label className="control-label" for="salutation">
          Anrede
        </Label>
      </Col>
      <Col sm="4">
        <Select
          isClearable={false}
          name="salutation"
          value={variables.training_participant.salutation}
          placeholder="auswählen..."
          onChange={changeSalutation}
          options={possible_salutations}
        />
      </Col>
    </Row>
    <Row>
      <ValidatedInput
        input_sm={'4'}
        label_sm={'4'}
        onChange={change}
        id={'training_participant.first_name'}
        errors={errors}
        variables={variables}
        locale={locale}
      />
    </Row>
    <Row>
      <ValidatedInput
        input_sm={'4'}
        label_sm={'4'}
        onChange={change}
        id={'training_participant.last_name'}
        errors={errors}
        variables={variables}
        locale={locale}
      />
    </Row>
  </React.Fragment>
)

export default withApollo(Create)
