// TODO: PLEASE refactor this file, currently its just copy-pasted from the old implementation without refactoring
// These icons are used at a few different locations throughout the application and are implemented multiple times.
// Refactor this into a standalone component which can be configured with a certification_data object or similar.
import React, { useRef } from 'react'

import { Tooltip, UncontrolledTooltip } from 'reactstrap'

import IconCertified from 'components/assets/certified-accessibility.svg'
import IconDocumented from 'components/assets/documented-accessibility.svg'
import IconVisual from 'components/assets/guestgroup-icons/blind-grau.svg'
import IconVisualInfo from 'components/assets/guestgroup-icons/blind-grau_info.svg'
import IconWalking from 'components/assets/guestgroup-icons/gehbehindert-grau.svg'
import IconWalkingInfo from 'components/assets/guestgroup-icons/gehbehindert-grau_info.svg'
import IconDeaf from 'components/assets/guestgroup-icons/gehoerlos-grau.svg'
import IconDeafInfo from 'components/assets/guestgroup-icons/gehoerlos-grau_info.svg'
import IconPartiallyDeaf from 'components/assets/guestgroup-icons/hoerbehindert-grau.svg'
import IconPartiallyDeafInfo from 'components/assets/guestgroup-icons/hoerbehindert-grau_info.svg'
import IconMental from 'components/assets/guestgroup-icons/kognitiv-grau.svg'
import IconMentalInfo from 'components/assets/guestgroup-icons/kognitiv-grau_info.svg'
import IconWheelchair from 'components/assets/guestgroup-icons/rollstuhlfahrer-grau.svg'
import IconWheelchairInfo from 'components/assets/guestgroup-icons/rollstuhlfahrer-grau_info.svg'
import IconPartiallyVisual from 'components/assets/guestgroup-icons/sehbehindert-grau.svg'
import IconPartiallyVisualInfo from 'components/assets/guestgroup-icons/sehbehindert-grau_info.svg'
import generateUUID from 'helpers/guid'
import { withLocale } from 'locale'

const CertificateTypeImage = withLocale(
  ({ certificate_type, locale }) => {
    const iconRef = useRef()
    const getIconImage = certificate_type => {
      switch (certificate_type) {
        case 'validated':
          return IconCertified
        case 'documented':
          return IconDocumented
        default:
          return null
      }
    }

    const icon = getIconImage(certificate_type)

    if (icon === null) {
      return null
    }

    return (
      <li className="list-inline-item mx-1">
        <img src={icon} height="24" alt={locale[certificate_type]} ref={iconRef} />
        <UncontrolledTooltip placement="top" target={iconRef} boundariesElement="viewport">
          {locale[certificate_type]}
        </UncontrolledTooltip>
      </li>
    )
  },
  { key: 'certificate_type' }
)

const CertificatePropertyImages = ({ certification_data, locale }) => {
  let properties_array = []
  const properties = certification_data.properties
  Object.keys(properties).map(property => {
    if (properties[property] > 0) {
      properties_array.push({
        key: property,
        label: property,
        value: properties[property]
      })
    }
  })

  return certification_data.certificate_type.key !== 'missing' ? (
    <ul className="list-unstyled certificate-type-icons-list text-left">
      <CertificateTypeImage certificate_type={certification_data.certificate_type.key} />
      {properties_array.map((property, index) => (
        <CertificatePropertyImage key={index} {...property} locale={locale} />
      ))}
    </ul>
  ) : null
}

class CertificatePropertyImage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tooltip_uuid: generateUUID()
    }
  }

  toggleTooltip = () =>
    this.setState({
      tooltip_open: !this.state.tooltip_open
    })

  getIconImage(label, value) {
    switch (label + '-' + value + '') {
      case 'deaf-1':
        return IconDeafInfo
      case 'deaf-2':
        return IconDeaf
      case 'partially_deaf-1':
        return IconPartiallyDeafInfo
      case 'partially_deaf-2':
        return IconPartiallyDeaf
      case 'mental-1':
        return IconMentalInfo
      case 'mental-2':
        return IconMental
      case 'visual-1':
        return IconVisualInfo
      case 'visual-2':
        return IconVisual
      case 'partially_visual-1':
        return IconPartiallyVisualInfo
      case 'partially_visual-2':
        return IconPartiallyVisual
      case 'walking-1':
        return IconWalkingInfo
      case 'walking-2':
        return IconWalking
      case 'wheelchair-1':
        return IconWheelchairInfo
      case 'wheelchair-2':
        return IconWheelchair
      default:
        return ''
    }
  }

  render() {
    const { locale, label, value } = this.props
    const { toggleTooltip, getIconImage } = this
    const { tooltip_uuid, tooltip_open } = this.state

    return (
      <li className="list-inline-item mx-1">
        <span className="certificate-type-icon certificate-type-icon-small" id={`tooltip-badge_${tooltip_uuid}`}>
          <img
            src={getIconImage(label, value)}
            height="24"
            width="24"
            alt={locale.guest_group_category_description[label][value]}
          />
        </span>
        <Tooltip
          placement="top"
          isOpen={tooltip_open}
          target={`tooltip-badge_${tooltip_uuid}`}
          toggle={toggleTooltip}
          boundariesElement="viewport"
        >
          {locale.guest_group_category_description[label][value]}
        </Tooltip>
      </li>
    )
  }
}

export default withLocale(CertificatePropertyImages)
