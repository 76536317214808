import gql from 'graphql-tag'

export const UserDataQuery = gql`
  query user_query {
    form_version {
      id
    }
    user {
      last_name
      first_name
      email
      id
      salutation {
        key
        label
      }
      permissions {
        key
        permitted
      }
    }
  }
`

export const requestNewPasswordMutation = gql`
  mutation send_reset_password_instructions($email: String!) {
    send_reset_password_instructions(email: $email) {
      success
      message
    }
  }
`

export const setNewPasswordByTokenMutation = gql`
  mutation reset_password_by_token(
    $reset_password_token: String!
    $password: String!
    $password_confirmation: String!
  ) {
    reset_password_by_token(
      reset_password_token: $reset_password_token
      password: $password
      password_confirmation: $password_confirmation
    ) {
      full_name
      email
    }
  }
`
