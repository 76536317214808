import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'

import { withLocale } from 'locale'
import PropTypes from 'prop-types'

import { DeleteModalContent } from '.'
import Modal from '../../../../modules/modal/modal'

const DeleteModal = ({ locale, provider }) => (
  <Modal
    toggleModalText={locale.provider.delete.title(provider.name)}
    button_type={{
      icon_only: true,
      icon_type: faTrashAlt,
      color: 'transparent',
      classes: 'text-danger btn-link btn-sm'
    }}
    modal_size="lg"
  >
    <DeleteModalContent {...{ locale, provider }} />
  </Modal>
)

DeleteModal.propTypes = {
  locale: PropTypes.object.isRequired,
  provider: PropTypes.object.isRequired
}

export default withLocale(DeleteModal)
