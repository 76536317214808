import { Alert } from 'reactstrap'

import { useQuery } from '@apollo/client'

import { Select } from 'components/modules/selects/react-select'
import { withLocale } from 'locale'
import PropTypes from 'prop-types'

import { getCertificationTypesForFacilitiesFilter } from './certificationTypesSelect.graphql'

const CertificationTypesSelect = ({ certificationTypes, setCertificationTypes, locale }) => {
  const { loading, error, data } = useQuery(getCertificationTypesForFacilitiesFilter)

  if (error) {
    console.error('Error fetching regions', error)
    return (
      <Alert color="danger" className="m-0">
        Error fetching regions
      </Alert>
    )
  }

  const options =
    data?.certificationTypeEnum?.enumValues?.map(enumValue => ({
      value: enumValue.name,
      label: locale.options[enumValue.name]
    })) || []

  const value = certificationTypes.map(certificationType => options.find(option => option.value === certificationType))

  const onChange = selectedOptions => {
    setCertificationTypes(selectedOptions.map(option => option.value))
  }

  return (
    <>
      <label>{locale.label}</label>
      <Select
        isLoading={loading}
        value={value}
        onChange={onChange}
        options={options}
        isMulti
        placeholder={locale.emptyPlaceholder}
        menuPortalTarget={document.body}
      />
    </>
  )
}

CertificationTypesSelect.propTypes = {
  certificationTypes: PropTypes.array.isRequired,
  setCertificationTypes: PropTypes.func.isRequired,
  locale: PropTypes.object.isRequired
}

export default withLocale(CertificationTypesSelect, { key: 'facilitiesDashboard.filters.certificationTypes' })
