import { useState } from 'react'

import { Col, Row } from 'reactstrap'

const withPageHeader = WrappedComponent => props => {
  const [pageHeader, setPageHeader] = useState('')

  return (
    <div className="page-wrapper">
      <Row className="page-header">
        <Col sm="12">
          <h1>{pageHeader}</h1>
        </Col>
        <Col sm="12">
          <hr className="seperator" />
        </Col>
      </Row>
      <WrappedComponent {...{ ...props, setPageHeader }} />
    </div>
  )
}

export default withPageHeader
