import React from 'react'

import { Alert, Col, Form, Row } from 'reactstrap'

import { graphql, withApollo } from '@apollo/client/react/hoc'

import Loading from '../../../helper/loading/loading.jsx'
import FormGroups from './form_groups'
import { versionCreateMutation, versionQuery } from './graphql'

const VersionClone = ({ locale, history, match, client, data: { loading, error, form_version } }) => {
  if (loading) return <Loading />
  if (error) return <p>Error</p>
  return (
    <div className="page-wrapper">
      <Row className="page-header">
        <Col sm="8">
          <h1>{`Neue Version basierend auf "${form_version.name}" anlegen`}</h1>
        </Col>
      </Row>
      <hr className="seperator" />
      <VersionCloneForm locale={locale} form_version={form_version} client={client} history={history} match={match} />
    </div>
  )
}

class VersionCloneForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      comment: '',
      error: false,
      error_msg: ''
    }
  }

  gqlValidationError = error =>
    this.setState({
      error_msg: error.graphQLErrors[0].message,
      error: true
    })
  change = event =>
    event.target ? this.setState({ [event.target.id]: event.target.value }) : this.setState({ [event.id]: event.value })
  submit = () =>
    this.props.client
      .mutate({
        mutation: versionCreateMutation,
        variables: {
          base_version_id: this.props.match.params.id,
          form_version: {
            name: this.state.name,
            comment: this.state.comment
          }
        }
      })
      .then(result => this.props.history.push('/form-kit/versions'))
      .catch(error => this.gqlValidationError(error))

  render = () => (
    <Form className="box">
      {this.state.error ? <Alert color="danger">{this.state.error_msg}</Alert> : ''}
      <FormGroups
        state={this.state}
        props={this.props}
        change={this.change}
        submit={this.submit}
        changeRegions={this.changeRegions}
      />
    </Form>
  )
}

export default withApollo(
  graphql(versionQuery, {
    options: props => ({
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      variables: {
        id: props.match.params.id
      }
    })
  })(VersionClone)
)
