import React from 'react'

import { Col, FormFeedback, Label, Row } from 'reactstrap'

import { LegacySelect as ReactSelect } from 'components/modules/selects/react-select'

import { HelpTooltip } from '../help-tooltip'
import Requirements from './Requirements'

const Select = ({
  requirements_with_status,
  depth,
  help_text,
  help_image,
  field_number,
  uuid,
  changeFieldValue,
  parent_field_id,
  name,
  enabled,
  processing,
  value_de,
  field_id,
  unit_de,
  embedded,
  i,
  errors
}) => (
  <React.Fragment>
    <Row
      className={
        depth > 0
          ? `mt-0 mb-0${enabled ? ' ' : ' hidden '}parent-${parent_field_id || 'none'}`
          : `${enabled ? '' : 'hidden '}parent-${parent_field_id || 'none'}`
      }
    >
      <Col
        sm={{ size: 6 - depth, offset: depth }}
        lg={{ size: 4, offset: depth }}
        className={depth > 0 ? 'pt-3 pb-3-lg pl-4 border-left border-secondary' : 'pl-4'}
      >
        <Label
          className={`control-label align-items-center justify-content-start ${errors?.value_de ? 'text-danger' : ''}`}
          for={uuid}
        >
          {field_number && <span className="text-primary-light">{field_number}&nbsp;&mdash;&nbsp;</span>}
          {name}
          <HelpTooltip help_text={help_text} help_image={help_image} uuid={uuid} />
        </Label>
        <Requirements requirements_with_status={requirements_with_status} />
      </Col>
      <Col
        sm={{ size: 6, offset: depth }}
        lg={{ size: 4, offset: 0 }}
        className={depth > 0 ? 'pb-3 pt-3-lg pl-4 border-left-md-only border-secondary' : ''}
      >
        <ReactSelect
          isDisabled={processing}
          options={unit_de.split('|').map((unit_de, index) => ({
            label: unit_de,
            value: index.toString(),
            target: { value_de: index.toString(), uuid }
          }))}
          onChange={e =>
            changeFieldValue({
              value_de: e.target.value_de,
              uuid,
              field_id,
              embedded,
              i
            })
          }
          name={uuid}
          placeholder={`Bitte auswählen`}
          value={value_de}
          isClearable={false}
          styles={
            !!errors?.value_de
              ? {
                  control: (provided, state) => ({
                    ...provided,
                    borderColor: 'red'
                  })
                }
              : undefined
          }
        />
      </Col>
      {errors?.value_de && (
        <Col sm={{ size: 6, offset: depth }} lg={{ size: 4, offset: 4 + depth }}>
          <FormFeedback valid={false} className="d-block">
            {errors?.value_de}
          </FormFeedback>
        </Col>
      )}
    </Row>
  </React.Fragment>
)

export default Select
