import { Fragment, useCallback, useEffect, useRef, useState } from 'react'

import { Button, Progress } from 'reactstrap'

import { faSave, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './processing_button/styles.scss'

const calcProgressLabelStyle = el => {
  if (el === null) return {}

  const styles = window.getComputedStyle(el)

  return {
    width: `${el.clientWidth}px`,
    padding: styles.getPropertyValue('padding')
  }
}

const Label = ({ processing, processing_label, label, no_icon, icon }) => {
  const text = processing ? processing_label : label
  return (
    <Fragment>
      {!no_icon || processing ? (
        <FontAwesomeIcon
          className={text != '' ? 'icon-prepend' : ''}
          icon={processing ? faSpinner : icon || faSave}
          pulse={processing}
        />
      ) : null}
      <span>{text}</span>
    </Fragment>
  )
}

export const ProcessingButton = ({
  label,
  processing,
  icon,
  no_icon,
  removebutton,
  type = 'button',
  outline,
  processing_label = null,
  color = 'primary-light',
  progress = 0,
  ...buttonProps
}) => {
  const progressContainer = useRef(null)
  const [progressLabelStyle, setProgressLabelStyle] = useState(calcProgressLabelStyle(progressContainer?.current))

  useEffect(_ => {
    window.addEventListener('resize', _ => setProgressLabelStyle(calcProgressLabelStyle(progressContainer?.current)))
  }, [])

  const buttonRefChanged = useCallback(
    el => {
      progressContainer.current = el
      setProgressLabelStyle(calcProgressLabelStyle(el))
    },
    [progressContainer?.current?.clientWidth]
  )

  if (removebutton) {
    color = 'danger'
    processing_label = processing_label !== null ? processing_label : 'wird gelöscht …'
  }
  outline = outline !== undefined ? outline : removebutton
  processing_label = processing_label !== null ? processing_label : 'wird gespeichert …'

  return (
    <Button
      {...buttonProps}
      disabled={processing || buttonProps.disabled}
      outline={outline}
      className={`btn-labeled processing-button ${buttonProps.className || ''}`.trim()}
      color={color}
      type={type}
      innerRef={buttonRefChanged}
    >
      <Label {...{ processing, processing_label, label, no_icon, icon }} />
      {processing ? (
        <Progress value={progress} color={outline ? color : 'white'}>
          <div
            className={`processing-button-progress-label text-nowrap text-${outline ? 'white' : color}`}
            style={progressLabelStyle}
          >
            <Label {...{ processing, processing_label, label, no_icon, icon }} />
          </div>
        </Progress>
      ) : null}
    </Button>
  )
}
