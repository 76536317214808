import BadgeWithTooltip from 'components/modules/badge/badge_with_tooltip'
import { withLocale } from 'locale'
import PropTypes from 'prop-types'

const CurrentBadge = ({ isCurrentVersion = false, facilityUuid, locale }) => {
  let attributes

  if (isCurrentVersion) {
    attributes = {
      color: 'success',
      tooltip: locale.currentHelp,
      children: locale.current
    }
  } else {
    attributes = {
      color: 'info',
      tooltip: locale.archivedHelp(facilityUuid),
      children: locale.archived
    }
  }

  return <BadgeWithTooltip className="m-1" pill {...attributes} />
}

CurrentBadge.propTypes = {
  isCurrentVersion: PropTypes.bool,
  facilityUuid: PropTypes.string.isRequired,
  locale: PropTypes.shape({
    currentHelp: PropTypes.string.isRequired,
    current: PropTypes.string.isRequired,
    archivedHelp: PropTypes.func.isRequired,
    archived: PropTypes.string.isRequired
  }).isRequired
}

export default withLocale(CurrentBadge, { key: 'facility.version_info' })
