// additional components
import React from 'react'

import { Button, Col, Container, FormGroup, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'

import { faCopy } from '@fortawesome/free-solid-svg-icons'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import gql from 'graphql-tag'

// GraphQL
import { getFacility } from '../../views/facility/facility.graphql'

import Loading from '../../helper/loading/loading'

const duplicate_facility = gql`
  mutation duplicate_facility($uuid: ID!) {
    duplicate_facility(uuid: $uuid)
  }
`

class CopyFacilityModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      modal: false,
      processing: false
    }
  }

  clean = () =>
    this.setState({
      modal: false,
      processing: false
    })

  toggle = event => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    this.setState({
      modal: !this.state.modal
    })
  }

  dublicate = () => {
    this.setState({ processing: true })
    this.props.client
      .mutate({
        mutation: duplicate_facility,
        variables: { uuid: this.props.facility.uuid },
        refetchQueries: [{ query: getFacility, variables: { uuid: this.props.facility.uuid, version_number: '' } }],
        awaitRefetchQueries: true
      })
      .then(() => this.clean())
  }

  render() {
    const { dublicate, toggle } = this
    const { className, facility, locale } = this.props
    const { modal, processing } = this.state

    return (
      <React.Fragment>
        <Button outline color={'primary-light'} className={'px-5 btn-labeled'} onClick={toggle}>
          <FontAwesomeIcon className="icon-prepend" icon={faCopy} />
          <span>
            {locale.facility.singular} {locale.dublicate}
          </span>
        </Button>
        <Modal centered isOpen={modal} toggle={toggle} className={className} size={'lg'}>
          <ModalHeader>
            {locale.facility.singular} {locale.dublicate}
          </ModalHeader>
          <ModalBody>
            Möchten Sie den Betrieb <strong className="text-primary-light">{facility.base_data.name_de}</strong>{' '}
            wirklich kopieren?
            {processing && <Loading loadingText={`Kopie wird erstellt...`} size={`1x`} />}
            <FormGroup className="form-action">
              <Container>
                <Row>
                  <Col sm="5">
                    <a className="link-btn" onClick={toggle} href="#">
                      {locale.cancel}
                    </a>
                  </Col>
                  <Col sm="7" className="text-right">
                    <Button className="btn-labeled" color="primary-light" onClick={dublicate}>
                      <FontAwesomeIcon className="icon-prepend" icon={faCopy} />
                      <span>{locale.dublicate}</span>
                    </Button>
                  </Col>
                </Row>
              </Container>
            </FormGroup>
          </ModalBody>
        </Modal>
      </React.Fragment>
    )
  }
}

export default CopyFacilityModal
