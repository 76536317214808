import { Component } from 'react'

import { TokenAuth } from '../../../helpers/token-auth'

class ProtectedComponent extends Component {
  signedIn = () => TokenAuth.getUser() !== null
  UNSAFE_componentWillMount = () => {
    if (!this.signedIn()) {
      this.props.history.replace({
        pathname: '/login',
        state: {
          afterSignInPath: this.props.location.pathname
        }
      })
    }
  }

  render = () => (this.signedIn() ? this.props.children : null)
}

export default ProtectedComponent
