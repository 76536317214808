import React, { forwardRef } from 'react'

import { UncontrolledTooltip } from 'reactstrap'

import { faCube, faCubes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { withLocale } from 'locale'
import PropTypes from 'prop-types'

const FacilityNameWithBundleInfo = forwardRef(
  (
    {
      name,
      firstBundledFacility,
      totalBundledFacilities,
      firstBundledByFacility,
      totalBundledByFacilities,
      locale,
      inheritColor = false
    },
    ref
  ) => {
    const bundleTypeMap = {
      bundledBy: {
        icon: faCubes,
        totalCount: totalBundledByFacilities,
        firstFacility: firstBundledByFacility
      },
      bundledFacilities: {
        icon: faCube,
        totalCount: totalBundledFacilities,
        firstFacility: firstBundledFacility
      }
    }

    const toolTipText = key => {
      const count = bundleTypeMap[key].totalCount
      const localizedTextTemplate = count > 1 ? locale[key].many : locale[key].one
      const name = bundleTypeMap[key].firstFacility.name
      const audit_order_number = bundleTypeMap[key].firstFacility.audit_order_number

      return localizedTextTemplate({ name, count, audit_order_number })
    }

    const bundleIconsWithTooltip = Object.keys(bundleTypeMap)
      .filter(type => bundleTypeMap[type].totalCount > 0)
      .map(type => (
        <IconWithTooltip
          key={type}
          text={toolTipText(type)}
          icon={bundleTypeMap[type].icon}
          inheritColor={inheritColor}
        />
      ))

    return (
      <span ref={ref}>
        {name}
        {bundleIconsWithTooltip}
      </span>
    )
  }
)

const IconWithTooltip = ({ icon, text, inheritColor }) => {
  const ref = React.useRef()

  return (
    <>
      <span className="ml-2" ref={ref}>
        <FontAwesomeIcon className={inheritColor ? '' : 'text-primary'} icon={icon} />
      </span>
      <UncontrolledTooltip placement="top" target={ref} boundariesElement="viewport">
        {text}
      </UncontrolledTooltip>
    </>
  )
}

FacilityNameWithBundleInfo.propTypes = {
  name: PropTypes.string.isRequired,
  firstBundledFacility: PropTypes.shape({
    name: PropTypes.string.isRequired,
    audit_order_number: PropTypes.string.isRequired
  }),
  totalBundledFacilities: PropTypes.number.isRequired,
  firstBundledByFacility: PropTypes.shape({
    name: PropTypes.string.isRequired,
    audit_order_number: PropTypes.string.isRequired
  }),
  totalBundledByFacilities: PropTypes.number.isRequired
}

export default withLocale(FacilityNameWithBundleInfo, { key: 'facility.bundleInformation.tooltips' })
