import dayjs from 'dayjs'
import { withLocale } from 'locale/index'

const InputTextValue = ({ date, dateLocaleKey, locale }) => {
  const format = 'MMM YYYY'
  const formatDate = value => dayjs(value).locale(dateLocaleKey).format(format)

  if (date[0] && date[1]) {
    if (date[0].getMonth() === date[1].getMonth() && date[0].getFullYear() === date[1].getFullYear()) {
      return locale.singleMonth(formatDate(date[0]))
    }

    return locale.betweenMonths(formatDate(date[0]), formatDate(date[1]))
  }

  if (date[0]) {
    return locale.incomplete(formatDate(date[0]))
  }
}

export default withLocale(InputTextValue, { key: 'userInterface.monthRangePicker.formattedValue' })
