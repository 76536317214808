import { withApollo } from '@apollo/client/react/hoc'
import { faEdit } from '@fortawesome/free-solid-svg-icons'

import { withLocale } from 'locale'
import PropTypes from 'prop-types'

import { getFacility } from '../../facility.graphql'

import Modal from '../../../../../components/modules/modal/modal'
import { ModalContent } from '../../../admin/providers/update/modal_content'

const EditModal = props => (
  <Modal
    toggleModalText={props.locale.facility.provider.edit}
    button_type={{ icon_only: false, icon_type: faEdit, color: 'primary', outlined: false, classes: 'btn-sm mx-2' }}
    modal_size={'lg'}
  >
    <ModalContent
      {...props}
      provider={props.facility.provider}
      refetchQueries={[{ query: getFacility, variables: { uuid: props.facility.uuid, version_number: '' } }]}
    />
  </Modal>
)

EditModal.propTypes = {
  client: PropTypes.object.isRequired,
  locale: PropTypes.object.isRequired,
  facility: PropTypes.object.isRequired
}

export default withApollo(withLocale(EditModal))
