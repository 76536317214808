import { Component } from 'react'

// ReactStrap Components
import { Alert, Button, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap'

import { withApollo } from '@apollo/client/react/hoc'
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { isValidEmail } from '../../helper/helper-functions'
import { requestNewPasswordMutation } from './graphql'

import '../../modules/box/_box.scss'
import './_login.scss'

class Reset extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: false,
      error_msg: '',
      succcess: false,
      variables: {
        email: ''
      }
    }
  }

  change = event =>
    this.setState({
      variables: {
        [event.target.id]: event.target.value
      },
      error: false,
      success: false,
      error_msg: ''
    })

  goToLogin = event => {
    event.preventDefault()
    this.props.history.push('/login')
  }

  submit = event => {
    const { email } = this.state.variables
    const { variables } = this.state
    event.preventDefault()

    //check frontendside if email
    if (!isValidEmail(email)) {
      this.setState({ error: true, error_msg: 'Bitte geben Sie eine korrekte Emailadresse an' })
      return
    }

    this.props.client
      .mutate({ mutation: requestNewPasswordMutation, variables })
      .then(response => this.setState({ success: true })) //TODO add mutation to reset
  }

  render = () => (
    <div className="page-wrapper">
      <Row className="page-header">
        <Col>
          <h1>Passwort vergessen</h1>
        </Col>
      </Row>
      <hr className="seperator" />
      <Form className="box">
        {this.state.error ? <Alert color="danger">{this.state.error_msg}</Alert> : null}
        {this.state.success ? (
          <Alert color="success">
            Wir haben ihre Anfrage zur Passwortänderung erhalten. Bitte überprüfen Sie ihr Email-Postfach
          </Alert>
        ) : null}
        <FormGroup>
          <Container>
            <Row>
              <Col sm="3" className="text-right">
                <Label className="control-label" for="email">
                  Ihre E-Mail-Adresse
                </Label>
              </Col>
              <Col sm="5">
                <Input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                  onChange={this.change}
                  value={this.state.email}
                />
              </Col>
            </Row>
          </Container>
        </FormGroup>
        <FormGroup className="form-action">
          <Container>
            <Row>
              <Col sm={{ size: 8, offset: 3 }}>
                <Button type="submit" className="btn-labeled btn-login" color="primary-light" onClick={this.submit}>
                  <FontAwesomeIcon className="icon-prepend" icon={faSignInAlt} />
                  <span>Zurücksetzen</span>
                </Button>
                <a href="/login" onClick={this.goToLogin}>
                  Zum Login
                </a>
              </Col>
            </Row>
          </Container>
        </FormGroup>
      </Form>
    </div>
  )
}

export default withApollo(Reset)
