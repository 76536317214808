import React from 'react'

import { graphql, withApollo } from '@apollo/client/react/hoc'

import { LegacySelect as Select } from 'components/modules/selects/react-select'

import { statusesQuery } from './graphql'

class Statuses extends React.Component {
  statusOptions = () => {
    const { possible_statuses } = this.props.data.user
    let options = []
    possible_statuses.map(status => options.push({ value: status.key, label: status.label }))
    return options
  }

  render = () => {
    const { error, loading } = this.props.data
    const { status, changeStatus } = this.props

    if (loading) return <Select isClearable={false} name="status" value={status} onChange={changeStatus} options={[]} />
    if (error) return <p>Error</p>
    return (
      <Select isClearable={false} name="status" value={status} onChange={changeStatus} options={this.statusOptions()} />
    )
  }
}

export default withApollo(
  graphql(statusesQuery, {
    options: () => ({
      notifyOnNetworkStatusChange: true
    })
  })(Statuses)
)
