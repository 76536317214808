import { Badge } from 'reactstrap'

import PropTypes from 'prop-types'

const ActiveFilterBadge = ({ activeFilterCount }) => {
  if (!activeFilterCount) {
    return null
  }

  return (
    <small>
      <Badge color="danger" pill className="facilitiesDashboard__facilitiesFilter__ActiveFilterBadge">
        {activeFilterCount}
      </Badge>
    </small>
  )
}

ActiveFilterBadge.propTypes = {
  activeFilterCount: PropTypes.number.isRequired
}

export default ActiveFilterBadge
