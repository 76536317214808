// additional components
import React from 'react'

import { Col, Input, Row, Table } from 'reactstrap'

// GraphQL
import { graphql, withApollo } from '@apollo/client/react/hoc'
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons'

// Icons
import { getInputApiUsers } from './input_api_users.graphql'

import Loading from '../../../helper/loading/loading.jsx'
import Modal from '../../../modules/modal/modal.jsx'
import Create from './create.jsx'
import Delete from './delete.jsx'
import Update from './update.jsx'

const InputApiUsers = ({ data: { error, loading, input_api_users }, locale, client, history }) =>
  loading ? (
    <Loading />
  ) : (
    <PartnerGatewayUserList input_api_users={input_api_users} locale={locale} client={client} history={history} />
  )

class PartnerGatewayUserList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      search_query: ''
    }
  }

  search = () => {
    const input_api_users = this.props.input_api_users
    const result = []
    if (this.state.search_query.length !== 0) {
      for (let i = 0; i < input_api_users.length; i++) {
        if (
          input_api_users[i].name &&
          input_api_users[i].name.toLowerCase().includes(this.state.search_query.toLowerCase())
        ) {
          result.push(input_api_users[i])
          continue
        }
        if (
          input_api_users[i].email &&
          input_api_users[i].email.toLowerCase().includes(this.state.search_query.toLowerCase())
        ) {
          result.push(input_api_users[i])
        }
      }
      return result
    }
    return input_api_users
  }

  change = event => this.setState({ [event.target.id]: event.target.value })

  render = () => {
    const { locale, client, history } = this.props
    const { search_query } = this.state
    const input_api_users = this.search()

    return (
      <div className="page-wrapper">
        <Row className="page-header">
          <Col sm="6">
            <h1>{locale.input_api_user.plural}</h1>
          </Col>
          <Col sm="3" className="text-right">
            <Input
              id="search_query"
              placeholder={locale.input_api_user.search_placeholder}
              value={search_query}
              onChange={this.change}
            />
          </Col>
          <Col sm="3" className="text-right">
            <Modal
              children={<Create locale={locale} client={client} />}
              toggleModalText={locale.input_api_user.create}
              button_type={{ icon_only: false, icon_type: faPlus, color: 'primary-light', classes: 'btn-labeled' }}
              modal_size={'lg'}
            />
          </Col>
        </Row>
        <hr className="seperator" />
        <Table striped responsive bordered>
          <thead className="thead-light">
            <tr>
              <th>{locale.input_api_user.name}</th>
              <th>{locale.input_api_user.email}</th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {input_api_users.map((input_api_user, i) => (
              <PartnerGatewayUserListItem key={i} input_api_user={input_api_user} locale={locale} />
            ))}
          </tbody>
        </Table>
      </div>
    )
  }
}

const PartnerGatewayUserListItem = ({ input_api_user, locale, client }) => (
  <tr>
    <td>
      <Update input_api_user={input_api_user} locale={locale} />
    </td>
    <td>{input_api_user.email}</td>
    <td>
      <Modal
        toggleModalText={`${locale.input_api_user.delete}`}
        children={<Delete input_api_user={input_api_user} locale={locale} />}
        button_type={{
          icon_only: true,
          icon_type: faTrashAlt,
          color: 'primary-light',
          classes: 'btn-transparent btn-icon-only text-danger'
        }}
        modal_size={'lg'}
      />
    </td>
  </tr>
)

export default withApollo(
  graphql(getInputApiUsers, {
    options: () => ({
      notifyOnNetworkStatusChange: true
    })
  })(InputApiUsers)
)
