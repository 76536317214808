import React, { Fragment } from 'react'

import { withApollo } from '@apollo/client/react/hoc'
import { faList, faSave } from '@fortawesome/free-solid-svg-icons'

import { withLocale } from 'locale'
import PropTypes from 'prop-types'

import { getFacility, updateProvider } from '../../facility.graphql'

import { ProviderSelect } from '.'
import Modal from '../../../../../components/modules/modal/modal'
import { ProcessingButton } from '../../../../modules/buttons/processing-button'

const UpdateModal = props => (
  <Modal
    toggleModalText={props.locale.facility.provider.update}
    button_type={{ icon_only: false, icon_type: faList, color: 'primary', outlined: false, classes: 'btn-sm mx-2' }}
    modal_size={'sm'}
  >
    <Update {...props} />
  </Modal>
)

class Update extends React.Component {
  static propTypes = {
    uuid: PropTypes.string.isRequired,
    providers: PropTypes.arrayOf(PropTypes.object).isRequired,
    currentProvider: PropTypes.object,
    client: PropTypes.object.isRequired,
    locale: PropTypes.object.isRequired,
    toggleModal: PropTypes.func
  }

  static defaultProps = {
    toggleModal: () => null
  }

  constructor(props) {
    super(props)

    this.state = {
      value: props.currentProvider?.id,
      processing: false
    }
  }

  onChange = value => {
    this.setState({
      value
    })
  }

  submit = () => {
    this.setState({ processing: true })

    this.props.client
      .mutate({
        mutation: updateProvider,
        variables: {
          uuid: this.props.uuid,
          provider_id: this.state.value
        },
        refetchQueries: [{ query: getFacility, variables: { uuid: this.props.uuid, version_number: '' } }],
        awaitRefetchQueries: true
      })
      .then(() => {
        this.setState({ processing: false })
        this.props.toggleModal()
      })
  }

  render = () => {
    const {
      onChange,
      props: { locale, providers },
      state: { value, processing },
      submit
    } = this

    return (
      <Fragment>
        <ProviderSelect {...{ providers, value, onChange }} />
        <div className="mt-3 text-right">
          <ProcessingButton processing={processing} onClick={submit} label={locale.save} icon={faSave} />
        </div>
      </Fragment>
    )
  }
}

export default withApollo(withLocale(UpdateModal))
