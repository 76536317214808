import React from 'react'

import { Button, Col, Container, FormGroup, Row } from 'reactstrap'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
// additional components
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { deleteImage, getFormDatumImages } from './images.graphql'

const Delete = ({ client, id, toggleModal, uuid, locale }) => {
  return (
    <React.Fragment>
      <DeleteForm toggleModal={toggleModal} client={client} image_uuid={id} facility_uuid={uuid} locale={locale} />
    </React.Fragment>
  )
}

class DeleteForm extends React.Component {
  submit = event => {
    event.preventDefault()
    event.stopPropagation()
    this.props.client
      .mutate({
        mutation: deleteImage,
        variables: {
          uuid: this.props.image_uuid
        },
        refetchQueries: [
          {
            query: getFormDatumImages,
            variables: {
              uuid: this.props.facility_uuid
            }
          }
        ]
      })
      .then(result => {
        this.cancel()
      })
  }
  cancel = () => this.props.toggleModal()
  render = () => {
    const { locale } = this.props
    const { cancel, submit } = this

    return (
      <FormGroup className="form-action">
        <Container>
          <Row>
            <Col sm="5">
              <a className="link-btn" onClick={cancel} href="#">
                {locale.cancel}
              </a>
            </Col>
            <Col sm="7" className="text-right">
              <Button outline onClick={submit} className="btn-labeled px-5" color="danger">
                <FontAwesomeIcon className="icon-prepend" icon={faTrashAlt} />
                <span>{locale.delete}</span>
              </Button>
            </Col>
          </Row>
        </Container>
      </FormGroup>
    )
  }
}

export default withApollo(
  graphql(getFormDatumImages, {
    options: props => ({
      notifyOnNetworkStatusChange: true,
      variables: {
        uuid: props.uuid
      }
    })
  })(Delete)
)
