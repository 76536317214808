import React from 'react'

import { graphql, withApollo } from '@apollo/client/react/hoc'

import { LegacySelect as Select } from 'components/modules/selects/react-select'

import { rolesQuery } from './graphql'

class Roles extends React.Component {
  roleOptions = () => {
    const { roles } = this.props.data
    let roleOptions = []
    roles.map(role => roleOptions.push({ value: role.id, label: role.name }))
    return roleOptions
  }

  render = () => {
    const { error, loading } = this.props.data
    const { role_id, changeRole } = this.props

    if (loading) return <Select isClearable={false} name="roles" value={role_id} onChange={changeRole} options={[]} />
    if (error) return <p>Error</p>
    if (error) return <p>Error</p>
    return (
      <Select
        isClearable={false}
        name="salutation"
        value={role_id}
        onChange={changeRole}
        options={this.roleOptions()}
      />
    )
  }
}

export default withApollo(
  graphql(rolesQuery, {
    options: () => ({
      notifyOnNetworkStatusChange: true
    })
  })(Roles)
)
