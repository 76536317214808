import { useCallback, useState } from 'react'

import { useMediaQuery } from 'react-responsive'

import { debounce } from 'lodash'
import PropTypes from 'prop-types'

import FacilitiesFilterContent from './facilitiesFilterContent/facilitiesFilterContent'
import FilterDropdown from './filterDropdown'
import FilterModal from './filterModal'

import './facilitiesFilter.scss'

const emptyFilter = {
  licenseOwnerIds: [],
  collectorIds: [],
  regionIds: [],
  trainingStatus: null,
  certificationEndDateRange: null,
  workflowStates: [],
  certificationTypes: []
}

const FacilitiesFilter = ({ currentSearchFilters, setCurrentSearchFilters }) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)

  // NOTE: currently the passed searchFilter is only used once to initialize.
  // After that, the filter state is entirely managed here and updates from
  // above won't propagate.
  const [filter, setFilterState] = useState(currentSearchFilters)

  const licenseOwnerFilterActive = filter.licenseOwnerIds.length > 0
  const collectorFilterActive = filter.collectorIds.length > 0
  const regionFilterActive = filter.regionIds.length > 0
  const trainingStatusFilterActive = filter.trainingStatus !== null
  const certificationEndDateRangeFilterActive = filter.certificationEndDateRange !== null
  const workflowStatesFilterActive = filter.workflowStates.length > 0
  const certificationTypesFilterActive = filter.certificationTypes.length > 0

  const activeFilterCount = [
    licenseOwnerFilterActive,
    collectorFilterActive,
    regionFilterActive,
    trainingStatusFilterActive,
    certificationEndDateRangeFilterActive,
    workflowStatesFilterActive,
    certificationTypesFilterActive
  ].filter(Boolean).length

  const debouncedSearchFilterUpdate = useCallback(
    // NOTE: we have to do this nested method thing, because otherwise the debounce will use a stale version of the setCurrentSearchFilters method
    debounce((setCurrentSearchFiltersMethod, newFilter) => setCurrentSearchFiltersMethod(newFilter), 350),
    []
  )

  const setFilter = newFilter => {
    setFilterState(newFilter)
    debouncedSearchFilterUpdate(setCurrentSearchFilters, newFilter)
  }

  const resetFilter = () => {
    setFilterState(emptyFilter)
    setCurrentSearchFilters(emptyFilter)
  }

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' }) // NOTE: sm breakpoint from bootstrap

  const FilterPopupElement = isMobile ? FilterModal : FilterDropdown

  return (
    <FilterPopupElement isOpen={isOpen} toggle={toggle} activeFilterCount={activeFilterCount}>
      <FacilitiesFilterContent toggle={toggle} setFilter={setFilter} resetFilter={resetFilter} filter={filter} />
    </FilterPopupElement>
  )
}

FacilitiesFilter.propTypes = {
  setCurrentSearchFilters: PropTypes.func.isRequired,
  currentSearchFilters: PropTypes.shape({
    licenseOwnerIds: PropTypes.arrayOf(PropTypes.string),
    collectorIds: PropTypes.arrayOf(PropTypes.string),
    regionIds: PropTypes.arrayOf(PropTypes.string),
    trainingStatus: PropTypes.string,
    certificationEndDateRange: PropTypes.shape({
      start: PropTypes.string.isRequired,
      end: PropTypes.string.isRequired
    }),
    workflowStates: PropTypes.arrayOf(PropTypes.string),
    certificationTypes: PropTypes.arrayOf(PropTypes.string)
  }).isRequired
}

export default FacilitiesFilter
