import React from 'react'

import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
  Tooltip
} from 'reactstrap'

import { Mutation, Query } from '@apollo/client/react/components'
import { withApollo } from '@apollo/client/react/hoc'
import { faDownload, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { createManual, deleteManual, getManuals } from './manuals.graphql'

import generateUUID from '../../../../helpers/guid'
import { TokenAuth } from '../../../../helpers/token-auth'
import Loading from '../../../helper/loading/loading'

const Manuals = props => (
  <Query query={getManuals}>
    {({ loading, error, data }) => {
      if (error) return <p>error</p>
      if (loading || !data) return <ManualList loading={true} manuals={[]} />
      return <ManualList {...props} manuals={data.manuals} />
    }}
  </Query>
)

const ManualList = props => (
  <div className="page-wrapper">
    <Row className="page-header">
      <Col sm="9">
        <h1>Handbuch und FAQ</h1>
      </Col>
      <Col sm="3" className="text-right">
        {TokenAuth.getPermissions().administrate_manuals ? (
          props.loading ? (
            <Button className="btn-labeled" color={'primary-light'}>
              <FontAwesomeIcon className="icon-prepend" icon={faPlus} />
              {<span>Neue Datei</span>}
            </Button>
          ) : (
            <Create {...props} id="1" name="222" locale={props.locale} />
          )
        ) : null}
      </Col>
    </Row>
    <hr className="seperator" />
    <Table style={{ tableLayout: 'auto' }} striped bordered>
      <thead className="thead-light">
        <tr>
          <th>Handbücher</th>
          {TokenAuth.getPermissions().administrate_manuals && <th width="50px"> </th>}
        </tr>
      </thead>
      <tbody>
        {!props.loading &&
          props.manuals.map((manual, i) => (
            <tr key={i}>
              <td>
                <a target="_blank" href={manual.file} download>
                  <FontAwesomeIcon icon={faDownload} /> {manual.name}
                </a>
              </td>
              {TokenAuth.getPermissions().administrate_manuals && (
                <td>
                  <Delete {...props} id={manual.id} name={manual.name} locale={props.locale} />
                </td>
              )}
            </tr>
          ))}
      </tbody>
    </Table>
    {props.loading && <Loading />}
  </div>
)

const CreateManual = ({ locale, toggle }) => {
  let name, file

  return (
    <Mutation
      mutation={createManual}
      update={(cache, { data: { create_manual } }) => {
        const { manuals } = cache.readQuery({ query: getManuals })
        cache.writeQuery({
          query: getManuals,
          data: { manuals: manuals.concat([create_manual]) }
        })
      }}
    >
      {create_manual => (
        <Form>
          <FormGroup>
            <Container>
              <Row>
                <Col sm={'2'} className="text-right">
                  <Label className="control-label" for={'name'}>
                    Name
                  </Label>
                </Col>
                <Col sm={'4'}>
                  <Input
                    required
                    type="name"
                    name="name"
                    id="name"
                    innerRef={node => {
                      name = node
                    }}
                  />
                </Col>

                <Col sm={'2'} className="text-right">
                  <Label className="control-label" for={'file'}>
                    Datei
                  </Label>
                </Col>
                <Col sm={'4'}>
                  <Input
                    required
                    type="file"
                    name="file"
                    id="file"
                    innerRef={node => {
                      file = node
                    }}
                  />
                  <FormText color="muted">Maximale Dateigröße: 10 MB</FormText>
                </Col>
              </Row>
            </Container>
          </FormGroup>
          <FormGroup className="form-action">
            <Container>
              <Row>
                <Col sm="5">
                  <a className="link-btn" onClick={toggle} href="#">
                    {locale.cancel}
                  </a>
                </Col>
                <Col sm="7" className="text-right">
                  <Button
                    className="btn-labeled px-5"
                    color="primary-light"
                    onClick={e => {
                      e.preventDefault()
                      name.value.length !== 0 &&
                        file.files.length !== 0 &&
                        create_manual({
                          variables: {
                            manual: {
                              name: name.value,
                              file: file.files[0]
                            }
                          }
                        })
                      file.value = null
                      name.value = ''
                      toggle()
                    }}
                  >
                    <FontAwesomeIcon className="icon-prepend" icon={faPlus} />
                    <span>Datei anlegen</span>
                  </Button>
                </Col>
              </Row>
            </Container>
          </FormGroup>
        </Form>
      )}
    </Mutation>
  )
}

class Delete extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
      processing: false,
      tooltip_open: false,
      tooltip_uuid: generateUUID()
    }
  }

  toggle = event => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    this.setState({
      modal: !this.state.modal
    })
  }

  toggleTooltip = () =>
    this.setState({
      tooltip_open: !this.state.tooltip_open
    })

  deleteManual = () => {
    this.setState({ processing: true })
    this.props.client
      .mutate({
        mutation: deleteManual,
        variables: {
          id: this.props.id
        },
        refetchQueries: [{ query: getManuals }]
      })
      .then(data => this.setState({ processing: false, modal: false }))
      .catch(error => this.setState({ processing: false, modal: false }))
  }

  render() {
    const {
      props: { className, name, locale },
      state: { modal, processing, tooltip_open, tooltip_uuid },
      deleteManual,
      toggle,
      toggleTooltip
    } = this

    return (
      <React.Fragment>
        <Button
          size={`sm`}
          className="btn-transparent btn-icon-only btn text-danger"
          color={'link'}
          onClick={toggle}
          id={`tooltip-modal_${tooltip_uuid}`}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </Button>
        <Tooltip
          delay={500}
          placement="top"
          isOpen={tooltip_open}
          target={`tooltip-modal_${tooltip_uuid}`}
          toggle={toggleTooltip}
        >
          {locale.delete}
        </Tooltip>
        <Modal size="lg" centered isOpen={modal} toggle={toggle} className={className}>
          <ModalHeader>{locale.delete_manual}</ModalHeader>
          <ModalBody>
            <p className="mt-3">
              Möchten Sie die Datei <strong className="text-danger">{name}</strong> wirklich löschen?
            </p>
            {processing && <Loading loadingText={`wird gelöscht...`} size={`1x`} />}
            <FormGroup className="form-action">
              <Container>
                <Row>
                  <Col sm="5">
                    <a className="link-btn" onClick={toggle} href="#">
                      {locale.cancel}
                    </a>
                  </Col>
                  <Col sm="7" className="text-right">
                    <Button outline onClick={deleteManual} className="btn-labeled px-5" color="danger">
                      <FontAwesomeIcon className="icon-prepend" icon={faTrashAlt} />
                      <span>{locale.delete}</span>
                    </Button>
                  </Col>
                </Row>
              </Container>
            </FormGroup>
          </ModalBody>
        </Modal>
      </React.Fragment>
    )
  }
}

class Create extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
      processing: false,
      tooltip_open: false,
      tooltip_uuid: generateUUID()
    }
  }

  toggle = event => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    this.setState({
      modal: !this.state.modal
    })
  }

  toggleTooltip = () =>
    this.setState({
      tooltip_open: !this.state.tooltip_open
    })

  render() {
    const {
      props: { className, locale },
      state: { modal },
      toggle
    } = this
    return (
      <React.Fragment>
        <Button className="btn-labeled" color={'primary-light'} onClick={toggle}>
          <FontAwesomeIcon className="icon-prepend" icon={faPlus} />
          {<span>Neue Datei</span>}
        </Button>
        <Modal size="lg" centered isOpen={modal} toggle={toggle} className={className}>
          <ModalHeader>{locale.create_manual}</ModalHeader>
          <ModalBody>
            <CreateManual locale={locale} toggle={toggle} />
          </ModalBody>
        </Modal>
      </React.Fragment>
    )
  }
}

export default withApollo(Manuals)
