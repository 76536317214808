import { Col, Row } from 'reactstrap'

import { withLocale } from 'locale'

import ScrollToTop from '../helper/scroll-to-top/scroll-to-top'
import Footer from '../modules/footer/footer'
import Header from '../modules/header/header'

const ApplicationLayout = ({ children, online, locale }) => (
  <>
    <Header locale={locale} online={online} />
    <ScrollToTop>
      <main role="main">
        <Row>
          <Col lg={{ size: 10, offset: 1 }}>{children}</Col>
        </Row>
      </main>
    </ScrollToTop>
    <Footer />
  </>
)

export default withLocale(ApplicationLayout)
