import React from 'react'

import { Button, ButtonGroup, Col, FormFeedback, Input, InputGroup, InputGroupAddon, Label, Row } from 'reactstrap'

import { HelpTooltip } from '../help-tooltip'
import Requirements from './Requirements'

const DecisionWithAddition = ({
  requirements_with_status,
  depth,
  help_text,
  help_image,
  field_number,
  uuid,
  changeFieldValue,
  parent_field_id,
  name,
  enabled,
  processing,
  value_de,
  value_en,
  field_id,
  unit_de,
  secondary_values,
  embedded,
  i,
  errors
}) => (
  <React.Fragment>
    <Row
      className={
        depth > 0
          ? `mt-0 mb-0${enabled ? ' ' : ' hidden '}parent-${parent_field_id || 'none'}`
          : `${enabled ? '' : 'hidden '}parent-${parent_field_id || 'none'}`
      }
    >
      <Col
        sm={{ size: 6 - depth, offset: depth }}
        lg={{ size: 3, offset: depth }}
        className={depth > 0 ? 'pt-3 pb-3-lg pl-4-md-down border-left border-secondary' : 'pl-4'}
      >
        <Label
          className={`control-label align-items-center justify-content-start ${
            errors?.value_de || errors?.secondary_values ? 'text-danger' : ''
          }`}
          for={uuid}
        >
          {field_number && <span className="text-primary-light">{field_number}&nbsp;&mdash;&nbsp;</span>}
          {name}
          <HelpTooltip help_text={help_text} help_image={help_image} uuid={uuid} />
        </Label>
        <Requirements requirements_with_status={requirements_with_status} />
      </Col>
      <Col
        sm={{ size: 6, offset: depth }}
        lg={{ size: 5, offset: 0 }}
        className={depth > 0 ? 'pb-3 pt-3-lg pl-4 border-left-md-only border-secondary' : ''}
      >
        <InputGroup className="align-items-center justify-content-sm-start">
          <ButtonGroup className="full-width">
            {unit_de
              .split('|')
              .slice(0, 2)
              .map((_unit_de, index) => {
                return (
                  <React.Fragment key={index}>
                    <Button
                      style={{ whiteSpace: 'normal' }}
                      disabled={processing}
                      key={index}
                      color="white"
                      onClick={() =>
                        changeFieldValue({
                          value_de: index.toString(),
                          uuid,
                          field_id,
                          embedded,
                          i
                        })
                      }
                      active={value_de === `${index}`}
                    >
                      {_unit_de.split(';')[0]}
                    </Button>{' '}
                  </React.Fragment>
                )
              })}
          </ButtonGroup>
        </InputGroup>
      </Col>
      {(errors?.value_de || errors?.secondary_values) && (
        <Col sm={{ size: 6, offset: depth }} lg={{ size: 5, offset: 3 + depth }}>
          <FormFeedback valid={false} className="d-block">
            {errors?.value_de}
          </FormFeedback>
          <FormFeedback valid={false} className="d-block">
            {errors?.secondary_values}
          </FormFeedback>
        </Col>
      )}
    </Row>
    {unit_de
      .split('|')
      .slice(2)
      .map((_unit_de, index) => {
        const name = _unit_de.split(';')[0] //first is name (label)
        const unit_de = _unit_de.split(';')[1] ? _unit_de.split(';')[1] : null //second is unit_de but optional
        return (
          <Row key={index} className="mt-0 mb-0">
            <Col
              sm={{ size: 5, offset: 1 }}
              lg={{ size: 4, offset: 1 }}
              className="pt-3 pb-3-lg pl-4 border-left border-secondary"
            >
              <Label className="control-label justify-content-start" for={uuid}>
                {field_number && <span className="text-primary-light">{field_number}&nbsp;&mdash;&nbsp;</span>}
                {name}
                <HelpTooltip help_text={help_text} help_image={help_image} uuid={uuid} uuid={uuid} />
              </Label>
            </Col>
            <Col sm={6} lg={4} className="pt-3 pb-3">
              <InputGroup>
                <Input
                  disabled={processing}
                  type={_unit_de.split(';').length === 2 ? 'number' : 'text'}
                  id={index}
                  onChange={e =>
                    changeFieldValue({
                      secondary_values_index: index.toString(),
                      uuid,
                      field_id,
                      embedded,
                      i,
                      secondary_value: e.target.value,
                      type: 'decisionWithAddition'
                    })
                  }
                  placeholder="Kein Wert eingetragen"
                  value={secondary_values[index]}
                />
                <React.Fragment>
                  <InputGroupAddon addonType="append">{unit_de}</InputGroupAddon>
                </React.Fragment>
              </InputGroup>
            </Col>
          </Row>
        )
      })}
  </React.Fragment>
)

export default DecisionWithAddition
