import { withLocale } from 'locale'
import PropTypes from 'prop-types'

import Modal from '../../../../modules/modal/modal'
import { ModalContent } from './modal_content'

const Update = ({ provider, locale }) => (
  <Modal toggleModalText={provider.name} button_type={{ color: 'transparent', classes: 'btn-link' }} modal_size="lg">
    <ModalContent {...{ provider }} />
  </Modal>
)

Update.propTypes = {
  provider: PropTypes.object.isRequired,
  locale: PropTypes.object.isRequired
}

export default withLocale(Update)
