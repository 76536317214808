/*
 * migration from IDB version 20200506103821 to 20200826143945
 *
 * add order_no to form in form_data
 */

const migrate = facility => ({
  ...facility,
  base_data: {
    ...facility.base_data,
    overview: {
      ...facility.base_data.overview,
      de: '',
      en: '',
      __typename: 'Translation'
    },
    accessibility_at_a_glance: {
      ...facility.base_data.accessibility_at_a_glance,
      de: '',
      en: '',
      __typename: 'Translation'
    }
  },
  permissions: {
    ...facility.permissions,
    update_confidential_base_data: false
  }
})

export default migrate
