import React from 'react'

import { Col, Input, Row, Table } from 'reactstrap'

// GraphQL
import { graphql, withApollo } from '@apollo/client/react/hoc'
// Icons
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import { getUsers } from './users.graphql'

import { DD_MM_YYYY__HH_mm } from '../../../helper/helper-functions'
import Loading from '../../../helper/loading/loading.jsx'
// additional components
import DeleteUserModal from '../../../modules/modal/deleteUserModal'
import Modal from '../../../modules/modal/modal'
import UpdateUserModal from '../../../modules/modal/updateUserModal'
import FormCreate from './create.jsx'

class Users extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      search_query: '',
      modal: false,
      delete_id: null,
      text: {
        header: '',
        body: '',
        confirm: '',
        cancel: ''
      }
    }
  }

  open = event => {
    this.setState({
      modal: true,
      delete_id: event.target.id
    })
  }

  change = event => this.setState({ [event.target.id]: event.target.value })

  searchUser = () => {
    const users = this.props.data.users
    const result = []
    if (this.state.search_query.length !== 0) {
      for (let i = 0; i < users.length; i++) {
        if (users[i].full_name && users[i].full_name.toLowerCase().includes(this.state.search_query.toLowerCase())) {
          result.push(users[i])
          continue
        }
        if (users[i].role?.name.toLowerCase().includes(this.state.search_query.toLowerCase())) {
          result.push(users[i])
          continue
        }
        if (users[i].license_owner?.name.toLowerCase().includes(this.state.search_query.toLowerCase())) {
          result.push(users[i])
          continue
        }
        if (users[i].email.toLowerCase().includes(this.state.search_query.toLowerCase())) {
          result.push(users[i])
        }
      }
      return result
    }
    return users
  }

  render = () => {
    const {
      data: { loading, error },
      locale,
      client,
      history
    } = this.props
    const { search_query } = this.state
    if (loading) return <Loading />
    if (error) return <p>{error}</p>

    const users = this.searchUser()

    return (
      <div className="page-wrapper">
        <Row className="page-header">
          <Col sm="6">
            <h1>{locale.user_management}</h1>
          </Col>
          <Col sm="3" className="text-right">
            <Input id="search_query" placeholder="Nutzer durchsuchen..." value={search_query} onChange={this.change} />
          </Col>
          <Col sm="3" className="text-right">
            <Modal
              children={<FormCreate locale={locale} client={client} history={history} />}
              toggleModalText={locale.add_user}
              button_type={{ icon_only: false, icon_type: faPlus, color: 'primary-light', classes: 'btn-labeled' }}
              modal_size={'lg'}
            />
          </Col>
        </Row>
        <hr className="seperator" />
        <Table striped responsive bordered>
          <thead className="thead-light">
            <tr>
              <th>{locale.email}</th>
              <th>{locale.name}</th>
              <th>{locale.license_owner.singular}</th>
              <th>{locale.role.singular}</th>
              <th>{locale.last_login}</th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {users.map(user => (
              <User key={user.id} user={user} client={client} locale={locale} />
            ))}
          </tbody>
        </Table>
      </div>
    )
  }
}

const User = ({ user, locale, history, client }) => (
  <tr>
    <td>
      <UpdateUserModal user={user} client={client} history={history} locale={locale} />
      {/*<Link to={`/admin/user/edit/${user.id}`}>{user.email}</Link>*/}
    </td>
    <td>{user?.full_name || '-'}</td>
    <td>{user?.license_owner?.name || '-'}</td>
    <td>{user?.role?.name || '-'}</td>
    <td>{DD_MM_YYYY__HH_mm(user?.last_login_at)}</td>
    <td className="text-center">
      {user.destroyable && (
        <DeleteUserModal id={user.id} client={client} name={user?.full_name} history={history} locale={locale} />
      )}
    </td>
  </tr>
)

export default withApollo(
  graphql(getUsers, {
    options: () => ({
      notifyOnNetworkStatusChange: true
    })
  })(Users)
)
