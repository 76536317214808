import { faCodeBranch } from '@fortawesome/free-solid-svg-icons'

import { withLocale } from 'locale'
import PropTypes from 'prop-types'

import VersionNumberBadge from './version_number_badge'

const ShownVersionBadge = ({ versionNumber, locale }) => (
  <VersionNumberBadge
    versionNumber={versionNumber}
    icon={faCodeBranch}
    tooltip={locale.shownVersionHelp(versionNumber)}
  />
)

ShownVersionBadge.propTypes = {
  versionNumber: PropTypes.string.isRequired,
  locale: PropTypes.shape({ shownVersionHelp: PropTypes.func.isRequired }).isRequired
}

export default withLocale(ShownVersionBadge, { key: 'facility.version_info' })
