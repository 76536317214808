import React from 'react'

import { Link } from 'react-router-dom'
import { Alert, Col, Container, FormGroup, Label, Row } from 'reactstrap'

import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { LegacySelect as ReactSelect } from 'components/modules/selects/react-select'
import { cloneDeep } from 'lodash'

import { getFacilitySectionData, getFormDataForSectionDatum, getFormDataWithoutSection } from './../facility.graphql'
import { createFormDatum, getFormDatumNew, updateFormDatum } from './form-datum.graphql'

import generateUUID from '../../../../helpers/guid'
import { ProcessingButton } from '../../../modules/buttons/processing-button'
import ValidatedInput from '../../../modules/inputs/validated-input'
import FieldDatum from './field_datum'
import Images from './images/images'

class FormDatum extends React.Component {
  /*
 FORMDATUM create
  uuid: ID
  facility_uuid: String
  name_de: String
  name_en: String
  embedded: Boolean
  form_id: ID
  alternative_form_datum_uuids: [String]
  section_datum_uuids: [String]
  sheet_number: String
  comment: String

   */

  /* FORMDATUM abfragen
  alternative_form_data: [FormDatum]
  changeable: Boolean
  comment: String
  deletable: Boolean
  embedded: Boolean
  facility: Facility
  field_data: [FieldDatum] @deprecated(reason: "we want to use it as a flat list, so use field_data_flat")
  field_data_flat: [FieldDatum]
  form: PublicForm
  form_id: ID
  form_name: String
  form_sheet_number: String
  images: [Image]
  name_de: String
  name_en: String
  possible_alternative_form_data: [FormDatum]
  possible_section_data: [SectionDatum]
  reportings: [Reporting]
  section_data: [SectionDatum]
  sheet_number: String
  uuid: ID
  viewable: Boolean

   */

  constructor(props) {
    super(props)
    if (this.props.new_form_datum) {
      const { field_data_flat, ...defaults } = this.props.new_form_datum

      //add uuids for fields and children
      const form_datum = {
        ...defaults,
        uuid: generateUUID(), //override clientside UUID
        field_data_flat: [
          ...this.props.new_form_datum.field_data_flat.map(normal_field => {
            const { embedded_form_datum, ...normal_field_rest } = normal_field
            if (embedded_form_datum) {
              const { field_data_flat: embedded_field_data_flat, ...embedded_rest } = embedded_form_datum
              return {
                embedded_form_datum: {
                  field_data_flat: embedded_field_data_flat.map(embedded_field => ({
                    ...embedded_field,
                    uuid: embedded_field.uuid || generateUUID()
                  })),
                  ...embedded_rest,
                  uuid: embedded_form_datum.uuid || generateUUID()
                },
                ...normal_field_rest,
                uuid: normal_field.uuid || generateUUID()
              }
            } else {
              return {
                embedded_form_datum,
                ...normal_field_rest,
                uuid: normal_field.uuid || generateUUID()
              }
            }
          })
        ]
      }

      form_datum.section_datum_uuids = this.props.match.params.section_datum_uuid
      this.state = form_datum
    }
    if (this.props.form_datum) {
      const { field_data_flat, ...defaults } = this.props.form_datum

      //add uuids for fields and children
      const form_datum = {
        ...defaults,
        field_data_flat: [
          ...this.props.form_datum.field_data_flat.map(normal_field => {
            const { embedded_form_datum, ...normal_field_rest } = normal_field
            if (embedded_form_datum) {
              const { field_data_flat: embedded_field_data_flat, ...embedded_rest } = embedded_form_datum
              return {
                embedded_form_datum: {
                  field_data_flat: embedded_field_data_flat.map(embedded_field => ({
                    ...embedded_field,
                    uuid: embedded_field.uuid || generateUUID()
                  })),
                  ...embedded_rest,
                  uuid: embedded_form_datum.uuid || generateUUID()
                },
                ...normal_field_rest,
                uuid: normal_field.uuid || generateUUID()
              }
            } else {
              return {
                embedded_form_datum,
                ...normal_field_rest,
                uuid: normal_field.uuid || generateUUID()
              }
            }
          })
        ]
      }

      this.state = form_datum
    }
  }

  routeToUpdate = uuid => {
    let route = this.props.match.url.includes('offline-formdatum') ? 'offline-formdatum' : 'formdatum'
    this.props.history.push(`/${route}/edit/${uuid}`)
  }

  formDatumAttributes = ({ formDatum, isCreate = false }) => {
    return {
      ...(isCreate && { uuid: formDatum.uuid }),
      facility_uuid: isCreate ? this.props.match.params.facility_uuid : this.props.form_datum.facility.uuid,
      name_de: formDatum.name_de,
      name_en: formDatum.name_en,
      form_id: formDatum.form_id,
      alternative_form_datum_uuids: formDatum.alternative_form_datum_uuids,
      section_datum_uuids: formDatum.section_datum_uuids,
      sheet_number: formDatum.sheet_number,
      comment: formDatum.comment,
      embedded: formDatum.embedded,
      field_data: formDatum.field_data_flat.map(field_datum => ({
        uuid: field_datum.uuid,
        ...(isCreate && { form_datum_uuid: formDatum.uuid }),
        field_id: field_datum.field_id,
        value_de: field_datum.value_de,
        value_en: field_datum.value_en,
        secondary_values: field_datum.secondary_values,
        linked_form_datum_uuids: field_datum.linked_form_datum_uuids,
        embedded_form_datum: field_datum.embedded_form_datum
          ? this.formDatumAttributes({
              formDatum: field_datum.embedded_form_datum,
              isCreate: isCreate
            })
          : null
      }))
    }
  }

  submit = e => {
    this.setState({ processing: true, disabled: true })

    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }

    if (this.props.isCreate) {
      const variables = {
        form_datum: this.formDatumAttributes({
          formDatum: this.state,
          isCreate: true
        })
      }

      let refetch = [
        {
          query: getFormDataWithoutSection,
          variables: {
            uuid: this.props.new_form_datum.facility.uuid,
            version_number: ''
          }
        },
        {
          query: getFacilitySectionData,
          variables: {
            uuid: this.props.new_form_datum.facility.uuid,
            version_number: ''
          }
        }
      ].concat(
        variables.form_datum.section_datum_uuids.split(',').map(sd_uuid => ({
          query: getFormDataForSectionDatum,
          variables: { uuid: sd_uuid }
        }))
      )

      this.props.client
        .mutate({
          mutation: createFormDatum,
          variables,
          refetchQueries: refetch
        })
        .then(() => {
          this.setState({ processing: false })
          this.routeToUpdate(variables.form_datum.uuid)
        })
        .catch(this.onError)
    } else {
      const variables = {
        uuid: this.state.uuid,
        form_datum: this.formDatumAttributes({ formDatum: this.state })
      }

      let refetch = [
        {
          query: getFormDatumNew,
          variables: { form_datum_uuid: variables.uuid }
        },
        {
          query: getFormDataWithoutSection,
          variables: {
            uuid: this.props.form_datum.facility.uuid,
            version_number: ''
          }
        },
        {
          query: getFacilitySectionData,
          variables: {
            uuid: this.props.form_datum.facility.uuid,
            version_number: ''
          }
        }
      ].concat(
        variables.form_datum.section_datum_uuids.split(',').map(sd_uuid => ({
          query: getFormDataForSectionDatum,
          variables: { uuid: sd_uuid }
        }))
      )

      this.props.client
        .mutate({
          mutation: updateFormDatum,
          variables,
          refetchQueries: refetch,
          awaitRefetchQueries: true
        })
        .then(() => {
          this.setState({
            processing: false,
            disabled: false,
            errors: {},
            error_msg: ''
          })
          this.routeToUpdate(variables.uuid)
        })
        .catch(this.onError)
    }
  }

  onChange = ({ target: { id, value } }) => this.setState({ [id]: value })

  onError = ({ graphQLErrors }) =>
    this.setState({
      processing: false,
      error_msg: graphQLErrors[0].message,
      errors: graphQLErrors[0].errors?.['form_datum']
    })

  changeFieldValue = ({
    uuid,
    value_de,
    value_en,
    field_id,
    type,
    i,
    j,
    embedded,
    value,
    secondary_value,
    secondary_values_index,
    event
  }) => {
    if (event && event.target.validity && !event.target.validity.valid) {
      return
    }

    //toggle linked_form
    //if its embedded look for the index of the field that has embedded (i) and add its value to state
    if (embedded) {
      const newFieldDataFlat = this.state.field_data_flat
      let field = this.state.field_data_flat[i].embedded_form_datum.field_data_flat[
        this.state.field_data_flat[i].embedded_form_datum.field_data_flat.findIndex(item => item.uuid === uuid)
      ]
      const newField = field
      if (typeof value_de !== 'undefined') {
        newField.value_de = value_de
      }
      if (typeof value_en !== 'undefined') {
        newField.value_en = value_en
      }

      if (type === 'decisionWithAddition') {
        newField.secondary_values[parseInt(secondary_values_index, 10)] = secondary_value
      }
      if (type === 'area') {
        newField.secondary_values[0] = secondary_value
      }

      let embeddedFiledDataFlat = newFieldDataFlat[i].embedded_form_datum.field_data_flat

      // toggle logic
      for (let i = 0; i < embeddedFiledDataFlat.length; i++) {
        let field_datum = embeddedFiledDataFlat[i]
        let parent = embeddedFiledDataFlat.find(fd => fd.field_id == field_datum.parent_field_id)
        embeddedFiledDataFlat[i].enabled = parent ? parent.enabled && parent.value_de === '1' : true
      }

      newFieldDataFlat[i].embedded_form_datum.field_data_flat = embeddedFiledDataFlat

      newFieldDataFlat[i].embedded_form_datum.field_data_flat[
        newFieldDataFlat[i].embedded_form_datum.field_data_flat.findIndex(item => item.uuid === uuid)
      ] = newField
      this.setState({ field_data_flat: newFieldDataFlat })
      return
    }

    if (type === 'area') {
      const index = this.state.field_data_flat.findIndex(item => item.uuid === uuid)
      let field = cloneDeep(this.state.field_data_flat[index])
      field.secondary_values[0] = secondary_value
      const newFieldDataFlat = this.state.field_data_flat
      newFieldDataFlat[index] = field
      this.setState({ field_data_flat: newFieldDataFlat })

      return
    }

    if (type === 'decisionWithAddition') {
      const index = this.state.field_data_flat.findIndex(item => item.uuid === uuid)
      let field = cloneDeep(this.state.field_data_flat[index])
      field.secondary_values[parseInt(secondary_values_index, 10)] = secondary_value
      const newFieldDataFlat = this.state.field_data_flat
      newFieldDataFlat[index] = field
      this.setState({ field_data_flat: newFieldDataFlat })

      return
    }

    if (type === 'linked_form') {
      const index = this.state.field_data_flat.findIndex(item => item.uuid === uuid)
      let field = cloneDeep(this.state.field_data_flat[index])
      field.linked_forms[i].linked_form_data[j].linked = !field.linked_forms[i].linked_form_data[j].linked
      if (field.linked_forms[i].linked_form_data[j].linked) {
        field.linked_form_datum_uuids = field.linked_form_datum_uuids || []
        field.linked_form_datum_uuids.push(value_de)
      } else {
        field.linked_form_datum_uuids = field.linked_form_datum_uuids.filter(linked_uuid => linked_uuid != value_de)
        if (field.linked_form_datum_uuids.length <= 0) {
          field.linked_form_datum_uuids = null
        }
      }
      const newFieldDataFlat = this.state.field_data_flat
      newFieldDataFlat[index] = field

      this.setState({ field_data_flat: newFieldDataFlat })

      return
    }

    const index = this.state.field_data_flat.findIndex(item => item.uuid === uuid)
    let field = cloneDeep(this.state.field_data_flat[index])
    if (typeof value_de !== 'undefined') field.value_de = value_de
    if (typeof value_en !== 'undefined') field.value_en = value_en
    const newFieldDataFlat = this.state.field_data_flat
    newFieldDataFlat[index] = field

    for (let i = 0; i < newFieldDataFlat.length; i++) {
      let field_datum = newFieldDataFlat[i]
      let parent = newFieldDataFlat.find(fd => fd.field_id == field_datum.parent_field_id)
      newFieldDataFlat[i].enabled = parent ? parent.enabled && parent.value_de === '1' : true
    }

    this.setState({ field_data_flat: newFieldDataFlat })
  }

  changeSections = section_datum_uuids => this.setState({ section_datum_uuids })
  changeAlternativeFormData = alternative_form_datum_uuids => this.setState({ alternative_form_datum_uuids })

  closeError = _ => this.setState({ error_msg: null })

  render = () => {
    const {
      changeFieldValue,
      onChange,
      submit,
      state,
      closeError,
      props: { locale, new_form_datum, form_datum, match, isRequirement }
    } = this

    const _props = {
      errors: state.errors,
      variables: state,
      locale,
      onChange
    }

    let facilityRoutePart = match.url.includes('offline-formdatum') ? 'offline-facility' : 'facility'

    return (
      <div className="page-wrapper">
        <Row className="page-header">
          <Col sm="9">
            <h1>Formular {new_form_datum ? 'erstellen' : 'bearbeiten'}</h1>
            {new_form_datum && (
              <h2>
                für {new_form_datum.facility.base_data.name_de} ({new_form_datum.form_name})
              </h2>
            )}
            {form_datum && (
              <h2>
                für {form_datum.facility.base_data.name_de} ({form_datum.form_name})
              </h2>
            )}
          </Col>
        </Row>
        <form noValidate className="box" onSubmit={submit}>
          <fieldset disabled={!(new_form_datum || form_datum).changeable || state.processing}>
            <Alert color="danger" isOpen={!!state.error_msg} toggle={closeError}>
              {state.error_msg}
            </Alert>
            <FormGroup>
              <Container>
                <Row>
                  <Col lg="6">
                    <Container>
                      <Row className="mb-0-lg mt-0">
                        <ValidatedInput
                          disabled={state.processing}
                          label_sm={4}
                          input_sm={6}
                          type="text"
                          id="name_de"
                          {..._props}
                        />
                      </Row>
                    </Container>
                  </Col>
                  <Col lg="6">
                    <Container>
                      <Row className="mb-0-lg mt-0">
                        <ValidatedInput
                          disabled={state.processing}
                          label_sm={4}
                          input_sm={6}
                          type="text"
                          id="name_en"
                          {..._props}
                        />
                      </Row>
                    </Container>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <Container>
                      <Row className="mb-0-lg mt-0">
                        <ValidatedInput
                          disabled={state.processing}
                          label_sm={4}
                          input_sm={6}
                          type="text"
                          maxlength="10"
                          id="sheet_number"
                          {..._props}
                        />
                      </Row>
                    </Container>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <Container>
                      <Row className="mb-0-lg mt-0">
                        <Col sm="4" className="text-right">
                          <Label className="control-label" for="name_de">
                            Zugehörige Teilbereiche
                          </Label>
                        </Col>
                        <Col sm="6">
                          <ReactSelect
                            isMulti
                            simpleValue
                            disabled={!(new_form_datum || form_datum).changeable || state.processing}
                            options={this.state.possible_section_data.map(section => ({
                              value: section.uuid,
                              label: section.name_de,
                              target: {
                                id: 'section_datum_uuids',
                                value: section.uuid
                              }
                            }))}
                            value={this.state.section_datum_uuids}
                            onChange={this.changeSections}
                          />
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                  <Col lg="6">
                    <Container>
                      <Row className="mb-0 mt-0">
                        <Col sm="4" className="text-right">
                          <Label className="control-label" for="name_de">
                            Alternative Formulare
                          </Label>
                        </Col>
                        <Col sm="6">
                          <ReactSelect
                            isMulti
                            simpleValue
                            disabled={!(new_form_datum || form_datum).changeable || state.processing}
                            options={this.state.possible_alternative_form_data.map(section => ({
                              value: section.uuid,
                              label: section.name_de,
                              target: {
                                id: 'alternative_form_datum_uuids',
                                value: section.uuid
                              }
                            }))}
                            value={this.state.alternative_form_datum_uuids}
                            onChange={this.changeAlternativeFormData}
                          />
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <Container>
                      <Row className="mb-0 mt-0">
                        <ValidatedInput
                          label_sm={4}
                          input_sm={6}
                          disabled={state.processing}
                          type="textarea"
                          name="comment"
                          id="comment"
                          {..._props}
                        />
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Container>
            </FormGroup>
            <FormGroup>
              <h3>Daten</h3>
              <hr className="seperator" />
              {state.field_data_flat.map((props, i) => {
                props = {
                  ...props,
                  processing: !(new_form_datum || form_datum).changeable || state.processing,
                  changeFieldValue,
                  i,
                  errors: state.errors?.field_data?.[i]
                }

                return <FieldDatum key={i} {...props} />
              })}
            </FormGroup>
            <FormGroup className="form-action fixed-bottom bg-white">
              <Container>
                <Row>
                  <Col sm={12}>
                    <Alert color="danger" isOpen={!!state.error_msg} toggle={closeError}>
                      {state.error_msg}
                    </Alert>
                  </Col>
                  <Col sm="2">
                    {new_form_datum && (
                      <Link
                        className="link-btn"
                        to={`/${facilityRoutePart}/${new_form_datum.facility.uuid}/${
                          new_form_datum.facility.is_current_version ? '' : new_form_datum.facility.version_number
                        }`}
                      >
                        <FontAwesomeIcon className="icon-prepend" icon={faArrowLeft} />
                        <span>zurück</span>
                      </Link>
                    )}
                    {form_datum &&
                      ((isRequirement && (
                        <Link
                          className="link-btn"
                          to={`/reportings/${form_datum.facility.uuid}/${form_datum.facility.version_number}`}
                        >
                          <FontAwesomeIcon className="icon-prepend" icon={faArrowLeft} />
                          <span>zurück</span>
                        </Link>
                      )) || (
                        <Link
                          className="link-btn"
                          to={`/${facilityRoutePart}/${form_datum.facility.uuid}/${
                            form_datum.facility.is_current_version ? '' : form_datum.facility.version_number
                          }`}
                        >
                          <FontAwesomeIcon className="icon-prepend" icon={faArrowLeft} />
                          <span>zurück</span>
                        </Link>
                      ))}
                  </Col>
                  <Col sm={{ size: '4', offset: '6' }} className="text-right">
                    {(new_form_datum || form_datum).changeable ? (
                      <ProcessingButton
                        type="submit"
                        onClick={submit}
                        processing={state.processing}
                        label={locale.save}
                      />
                    ) : null}
                  </Col>
                </Row>
              </Container>
            </FormGroup>
          </fieldset>
        </form>
        {new_form_datum ? null : <Images {...this.props} uuid={this.state.uuid} />}
      </div>
    )
  }
}

export default FormDatum
