// additional components
import React from 'react'

import { Button, Col, Container, FormGroup, Modal, ModalBody, ModalHeader, Row, Tooltip } from 'reactstrap'

import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { deleteRegion, getRegions } from '../../views/admin/regions/regions.graphql'

import generateUUID from '../../../helpers/guid'
import Loading from '../../helper/loading/loading'

class DeleteRegionModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      modal: false,
      processing: false,
      tooltip_open: false,
      tooltip_uuid: generateUUID()
    }
  }

  toggle = event => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    this.setState({
      modal: !this.state.modal
    })
  }

  toggleTooltip = () =>
    this.setState({
      tooltip_open: !this.state.tooltip_open
    })

  deleteRegion = () => {
    this.setState({ processing: true })
    this.props.client
      .mutate({
        mutation: deleteRegion,
        variables: {
          id: this.props.id
        },
        refetchQueries: [
          {
            query: getRegions
          }
        ]
      })
      .then(() => this.toggle())
  }

  render() {
    const { className, name, locale } = this.props
    const { modal, processing, tooltip_open, tooltip_uuid } = this.state
    const { deleteRegion, toggle, toggleTooltip } = this

    return (
      <React.Fragment>
        <Button
          size={`sm`}
          className="btn-transparent btn-icon-only btn text-danger"
          color={'link'}
          onClick={toggle}
          id={`tooltip-modal_${tooltip_uuid}`}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </Button>
        <Tooltip
          delay={500}
          placement="top"
          isOpen={tooltip_open}
          target={`tooltip-modal_${tooltip_uuid}`}
          toggle={toggleTooltip}
        >
          {locale.delete}
        </Tooltip>
        <Modal centered isOpen={modal} toggle={toggle} className={className} size={'lg'}>
          <ModalHeader>{locale.delete_region}</ModalHeader>
          <ModalBody>
            <p className="mt-3">
              Möchten Sie die Region <strong className="text-danger">{name}</strong> wirklich löschen?
            </p>
            {processing ? <Loading loadingText={`wird gelöscht...`} size={`1x`} /> : null}
            <FormGroup className="form-action">
              <Container>
                <Row>
                  <Col sm="5">
                    <a className="link-btn" onClick={toggle} href="#">
                      {locale.cancel}
                    </a>
                  </Col>
                  <Col sm="7" className="text-right">
                    <Button outline onClick={deleteRegion} className="btn-labeled px-5" color="danger">
                      <FontAwesomeIcon className="icon-prepend" icon={faTrashAlt} />
                      <span>{locale.delete}</span>
                    </Button>
                  </Col>
                </Row>
              </Container>
            </FormGroup>
          </ModalBody>
        </Modal>
      </React.Fragment>
    )
  }
}

export default DeleteRegionModal
