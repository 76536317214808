import { Query } from '@apollo/client/react/components'
import { withApollo } from '@apollo/client/react/hoc'

import { getFormDatumNew } from './form-datum.graphql'

import Loading from '../../../helper/loading/loading'
import FormDatum from './form_datum'

const EditFormDatumQueryComponent = props => {
  return (
    <Query
      fetchPolicy="network-only"
      query={getFormDatumNew}
      variables={{
        form_datum_uuid: props.match.params.uuid
      }}
    >
      {({ loading, error, data }) => {
        if (loading || !data) return <Loading />
        if (error) return `Error! ${error.message}`
        return <FormDatum {...props} {...data} isUpdate={true} />
      }}
    </Query>
  )
}

export default withApollo(EditFormDatumQueryComponent)
