import { useState } from 'react'

import {
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap'

import { faCoins, faPlusMinus, faTableList } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { withLocale } from 'locale'

const AccountingVolumeUnitsModal = ({ children, locale, generalLocale, balance, ownerName }) => {
  const [modal, setModal] = useState(false)

  const toggle = () => setModal(!modal)

  return (
    <>
      <InputGroup>
        <InputGroupText className={`px-3 flex-fill ${balance < 0 ? 'text-danger' : ''}`}>
          <FontAwesomeIcon icon={faCoins} className="mr-3" />
          {balance}
        </InputGroupText>
        <InputGroupAddon addonType="append">
          <Button color="primary" onClick={toggle} aria-label={locale.openDetails}>
            <FontAwesomeIcon icon={faTableList} className="mx-2" />
            <FontAwesomeIcon icon={faPlusMinus} className="mx-2" />
          </Button>
        </InputGroupAddon>
      </InputGroup>

      <Modal centered isOpen={modal} toggle={toggle} size={'lg'}>
        <ModalHeader toggle={toggle}>
          {locale.volumeUnits}: {ownerName}
        </ModalHeader>
        <ModalBody>{children}</ModalBody>
        <ModalFooter>
          <Button color="link" onClick={toggle}>
            {generalLocale.close}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default withLocale(withLocale(AccountingVolumeUnitsModal, { key: 'licenseOwners.volumeUnitAccount' }), {
  attributeName: 'generalLocale'
})
