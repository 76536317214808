import AccountingVolumeUnitsModal from './accountingVolumeUnitsModal'
import AccountingVolumeUnitsTransactionTable from './accountingVolumeUnitsTransactionTable'
import CreateTransaction from './createTransaction'

const VolumeUnitAccountManagement = ({ balance, ownerName, accountId }) => (
  <AccountingVolumeUnitsModal {...{ ownerName, balance }}>
    <CreateTransaction accountId={accountId} />
    <AccountingVolumeUnitsTransactionTable accountId={accountId} />
  </AccountingVolumeUnitsModal>
)

export { VolumeUnitAccountManagement }
