import { Badge } from 'reactstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import BadgeWithTooltip from 'components/modules/badge/badge_with_tooltip'
import PropTypes from 'prop-types'

const ComponentTypeBadge = ({ tooltip, icon, children, ...props }) => {
  const className = ['mx-1', props.className].filter(Boolean).join(' ')

  return (
    <BadgeWithTooltip tooltip={tooltip} className={className} pill {...props}>
      {icon && <FontAwesomeIcon icon={icon} className={children !== undefined ? 'mr-2' : ''} />}
      {children}
    </BadgeWithTooltip>
  )
}

ComponentTypeBadge.propTypes = {
  tooltip: PropTypes.node.isRequired,
  icon: PropTypes.any, // NOTE: font awesome icon definition. Ideally imported from the library and not a magic string
  children: PropTypes.node.isRequired,
  ...Badge.propTypes
}

export default ComponentTypeBadge
