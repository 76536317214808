// additional components
import React from 'react'

import { Alert } from 'reactstrap'

// GraphQL
import { graphql, withApollo } from '@apollo/client/react/hoc'

import update from 'immutability-helper'

import { createSection, getFormVersionAndForms, getSections } from './sections.graphql'

import Loading from '../../../helper/loading/loading.jsx'
import FormGroups from './form_groups'

const SectionCreate = ({ locale, history, client, toggleModal, id, data: { loading, error, forms } }) => {
  if (loading) return <Loading />
  if (error) return <p>Error</p>

  return (
    <SectionCreateForm
      client={client}
      form_version_id={id}
      history={history}
      locale={locale}
      possibleForms={forms}
      toggleModal={toggleModal}
    />
  )
}

class SectionCreateForm extends React.Component {
  UNSAFE_componentWillMount = () => {
    const { form_version_id } = this.props

    this.setState({
      error: false,
      error_msg: '',
      variables: {
        section: {
          form_version_id,
          name_de: '',
          name_en: '',
          missing_message_de: '',
          missing_message_en: '',
          standard_form_ids: [],
          additional_form_ids: []
        }
      }
    })
  }

  changeStandard = id_list => {
    this.setState(
      update(this.state, {
        variables: { section: { standard_form_ids: { $set: id_list.split(',') } } }
      })
    )
  }

  changeAdditional = id_list => {
    this.setState(
      update(this.state, {
        variables: { section: { additional_form_ids: { $set: id_list.split(',') } } }
      })
    )
  }

  change = event => {
    this.setState(
      update(this.state, {
        variables: { section: { [event.target.id]: { $set: event.target.value } } }
      })
    )
  }

  gqlValidationError = error =>
    this.setState({
      error_msg: error.graphQLErrors[0].message,
      error: true
    })

  submit = () => {
    const { variables } = this.state
    const { client, form_version_id } = this.props
    const { gqlValidationError } = this

    client
      .mutate({
        mutation: createSection,
        variables,
        refetchQueries: [
          {
            query: getSections,
            variables: {
              form_version_id: form_version_id
            }
          }
        ]
      })
      .then(() => this.props.toggleModal())
      .catch(error => gqlValidationError(error))
  }

  render = () => {
    const { possibleForms, toggleModal, locale } = this.props
    const { section } = this.state.variables
    const { change, changeAdditional, changeStandard, submit } = this

    return (
      <React.Fragment>
        {this.state.error ? <Alert color="danger">{this.state.error_msg}</Alert> : ''}
        <FormGroups
          change={change}
          changeAdditional={changeAdditional}
          changeStandard={changeStandard}
          locale={locale}
          possibleForms={possibleForms}
          section={section}
          submit={submit}
          toggleModal={toggleModal}
        />
      </React.Fragment>
    )
  }
}

export default withApollo(
  graphql(getFormVersionAndForms, {
    options: props => ({
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      variables: {
        id: props.id
      }
    })
  })(SectionCreate)
)
