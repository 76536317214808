// CSS
import React from 'react'

// additional components
import { GoogleApiWrapper, InfoWindow, Map, Marker } from 'google-maps-react'

import './_google-map.scss'

export class MapContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showingInfoWindow: false,
      activeMarker: {}
    }
  }

  onMarkerClick = (props, marker, e) =>
    this.setState({
      activeMarker: marker,
      showingInfoWindow: true
    })

  render() {
    return (
      <div className="google-map-container">
        <Map
          className="google-map"
          google={this.props.google}
          zoom={14}
          initialCenter={{ lat: this.props.lat, lng: this.props.lon }}
        >
          <Marker
            onClick={this.onMarkerClick}
            position={{ lat: this.props.lat, lng: this.props.lon }}
            name={this.props.name_de}
          />
          {this.props.marker_info && (
            <InfoWindow marker={this.state.activeMarker} visible={this.state.showingInfoWindow}>
              <div>
                <h4>{this.props.marker_info.name_de}</h4>
                <ul>
                  <li>
                    {this.props.marker_info.street} {this.props.marker_info.street_addition}
                  </li>
                  <li>
                    {this.props.marker_info.zipcode} {this.props.marker_info.city}
                  </li>
                </ul>
              </div>
            </InfoWindow>
          )}
        </Map>
      </div>
    )
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyD5xGA7_TeoOOX-W-NSPfDD_gXKeuXmV3c',
  language: 'de'
})(MapContainer)
