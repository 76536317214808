import React from 'react'

import { LegacySelect as Select } from 'components/modules/selects/react-select'

import { getPropertyFromObject } from '../../helper/helper-functions'

const SectionsForFormVersionSelect = ({ sections, id, errors, variables, onChange, selected_sections }) => {
  let options = []
  sections.map(section => {
    options.push({
      value: section.id,
      label: section.name_de,
      target: { id, value: section.id }
    })
  })

  return (
    <React.Fragment>
      <Select
        id={id}
        noOptionsMessage={_ => 'Keine Teilbereiche für diese Version verfügbar'}
        style={
          getPropertyFromObject(errors, id) && {
            borderColor: '#e53012'
          }
        }
        placeholder={'auswählen'}
        isClearable={false}
        name={id}
        isMulti
        value={getPropertyFromObject(selected_sections, id)}
        onChange={onChange}
        options={options}
      />
      {!!getPropertyFromObject(errors, id) && (
        <div
          style={{
            width: '100%',
            marginTop: '0.25rem',
            fontSize: '80%',
            color: '#e53012'
          }}
        >
          {getPropertyFromObject(errors, id)}
        </div>
      )}
    </React.Fragment>
  )
}
export default SectionsForFormVersionSelect
