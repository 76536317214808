import React from 'react'

import { Col, Input, Row, Table } from 'reactstrap'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import PropTypes from 'prop-types'

import { CreateProvider } from '.'
import Loading from '../../../helper/loading/loading'
import Modal from '../../../modules/modal/modal'
import { ProviderDetail } from './detail'
import { getProviders } from './graphql'

class Providers extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      searchQuery: '',
      providers: this.props.data.providers
    }
  }

  static propTypes = {
    client: PropTypes.object.isRequired,
    data: PropTypes.shape({
      error: PropTypes.object,
      loading: PropTypes.boolean,
      providers: PropTypes.arrayOf(PropTypes.object)
    }).isRequired,
    locale: PropTypes.object.isRequired
  }

  UNSAFE_componentWillReceiveProps = newProps => {
    const oldProviders = this.props.data.providers
    newProps.data.providers = newProps.data.providers.map(provider => {
      const isNew = oldProviders && !oldProviders.map(({ id }) => id)?.includes(provider.id)
      const isChanged =
        !isNew && oldProviders && oldProviders.find(({ id }) => id === provider.id).updated_at !== provider.updated_at

      return {
        ...provider,
        new: isNew,
        changed: isChanged
      }
    })

    this.setState({
      providers: newProps.data.providers
    })
  }

  change = e =>
    this.setState({
      searchQuery: e.target.value
    })

  search = () => {
    const providers = this.state.providers
    let searchQuery = this.state.searchQuery

    if (!searchQuery.length) return providers

    searchQuery = searchQuery.toLowerCase()

    return providers.filter(
      provider => provider.name.toLowerCase().includes(searchQuery) || provider.email.includes(searchQuery)
    )
  }

  removeChangeIndicators = () =>
    this.setState({
      providers: this.state.providers.map(provider => {
        provider.new = provider.changed = false
        return provider
      })
    })

  render = () => {
    let {
      change,
      props: {
        client,
        data: { error, loading },
        locale
      },
      search,
      state: { searchQuery, providers },
      removeChangeIndicators
    } = this

    if (loading) return <Loading />
    if (error) return <p>Error</p>

    if (providers.filter(provider => provider.new || provider.changed).length) setTimeout(removeChangeIndicators, 500)

    return (
      <div className="page-wrapper">
        <Row className="page-header">
          <Col sm="6">
            <h1>{locale.provider.plural}</h1>
          </Col>
          <Col sm="3" className="text-right">
            <Input id="search_query" placeholder={locale.provider.search} value={searchQuery} onChange={change} />
          </Col>
          <Col sm="3" className="text-right">
            <Modal
              toggleModalText={locale.provider.add}
              button_type={{ icon_only: false, icon_type: faPlus, color: 'primary-light', classes: 'btn-labeled' }}
              modal_size={'lg'}
            >
              <CreateProvider locale={locale} client={client} />
            </Modal>
          </Col>
        </Row>
        <hr className="seperator" />
        <Table striped responsive bordered>
          <thead className="thead-light">
            <tr>
              <th>{locale.name}</th>
              <th>{locale.email}</th>
              <th>{locale.facilities}</th>
              <th>{locale.created_at}</th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {search().map(provider => (
              <ProviderDetail key={provider.id} provider={provider} isNew={provider.new} changed={provider.changed} />
            ))}
          </tbody>
        </Table>
      </div>
    )
  }
}

export default withApollo(
  graphql(getProviders, {
    options: () => ({
      notifyOnNetworkStatusChange: true
    })
  })(Providers)
)
