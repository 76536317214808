import React from 'react'

import { Link } from 'react-router-dom'
import { Button, Col, Container, FormGroup, Input, Label, Row } from 'reactstrap'

import { faArrowLeft, faPlus } from '@fortawesome/free-solid-svg-icons'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const FormGroups = formdata => (
  <React.Fragment>
    <FormGroup>
      <Container>
        <Row>
          <Col sm="2" className="text-right">
            <Label className="control-label" for="name">
              {formdata.props.locale.label}
            </Label>
          </Col>
          <Col sm="4">
            <Input type="text" name="name" id="name" value={formdata.state.name} onChange={formdata.change} />
          </Col>
          <Col sm="2" className="text-right">
            <Label className="control-label" for="comment">
              {formdata.props.locale.comment}
            </Label>
          </Col>
          <Col sm="4">
            <Input type="text" name="comment" id="comment" value={formdata.state.comment} onChange={formdata.change} />
          </Col>
        </Row>
      </Container>
    </FormGroup>
    <FormGroup className="form-action">
      <Container>
        <Row>
          <Col sm="2">
            <Link className="link-btn" to="/form-kit/versions">
              <FontAwesomeIcon className="icon-prepend" icon={faArrowLeft} />
              <span>zurück</span>
            </Link>
          </Col>
          <Col sm={{ size: '4', offset: '6' }} className="text-right">
            <Button className="btn-labeled" color="primary-light" onClick={formdata.submit}>
              <FontAwesomeIcon className="icon-prepend" icon={faPlus} />
              <span>Speichern</span>
            </Button>
          </Col>
        </Row>
      </Container>
    </FormGroup>
  </React.Fragment>
)

export default FormGroups
