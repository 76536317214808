import gql from 'graphql-tag'

export const licenseOwnerQuery = gql`
  query license_owner_query {
    license_owners {
      id
      name
      sublicense_owners {
        id
        name
      }
    }
  }
`
