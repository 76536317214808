import { components as reactSelectComponents } from 'react-select'
import { Alert, Badge } from 'reactstrap'

import { useQuery } from '@apollo/client'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Select } from 'components/modules/selects/react-select'
import { withLocale } from 'locale'
import PropTypes from 'prop-types'

import { getCollectorsForFacilitiesFilter } from './collectorSelect.graphql'

// Extending the default select option component to show the parent license owner if present
const CollectorOptionWithEmail = props => {
  if (props.data.email) {
    return (
      <reactSelectComponents.Option {...props}>
        {props.data.full_name}
        <span className="d-block">
          <Badge color="light" className="text-wrap">
            <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
            {props.data.email}
          </Badge>
        </span>
      </reactSelectComponents.Option>
    )
  } else {
    return <reactSelectComponents.Option {...props} data={{ ...props.data, label: props.data.full_name }} />
  }
}

const CollectorSelect = ({ collectorIds, setCollectorIds, locale }) => {
  const { loading, error, data } = useQuery(getCollectorsForFacilitiesFilter)

  if (error) {
    console.error('Error fetching collectors', error)
    return (
      <Alert color="danger" className="m-0">
        Error fetching collectors
      </Alert>
    )
  }

  const options =
    data?.collectors
      ?.filter(({ collector }) => {
        // HACK: only show users working as collector. This filtering should be
        // done in the backend, but we currently have no endpoint for it and
        // must no implement one for this feature alone.
        return !!collector
      })
      ?.map(collector => ({
        value: collector.id,
        label: collector.full_name,
        full_name: collector.full_name,
        email: collector.email
      })) || []

  options.unshift({
    value: null,
    label: locale.none
  })

  const value = collectorIds.map(collectorId => options.find(option => option.value === collectorId))

  const onChange = selectedOptions => {
    setCollectorIds(selectedOptions.map(option => option.value))
  }

  const filterOption = ({ data: { full_name = '', email = '' } }, filterString) =>
    full_name.toLowerCase().includes(filterString.toLowerCase()) ||
    email.toLowerCase().includes(filterString.toLowerCase())

  return (
    <>
      <label>{locale.label}</label>
      <Select
        isLoading={loading}
        value={value}
        onChange={onChange}
        options={options}
        isMulti
        placeholder={locale.emptyPlaceholder}
        components={{ Option: CollectorOptionWithEmail }}
        menuPortalTarget={document.body}
        useReactWindowMenuList
        filterOption={filterOption}
      />
    </>
  )
}

CollectorSelect.propTypes = {
  collectorIds: PropTypes.array.isRequired,
  setCollectorIds: PropTypes.func.isRequired,
  locale: PropTypes.object.isRequired
}

export default withLocale(CollectorSelect, { key: 'facilitiesDashboard.filters.collector' })
