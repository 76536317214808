import React from 'react'

import { Alert } from 'reactstrap'

import { graphql, withApollo } from '@apollo/client/react/hoc'

import update from 'immutability-helper'

import { getRole, getRoles, updateRole } from './roles.graphql'

import Loading from '../../../helper/loading/loading.jsx'
import FormGroups from './form'

const RoleUpdate = ({ locale, history, client, toggleModal, data: { loading, error, role } }) => {
  if (loading) return <Loading />
  if (error) return <p>Error</p>
  return <RoleForm {...role} client={client} id={role.id} history={history} locale={locale} toggleModal={toggleModal} />
}

class RoleForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      variables: {
        id: this.props.id,
        role: {
          name: this.props.name,
          permission_keys: this.props.permissions
            .map(permission => (permission.permitted ? permission.key : null))
            .filter(item => item !== null),
          dsft: this.props.dsft
        }
      }
    }
  }

  onChange = event => {
    if (event.target.id === 'dsft-1' || event.target.id === 'dsft-0') {
      let value
      event.target.id.includes('0') ? (value = false) : (value = true)

      this.setState(
        update(this.state, {
          variables: {
            role: {
              dsft: {
                $set: value
              }
            }
          }
        })
      )
    } else {
      const depth = event.target.id.split('.').length
      const fragments = event.target.id.split('.')

      depth === 2 &&
        this.setState(
          update(this.state, {
            variables: {
              [fragments[0]]: {
                [fragments[1]]: {
                  $set: event.target.value
                }
              }
            }
          })
        )

      if (depth === 1) {
        if (event.target.id.slice(-1) === '1') {
          let add = this.state.variables.role.permission_keys
          add.push(event.target.name)
          this.setState(
            update(this.state, {
              variables: {
                role: {
                  permission_keys: {
                    $set: add
                  }
                }
              }
            })
          )
        } else {
          let removed = this.state.variables.role.permission_keys
          removed.splice(removed.indexOf(event.target.name), 1)
          this.setState({ selected_permission_ids: removed })
          this.setState(
            update(this.state, {
              variables: {
                role: {
                  permission_keys: {
                    $set: removed
                  }
                }
              }
            })
          )
        }
      }
    }
  }
  onError = ({ graphQLErrors }) =>
    this.setState({
      error_msg: graphQLErrors[0].message,
      errors: graphQLErrors[0].errors
    })

  submit = () =>
    this.props.client
      .mutate({
        mutation: updateRole,
        variables: this.state.variables,
        refetchQueries: [
          {
            query: getRoles
          }
        ]
      })
      .then(() => this.props.toggleModal())
      .catch(error => this.onError(error))

  render = () => (
    <React.Fragment>
      {this.state.errors && <Alert color="danger">{this.state.error_msg}</Alert>}
      <FormGroups
        locale={this.props.locale}
        permissions={this.props.permissions}
        variables={this.state.variables}
        errors={this.state.errors}
        onChange={this.onChange}
        submit={this.submit}
        toggleModal={this.props.toggleModal}
        changeable={this.props.changeable}
      />
    </React.Fragment>
  )
}

export default withApollo(
  graphql(getRole, {
    options: ({ id }) => ({
      notifyOnNetworkStatusChange: true,
      variables: {
        id
      }
    })
  })(RoleUpdate)
)
