import { Col, Input, Label, Row } from 'reactstrap'

import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Icon_visual from 'components/assets/guestgroup-icons/blind-grau.svg'
import Icon_visual_info from 'components/assets/guestgroup-icons/blind-grau_info.svg'
import Icon_walking from 'components/assets/guestgroup-icons/gehbehindert-grau.svg'
import Icon_walking_info from 'components/assets/guestgroup-icons/gehbehindert-grau_info.svg'
import Icon_deaf from 'components/assets/guestgroup-icons/gehoerlos-grau.svg'
import Icon_deaf_info from 'components/assets/guestgroup-icons/gehoerlos-grau_info.svg'
import Icon_partially_deaf from 'components/assets/guestgroup-icons/hoerbehindert-grau.svg'
import Icon_partially_deaf_info from 'components/assets/guestgroup-icons/hoerbehindert-grau_info.svg'
import Icon_mental from 'components/assets/guestgroup-icons/kognitiv-grau.svg'
import Icon_mental_info from 'components/assets/guestgroup-icons/kognitiv-grau_info.svg'
import Icon_wheelchair from 'components/assets/guestgroup-icons/rollstuhlfahrer-grau.svg'
import Icon_wheelchair_info from 'components/assets/guestgroup-icons/rollstuhlfahrer-grau_info.svg'
import Icon_partially_visual from 'components/assets/guestgroup-icons/sehbehindert-grau.svg'
import Icon_partially_visual_info from 'components/assets/guestgroup-icons/sehbehindert-grau_info.svg'
import { withLocale } from 'locale'
import PropTypes from 'prop-types'

const LEVEL_NOT_ACCESSIBLE = 'NOT_ACCESSIBLE'
const LEVEL_PARTIALLY_ACCESSIBLE = 'PARTIALLY_ACCESSIBLE'
const LEVEL_FULLY_ACCESSIBLE = 'FULLY_ACCESSIBLE'

const icons = {
  Icon_visual,
  Icon_visual_info,
  Icon_walking,
  Icon_walking_info,
  Icon_deaf,
  Icon_deaf_info,
  Icon_partially_deaf,
  Icon_partially_deaf_info,
  Icon_mental,
  Icon_mental_info,
  Icon_wheelchair,
  Icon_wheelchair_info,
  Icon_partially_visual,
  Icon_partially_visual_info
}

const getIconImage = ({ guestGroupKey, level }) => {
  if (level === LEVEL_NOT_ACCESSIBLE) return null

  const iconKey = `Icon_${guestGroupKey}${level === LEVEL_FULLY_ACCESSIBLE ? '' : '_info'}`
  return icons[iconKey]
}

const certificationLevelOrder = [LEVEL_NOT_ACCESSIBLE, LEVEL_PARTIALLY_ACCESSIBLE, LEVEL_FULLY_ACCESSIBLE]

const certificationLevelLocaleKeyMapping = {
  // HACK: mapping of enum values to legacy locale keys
  NOT_ACCESSIBLE: '0',
  PARTIALLY_ACCESSIBLE: '1',
  FULLY_ACCESSIBLE: '2'
}

const GuestGroupLevelChooser = ({ locale, guestGroupConfigs, guestGroupKey, updateCertificateConfiguration }) => (
  <Row className="mb-0 mt-5">
    <Col xs={12} md={3}>
      <strong>{locale.guest_group_categories[guestGroupKey]}</strong>
    </Col>
    {certificationLevelOrder.map(level => {
      const config = guestGroupConfigs.find(config => config.level === level)
      const checkboxId = `facilityShortReportVariableCertificateConfiguration-${guestGroupKey}-${level}`

      return (
        <Col key={level} xs={12} md={3} className="mt-4 mt-md-0">
          {/* HACK: this is mostly copy-pasted from `services/spa/src/components/modules/modal/updateCertificationStatusModal.jsx` */}
          <div className="checkbox-badge-container">
            <span className="checkbox-badge-icon">
              <img
                src={getIconImage({ guestGroupKey, level })}
                height="40"
                width="40"
                alt={locale.guest_group_category_description[guestGroupKey][certificationLevelLocaleKeyMapping[level]]}
              />
            </span>
            <Label className="control-label checkbox-label checkbox-badge justify-content-start" for={checkboxId}>
              <Input
                className="checkbox-control"
                name={checkboxId}
                defaultChecked={config.enabled}
                type="checkbox"
                id={checkboxId}
                onChange={_ => updateCertificateConfiguration({ guestGroupKey, level, enabled: !config.enabled })}
              />
              <span className="check-icon-container">
                <span className="check-icon text-primary-dark">
                  <FontAwesomeIcon icon={faCheck} />
                </span>
              </span>
              <span className="label-text">
                {locale.certification_level[certificationLevelLocaleKeyMapping[level]]}
              </span>
            </Label>
          </div>
        </Col>
      )
    })}
  </Row>
)

GuestGroupLevelChooser.propTypes = {
  locale: PropTypes.object.isRequired,
  guestGroupKey: PropTypes.string.isRequired,
  guestGroupConfigs: PropTypes.arrayOf(
    PropTypes.shape({
      level: PropTypes.string.isRequired,
      enabled: PropTypes.bool.isRequired
    })
  ).isRequired,
  updateCertificateConfiguration: PropTypes.func.isRequired
}

export default withLocale(GuestGroupLevelChooser)
