import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'

import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { withLocale } from 'locale'
import PropTypes from 'prop-types'

const CursorBasedPagination = ({ locale, pageInfo, totalCount, getItemsFromCursor, perPage }) => (
  <>
    <Pagination>
      <PaginationItem disabled={!pageInfo.hasPreviousPage}>
        <PaginationLink first onClick={_ => getItemsFromCursor({ first: perPage })}>
          <small>
            <FontAwesomeIcon icon={faAngleDoubleLeft} />
          </small>
        </PaginationLink>
      </PaginationItem>
      <PaginationItem disabled={!pageInfo.hasPreviousPage}>
        <PaginationLink previous onClick={_ => getItemsFromCursor({ last: perPage, before: pageInfo.startCursor })}>
          <small>
            <FontAwesomeIcon icon={faAngleLeft} />
          </small>
        </PaginationLink>
      </PaginationItem>
      <PaginationItem disabled>
        <PaginationLink tag="span" className="text-dark">
          {/* TODO: an actual i18n library would be really good here */}
          {locale.results({ total: totalCount })}
        </PaginationLink>
      </PaginationItem>
      <PaginationItem disabled={!pageInfo.hasNextPage}>
        <PaginationLink next onClick={_ => getItemsFromCursor({ first: perPage, after: pageInfo.endCursor })}>
          <small>
            <FontAwesomeIcon icon={faAngleRight} />
          </small>
        </PaginationLink>
      </PaginationItem>
      <PaginationItem disabled={!pageInfo.hasNextPage}>
        <PaginationLink last onClick={_ => getItemsFromCursor({ last: perPage })}>
          <small>
            <FontAwesomeIcon icon={faAngleDoubleRight} />
          </small>
        </PaginationLink>
      </PaginationItem>
    </Pagination>
  </>
)

CursorBasedPagination.propTypes = {
  pageInfo: PropTypes.shape({
    startCursor: PropTypes.string,
    endCursor: PropTypes.string,
    hasNextPage: PropTypes.bool,
    hasPreviousPage: PropTypes.bool
  }).isRequired,
  totalCount: PropTypes.number.isRequired,
  getItemsFromCursor: PropTypes.func.isRequired,
  perPage: PropTypes.number,
  locale: PropTypes.object.isRequired
}

export default withLocale(CursorBasedPagination, { key: 'pagination' })
