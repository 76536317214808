/*
 * migration from IDB version 20200506103821 to 20200720163715
 *
 * add form_data_count to facility
 */

const migrate = facility => ({
  ...facility,
  form_data_count:
    facility.form_data_without_section.length +
    facility.section_data.reduce((sum, sd) => (sum += sd.form_data.length), 0)
})

export default migrate
