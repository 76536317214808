// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "", "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"theme-colors-primary": "#1d5062",
	"theme-colors-secondary": "#6a7176",
	"theme-colors-success": "#a0c52a",
	"theme-colors-info": "#337ab7",
	"theme-colors-warning": "#fc0",
	"theme-colors-danger": "#e53012",
	"theme-colors-light": "#dee2e6",
	"theme-colors-dark": "#343a40",
	"theme-colors-primary-light": "#0080a6",
	"theme-colors-primary-dark": "#002335",
	"theme-colors-text-dark": "#212529",
	"theme-colors-text-light": "#fff",
	"theme-colors-input-border-color": "#6a7176",
	"theme-colors-input-bg": "#fafafa",
	"theme-colors-input-bg-disabled": "#f9f9f9",
	"theme-colors-table-bg": "#fff",
	"theme-colors-table-accent-bg": "#e9ecef"
};
export default ___CSS_LOADER_EXPORT___;
