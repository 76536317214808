import React, { Fragment } from 'react'

import { Alert, Button, Col, Container, FormGroup, Modal, ModalBody, ModalHeader, Progress, Row } from 'reactstrap'

import { faDownload, faTrashAlt, faUpload } from '@fortawesome/free-solid-svg-icons'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import moment from 'moment'

import { getSyncInformation } from '../../views/facility/facility.graphql'

import db from '../../../indexed-db/db'
// additional components
import Loading from '../../helper/loading/loading'
import { removeOffline, syncOnline } from '../offline'
import makeOfflineAvailable from '../offline/make_offline_available'

class OfflineFacilityModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      modal: false,
      processing: false,
      facilityOfflineAvailable: this.props.facility.download !== null,
      progress: {
        current: 0,
        max: 100,
        eta: null
      },
      allowedToSync: null,
      workflowMovedOn: false,
      storageRemainingInMegaByte: 0
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    let previousWorkflowState = this.props.facility.current_workflow_state.state
    let promises = []
    promises.push(db.keys('Facilities'))
    if (this.props.online) {
      promises.push(
        this.props.client.query({
          query: getSyncInformation,
          variables: {
            uuid: this.props.facility.uuid,
            download_id: this.props.facility.download?.id
          }
        })
      )
    }

    Promise.all(promises).then(results => {
      let facilityOfflineAvailable = results[0].includes(this.props.facility.uuid)
      let allowedToSync = this.props.online ? results[1].data.facility.permissions.sync : false
      let workflowMovedOn = this.props.online
        ? results[1].data.facility.current_workflow_state.state !== previousWorkflowState
        : false

      if ((!prevState || prevState.facilityOfflineAvailable != facilityOfflineAvailable) && this._mounted) {
        this.setState({
          facilityOfflineAvailable,
          allowedToSync,
          workflowMovedOn
        })
      }
    })
  }

  componentDidMount = () => {
    this._mounted = true
    this.componentDidUpdate()

    let thisCopy = this

    if (navigator.storage && navigator.storage.estimate) {
      navigator.storage.estimate().then(quota => {
        if (quota) {
          thisCopy.setState({
            storageRemainingInMegaByte: (quota.quota - quota.usage) / 1024 / 1024
          })
        }
      })
    }
  }

  componentWillUnmount = () => {
    this._mounted = false
  }

  isIOS = () => {
    let isIOS = false

    let devices = ['ipad', 'iphone', 'ipod', 'safari']

    if (navigator.platform)
      devices.forEach(device => {
        if (navigator.platform.toLowerCase() === device) isIOS = true
      })

    if (isIOS) return true

    if (!window.MSStream)
      devices.forEach(device => {
        if (navigator.userAgent.toLowerCase().includes(device) && !navigator.userAgent.toLowerCase().includes('chrome'))
          isIOS = true
      })

    return isIOS
  }

  clean = () => {
    if (this._mounted)
      this.setState({
        modal: false,
        modalDelete: false,
        modalSync: false,
        processing: false,
        progress: {
          current: 0,
          max: 0,
          eta: null
        }
      })
  }

  toggle = (key = 'modal') => event => {
    let cancel = {}
    if (event) {
      event.preventDefault()
      event.stopPropagation()
      cancel.processing = false
    }
    this.setState({
      ...cancel,
      [key]: !this.state[key]
    })
  }

  submit = () => {
    this.setState({ processing: true })
    console.time('facility download')
    makeOfflineAvailable({
      facility: this.props.facility,
      client: this.props.client,
      updateProgress: this.updateProgress
    })
      .catch()
      .then(_ => this.clean())
      .then(_ => {
        console.timeEnd('facility download')
        this.props.history.push(`/offline-facility/${this.props.facility.uuid}`)
      })
  }

  syncOnline = () => {
    this.setState({ processing: true })
    syncOnline({
      facility: this.props.facility,
      updateProgress: this.updateProgress,
      client: this.props.client
    }).then(_ => {
      if (this.props.match.url.includes('offline')) {
        return this.props.history.push(`/facility/${this.props.facility.uuid}`)
      }
    })
  }

  updateProgress = progress => {
    let current = progress.increment ? this.state.progress.current + progress.increment : this.state.progress.current
    let started_at = typeof progress.max === 'undefined' ? this.state.progress.started_at : new Date()

    let newProgress = {
      ...this.state.progress,
      current,
      started_at,
      ...progress
    }

    if (started_at && newProgress.max > 0 && newProgress.current > 0) {
      let time_diff = new Date().getTime() - started_at.getTime()
      let remaining = (time_diff * newProgress.max) / newProgress.current - time_diff
      let eta_time = new Date().getTime() + remaining
      newProgress.eta = new Date(eta_time)
    }

    this.setState({
      ...this.state,
      progress: newProgress
    })
  }

  removeOffline = () => {
    this.setState({ processing: true })
    removeOffline(this.props.facility, this.props.client).then(_ => {
      if (this.props.match.url.includes('offline')) {
        this.props.history.push(`/facility/${this.props.facility.uuid}`)
      }
      this.clean()
    })
  }

  render = () => {
    const { submit, toggle, syncOnline, removeOffline } = this
    const { className, facility, locale } = this.props
    const {
      modal,
      processing,
      facilityOfflineAvailable,
      progress,
      modalDelete,
      modalSync,
      allowedToSync,
      workflowMovedOn,
      storageRemainingInMegaByte
    } = this.state
    const ios = this.isIOS()

    if (this.props.online) {
      if (!modal && facilityOfflineAvailable) {
        return (
          <React.Fragment>
            {facility.permissions.update_barrier_free_data && (
              <React.Fragment>
                {allowedToSync !== false ? (
                  <Button
                    id="syncModal"
                    outline
                    color={'primary-light'}
                    className={'px-5 btn-labeled'}
                    onClick={allowedToSync ? toggle('modalSync') : () => null}
                    disabled={!allowedToSync}
                  >
                    {allowedToSync ? (
                      <React.Fragment>
                        <FontAwesomeIcon className="icon-prepend" icon={faUpload} />
                        <span>Synchronisieren</span>
                      </React.Fragment>
                    ) : (
                      <Loading loadingText="Berechtigung wird geprüft..." size="1x" inline />
                    )}
                  </Button>
                ) : (
                  <Alert color="danger" className="d-inline">
                    {locale.not_allowed_to_sync}
                  </Alert>
                )}
                {allowedToSync ? (
                  <Modal
                    centered
                    isOpen={modalSync}
                    toggle={processing ? () => null : toggle('modalSync')}
                    className={className}
                    size={'lg'}
                  >
                    <ModalHeader>
                      Offline {locale.facility.singular} {locale.sync}
                    </ModalHeader>
                    <ModalBody>
                      <p>
                        Möchten Sie die offline geänderten Daten des Betriebes{' '}
                        <strong className="text-primary-light">{facility.base_data.name_de}</strong> und der
                        dazugehörigen Formulare mit der Datenbank synchronisieren?
                      </p>
                      <p>
                        <strong className="text-danger">Achtung:</strong> Dabei werden alle Änderungen, die in der
                        Zwischenzeit an den gleichen Objekten in der Datenbank vorgenommen wurden, überschrieben.
                      </p>
                      <p>
                        Die Synchronisierung kann je nach Anzahl der lokal vorgenommenen Änderungen einige Minuten in
                        Anspruch nehmen.
                      </p>
                      {workflowMovedOn ? (
                        <Alert color="warning">
                          Der Workflow Status des Betriebes hat sich seit dem Herunterladen geändert. Stellen Sie
                          sicher, dass keine wichtigen Daten überschrieben werden.
                        </Alert>
                      ) : null}
                      {processing && (
                        <Fragment>
                          <Loading loadingText={`Betrieb wird synchronisiert, bitte warten...`} size={`1x`} />
                          <Progress value={(progress.current / progress.max) * 100} color="primary" />
                          {progress.eta ? (
                            <span>verbleibende Zeit: ca. {moment(progress.eta).fromNow(true)}</span>
                          ) : null}
                        </Fragment>
                      )}
                      <FormGroup className="form-action">
                        <Container>
                          <Row>
                            <Col sm="5">
                              {!processing && (
                                <a className="link-btn" onClick={toggle('modalSync')} href="#">
                                  {locale.cancel}
                                </a>
                              )}
                            </Col>
                            <Col sm="7" className="text-right">
                              <Button
                                id="triggerSync"
                                className="btn-labeled"
                                color="primary-light"
                                onClick={syncOnline}
                                disabled={processing}
                              >
                                <FontAwesomeIcon className="icon-prepend" icon={faUpload} />
                                <span>{locale.sync}</span>
                              </Button>
                            </Col>
                          </Row>
                        </Container>
                      </FormGroup>
                    </ModalBody>
                  </Modal>
                ) : null}
              </React.Fragment>
            )}
            <React.Fragment>
              <Button
                id="deleteOfflineModal"
                outline
                color={'danger'}
                className={'px-5 btn-labeled'}
                onClick={toggle('modalDelete')}
              >
                <FontAwesomeIcon className="icon-prepend" icon={faTrashAlt} />
                <span>{locale.delete_offline_facility}</span>
              </Button>
              <Modal
                centered
                isOpen={modalDelete}
                toggle={processing ? () => null : toggle('modalDelete')}
                className={className}
                size={'lg'}
              >
                <ModalHeader>{locale.delete_offline_facility}</ModalHeader>
                <ModalBody>
                  <p>
                    Möchten Sie alle für die Offline-Nutzung heruntergeladenen Daten des Betriebes{' '}
                    <strong className="text-primary-light">{facility.base_data.name_de}</strong> löschen?
                  </p>
                  <p>
                    <strong className="text-danger">Achtung:</strong> Alle Änderungen, die Sie offline vorgenommen
                    haben, gehen dabei verloren. Die in der Datenbank gespeicherten Daten bleiben dann unverändert.
                  </p>
                  {processing && (
                    <Fragment>
                      <Loading loadingText={`Betrieb wird gelöscht, bitte warten...`} size={`1x`} />
                    </Fragment>
                  )}
                  <FormGroup className="form-action">
                    <Container>
                      <Row>
                        <Col sm="5">
                          {!processing && (
                            <a className="link-btn" onClick={toggle('modalDelete')} href="#">
                              {locale.cancel}
                            </a>
                          )}
                        </Col>
                        <Col sm="7" className="text-right">
                          <Button
                            id="triggerSync"
                            className="btn-labeled"
                            color="primary-light"
                            onClick={removeOffline}
                            disabled={processing}
                          >
                            <FontAwesomeIcon className="icon-prepend" icon={faTrashAlt} />
                            <span>{locale.delete_offline_facility}</span>
                          </Button>
                        </Col>
                      </Row>
                    </Container>
                  </FormGroup>
                </ModalBody>
              </Modal>
            </React.Fragment>
          </React.Fragment>
        )
      } else {
        return (
          <React.Fragment>
            <Button id="offlineModal" outline color={'primary-light'} className={'px-5 btn-labeled'} onClick={toggle()}>
              <FontAwesomeIcon className="icon-prepend" icon={faDownload} />
              <span>Offline herunterladen</span>
            </Button>
            <Modal
              centered
              isOpen={modal}
              toggle={processing ? () => null : toggle()}
              className={className}
              size={'lg'}
            >
              <ModalHeader>
                {locale.facility.singular} {locale.download}
              </ModalHeader>
              <ModalBody>
                Möchten Sie den Betrieb <strong className="text-primary-light">{facility.base_data.name_de}</strong> und
                die dazugehörigen Formulare zur Bearbeitung offline verfügbar machen?
                <p>
                  Der Download kann je nach Größe des Betriebs und Anzahl der Formulare einige Minuten in Anspruch
                  nehmen.
                </p>
                <p>
                  <strong className="text-danger">Achtung: </strong>
                </p>
                <p>
                  Bitte beachten Sie, dass es aktuell beim Arbeiten mit der Offline-Funktionalität zu Problemen kommen
                  kann. Laden Sie deswegen KEINE Fotos während der Offline-Bearbeitung hoch, da dies zu
                  Speicherproblemen führen kann.
                </p>
                <p>
                  Die Arbeit mit diesem Dienst kann bei geringem Gerätespeicher zu Datenverlust führen! Vermeiden sie
                  das Hochladen großer Daten/Bilder.
                  {storageRemainingInMegaByte && storageRemainingInMegaByte <= 1024 && (
                    <p>
                      <strong className="text-danger">Der Verfügbare Speicher der Anwendung ist gering</strong>. Bitte
                      stellen Sie mehr freien Speicherplatz zur Verfügung.
                      <br></br>
                      Aktuell freier Speicherplatz: {storageRemainingInMegaByte.toFixed(2)} MB
                    </p>
                  )}
                </p>
                <p>
                  <strong className="text-primary-light">Hinweis zur Offline Arbeit: </strong>
                </p>
                <p>
                  Um den Betrieb <strong className="text-primary-light">{facility.base_data.name_de} </strong>
                  offline bearbeiten zu können, müssen Sie in der Datenbank angemeldet sein. Zum Anmelden in der
                  Datenbank wird eine aktive Internetverbindung benötigt. Melden Sie sich deshalb an, solange Sie noch
                  mit dem Internet verbunden sind und loggen sich bis zur Offline Erhebung nicht aus.
                  <br></br>
                  Es wird empfohlen, während einer Offline Erhebung die Verbindung Ihres Gerätes zum Internet zu
                  trennen.
                </p>
                <p>
                  <strong className="text-primary-light">Hinweis zum Browser: </strong>
                </p>
                <p>
                  Es wird empfohlen, den Google Chrome Browser zu nutzen.
                  {!ios &&
                    ' Aufgrund technischer Limitationen seitens Apple kann die Funktion auf iOS Geräten nicht garantiert werden.'}
                </p>
                {ios && (
                  <Alert color="danger">
                    Es wurde ein iOS Gerät oder Safari Browser erkannt. Die Offline Erhebung wird auf diesem Gerät
                    aufgrund technischer Limitationen seitens Apple nicht funktionieren.
                  </Alert>
                )}
                {processing && (
                  <Fragment>
                    <Loading loadingText={'Betrieb wird heruntergeladen, bitte warten...'} size={`1x`} />
                    <Progress value={(progress.current / progress.max) * 100} color="primary" />
                    {progress.eta ? <span>verbleibende Zeit: ca. {moment(progress.eta).fromNow(true)}</span> : null}
                  </Fragment>
                )}
                <FormGroup className="form-action">
                  <Container>
                    <Row>
                      <Col sm="5">
                        {!processing && (
                          <a className="link-btn" onClick={toggle()} href="#">
                            {locale.cancel}
                          </a>
                        )}
                      </Col>
                      <Col sm="7" className="text-right">
                        <Button
                          id="triggerOffline"
                          className="btn-labeled"
                          color="primary-light"
                          onClick={submit}
                          disabled={processing}
                        >
                          <FontAwesomeIcon className="icon-prepend" icon={faDownload} />
                          <span>{locale.download}</span>
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                </FormGroup>
              </ModalBody>
            </Modal>
          </React.Fragment>
        )
      }
    }
    return null
  }
}

export default OfflineFacilityModal
