import { createRef, useEffect, useState } from 'react'

import { Table } from 'reactstrap'

import { withApollo } from '@apollo/client/react/hoc'

import { arrayMoveImmutable } from 'array-move'
import cssVariables from 'components/app/_export.scss'
import cssText from 'helpers/cssText'
import rgb2hex from 'helpers/rgb2hex'
import { withLocale } from 'locale/'

import { getForm } from '../../../forms/forms.graphql'
import { getVariables, reorderVariable } from '../../short_report_variables.graphql'

import SortableBody from './SortableBody'

const RenderedTable = ({ form = null, variables: initialVariables, locale, client }) => {
  const tableRef = createRef()
  const [variables, setVariables] = useState(initialVariables)
  const [loading, setLoading] = useState(false)

  // save td element styles before sorting starts
  const updateBeforeSortStart = ({ node }, event) => {
    const rowBackground = window.getComputedStyle(node).getPropertyValue('background-color')
    event.rowEven = rowBackground && rgb2hex(rowBackground) !== cssVariables['theme-colors-table-accent-bg']
    event.styles = [...node.children].map(child => cssText(window.getComputedStyle(child)))
  }

  // apply td element styles to copied sorting container children
  const onSortStart = (_, event) => {
    const sortingElement = tableRef.current.querySelector('tr:last-child')
    sortingElement.style.backgroundColor = cssVariables[`theme-colors-table-${event.rowEven ? 'bg' : 'accent-bg'}`]
    ;[...sortingElement.children].forEach((child, index) => {
      child.style.cssText = event.styles[index]
    })
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const id = variables[oldIndex].id
    setLoading(true)
    setVariables(arrayMoveImmutable(variables, oldIndex, newIndex))
    client
      .mutate({
        mutation: reorderVariable,
        variables: {
          input: {
            id,
            position: variables[newIndex].position
          }
        },
        refetchQueries: [!!form ? { query: getForm, variables: { id: form.id } } : { query: getVariables }],
        awaitRefetchQueries: true
      })
      .then(_ => setLoading(false))
  }

  useEffect(
    _ => {
      setVariables[initialVariables]
    },
    [initialVariables]
  )

  return (
    <Table striped responsive bordered className="table-sortable" style={{ tableLayout: 'fixed' }}>
      <thead className="thead-light">
        <tr>
          <th>{locale.short_report.variable.name}</th>
          <th>{locale.short_report.variable.description}</th>
          {/* TODO: maybe show information about relevant certificate guest groups, when implemented */}
          <th></th>
        </tr>
      </thead>
      <SortableBody
        lockAxis="y"
        useDragHandle
        helperClass="sortable-helper"
        lockToContainerEdges
        helperContainer={_ => tableRef.current}
        useWindowAsScrollContainer
        {...{ variables, form, loading, tableRef, onSortEnd, updateBeforeSortStart, onSortStart }}
      />
    </Table>
  )
}

export default withApollo(withLocale(RenderedTable))
