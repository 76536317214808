import { faExpand } from '@fortawesome/free-solid-svg-icons'

import { withLocale } from 'locale/index'
import PropTypes from 'prop-types'

import ComponentTypeBadge from '../componentTypeBadge'

const FacilitySizeCalculationComponentType = ({ value, locale }) => (
  <ComponentTypeBadge color="info" tooltip={locale.tooltip} icon={faExpand}>
    {value}
  </ComponentTypeBadge>
)

FacilitySizeCalculationComponentType.propTypes = {
  value: PropTypes.string.isRequired,
  locale: PropTypes.shape({
    tooltip: PropTypes.string.isRequired
  }).isRequired
}

export default withLocale(FacilitySizeCalculationComponentType, {
  key: 'accounting_information.calculatedVolumeUnitCostsCalculationComponents.facilitySize'
})
