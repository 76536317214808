import { Col, Container, FormGroup, Row } from 'reactstrap'

import { ProcessingButton } from 'components/modules/buttons/processing-button'

export const FormGroupContainerActions = ({ locale, hideEditBoxRight, processing, submit }) => (
  <>
    <FormGroup className="form-action">
      <Container>
        <Row>
          <Col sm="5">
            <a
              className="link-btn"
              onClick={e => {
                e.preventDefault()
                hideEditBoxRight()
              }}
              href="#"
            >
              {locale.cancel}
            </a>
          </Col>
          <Col sm="7" className="text-right">
            <ProcessingButton processing={processing} onClick={submit} label={locale.save} />
          </Col>
        </Row>
      </Container>
    </FormGroup>
  </>
)
