import { Fragment } from 'react'

import { Button, Col, Row } from 'reactstrap'

const OnlineTraining = props => (
  <div className="page-wrapper">
    <Row className="page-header">
      <Col>
        <h1>{props.locale.online_training.header}</h1>

        <p>{props.locale.online_training.greeting},</p>

        {props.locale.online_training.paragraphs.map((paragraph, index) => {
          return <p key={index}>{paragraph}</p>
        })}

        <h2>{props.locale.online_training.registration.header}</h2>

        <p>{props.locale.online_training.registration.text_before}</p>

        <p>
          <Button
            color="primary"
            tag="a"
            href={props.locale.online_training.registration.button.href}
            target="_blank"
            size="lg"
          >
            {props.locale.online_training.registration.button.text}
          </Button>
        </p>

        <p>{props.locale.online_training.registration.text_after}</p>

        <p>
          {props.locale.online_training.footer.text.map((line, index) => {
            return (
              <Fragment key={index}>
                {line} {index < props.locale.online_training.footer.text.length - 1 ? <br /> : null}
              </Fragment>
            )
          })}
        </p>
        <p>
          {props.locale.online_training.footer.links.map((link, index) => {
            return (
              <Fragment key={index}>
                <a href={`https://${link}`} target="_blank">
                  {link}
                </a>
                <br />
              </Fragment>
            )
          })}
        </p>
      </Col>
    </Row>
  </div>
)

export default OnlineTraining
