import { Alert } from 'reactstrap'

import { useQuery } from '@apollo/client'

import { Select } from 'components/modules/selects/react-select'
import { withLocale } from 'locale'
import PropTypes from 'prop-types'

import { getRegionsForFacilitiesFilter } from './regionSelect.graphql'

const RegionSelect = ({ regionIds, setRegionIds, locale }) => {
  const { loading, error, data } = useQuery(getRegionsForFacilitiesFilter)

  if (error) {
    console.error('Error fetching regions', error)
    return (
      <Alert color="danger" className="m-0">
        Error fetching regions
      </Alert>
    )
  }

  const options =
    data?.regions?.map(region => ({
      value: region.id,
      label: region.name_de
    })) || []

  options.unshift({
    value: null,
    label: locale.none
  })

  const value = regionIds.map(regionId => options.find(option => option.value === regionId))

  const onChange = selectedOptions => {
    setRegionIds(selectedOptions.map(option => option.value))
  }

  return (
    <>
      <label>{locale.label}</label>
      <Select
        isLoading={loading}
        value={value}
        onChange={onChange}
        options={options}
        isMulti
        placeholder={locale.emptyPlaceholder}
        menuPortalTarget={document.body}
      />
    </>
  )
}

RegionSelect.propTypes = {
  regionIds: PropTypes.array.isRequired,
  setRegionIds: PropTypes.func.isRequired,
  locale: PropTypes.object.isRequired
}

export default withLocale(RegionSelect, { key: 'facilitiesDashboard.filters.regions' })
