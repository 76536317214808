import { useState } from 'react'

import { withApollo } from '@apollo/client/react/hoc'

import { withLocale } from 'locale/'

import { FormGroupContainerActions } from '../../partials'
import AddBundledFacilities from './form/add_bundled_facilities'
import RemoveBundledFacilities from './form/remove_bundled_facilities'

const BundleInformationForm = ({ facility, locale, hideEditBoxRight }) => {
  const actionProps = { locale, hideEditBoxRight, processing: false, submit: hideEditBoxRight }
  const [selectOptionsCacheKey, setSelectOptionsCacheKey] = useState(Date.now())

  return (
    <div className="box">
      <h3>{locale.facility.bundleInformation.label}</h3>

      <AddBundledFacilities {...{ facility, selectOptionsCacheKey, setSelectOptionsCacheKey }} />
      <RemoveBundledFacilities {...{ facility, selectOptionsCacheKey, setSelectOptionsCacheKey }} />

      <FormGroupContainerActions {...actionProps} />
    </div>
  )
}

export default withApollo(withLocale(BundleInformationForm))
