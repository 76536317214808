/*
 * migration from IDB version 20200110134640 to 20200306170946
 *
 * add field help_image to all field data
 *
 * add field provider to facility
 * add field possible_providers to facility
 */

const migrate = facility => ({
  ...facility,
  short_report: {
    ...facility.short_report,
    allergic_de: null,
    allergic_en: null,
    generations_de: null,
    generations_en: null
  }
})

export default migrate
