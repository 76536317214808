const migrate = facility => ({
  bundleInformation: {
    bundledBy: {
      totalCount: 0,
      nodes: []
    },
    bundledFacilities: {
      totalCount: 0,
      nodes: []
    }
  },
  ...facility
})

export default migrate
