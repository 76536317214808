import React, { Component, Fragment } from 'react'

import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
  UncontrolledTooltip
} from 'reactstrap'

import { Query } from '@apollo/client/react/components'
import { withApollo } from '@apollo/client/react/hoc'
import { faCheck, faEdit, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { deepNestInObjectsWithKeys } from 'helpers/object'
import update from 'immutability-helper'

import {
  createField,
  createRequirement,
  deleteRequirement,
  getField,
  getNewField,
  updateField,
  updateRequirement
} from '../../forms.graphql'

import generateUUID from '../../../../../../helpers/guid'
import { getPropertyFromObject } from '../../../../../helper/helper-functions'
import Loading from '../../../../../helper/loading/loading'
import { ProcessingButton } from '../../../../../modules/buttons/processing-button'
import ValidatedInput from '../../../../../modules/inputs/validated-input'
import RadioButtons from '../../../../../modules/radio-switch/radio-buttons'
import DynamicSelect from '../../../../../modules/selects/dynamicSelect'

const UpdateField = props => (
  <Query
    query={props.match.params.id ? getField : getNewField}
    variables={props.match.params.id ? { id: props.match.params.id } : { form_id: props.match.params.form_id }}
  >
    {({ loading, error, data }) => {
      if (loading || !data) return <Loading />
      if (error) return <p>Error! ${error.message}</p>
      return <Skeleton {...props} field={data.field || data.new_field} new_requirement={data.new_requirement || null} />
    }}
  </Query>
)

const Skeleton = props => (
  <div className="page-wrapper">
    <FieldForm {...props} />
    {props.new_requirement && <Requirements {...props} />}
    {props.loading && <Loading />}
  </div>
)

class FieldForm extends Component {
  constructor(props) {
    super(props)
    const {
      props: {
        field,
        field: {
          relevant_for_guest_groups: { __typename, ...relevant_for_guest_groups }
        }
      }
    } = this
    this.state = {
      delete_help_image: false,
      processing: false,
      variables: {
        id: field.id,
        field: {
          name: field.name || '',
          field_number: field.field_number || '',
          form_id: field.form_id,
          parent_id: field.parent_id,
          order_no: field.order_no,
          kind: field.kind || 'Checkbox',
          unit_de: field.unit_de || '',
          unit_en: field.unit_en || '',
          result_de: field.result_de || '',
          result_en: field.result_en || '',
          help_text: field.help_text || '',
          heading: field.heading,
          linked_form_ids: field.chosen_linked_forms?.map(item => item.id) || '',
          embedded_form_id: field.possible_embedded_form?.id || '',
          is_mandatory: field.is_mandatory,
          show_in_search: field.show_in_search,
          show_in_audit_report: field.show_in_audit_report,
          relevant_guest_groups: relevant_for_guest_groups
        }
      }
    }
  }

  submit = () => {
    this.setState({ processing: true })
    const {
      state: { variables },
      props: { client, field }
    } = this

    if (this.props.match.params.id) {
      client
        .mutate({
          mutation: updateField,
          variables
        })
        .then(result => this.onSuccess())
        .catch(error => this.onError(error))
    } else {
      const { field, id } = variables
      client
        .mutate({
          mutation: createField,
          variables: { field },
          refetchQueries: queriesToRefetch
        })
        .then(result => this.onSuccess())
        .catch(error => this.onError(error))
    }
  }

  onError = ({ graphQLErrors }) =>
    this.setState({
      processing: false,
      error_msg: graphQLErrors[0].message,
      errors: graphQLErrors[0].errors
    })

  onSuccess = result =>
    this.props.history.push(`/form-kit/form/${this.props.match.params.form_id || this.props.field.form_id}`)

  onChange = (event, id) => {
    if (Array.isArray(event)) {
      event = {
        target: {
          id: event[0]?.target?.id || id,
          value: event.map(({ target: { value } }) => value)
        }
      }
    }

    const fragments = event?.target?.id?.split('.')
    const depth = fragments?.length

    if (depth === 3) {
      this.setState(
        update(this.state, {
          variables: {
            [fragments[0]]: {
              [fragments[1]]: {
                [fragments[2]]: {
                  $set: !this.state.variables.field.relevant_guest_groups[fragments[2]]
                }
              }
            }
          }
        })
      )
    } else if (depth === undefined) {
      this.setState(
        update(this.state, {
          variables: { field: { linked_form_ids: { $set: event.split(',') } } }
        })
      )
    } else {
      const variables = deepNestInObjectsWithKeys(event.target.value, ...fragments, '$set')

      this.setState(update(this.state, { variables }))
    }
  }

  render = () => {
    const {
      onChange,
      submit,
      props: { locale, field, history },
      state: { errors, variables }
    } = this
    const props = {
      field,
      variables,
      errors,
      locale,
      onChange,
      label_sm: 4,
      input_sm: 8
    }
    return (
      <Fragment>
        <Row className="page-header">
          <Col sm="12">
            <h1>Feld bearbeiten</h1>
          </Col>
        </Row>
        <hr className="seperator" />
        <div className="box">
          {this.state.error_msg ? <Alert color="danger">{this.state.error_msg}</Alert> : ''}
          <Form>
            <FormGroup>
              <Row>
                <ValidatedInput id={'field.name'} type={'textarea'} {...props} />
              </Row>
              <Row>
                <ValidatedInput id={'field.field_number'} {...props} />
              </Row>
              <Row>
                <Col sm={props.label_sm} className="text-right">
                  <Label className="control-label" for={'field.parent_id'}>
                    {getPropertyFromObject(locale, 'field.parent_id')}
                  </Label>
                </Col>
                <Col sm={props.input_sm}>
                  <DynamicSelect
                    id={'field.parent_id'}
                    options={field.possible_parents.map(possible_parent => ({
                      label: possible_parent.name,
                      value: possible_parent.id,
                      target: {
                        id: 'field.parent_id',
                        value: possible_parent.id
                      }
                    }))}
                    {...props}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={props.label_sm} className="text-right">
                  <Label className="control-label" for={'field.order_no'}>
                    {getPropertyFromObject(locale, 'field.order_no')}
                  </Label>
                </Col>
                <Col sm={props.input_sm}>
                  <DynamicSelect
                    id={'field.order_no'}
                    options={field.siblings.map((sibling, i) => ({
                      value: sibling.order_no,
                      label: `(${i + 1}) ${sibling.name}`,
                      target: {
                        id: 'field.order_no',
                        value: sibling.order_no
                      }
                    }))}
                    {...props}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={props.label_sm} className="text-right">
                  <Label className="control-label" for={'field.kind'}>
                    {getPropertyFromObject(locale, 'field.kind')}
                  </Label>
                </Col>
                <Col sm={props.input_sm}>
                  <DynamicSelect
                    isDisabled={field.field_data_count > 0}
                    form_text={`Dieses Feld wurde schon ${field.field_data_count}x mit Daten gefüllt. Deswegen kann der Typ nicht geändert werden. Sie können das Feld löschen und ein neues an seiner Stelle anlegen. Dabei gehen alle Eingaben verloren.`}
                    id={'field.kind'}
                    options={[
                      {
                        value: 'Checkbox',
                        label: 'Ja/Nein',
                        target: {
                          id: 'field.kind',
                          value: 'Checkbox'
                        }
                      },
                      {
                        value: 'CheckboxNA',
                        label: 'Ja/Nein/Nicht anwendbar',
                        target: {
                          id: 'field.kind',
                          value: 'CheckboxNA'
                        }
                      },
                      {
                        value: 'String',
                        label: 'Kurztext',
                        target: {
                          id: 'field.kind',
                          value: 'String'
                        }
                      },
                      {
                        value: 'Text',
                        label: 'Freitext',
                        target: {
                          id: 'field.kind',
                          value: 'Text'
                        }
                      },
                      {
                        value: 'Fixnum',
                        label: 'Ganzzahl',
                        target: {
                          id: 'field.kind',
                          value: 'Fixnum'
                        }
                      },
                      {
                        value: 'Float',
                        label: 'Gleitkommazahl',
                        target: {
                          id: 'field.kind',
                          value: 'Float'
                        }
                      },
                      {
                        value: 'Area',
                        label: 'Fläche',
                        target: {
                          id: 'field.kind',
                          value: 'Area'
                        }
                      },
                      {
                        value: 'Select',
                        label: 'Auswahlfeld',
                        target: {
                          id: 'field.kind',
                          value: 'Select'
                        }
                      },
                      {
                        value: 'Multiselect',
                        label: 'Mehrfachauswahlfeld',
                        target: {
                          id: 'field.kind',
                          value: 'Multiselect'
                        }
                      },
                      {
                        value: 'DecisionWithAddition',
                        label: 'Entscheidung mit Zusatzfeldern',
                        target: {
                          id: 'field.kind',
                          value: 'DecisionWithAddition'
                        }
                      }
                    ]}
                    {...props}
                  />
                </Col>
              </Row>
              <Unit {...props} />
              <ResultDe {...props} />
              <Row>
                <ValidatedInput id={'field.result_en'} type={'textarea'} {...props} />
              </Row>
              <Row>
                <ValidatedInput id={'field.help_text'} type={'textarea'} {...props} />
              </Row>
              <Row>
                <Col sm={props.label_sm} className="text-right">
                  <Label className="control-label" for={'field.help_image'}>
                    {getPropertyFromObject(locale, 'field.help_image')}
                  </Label>
                </Col>
                <Col>
                  <Input
                    id={'field.help_image'}
                    type={'file'}
                    onChange={e =>
                      this.setState(
                        update(this.state, {
                          variables: {
                            field: { help_image: { $set: e.target.files[0] } }
                          }
                        })
                      )
                    }
                  />
                </Col>
              </Row>
              {this.props.field.help_image ? (
                <Row>
                  <Col sm={props.label_sm} className="text-right">
                    <Label className="control-label" for={'field.help_image_preview'}>
                      {getPropertyFromObject(locale, 'field.help_image_preview')}
                    </Label>
                  </Col>
                  <Col>
                    <img src={this.props.field.help_image.path_for_thumb} alt={this.props.field.help_text} />
                  </Col>
                </Row>
              ) : (
                ''
              )}
              {variables.field.kind === 'Checkbox' || variables.field.kind === 'CheckboxNA' ? (
                <Fragment>
                  <Row>
                    <Col sm={props.label_sm} className="text-right">
                      <Label className="control-label" for={'field.linked_form_ids'}>
                        {getPropertyFromObject(locale, 'field.linked_form_ids')}
                      </Label>
                    </Col>
                    <Col sm={props.input_sm}>
                      <DynamicSelect
                        id={'field.linked_form_ids'}
                        options={field.linked_forms.map(linked_form => ({
                          value: linked_form.id,
                          label: linked_form.name_de,
                          target: {
                            id: 'field.linked_form_ids',
                            value: linked_form.id
                          }
                        }))}
                        simpleValue={true}
                        isMulti={true}
                        {...props}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={props.label_sm} className="text-right">
                      <Label className="control-label" for={'field.embedded_form_id'}>
                        {getPropertyFromObject(locale, 'field.embedded_form_id')}
                      </Label>
                    </Col>
                    <Col sm={props.input_sm}>
                      <DynamicSelect
                        id={'field.embedded_form_id'}
                        options={field.embedded_forms.map(embedded_form => ({
                          value: embedded_form.id,
                          label: `${embedded_form.name_de}${embedded_form.in_use === false ? ' (deaktiviert)' : ''}`,
                          target: {
                            id: 'field.embedded_form_id',
                            value: embedded_form.id
                          }
                        }))}
                        {...props}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={props.label_sm} className="text-right">
                      <Label className="control-label" for={'field.heading'}>
                        {getPropertyFromObject(locale, 'field.heading')}
                      </Label>
                    </Col>
                    <Col sm={props.input_sm}>
                      <RadioButtons id="field.heading" {...props} />
                    </Col>
                  </Row>
                </Fragment>
              ) : null}
              <Row>
                <Col sm={props.label_sm} className="text-right">
                  <Label className="control-label" for={'field.is_mandatory'}>
                    {getPropertyFromObject(locale, 'field.is_mandatory')}
                  </Label>
                </Col>
                <Col sm={props.input_sm}>
                  <RadioButtons id="field.is_mandatory" {...props} />
                </Col>
              </Row>
              <Row>
                <Col sm={props.label_sm} className="text-right">
                  <Label className="control-label" for={'field.show_in_search'}>
                    {getPropertyFromObject(locale, 'field.show_in_search')}
                  </Label>
                </Col>
                <Col sm={props.input_sm}>
                  <RadioButtons id="field.show_in_search" {...props} />
                </Col>
              </Row>
              <Row>
                <Col sm={props.label_sm} className="text-right">
                  <Label className="control-label" for={'field.show_in_audit_report'}>
                    {getPropertyFromObject(locale, 'field.show_in_audit_report')}
                  </Label>
                </Col>
                <Col sm={props.input_sm}>
                  <RadioButtons id="field.show_in_audit_report" {...props} />
                </Col>
              </Row>
              <Row>
                <Col sm={props.label_sm} className="text-right">
                  <Label className="control-label" for={'field.relevant_guest_groups.label'}>
                    {getPropertyFromObject(locale, 'field.relevant_guest_groups.label')}
                  </Label>
                </Col>
                <Col sm={props.input_sm}>
                  <GuestGroups
                    sm_label={'3'}
                    sm_input={'1'}
                    relevant_for_guest_groups={variables.field?.relevant_guest_groups}
                    {...props}
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup className="form-action">
              <Container>
                <Row>
                  <Col sm="5">
                    <a
                      className="link-btn"
                      href="#"
                      onClick={e => {
                        e.preventDefault()
                        history.push(`/form-kit/form/${field.form_id}`)
                      }}
                    >
                      {locale.cancel}
                    </a>
                  </Col>
                  <Col sm="7" className="text-right">
                    <ProcessingButton
                      onClick={submit}
                      label={'speichern'}
                      no_icon={true}
                      processing={this.state.processing}
                    />
                  </Col>
                </Row>
              </Container>
            </FormGroup>
          </Form>
        </div>
      </Fragment>
    )
  }
}

const ResultDe = props => {
  let form_text
  switch (props.variables.field.kind) {
    case 'Fixnum':
      form_text = (
        <p>
          Bei Zahlen und Texten können folgende Platzhalter benutzt werden:
          <br />
          <br />
          <ul>
            <li>
              <strong>WERT</strong> → der tatsächlich eingetragenen Wert
            </li>
            <li>
              <strong>EINHEIT</strong> → die Einheit des Feldes
            </li>
          </ul>
        </p>
      )
      break
    case 'Float':
      form_text = (
        <p>
          Bei Zahlen und Texten können folgende Platzhalter benutzt werden:
          <br />
          <br />
          <ul>
            <li>
              <strong>WERT</strong> → der tatsächlich eingetragenen Wert
            </li>
            <li>
              <strong>EINHEIT</strong> → die Einheit des Feldes
            </li>
          </ul>
        </p>
      )
      break
    case 'Area':
      form_text = (
        <p>
          Bei Zahlen und Texten können folgende Platzhalter benutzt werden:
          <br />
          <br />
          <ul>
            <li>
              <strong>WERT1</strong> → der eingetragene Wert des ersten Feldes
            </li>
            <li>
              <strong>EINHEIT1</strong> → die Einheit des ersten Feldes
            </li>
            <li>
              <strong>WERT2</strong> → der eingetragene Wert des zweiten Feldes
            </li>
            <li>
              <strong>EINHEIT2</strong> → die Einheit des zweiten Feldes
            </li>
          </ul>
        </p>
      )
      break
    case 'Select':
      form_text = (
        <span>
          Bei Auswahlfeldern geben Sie die Antworten für die Ausgabe passend zu den möglichen Werten im Feld Einheit an
          und trennen diese mit | .
        </span>
      )
      break
    case 'Multiselect':
      form_text = (
        <span>
          Bei Auswahlfeldern geben Sie die Antworten für die Ausgabe passend zu den möglichen Werten im Feld Einheit an
          und trennen diese mit | .
        </span>
      )
      break
    case 'DecisionWithAddition':
      form_text = (
        <span>
          Geben Sie hier die zwei Werte für die möglichen Antworten getrennt durch | an. Beim zweiten Wert können
          zusätzlich folgende Platzhalter benutzt werden:
          <br />
          <br />
          <ul>
            <li>
              <strong>WERT1</strong> → der eingetragene Wert des ersten Feldes
            </li>
            <li>
              <strong>EINHEIT1</strong> → die Einheit des ersten Feldes
            </li>
            <li>
              <strong>WERT2</strong> → der eingetragene Wert des zweiten Feldes
            </li>
            <li>
              <strong>EINHEIT2</strong> → die Einheit des zweiten Feldes
            </li>
            <li>
              <strong>...</strong>
            </li>
          </ul>
        </span>
      )
      break
    default:
      form_text = ''
      break
  }
  return (
    <Row>
      <ValidatedInput id={'field.result_de'} type={'textarea'} form_text={form_text} {...props} />
    </Row>
  )
}

const Unit = props => {
  const show_unit_de_for = ['Fixnum', 'Float', 'Area', 'Select', 'Multiselect', 'DecisionWithAddition']
  if (show_unit_de_for.includes(props.variables.field.kind)) {
    return (
      <Fragment>
        <Row>
          <ValidatedInput
            id={'field.unit_de'}
            form_text={
              props.variables.field.kind === 'Area' &&
              'Flächenfelder müssen in folgendem Format angegeben werden: Länge;m|Breite;m'
            }
            {...props}
          />
        </Row>
        <Row>
          <ValidatedInput
            id={'field.unit_en'}
            form_text={
              props.variables.field.kind === 'Area' &&
              'Flächenfelder müssen in folgendem Format angegeben werden: Länge;m|Breite;m'
            }
            {...props}
          />
        </Row>
      </Fragment>
    )
  } else {
    return null
  }
}

const GuestGroups = props => {
  let guestgroups = []
  for (let group in props.relevant_for_guest_groups) {
    if (group === '__typename') continue
    guestgroups.push({
      name: group,
      is_active: props.relevant_for_guest_groups[group]
    })
  }
  return guestgroups.map((group, i) => (
    // <p key={i}>{group.name} {group.is_active ? 'yes' : 'no'}</p>
    <Checkbox key={i} id={`field.relevant_guest_groups.${group.name}`} {...props} />
  ))
}

const Checkbox = ({ onChange, variables, id, locale }) => (
  <React.Fragment>
    <Label className="control-label checkbox-label justify-content-start" for={id}>
      <Input
        className="checkbox-control"
        type="checkbox"
        id={id}
        defaultChecked={getPropertyFromObject(variables, id)}
        onChange={onChange}
      />
      <span className="check-icon-container">
        <span className="check-icon text-primary-dark">
          <FontAwesomeIcon icon={faCheck} />
        </span>
      </span>
      <Label className="control-label" for={id}>
        {getPropertyFromObject(locale, id)}
      </Label>
    </Label>
  </React.Fragment>
)

const Requirements = props => (
  <Fragment>
    <Row className="page-header">
      <Col sm="9">
        <h1>Anforderungen</h1>
      </Col>
      <Col sm="3" className="text-right">
        {props.field.kind === 'String' || props.field.kind === 'Text' ? null : (
          <CreateRequirement {...props} kind={props.field.kind} />
        )}
      </Col>
    </Row>
    <hr className="seperator" />
    {props.field.kind === 'String' || props.field.kind === 'Text' ? (
      <p>Für Textfelder können keine Anforderungen definiert werden.</p>
    ) : (
      <p>Fügen Sie für jede gewünschte Kombination aus Gästegruppe und Stufe die entsprechenden Anforderungen hinzu.</p>
    )}
    {props.field.kind === 'String' || props.field.kind === 'Text' || props.field.requirements.length === 0 ? null : (
      <Table striped responsive bordered>
        <thead className="thead-light">
          <tr>
            <th>Gästegruppe</th>
            <th>Kategorie</th>
            <th>Bedingung</th>
            <th>Alternativen</th>
            <th> </th>
          </tr>
        </thead>
        <tbody>
          {props.field?.requirements?.map((requirement, i) => (
            <tr key={i}>
              <td>{requirement.guest_group.label_explicit}</td>
              <td>{requirement.category}</td>
              <td>
                {requirement.equals && `= ${requirement.equals}`} {requirement.max && `<= ${requirement.max}`}{' '}
                {requirement.min && `>= ${requirement.min}`}
              </td>
              <td>{requirement.alternative_requirements?.length || 0}</td>
              <td className="text-center">
                <UpdateRequirement requirement={requirement} {...props} kind={props.field.kind} />
                <DeleteModal requirement={requirement} {...props} />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    )}
  </Fragment>
)

class DeleteModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
      processing: false,
      tooltip_uuid: generateUUID(),
      force: false
    }
  }

  toggle = event => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    this.setState({
      processing: false,
      modal: !this.state.modal
    })
  }

  submit = () => {
    this.setState({ processing: true })
    this.props.client
      .mutate({
        mutation: deleteRequirement,
        variables: {
          id: this.props.requirement.id
        },
        refetchQueries: [{ query: getField, variables: { id: this.props.match.params.id } }],
        awaitRefetchQueries: true
      })
      .then(() => this.toggle())
  }

  render() {
    const { className, locale, form } = this.props
    const { modal, processing, tooltip_uuid } = this.state
    const { submit, toggle } = this

    return (
      <React.Fragment>
        <Button
          size={`sm`}
          className="btn-transparent btn-icon-only btn text-danger"
          color={'link'}
          onClick={toggle}
          id={`tooltip-modal_${tooltip_uuid}`}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </Button>
        <UncontrolledTooltip delay={500} placement="top" target={`tooltip-modal_${tooltip_uuid}`}>
          {locale.delete}
        </UncontrolledTooltip>
        <Modal centered isOpen={modal} toggle={toggle} className={className} size={'lg'}>
          <ModalHeader>Anforderung löschen</ModalHeader>
          <ModalBody>
            <p className="mt-3">Möchten Sie die Anforderung wirklich löschen?</p>
            {processing ? <Loading loadingText={`wird gelöscht...`} size={`1x`} /> : null}
            <FormGroup className="form-action">
              <Container>
                <Row>
                  <Col sm="5">
                    <a className="link-btn" onClick={toggle} href="#">
                      {locale.cancel}
                    </a>
                  </Col>
                  <Col sm="7" className="text-right">
                    <Button outline onClick={submit} className="btn-labeled px-5" color="danger">
                      <FontAwesomeIcon className="icon-prepend" icon={faTrashAlt} />
                      <span>{locale.delete}</span>
                    </Button>
                  </Col>
                </Row>
              </Container>
            </FormGroup>
          </ModalBody>
        </Modal>
      </React.Fragment>
    )
  }
}

class CreateRequirement extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
      processing: false,
      variables: {
        requirement: {
          field_id: this.props.match.params.id,
          equals: '',
          min: '',
          max: '',
          guest_group: 'wheelchair',
          category: '1',
          alternative_requirement_ids: []
        }
      }
    }
  }

  toggle = event => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    this.setState({
      processing: false,
      modal: !this.state.modal
    })
  }

  onChange = event => {
    const fragments = event?.target?.id?.split('.')
    const variables = deepNestInObjectsWithKeys(event.target.value, ...fragments, '$set')

    this.setState(update(this.state, { variables }))
  }

  changeEquals = event =>
    this.setState(
      update(this.state, {
        variables: { requirement: { equals: { $set: event.split(',') } } }
      })
    )

  changeAlternatives = event =>
    this.setState(
      update(this.state, {
        variables: {
          requirement: {
            alternative_requirement_ids: { $set: event.split(',') }
          }
        }
      })
    )

  submit = () => {
    this.setState({ processing: true })
    const {
      field_id,
      equals,
      min,
      max,
      guest_group,
      category,
      alternative_requirement_ids
    } = this.state.variables.requirement

    this.props.client
      .mutate({
        mutation: createRequirement,
        variables: {
          requirement: {
            field_id,
            equals: Array.isArray(equals) ? equals.join('|') : equals,
            min,
            max,
            guest_group,
            category,
            alternative_requirement_ids:
              alternative_requirement_ids.indexOf('') === -1 ? alternative_requirement_ids : []
          }
        },
        refetchQueries: [{ query: getField, variables: { id: this.props.match.params.id } }],
        awaitRefetchQueries: true
      })
      .then(() => this.onSuccess())
      .catch(error => this.onError(error))
  }

  onError = ({ graphQLErrors }) =>
    this.setState({
      processing: false,
      error_msg: graphQLErrors[0].message,
      errors: graphQLErrors[0].errors
    })

  onSuccess = result =>
    this.setState(
      {
        processing: false,
        error_msg: '',
        errors: {}
      },
      this.toggle()
    )

  render() {
    const { className, locale, match, client, kind, new_requirement } = this.props
    const { modal, processing, variables, errors, error_msg } = this.state
    const { submit, toggle, onChange, changeAlternatives, changeEquals } = this

    const props = {
      variables,
      locale,
      match,
      client,
      onChange,
      errors,
      error_msg
    }

    return (
      <React.Fragment>
        <Button className={'btn-labeled'} color={'primary-light'} onClick={toggle} outline>
          <FontAwesomeIcon className={'icon-prepend'} icon={faPlus} />
          <span>{locale.requirement.create_label}</span>
        </Button>
        <Modal centered isOpen={modal} toggle={toggle} className={className} size={'lg'}>
          <ModalHeader>Anforderung hinzufügen</ModalHeader>
          <ModalBody>
            {this.state.error_msg ? <Alert color="danger">{this.state.error_msg}</Alert> : ''}
            {processing ? <Loading loadingText={`wird gespeichert...`} size={`1x`} /> : null}
            <FormGroup>
              <Container>
                <Row>
                  <Col sm="2" className="text-right">
                    <Label className="control-label" for={'requirement.guest_group'}>
                      {getPropertyFromObject(locale, 'requirement.guest_group')}
                    </Label>
                  </Col>
                  <Col sm="4">
                    <DynamicSelect
                      id={'requirement.guest_group'}
                      {...props}
                      options={new_requirement.possible_guest_groups.map(guest_group => ({
                        label: guest_group.label_explicit,
                        value: guest_group.key,
                        target: {
                          id: 'requirement.guest_group',
                          value: guest_group.key
                        }
                      }))}
                    />
                  </Col>

                  <Col sm="2" className="text-right">
                    <Label className="control-label" for={'requirement.category'}>
                      {getPropertyFromObject(locale, 'requirement.category')}
                    </Label>
                  </Col>
                  <Col sm="4">
                    <DynamicSelect
                      id={'requirement.category'}
                      {...props}
                      options={new_requirement.possible_guest_groups[
                        this.state.variables.requirement.guest_group === 'mental' ? 6 : 0
                      ].possible_categories.map(category => ({
                        label: category,
                        value: category,
                        target: { id: 'requirement.category', value: category }
                      }))}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="2" className="text-right">
                    <Label className="control-label" for={'requirement.label'}>
                      {getPropertyFromObject(locale, 'requirement.label')}
                    </Label>
                  </Col>
                  {kind === 'Fixnum' || kind === 'Float' || kind === 'Area' ? (
                    <Col>
                      <ValidatedInput
                        form_text={
                          kind === 'Area'
                            ? 'Bei Feldern des Typs "Fläche", folgende Syntax benutzen: Anforderung1|Anforderung2'
                            : null
                        }
                        label_sm={2}
                        input_sm={3}
                        id={'requirement.equals'}
                        {...props}
                        unit_de={'='}
                      />
                      <ValidatedInput
                        form_text={
                          kind === 'Area'
                            ? 'Bei Feldern des Typs "Fläche", folgende Syntax benutzen: Anforderung1|Anforderung2'
                            : null
                        }
                        label_sm={2}
                        input_sm={3}
                        id={'requirement.min'}
                        {...props}
                        unit_de={'>='}
                      />
                      <ValidatedInput
                        form_text={
                          kind === 'Area'
                            ? 'Bei Feldern des Typs "Fläche", folgende Syntax benutzen: Anforderung1|Anforderung2'
                            : null
                        }
                        label_sm={2}
                        input_sm={3}
                        id={'requirement.max'}
                        {...props}
                        unit_de={'<='}
                      />
                      {!!getPropertyFromObject(errors, 'requirement.base') && (
                        <div
                          style={{
                            width: '100%',
                            marginTop: '0.25rem',
                            fontSize: '80%',
                            color: '#e53012'
                          }}
                        >
                          {getPropertyFromObject(errors, 'requirement.base')}
                        </div>
                      )}
                    </Col>
                  ) : (
                    <Col sm="4">
                      <DynamicSelect
                        isMulti
                        simpleValue
                        id={'requirement.equals'}
                        {...props}
                        onChange={changeEquals}
                        options={new_requirement.possible_equals.map(possible_equal => ({
                          label: possible_equal.label,
                          value: possible_equal.key,
                          target: {
                            id: 'requirement.equals',
                            value: possible_equal.key
                          }
                        }))}
                      />
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col sm="2" className="text-right">
                    <Label className="control-label" for={'requirement.label'}>
                      {getPropertyFromObject(locale, 'requirement.alternative_requirement_ids')}
                    </Label>
                  </Col>
                  <Col sm="4">
                    <DynamicSelect
                      isMulti
                      simpleValue
                      id={'requirement.alternative_requirement_ids'}
                      {...props}
                      onChange={changeAlternatives}
                      options={new_requirement?.possible_alternative_requirements[
                        this.state.variables.requirement.guest_group
                      ][this.state.variables.requirement.category === '1' ? 'one' : 'two']?.map(requirement => ({
                        label: `${requirement.equals && `= ${requirement.equals}`}
                        ${requirement.min && `>= ${requirement.min}`}
                        ${requirement.max && `<= ${requirement.max}`}`,
                        value: requirement.id,
                        hint: `${requirement.field.field_number} - ${requirement.field.name}`,
                        target: {
                          id: 'requirement.equals',
                          value: requirement.id
                        }
                      }))}
                    />
                  </Col>
                </Row>
              </Container>
            </FormGroup>
            <FormGroup className="form-action">
              <Container>
                <Row>
                  <Col sm="5">
                    <a className="link-btn" onClick={toggle} href="#">
                      {locale.cancel}
                    </a>
                  </Col>
                  <Col sm="7" className="text-right">
                    <ProcessingButton onClick={submit} label={'speichern'} processing={this.state.processing} />
                  </Col>
                </Row>
              </Container>
            </FormGroup>
          </ModalBody>
        </Modal>
      </React.Fragment>
    )
  }
}

class UpdateRequirement extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tooltip_uuid: generateUUID(),
      modal: false,
      processing: false,
      variables: {
        id: this.props.requirement.id,
        requirement: {
          equals: this.props.requirement.equals,
          min: this.props.requirement.min,
          max: this.props.requirement.max,
          guest_group: this.props.requirement.guest_group.key,
          category: this.props.requirement.category,
          alternative_requirement_ids: this.props.requirement?.alternative_requirements?.map(req => req.id) || []
        }
      }
    }
  }

  toggle = event => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    this.setState({
      processing: false,
      modal: !this.state.modal
    })
  }

  onChange = event => {
    const fragments = event?.target?.id?.split('.')
    const variables = deepNestInObjectsWithKeys(event.target.value, ...fragments, '$set')

    this.setState(update(this.state, { variables }))
  }

  changeEquals = event =>
    this.setState(
      update(this.state, {
        variables: { requirement: { equals: { $set: event.split(',') } } }
      })
    )

  changeAlternatives = event =>
    this.setState(
      update(this.state, {
        variables: {
          requirement: {
            alternative_requirement_ids: { $set: event.split(',') }
          }
        }
      })
    )

  submit = () => {
    this.setState({ processing: true })
    const {
      field_id,
      equals,
      min,
      max,
      guest_group,
      category,
      alternative_requirement_ids
    } = this.state.variables.requirement
    const { id } = this.state.variables
    this.props.client
      .mutate({
        mutation: updateRequirement,
        variables: {
          id,
          requirement: {
            field_id,
            equals: Array.isArray(equals) ? equals.join('|') : equals,
            min,
            max,
            guest_group,
            category,
            alternative_requirement_ids:
              alternative_requirement_ids.indexOf('') === -1 ? alternative_requirement_ids : []
          }
        },
        refetchQueries: [{ query: getField, variables: { id: this.props.match.params.id } }],
        awaitRefetchQueries: true
      })
      .then(() => this.onSuccess())
      .catch(error => this.onError(error))
  }

  onError = ({ graphQLErrors }) =>
    this.setState({
      processing: false,
      error_msg: graphQLErrors[0].message,
      errors: graphQLErrors[0].errors
    })

  onSuccess = result =>
    this.setState(
      {
        processing: false,
        error_msg: '',
        errors: {}
      },
      this.toggle()
    )

  render() {
    const { className, locale, match, client, kind, new_requirement } = this.props
    const { modal, processing, variables, errors, error_msg, tooltip_uuid } = this.state
    const { submit, toggle, onChange, changeAlternatives, changeEquals } = this

    const props = {
      variables,
      locale,
      match,
      client,
      onChange,
      errors,
      error_msg
    }

    return (
      <React.Fragment>
        <Button
          size={`sm`}
          className="btn-transparent btn-icon-only btn text-primary"
          color={'link'}
          onClick={toggle}
          id={`tooltip-modal_${tooltip_uuid}`}
        >
          <FontAwesomeIcon icon={faEdit} />
        </Button>
        <UncontrolledTooltip delay={500} placement="top" target={`tooltip-modal_${tooltip_uuid}`}>
          {locale.edit}
        </UncontrolledTooltip>
        <Modal centered isOpen={modal} toggle={toggle} className={className} size={'lg'}>
          <ModalHeader>Anforderung hinzufügen</ModalHeader>
          <ModalBody>
            {this.state.error_msg ? <Alert color="danger">{this.state.error_msg}</Alert> : ''}
            {processing ? <Loading loadingText={`wird gespeichert...`} size={`1x`} /> : null}
            <FormGroup>
              <Container>
                <Row>
                  <Col sm="2" className="text-right">
                    <Label className="control-label" for={'requirement.guest_group'}>
                      {getPropertyFromObject(locale, 'requirement.guest_group')}
                    </Label>
                  </Col>
                  <Col sm="4">
                    <DynamicSelect
                      id={'requirement.guest_group'}
                      {...props}
                      options={new_requirement.possible_guest_groups.map(guest_group => ({
                        label: guest_group.label_explicit,
                        value: guest_group.key,
                        target: {
                          id: 'requirement.guest_group',
                          value: guest_group.key
                        }
                      }))}
                    />
                  </Col>

                  <Col sm="2" className="text-right">
                    <Label className="control-label" for={'requirement.category'}>
                      {getPropertyFromObject(locale, 'requirement.category')}
                    </Label>
                  </Col>
                  <Col sm="4">
                    <DynamicSelect
                      id={'requirement.category'}
                      {...props}
                      options={new_requirement.possible_guest_groups[
                        this.state.variables.requirement.guest_group === 'mental' ? 6 : 0
                      ].possible_categories.map(category => ({
                        label: category,
                        value: category,
                        target: { id: 'requirement.category', value: category }
                      }))}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="2" className="text-right">
                    <Label className="control-label" for={'requirement.label'}>
                      {getPropertyFromObject(locale, 'requirement.label')}
                    </Label>
                  </Col>
                  {kind === 'Fixnum' || kind === 'Float' || kind === 'Area' ? (
                    <Col>
                      <ValidatedInput
                        form_text={
                          kind === 'Area'
                            ? 'Bei Feldern des Typs "Fläche", folgende Syntax benutzen: Anforderung1|Anforderung2'
                            : null
                        }
                        label_sm={2}
                        input_sm={3}
                        id={'requirement.equals'}
                        {...props}
                        unit_de={'='}
                      />
                      <ValidatedInput
                        form_text={
                          kind === 'Area'
                            ? 'Bei Feldern des Typs "Fläche", folgende Syntax benutzen: Anforderung1|Anforderung2'
                            : null
                        }
                        label_sm={2}
                        input_sm={3}
                        id={'requirement.min'}
                        {...props}
                        unit_de={'>='}
                      />
                      <ValidatedInput
                        form_text={
                          kind === 'Area'
                            ? 'Bei Feldern des Typs "Fläche", folgende Syntax benutzen: Anforderung1|Anforderung2'
                            : null
                        }
                        label_sm={2}
                        input_sm={3}
                        id={'requirement.max'}
                        {...props}
                        unit_de={'<='}
                      />
                      {!!getPropertyFromObject(errors, 'requirement.base') && (
                        <div
                          style={{
                            width: '100%',
                            marginTop: '0.25rem',
                            fontSize: '80%',
                            color: '#e53012'
                          }}
                        >
                          {getPropertyFromObject(errors, 'requirement.base')}
                        </div>
                      )}
                    </Col>
                  ) : (
                    <Col sm="4">
                      <DynamicSelect
                        isMulti
                        simpleValue
                        id={'requirement.equals'}
                        {...props}
                        onChange={changeEquals}
                        options={new_requirement.possible_equals.map(possible_equal => ({
                          label: possible_equal.label,
                          value: possible_equal.key,
                          target: {
                            id: 'requirement.equals',
                            value: possible_equal.key
                          }
                        }))}
                      />
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col sm="2" className="text-right">
                    <Label className="control-label" for={'requirement.label'}>
                      {getPropertyFromObject(locale, 'requirement.alternative_requirement_ids')}
                    </Label>
                  </Col>
                  <Col sm="4">
                    <DynamicSelect
                      isMulti
                      simpleValue
                      id={'requirement.alternative_requirement_ids'}
                      {...props}
                      onChange={changeAlternatives}
                      options={new_requirement?.possible_alternative_requirements[
                        this.state.variables.requirement.guest_group
                      ][this.state.variables.requirement.category === '1' ? 'one' : 'two']?.map(requirement => ({
                        label: `${requirement.equals && `= ${requirement.equals}`}
                        ${requirement.min && `>= ${requirement.min}`}
                        ${requirement.max && `<= ${requirement.max}`}`,
                        value: requirement.id,
                        hint: `${requirement.field.field_number} - ${requirement.field.name}`,
                        target: {
                          id: 'requirement.equals',
                          value: requirement.id
                        }
                      }))}
                    />
                  </Col>
                </Row>
              </Container>
            </FormGroup>
            <FormGroup className="form-action">
              <Container>
                <Row>
                  <Col sm="5">
                    <a className="link-btn" onClick={toggle} href="#">
                      {locale.cancel}
                    </a>
                  </Col>
                  <Col sm="7" className="text-right">
                    <ProcessingButton onClick={submit} label={'speichern'} processing={this.state.processing} />
                  </Col>
                </Row>
              </Container>
            </FormGroup>
          </ModalBody>
        </Modal>
      </React.Fragment>
    )
  }
}

export default withApollo(UpdateField)
