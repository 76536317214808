/*
 * migration from IDB version 20200306170946 to 20200506103821
 *
 * add order_no to form in form_data
 */

const migrate = facility => ({
  ...facility,
  form_data_without_section: addOrderNoToForm(facility.form_data_without_section),
  section_data: facility.section_data.map(section_datum => ({
    ...section_datum,
    form_data: addOrderNoToForm(section_datum.form_data)
  }))
})

const addOrderNoToForm = form_data =>
  form_data.map((form_datum, index) => ({
    ...form_datum,
    form: {
      ...form_datum.form,
      order_no: index + 1
    }
  }))

export default migrate
