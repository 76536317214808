import React from 'react'

import { graphql, withApollo } from '@apollo/client/react/hoc'

import { LegacySelect as Select } from 'components/modules/selects/react-select'

import { salutationsQuery } from './graphql'

class Salutation extends React.Component {
  salutationOptions = () => {
    const { possible_salutations } = this.props.data
    let options = []
    possible_salutations.map(salutation => options.push({ value: salutation.key, label: salutation.label }))
    return options
  }

  render = () => {
    const { error, loading } = this.props.data
    const { salutation, onChange } = this.props

    if (loading)
      return (
        <Select
          isClearable={false}
          name="salutation"
          value={salutation}
          placeholder="auswählen..."
          onChange={onChange}
          options={[]}
        />
      )
    if (error) return <p>Error</p>
    return (
      <Select
        isClearable={false}
        name="salutation"
        value={salutation}
        placeholder="auswählen..."
        onChange={onChange}
        options={this.salutationOptions()}
      />
    )
  }
}

export default withApollo(
  graphql(salutationsQuery, {
    options: () => ({
      notifyOnNetworkStatusChange: true
    })
  })(Salutation)
)
