import { useCallback, useState } from 'react'

import { Button, Input, InputGroup, InputGroupAddon } from 'reactstrap'

import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { withLocale } from 'locale'
import { debounce } from 'lodash'
import PropTypes from 'prop-types'

const SearchQueryInput = ({ locale, setSearchQuery }) => {
  const [value, setValue] = useState('')

  // NOTE: we have to do this nested method thing, because otherwise the debounce will use a stale version of the setCurrentSearchFilters method
  const debouncedUpdateCall = useCallback(
    debounce((setSearchQueryMethod, newValue) => setSearchQueryMethod(newValue), 350),
    []
  )

  const onChange = event => {
    setValue(event.target.value)
    debouncedUpdateCall(setSearchQuery, event.target.value)
  }

  const clearSearchQuery = () => {
    setValue('')
    setSearchQuery('')
  }

  return (
    <InputGroup>
      <Input placeholder={locale.searchQuery} value={value} onChange={onChange} className="px-3" />
      {value && (
        <InputGroupAddon addonType="append">
          <Button color="primary-light" onClick={clearSearchQuery} className="px-3">
            <FontAwesomeIcon icon={faXmark} />
          </Button>
        </InputGroupAddon>
      )}
    </InputGroup>
  )
}

SearchQueryInput.propTypes = {
  searchQuery: PropTypes.string.isRequired,
  setSearchQuery: PropTypes.func.isRequired,
  locale: PropTypes.object.isRequired
}

export default withLocale(SearchQueryInput, { key: 'facilitiesDashboard' })
