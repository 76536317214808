import React, { Fragment } from 'react'

import { Alert, Button, ButtonGroup, Col, Label } from 'reactstrap'

import { withApollo } from '@apollo/client/react/hoc'

import moment from 'moment'

import { getFacility } from '../../facility.graphql'
import { updateFacilityAccountingInformation } from './information.graphql'

import ValidatedInformationInput from '../../../../modules/inputs/validated-information-input'
import DynamicSelect from '../../../../modules/selects/dynamicSelect'
import { FormGroupContainer, FormGroupContainerActions } from '../partials'

const AccountingInformationForm = props => (
  <div className="box">
    <Form {...props} />
  </div>
)

class Form extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      processing: false,
      variables: this.getVariables(props),
      errors: [],
      error_msg: ''
    }
  }

  getVariables = props => ({
    uuid: props.facility.uuid,
    accounting_information: {
      ...props.facility.accounting_information,
      licence_owner_billing_type: props.facility.accounting_information.licence_owner_billing_type?.key
    }
  })

  onChange = (fieldKey, value) => e => {
    let newState = this.state
    newState.variables.accounting_information[fieldKey] =
      typeof value === 'function'
        ? value(e)
        : typeof value !== 'undefined'
        ? value
        : typeof e === 'string'
        ? e
        : e?.target?.value || null
    this.setState(newState)
  }

  onError = ({ graphQLErrors }) =>
    this.setState({
      processing: false,
      error_msg: graphQLErrors[0].message,
      errors: graphQLErrors[0].errors
    })

  onSuccess = () =>
    this.setState(
      {
        processing: false,
        error_msg: '',
        errors: {}
      },
      this.props.hideEditBoxRight()
    )

  submit = () => {
    this.setState({ processing: true })
    const {
      props: {
        client,
        facility: { uuid },
        match: {
          params: { version_number }
        }
      },
      state: { variables }
    } = this

    client
      .mutate({
        mutation: updateFacilityAccountingInformation,
        variables: {
          ...variables,
          accounting_information: {
            ...variables.accounting_information,
            possible_licence_owner_billing_types: undefined,
            __typename: undefined
          }
        },
        refetchQueries: [
          {
            query: getFacility,
            variables: {
              uuid,
              version_number: version_number || ''
            }
          }
        ],
        awaitRefetchQueries: true
      })
      .then(() => this.onSuccess())
      .catch(error => this.onError(error))
  }

  render = () => {
    const {
      onChange,
      submit,
      props: { locale, hideEditBoxRight },
      state: {
        variables: { accounting_information },
        errors,
        error_msg,
        processing
      }
    } = this

    const actions_props = { locale, hideEditBoxRight, processing, submit }
    return (
      <React.Fragment>
        {error_msg && <Alert color="danger">{error_msg}</Alert>}
        <h3>{locale.accounting_information.singular}</h3>
        <UserGroupBilled {...{ locale, accounting_information, onChange }} userGroup="licence_owner">
          <FormGroupContainer>
            <Col lg={5} className="text-lg-right">
              <Label className="control-label font-weight-normal">
                {locale.accounting_information[`licence_owner_billing_type`]}
              </Label>
            </Col>
            <Col lg={7}>
              <DynamicSelect
                simpleValue
                isClearable
                id={'accounting_information.licence_owner_billing_type'}
                variables={{ accounting_information }}
                errors={errors}
                locale={locale}
                onChange={onChange('licence_owner_billing_type')}
                options={accounting_information.possible_licence_owner_billing_types.map(billing_type => ({
                  value: billing_type.key,
                  label: billing_type.name[locale.code]
                }))}
              />
            </Col>
          </FormGroupContainer>
          <FormGroupContainer>
            <ValidatedInformationInput
              id={`accounting_information.licence_owner_billing_details`}
              localeKey={`accounting_information.bill_number`}
              variables={{ accounting_information }}
              locale={locale}
              onChange={onChange(`licence_owner_billing_details`)}
              errors={errors}
              type="textarea"
              thinLabel={true}
            />
          </FormGroupContainer>
        </UserGroupBilled>
        <UserGroupBilled {...{ locale, accounting_information, onChange, errors }} userGroup="facility" />
        <UserGroupBilled {...{ locale, accounting_information, onChange, errors }} userGroup="collector" />

        <h3>{locale.accounting_information.other}</h3>

        <CheckboxWithDate
          {...{ locale, accounting_information, onChange, errors }}
          name="sticker_sent_at"
          label={locale.accounting_information.sticker_sent}
          dateLabelKey="date"
        />
        <CheckboxWithDate
          {...{ locale, accounting_information, onChange, errors }}
          name="sign_sent_at"
          label={locale.accounting_information.sign_sent}
          dateLabelKey="date"
        />

        <FormGroupContainer>
          <ValidatedInformationInput
            id={`accounting_information.comment`}
            variables={{ accounting_information }}
            locale={locale}
            onChange={onChange(`comment`)}
            errors={errors}
            type="textarea"
          />
        </FormGroupContainer>

        <FormGroupContainerActions {...actions_props} />
      </React.Fragment>
    )
  }
}

const UserGroupBilled = ({ locale, accounting_information, userGroup, onChange, children, errors }) => (
  <CheckboxWithDate
    {...{ locale, accounting_information, onChange, children, errors }}
    name={`${userGroup}_billed_at`}
    label={locale.accounting_information[`${userGroup}_billed`]}
  >
    <FormGroupContainer>
      <ValidatedInformationInput
        id={`accounting_information.${userGroup}_bill_number`}
        localeKey={`accounting_information.bill_number`}
        variables={{ accounting_information }}
        locale={locale}
        onChange={onChange(`${userGroup}_bill_number`)}
        errors={errors}
        thinLabel={true}
      />
    </FormGroupContainer>
    {children}
  </CheckboxWithDate>
)

const CheckboxWithDate = ({
  locale,
  accounting_information,
  name,
  onChange,
  children,
  errors,
  label,
  dateLabelKey
}) => (
  <Fragment>
    <FormGroupContainer>
      <Col lg={5} className="text-lg-right">
        <Label className="control-label">{label}</Label>
      </Col>
      <Col lg={7}>
        <ButtonGroup className="full-width">
          <Button
            color="white"
            onClick={onChange(name, () => moment().format('YYYY-MM-DD'))}
            active={!!accounting_information[name]}
          >
            {locale.yes}
          </Button>{' '}
          <Button color="white" onClick={onChange(name, null)} active={!accounting_information[name]}>
            {locale.no}
          </Button>
        </ButtonGroup>
      </Col>
    </FormGroupContainer>
    {accounting_information[name] && (
      <Fragment>
        <FormGroupContainer>
          <ValidatedInformationInput
            id={`accounting_information.${name}`}
            localeKey={dateLabelKey || 'accounting_information.bill_date'}
            variables={{ accounting_information }}
            locale={locale}
            onChange={onChange(name)}
            errors={errors}
            type="date"
            thinLabel={true}
          />
        </FormGroupContainer>
        {children}
      </Fragment>
    )}
  </Fragment>
)

export default withApollo(AccountingInformationForm)
