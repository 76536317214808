const syncMutation = (syncObjects, syncConfig, client, updateProgress, addToErrors) => {
  let promiseChain = Promise.resolve()

  syncObjects.forEach(object => {
    promiseChain = promiseChain.then(() => {
      let mutation = typeof syncConfig.mutation === 'function' ? syncConfig.mutation(object) : syncConfig.mutation
      let variables = syncConfig.variableResolver ? syncConfig.variableResolver(object) : object

      console.log('SYNC', {
        mutation,
        variables
      })

      return client
        .mutate({
          mutation,
          variables: {
            ...variables,
            sync: true
          }
        })
        .catch(({ graphQLErrors, networkError }) => {
          if (networkError && networkError.statusCode === 401) {
            return Promise.reject()
          }

          if (graphQLErrors) {
            addToErrors({
              graphQLErrors,
              syncConfig,
              syncObjects
            })
          }
        })
        .then(() => updateProgress({ increment: 1 }))
    })
  })

  return promiseChain
}

export default syncMutation
