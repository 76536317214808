import React from 'react'

import { Alert, Col, Form, Row } from 'reactstrap'

import { withApollo } from '@apollo/client/react/hoc'

import FormGroups from './form_groups'
import { versionCreateMutation } from './graphql'

const VersionCreate = ({ locale, history, match, client }) => (
  <div className="page-wrapper">
    <Row className="page-header">
      <Col sm="8">
        <h1>Neue Version anlegen</h1>
      </Col>
    </Row>
    <hr className="seperator" />
    <VersionCreateForm locale={locale} client={client} history={history} match={match} />
  </div>
)

class VersionCreateForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      comment: '',
      error: false,
      error_msg: ''
    }
  }

  gqlValidationError = error =>
    this.setState({
      error_msg: error.graphQLErrors[0].message,
      error: true
    })
  change = event =>
    event.target ? this.setState({ [event.target.id]: event.target.value }) : this.setState({ [event.id]: event.value })
  submit = () =>
    this.props.client
      .mutate({
        mutation: versionCreateMutation,
        variables: {
          form_version: {
            name: this.state.name,
            comment: this.state.comment
          }
        }
      })
      .then(result => this.props.history.push('/form-kit/versions'))
      .catch(error => this.gqlValidationError(error))

  render = () => (
    <Form className="box">
      {this.state.error ? <Alert color="danger">{this.state.error_msg}</Alert> : ''}
      <FormGroups
        state={this.state}
        props={this.props}
        change={this.change}
        submit={this.submit}
        changeRegions={this.changeRegions}
      />
    </Form>
  )
}

export default withApollo(VersionCreate)
