import React, { Fragment } from 'react'

import { Button, Col, Container, FormGroup, Modal, ModalBody, ModalHeader, Row, UncontrolledTooltip } from 'reactstrap'

import { withApollo } from '@apollo/client/react/hoc'
import { faEdit, faSave } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { deepNestInObjectsWithKeys } from 'helpers/object'
import update from 'immutability-helper'

import { getFacility } from '../facility.graphql'
import { updateAuditOrderNumber } from './audit-order-number.graphql'

import { ProcessingButton } from '../../../modules/buttons/processing-button'
import ValidatedInput from '../../../modules/inputs/validated-input'

class Update extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      processing: false,
      modal: false,
      variables: {
        uuid: this.props.facility.uuid,
        certification_data: {
          audit_order_number: this.props.facility.certification_data.audit_order_number || ''
        }
      }
    }
  }

  toggle = () => this.setState({ modal: !this.state.modal, processing: false })

  submit = () => {
    this.setState({ processing: true })
    this.props.client
      .mutate({
        mutation: updateAuditOrderNumber,
        variables: this.state.variables,
        refetchQueries: [
          {
            query: getFacility,
            variables: {
              uuid: this.state.variables.uuid,
              version_number: this.props.match.params.version_number || ''
            }
          }
        ],
        awaitRefetchQueries: true
      })
      .then(result => this.toggle())
  }

  onChange = event => {
    const fragments = event.target.id.split('.')
    const variables = deepNestInObjectsWithKeys(event.target.value, ...fragments, '$set')

    this.setState(update(this.state, { variables }))
  }

  render() {
    const {
      onChange,
      submit,
      state: { errors, variables },
      props: { locale },
      toggle
    } = this
    return (
      <Fragment>
        <Button
          size={`sm`}
          className="btn-transparent btn-icon-only btn"
          color={'link'}
          onClick={toggle}
          id="edit_audit_order_number_tooltip"
        >
          <FontAwesomeIcon icon={faEdit} />
        </Button>
        <UncontrolledTooltip delay={500} placement="top" target="edit_audit_order_number_tooltip">
          Bearbeiten
        </UncontrolledTooltip>
        <Modal size={'lg'} centered isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader>Prüfauftragsnummer bearbeiten</ModalHeader>
          <ModalBody>
            <Row>
              <ValidatedInput
                errors={errors}
                id="certification_data.audit_order_number"
                input_sm={6}
                label_sm={4}
                locale={locale}
                onChange={onChange}
                variables={variables}
              />
            </Row>

            <FormGroup className="form-action">
              <Container>
                <Row>
                  <Col sm="5">
                    <a className="link-btn" onClick={this.toggle} href="#">
                      {locale.cancel}
                    </a>
                  </Col>
                  <Col sm="7" className="text-right">
                    <ProcessingButton
                      processing={this.state.processing}
                      onClick={submit}
                      label={'Speichern'}
                      icon={faSave}
                    />
                  </Col>
                </Row>
              </Container>
            </FormGroup>
          </ModalBody>
        </Modal>
      </Fragment>
    )
  }
}

export default withApollo(Update)
