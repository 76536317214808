import { Fragment } from 'react'

import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'

import './_pagination.scss'

const findElementsAround = (arr, index, buffer) => {
  const elementCount = buffer * 2 + 1

  if (index < buffer) {
    return arr.slice(0, elementCount)
  } else if (arr.length - index <= buffer) {
    return arr.slice(arr.length - elementCount, arr.length)
  } else {
    return arr.slice(index - buffer, index + buffer + 1)
  }
}

const PaginationComponent = ({ currentPage, itemsPerPage, totalCount, setPage }) => {
  const totalPages = Math.ceil(totalCount / itemsPerPage)
  const allPages = new Array(totalPages).fill().map((_, i) => i + 1)

  const pageBuffer = 3

  const currentPages = findElementsAround(allPages, currentPage - 1, pageBuffer)

  return (
    <Pagination>
      {currentPage > allPages[0] && (
        <PaginationItem>
          <PaginationLink previous onClick={_ => setPage(currentPage - 1)} />
        </PaginationItem>
      )}
      {currentPages[0] > allPages[0] && (
        <Fragment>
          <PaginationItem>
            <PaginationLink onClick={_ => setPage(allPages[0])}>{allPages[0]}</PaginationLink>
          </PaginationItem>
          {currentPages[0] > allPages[1] && (
            <PaginationItem disabled>
              <PaginationLink>…</PaginationLink>
            </PaginationItem>
          )}
        </Fragment>
      )}
      {currentPages.map(page => (
        <PaginationItem key={page} active={page === currentPage}>
          <PaginationLink onClick={_ => setPage(page)}>{page}</PaginationLink>
        </PaginationItem>
      ))}
      {currentPages[currentPages.length - 1] < allPages[allPages.length - 1] && (
        <Fragment>
          {currentPages[currentPages.length - 1] < allPages[allPages.length - 2] && (
            <PaginationItem disabled>
              <PaginationLink>…</PaginationLink>
            </PaginationItem>
          )}
          <PaginationItem>
            <PaginationLink onClick={_ => setPage(allPages[allPages.length - 1])}>
              {allPages[allPages.length - 1]}
            </PaginationLink>
          </PaginationItem>
        </Fragment>
      )}
      {currentPage < allPages[allPages.length - 1] && (
        <PaginationItem>
          <PaginationLink next onClick={_ => setPage(currentPage + 1)} />
        </PaginationItem>
      )}
    </Pagination>
  )
}

export default PaginationComponent
