import React, { Fragment } from 'react'

import { Alert, Col, Form } from 'reactstrap'

import { Query } from '@apollo/client/react/components'
import { withApollo } from '@apollo/client/react/hoc'

import { withLocale } from 'locale'
import PropTypes from 'prop-types'

import { FormFields } from '.'
import allowKeys from '../../../../../helpers/allow_keys'
import valueFromString from '../../../../../helpers/value_from_string'
import Loading from '../../../../helper/loading/loading'
import { ProcessingButton } from '../../../../modules/buttons/processing-button'
import { FormGroupContainer } from '../../../facility/information/partials'
import { createProvider, getProviders, newProvider } from '../graphql'

const CreateWrapper = props => (
  <Query query={newProvider}>
    {({ data, loading, error }) => {
      if (loading) return <Loading />
      if (error) return 'error'

      return (
        <Create
          {...{
            ...props,
            newProvider: data.new_provider
          }}
        />
      )
    }}
  </Query>
)

class Create extends React.Component {
  static propTypes = {
    client: PropTypes.object.isRequired,
    locale: PropTypes.object.isRequired,
    newProvider: PropTypes.object.isRequired,
    toggleModal: PropTypes.func.isRequired,
    refetchQueries: PropTypes.arrayOf(PropTypes.object),
    facilityUuids: PropTypes.arrayOf(PropTypes.string)
  }

  constructor(props) {
    super(props)

    let provider = allowKeys(props.newProvider, [
      'name',
      'email',
      'phone',
      'miscellaneous',
      {
        address: ['street', 'street_addition', 'zipcode', 'city']
      }
    ])

    provider.address.country_code = props.newProvider.address.country?.key
    provider.address.state = props.newProvider.address.state?.key

    this.state = {
      error_msg: null,
      errors: {},
      processing: false,
      variables: {
        provider: {
          ...provider,
          facility_uuid: props.facilityUuid || undefined
        }
      }
    }
  }

  onChange = e => {
    let newState = this.state
    if (
      e.target.id === 'provider.address.country_code' &&
      e.target.value !== this.state.variables.provider.address.country_code
    ) {
      newState.variables.provider.address.state = null
    }
    valueFromString(newState.variables, e.target.id, e.target.value)
    this.setState(newState)
  }

  submit = e => {
    e.preventDefault()
    this.setState({
      processing: true
    })

    this.props.client
      .mutate({
        mutation: createProvider,
        variables: this.state.variables,
        refetchQueries: this.props.refetchQueries || [{ query: getProviders }],
        awaitRefetchQueries: true
      })
      .then(() => this.props.toggleModal())
      .catch(this.onError)
  }

  onError = ({ graphQLErrors }) =>
    this.setState({
      error_msg: graphQLErrors[0].message,
      errors: graphQLErrors[0].errors,
      processing: false
    })

  render = () => {
    const {
      onChange,
      props: { locale, toggleModal },
      state: { error_msg, errors, processing, variables },
      submit
    } = this

    return (
      <Fragment>
        {error_msg && <Alert color="danger">{error_msg}</Alert>}
        <Form onSubmit={submit}>
          <fieldset disabled={processing}>
            <FormFields {...{ locale, variables, errors, onChange }} />
          </fieldset>
          <FormGroupContainer>
            <Col sm="2">
              <a className="link-btn" onClick={toggleModal} href="#">
                {locale.cancel}
              </a>
            </Col>
            <Col sm={{ size: '4', offset: '6' }} className="text-right">
              <ProcessingButton processing={processing} onClick={submit} label={locale.save} />
            </Col>
          </FormGroupContainer>
        </Form>
      </Fragment>
    )
  }
}

export default withApollo(withLocale(CreateWrapper))
