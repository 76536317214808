import { useId } from 'react'

import RichTextEditor from 'react-rte'
import { FormFeedback, FormGroup, Label } from 'reactstrap'

import { withLocale } from 'locale/index'

const CommentInput = ({ comment, setComment, errorMessage, locale }) => {
  const commentInputId = useId()

  return (
    <FormGroup>
      <Label for={commentInputId}>{locale.comment}</Label>
      <RichTextEditor
        value={comment}
        onChange={setComment}
        id={commentInputId}
        toolbarConfig={{
          display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'HISTORY_BUTTONS'],
          INLINE_STYLE_BUTTONS: [
            { label: 'Fett', style: 'BOLD' },
            { label: 'Kursiv', style: 'ITALIC' },
            { label: 'Durchgestrichen', style: 'STRIKETHROUGH' },
            { label: 'Unterstrichen', style: 'UNDERLINE' }
          ],
          BLOCK_TYPE_DROPDOWN: [{ label: 'Text', style: 'unstyled' }],
          BLOCK_TYPE_BUTTONS: [
            { label: 'Liste (unsortiert)', style: 'unordered-list-item' },
            { label: 'Liste (sortiert)', style: 'ordered-list-item' }
          ]
        }}
        className={errorMessage ? 'border-danger is-invalid' : ''}
      />
      <FormFeedback>{errorMessage}</FormFeedback>
    </FormGroup>
  )
}

export default withLocale(CommentInput, { key: 'licenseOwners.volumeUnitAccount' })
