import { useRef, useState } from 'react'

import { useMediaQuery } from 'react-responsive'
import { Button, InputGroup, InputGroupAddon, InputGroupText, Label, UncontrolledTooltip } from 'reactstrap'

import { faDownload, faTable } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { MonthRangePicker } from 'components/ui/genericComponents/monthRangePicker'
import { formatISO } from 'date-fns'
import { withLocale } from 'locale/index'

const TransactionsExport = ({ exportBaseUrl, locale }) => {
  const downloadButtonRef = useRef()

  const [dateRange, setDateRange] = useState(null)

  const onDateRangeChange = ({ start, end }) => {
    if (start && end) {
      setDateRange({ start, end })
    } else {
      setDateRange(null)
    }
  }

  let downloadUrl = exportBaseUrl

  if (dateRange) {
    downloadUrl += `?start_date=${formatISO(dateRange.start, { representation: 'date' })}`
    downloadUrl += `&end_date=${formatISO(dateRange.end, { representation: 'date' })}`
  }

  const numberOfColumns = useMediaQuery({ query: '(max-width: 576px)' }) ? 1 : 2 // NOTE: bootstraps `sm` breakpoint

  return (
    <>
      <InputGroup>
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            <Label className="m-0">
              <FontAwesomeIcon icon={faTable} className="mr-3" />
              Als .xlsx exportieren
            </Label>
          </InputGroupText>
        </InputGroupAddon>
        <MonthRangePicker
          numberOfColumns={numberOfColumns}
          placeholder={locale.monthRangePicker.placeholder}
          value={dateRange}
          onChange={onDateRangeChange}
          strictRange
        />
        <InputGroupAddon addonType="append">
          <Button tag="a" href={downloadUrl} color="primary" className="float-right px-3" innerRef={downloadButtonRef}>
            <FontAwesomeIcon icon={faDownload} />
          </Button>
          <UncontrolledTooltip target={downloadButtonRef} placement="top">
            {locale.downloadTooltip}
          </UncontrolledTooltip>
        </InputGroupAddon>
      </InputGroup>
    </>
  )
}

export default withLocale(TransactionsExport, { key: 'licenseOwners.volumeUnitAccount.export' })
