import { Alert, Col, Row } from 'reactstrap'

import { faPlus } from '@fortawesome/free-solid-svg-icons'

import Modal from '../../modules/modal/modal.jsx'
import { FacilitiesTableWithFilter } from './facilitiesTableWithFilter'
import Create from './form/form-create.jsx'
import PermittedWorkingList from './permitted-working-list'

const Facilities = ({ locale, history, online }) => {
  const showFacilitiesTable = online === true

  return (
    <div className="page-wrapper">
      <Row className="page-header">
        <Col sm="8">
          <h1>{locale.facilities}</h1>
        </Col>
        <Col sm="4" className="text-right">
          {online && (
            <Modal
              toggleModalText={locale.add_facility}
              button_type={{ icon_only: false, icon_type: faPlus, color: 'primary-light', classes: 'btn-labeled' }}
              modal_size={'lg'}
            >
              <Create locale={locale} history={history} />
            </Modal>
          )}
        </Col>
      </Row>
      <hr className="seperator" />
      {online === false && (
        <Alert color="warning">Sie benutzen das Erhebungssystem momentan ohne aktive Internetverbindung.</Alert>
      )}
      {/* TODO: refactor the `PermittedWorkingList` component into a dedicated `OfflineFacilities` component */}
      <PermittedWorkingList locale={locale} permitted_working_list="offline" online={online} />
      {showFacilitiesTable && <FacilitiesTableWithFilter />}
    </div>
  )
}

export default Facilities
