import React, { createRef } from 'react'

import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { Badge, Button, Col, Container, FormGroup, Modal, ModalBody, ModalHeader, Row, Tooltip } from 'reactstrap'

import { faArrowsAlt, faEye, faUserSecret } from '@fortawesome/free-solid-svg-icons'
// GraphQL
// import {getFacility} from "../../views/facility/graphql";
// import gql from 'graphql-tag';
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { arrayMoveImmutable as arrayMove } from 'array-move'

import { checkIfNull } from '../../helper/helper-functions'
// additional components
import Loading from '../../helper/loading/loading'
import DragHandle from '../sortable/DragHandle'

class SortImagesModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      modal: false
    }
  }

  toggleModal = event => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    this.setState({
      modal: !this.state.modal
    })
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const position = this.props.images[newIndex].position
    this.setState(
      {
        image_data: arrayMove(
          //TODO: state benutzen wenn optimistic ui gewünscht
          this.props.images,
          oldIndex,
          newIndex
        )
      },
      this.props.mutateReorder(this.props.images[oldIndex].uuid, position)
    )
  }

  render() {
    const { toggleModal, onSortEnd } = this
    const { className, locale, client, images, processing } = this.props
    const { modal } = this.state

    return (
      <React.Fragment>
        <Button outline color={'primary-light'} className={'px-5 btn-labeled align-self-center'} onClick={toggleModal}>
          <FontAwesomeIcon className="icon-prepend" icon={faArrowsAlt} />
          <span>sortieren</span>
        </Button>
        <Modal centered isOpen={modal} toggle={toggleModal} className={className} size={'lg'}>
          <ModalHeader>Bilder sortieren</ModalHeader>
          <ModalBody>
            {processing ? <Loading loadingText={`Bild wird verschoben...`} size={`1x`} /> : null}
            <ImagesSortableList
              toggleModal={toggleModal}
              locale={locale}
              client={client}
              images={images}
              onSortEnd={onSortEnd}
            />
          </ModalBody>
        </Modal>
      </React.Fragment>
    )
  }
}

export default SortImagesModal

const ImagesSortableList = ({ toggleModal, locale, submit, images, facility_uuid, client, onSortEnd }) => {
  const listRef = createRef()
  return (
    <React.Fragment>
      <FormGroup>
        <Container>
          <SortableList
            lockAxis="y"
            client={client}
            images={images}
            facility_uuid={facility_uuid}
            // history={history}
            locale={locale}
            onSortEnd={onSortEnd}
            useDragHandle
            lockToContainerEdges
            helperClass="sortable-helper bg-white"
            listRef={listRef}
            helperContainer={_ => listRef.current}
          />
        </Container>
      </FormGroup>
      <FormGroup className="form-action">
        <Container>
          <Row>
            <Col sm={{ size: '12' }} className="text-right">
              <a className="link-btn" onClick={toggleModal} href="#">
                {locale.close}
              </a>
            </Col>
          </Row>
        </Container>
      </FormGroup>
    </React.Fragment>
  )
}

const SortableList = SortableContainer(({ client, locale, history, images, facility_uuid, onSortEnd, listRef }) => (
  <ul ref={listRef} className="list-unstyled sortable-list thumbnail-list">
    {Object.keys(images).map((image, index) => (
      <SortableItem
        client={client}
        facility_uuid={facility_uuid}
        history={history}
        index={index}
        image={images[image]}
        key={`image-${images[image].uuid}`}
        locale={locale}
        onSortEnd={onSortEnd}
      />
    ))}
  </ul>
))

const SortableItem = SortableElement(({ image, locale, facility_uuid }) => (
  <DragableListItem locale={locale} draggable={true} facility_uuid={facility_uuid} key={image.uuid} image={image}>
    <DragHandle
      className={`border-left ml-4 ${image.is_main_image ? 'border-primary-light' : 'border-secondary'}`}
      iconProps={{ className: image.is_main_image ? 'text-primary-light' : '' }}
    />
  </DragableListItem>
))

class DragableListItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      short_name: checkIfNull(this.props.image.short_name_de),
      title: checkIfNull(this.props.image.title_de)
    }
  }

  toggleTooltipPublic = () =>
    this.setState({
      tooltip_public_open: !this.state.tooltip_public_open
    })

  render() {
    const { image, locale, children } = this.props
    const { short_name, title, tooltip_public_open } = this.state
    const { toggleTooltipPublic } = this

    return (
      <li
        className={`d-flex align-items-center justify-content-end ${image.is_main_image ? 'border-primary-light' : ''}`}
      >
        <span className="thumbnail-list-content d-flex align-items-center">
          <span className="thumbnail-container">
            <img src={image.path_for_gallery_thumb} alt="" />
          </span>
          <span className={`thumbnail-list-headline ${image.is_main_image ? 'text-primary-light' : ''}`}>
            <span className="thumbnail-list-title">{title}</span>
            <span className="thumbnail-list-subline">{short_name}</span>
          </span>
        </span>
        <Badge className="h3 badge-square text-white" color="info" id={`tooltip-public_${image.uuid}`}>
          {image.is_public ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faUserSecret} />}
        </Badge>
        <Tooltip
          delay={500}
          placement="top"
          isOpen={tooltip_public_open}
          target={`tooltip-public_${image.uuid}`}
          toggle={toggleTooltipPublic}
        >
          {image.is_public ? locale.public_image : locale.private_image}
        </Tooltip>
        {children}
      </li>
    )
  }
}
