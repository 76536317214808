/*
migration from IDB version 2 to 3

field `version_number` got added to facility
field `version_number` got added to facility in form datum
field `is_current_version` got added to facility in form datum
*/

const migrate = facility => ({
  version_number: '1',
  ...facility,
  form_data_without_section: updatedFormData(facility.form_data_without_section),
  section_data: facility.section_data.map(section_datum => ({
    ...section_datum,
    form_data: updatedFormData(section_datum.form_data)
  }))
})

const updatedFormData = form_data =>
  form_data.map(form_datum => ({
    ...form_datum,
    facility: {
      version_number: '1',
      is_current_version: true,
      ...form_datum.facility
    }
  }))

export default migrate
