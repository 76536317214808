import { InMemoryCache } from '@apollo/client'

import {
  AccountingVolumeUnitAccountPolicy,
  AssetDownloadPolicy,
  BundlePolicy,
  FacilityKindPolicy,
  FacilityPermissionsPolicy,
  FacilityPolicy,
  FormDatumPolicy,
  FormPolicy,
  LicenseOwnerPolicy,
  QueryPolicy,
  SectionDatumPolicy,
  VariablePolicy,
  WorkflowStatePolicy
} from './policies'

export default new InMemoryCache({
  typePolicies: {
    AssetDownload: AssetDownloadPolicy,
    Facility: FacilityPolicy,
    FacilityKind: FacilityKindPolicy,
    FacilityPermissions: FacilityPermissionsPolicy,
    Form: FormPolicy,
    FormDatum: FormDatumPolicy,
    LicenseOwner: LicenseOwnerPolicy,
    Query: QueryPolicy,
    SectionDatum: SectionDatumPolicy,
    Variable: VariablePolicy,
    WorflowState: WorkflowStatePolicy,
    Bundle: BundlePolicy,
    AccountingVolumeUnitAccount: AccountingVolumeUnitAccountPolicy
  }
})
