import { useId } from 'react'

import { Col, FormFeedback, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Row } from 'reactstrap'

import { faCoins } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { withLocale } from 'locale/index'

const AmountInput = ({ amount, setAmount, positive, errorMessage, locale }) => {
  const inputId = useId()

  return (
    <Row>
      <Col xs={{ size: 12 }} sm={{ size: 8, offset: 2 }} md={{ size: 6, offset: 3 }} lg={{ size: 4, offset: 4 }}>
        <Label for={inputId} className="sr-only">
          {locale.amount}
        </Label>
        <InputGroup className={!!errorMessage ? 'is-invalid' : ''}>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <FontAwesomeIcon icon={faCoins} className="mx-2" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            type="number"
            id={inputId}
            bsSize="lg"
            value={`${positive ? '' : '-'}${amount.replace(/^0+(0$|[1-9])/gm, '$1')}`} // NOTE: dark magic removing leading zeros but keeping one if it's the only one before the decimal point.
            step={0.25}
            onChange={({ target: { value } }) => setAmount(value.replace(/^-/g, '') || '0')} // NOTE: make unsigned, but keep it a string
            className={positive ? 'bg-success text-white font-weight-bold pl-4' : 'text-danger border-danger'}
            invalid={!!errorMessage}
            aria-label={locale.amount}
          />
        </InputGroup>
        <FormFeedback>{errorMessage}</FormFeedback>
      </Col>
    </Row>
  )
}

export default withLocale(AmountInput, { key: 'licenseOwners.volumeUnitAccount.createTransaction' })
