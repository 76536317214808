import { faListCheck } from '@fortawesome/free-solid-svg-icons'

import { withLocale } from 'locale/index'
import PropTypes from 'prop-types'

import ComponentTypeBadge from '../componentTypeBadge'

const FormCountCalculationComponentType = ({ value, locale }) => (
  <ComponentTypeBadge color="secondary" tooltip={locale.tooltip} icon={faListCheck}>
    {value}
  </ComponentTypeBadge>
)

FormCountCalculationComponentType.propTypes = {
  value: PropTypes.string.isRequired,
  locale: PropTypes.shape({
    tooltip: PropTypes.string.isRequired
  }).isRequired
}

export default withLocale(FormCountCalculationComponentType, {
  key: 'accounting_information.calculatedVolumeUnitCostsCalculationComponents.formCount'
})
