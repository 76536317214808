export const auth = {
  uri: __AUTH_REST_URL__,
  options: {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json'
    }),
    body: null
  }
}
