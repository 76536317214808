// TODO: refactor the `PermittedWorkingList` component into a dedicated `OfflineFacilities` component
import React from 'react'

import { Alert, Col, Row } from 'reactstrap'

import db from '../../../indexed-db/db'
import OfflineFacilities from './working-list-types/offline_facilities'

import '../../modules/table/_table.scss'

class PermittedWorkingList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      search: '',
      temp_search_query: '',
      offline_facilities_available: false
    }
  }

  componentDidUpdate = (_prevProps, prevState) =>
    db
      .keys('Facilities')
      .then(keys => {
        let offline_facilities_available = keys.length > 0
        if ((!prevState || prevState.offline_facilities_available != offline_facilities_available) && this._mounted) {
          this.setState({ offline_facilities_available })
        }
      })
      .catch(error => {
        if (error.name === 'InvalidStateError') {
          console.error('Could not access indexedDB:', error)
          this.setState({ offline_facilities_available: false })
        } else {
          throw error
        }
      })

  componentDidMount = () => {
    this._mounted = true
    this.componentDidUpdate()
  }

  componentWillUnmount = () => {
    this._mounted = false
  }

  change = event => {
    if (event.target.value.length > 3) {
      this.setState({ [event.target.id]: event.target.value, search: event.target.value })
    } else {
      this.setState({ [event.target.id]: event.target.value, search: '' })
    }
  }

  getWorkingList = worklist_data => {
    switch (worklist_data.permitted_working_list) {
      case 'offline':
        if ('serviceWorker' in navigator) {
          return <OfflineFacilities {...worklist_data} />
        } else {
          return null
        }
      default:
        return <p>Unbekannte Arbeitsliste: {worklist_data.permitted_working_list}</p>
    }
  }

  render = () => {
    const {
      change,
      getWorkingList,
      props: { locale, permitted_working_list, online },
      state: { search, offline_facilities_available }
    } = this

    if ((online && permitted_working_list !== 'offline') || offline_facilities_available) {
      return (
        <div className="box">
          <Row className="page-header">
            <Col sm="5">
              <h2>{locale.permitted_working_list[permitted_working_list]}</h2>
            </Col>
          </Row>
          <hr className="seperator seperator-small border-gray" />
          {getWorkingList({
            permitted_working_list,
            locale,
            search,
            online
          })}
        </div>
      )
    } else if (!online && permitted_working_list === 'offline') {
      return <Alert color="info">Sie haben noch keinen Betrieb zur offline Erhebung heruntergeladen</Alert>
    } else {
      return null
    }
  }
}

export default PermittedWorkingList
