import generateUUID from './guid'

const uploadImages = ({ event, parent_uuid, client, afterEach, mutation, refetchQueries, updateUploads }) => {
  let images = event.target.files
  let imageArray = Array.from(images)

  updateUploads(imageArray)

  let promise = Promise.resolve()

  imageArray.forEach((image, index) => {
    let options = {
      mutation: mutation,
      variables: {
        uuid: parent_uuid,
        image: {
          image,
          uuid: generateUUID()
        },
        user: JSON.parse(localStorage.getItem('user'))
      },
      refetchQueries,
      awaitRefetchQueries: true
    }

    promise = promise.then(() =>
      client.mutate(options).then(result => {
        if (afterEach instanceof Function) afterEach(result)
        updateUploads(imageArray.filter((_el, i) => i > index))
      })
    )
  })

  return promise
}

export default uploadImages
