import { Fragment } from 'react'

import { Col, Label, UncontrolledTooltip } from 'reactstrap'

import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import generateUUID from 'helpers/guid'
import PropTypes from 'prop-types'

import ValidatedInput from '../../../inputs/validated-input'

const InputWrapper = ({ label, children, layout, id, hint = '' }) => {
  const hintId = `field_hint_${generateUUID()}`

  if (!Array.isArray(children) && children?.type === ValidatedInput) {
    return children
  } else {
    return (
      <Fragment>
        <Col className="text-right" sm="3" {...layout?.label}>
          <Label className="control-label" for={id}>
            {label}
            {hint ? (
              <Fragment>
                <span id={hintId} style={{ marginLeft: '1rem' }}>
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </span>
                <UncontrolledTooltip target={hintId} placement="right" delay={0}>
                  {hint}
                </UncontrolledTooltip>
              </Fragment>
            ) : null}
          </Label>
        </Col>
        <Col sm="9" {...layout?.input}>
          {children}
        </Col>
      </Fragment>
    )
  }
}

const columnProps = {
  xs: PropTypes.any,
  sm: PropTypes.any,
  md: PropTypes.any,
  lg: PropTypes.any,
  xl: PropTypes.any,
  widths: PropTypes.any
}

InputWrapper.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  label: PropTypes.string,
  layout: PropTypes.shape({
    label: PropTypes.shape(columnProps),
    input: PropTypes.shape(columnProps)
  })
}

export default InputWrapper
