import gql from 'graphql-tag'

//TODO: small hack because API is not supporting global status query. remove soon, edit statuses.jsx also

export const statusesQuery = gql`
  query statuses_query {
    user {
      id
      possible_statuses {
        key
        label
      }
    }
  }
`
