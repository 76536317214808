import React from 'react'

import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  Carousel,
  CarouselCaption,
  CarouselControl,
  CarouselItem,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  Progress,
  Row,
  Tooltip,
  UncontrolledTooltip
} from 'reactstrap'

// GraphQL
import { graphql, withApollo } from '@apollo/client/react/hoc'
import {
  faCheck,
  faDownload,
  faEdit,
  faEye,
  faImage,
  faSave,
  faTimes,
  faTrashAlt,
  faUserSecret
} from '@fortawesome/free-solid-svg-icons'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import update from 'immutability-helper'

import { createFormDatumImage, getFormDatumImages, reorderImages, updateImage } from './images.graphql'

import uploadImages from '../../../../../helpers/upload-images'
import IconAllergic from '../../../../assets/guestgroup-icons/allergien-grau.svg'
// Images
import IconGenerations from '../../../../assets/guestgroup-icons/bequem-grau.svg'
import IconVisual from '../../../../assets/guestgroup-icons/blind-grau.svg'
import IconWalking from '../../../../assets/guestgroup-icons/gehbehindert-grau.svg'
import IconDeaf from '../../../../assets/guestgroup-icons/gehoerlos-grau.svg'
import IconMental from '../../../../assets/guestgroup-icons/kognitiv-grau.svg'
import IconWheelchair from '../../../../assets/guestgroup-icons/rollstuhlfahrer-grau.svg'
// additional components
import { checkIfNull } from '../../../../helper/helper-functions'
import Loading from '../../../../helper/loading/loading'
import CustomModal from '../../../../modules/modal/modal'
import SortImagesModal from '../../../../modules/modal/sortImagesModal'
import Delete from './delete.jsx'
import Update from './update.jsx'

// CSS
import '../../../../modules/card/_card.scss'
import '../../images/_images.scss'

const Images = ({ locale, client, uuid, data: { loading, error, form_datum, refetch } }) => {
  if (loading) return <Loading />
  if (error) return null

  let gallery_images = []

  form_datum.images.map(image => {
    gallery_images.push({
      thumb_src: image.path_for_gallery_thumb,
      src: image.path_for_gallery_full,
      caption: image.description_de,
      title: image.description_de,
      altText: image.description_de
    })
  })

  return (
    <React.Fragment>
      <ImagesWithLightbox
        {...form_datum}
        gallery_images={gallery_images}
        refetch={refetch}
        locale={locale}
        client={client}
        form_datum_uuid={uuid}
      />
    </React.Fragment>
  )
}

class UploadImages extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      processing: false,
      image_upload: []
    }
  }

  addFiles = event =>
    uploadImages({
      event,
      parent_uuid: this.props.form_datum_uuid,
      client: this.props.client,
      afterEach: result => this.props.getImageUuid(result.data.create_form_datum_image.uuid),
      mutation: createFormDatumImage,
      refetchQueries: [
        {
          query: getFormDatumImages,
          variables: {
            uuid: this.props.form_datum_uuid
          }
        }
      ],
      updateUploads: image_upload => this.setState({ image_upload })
    })
      .then()
      .catch(this.resetFiles)

  clean = () =>
    this.setState({
      processing: false
    })

  resetFiles = () => {
    this.fileInput.value = ''
    this.setState({
      image_upload: []
    })
  }

  mutateReorder = (uuid, position) => {
    this.setState({ processing: true })
    this.props.client
      .mutate({
        mutation: reorderImages,
        variables: {
          uuid,
          position
        },
        refetchQueries: [{ query: getFormDatumImages, variables: { uuid: this.props.form_datum_uuid } }]
      })
      .then(() => this.clean())
      .catch(error => console.log(error))
  }

  render() {
    const { addFiles, mutateReorder } = this
    const { locale, client, form_datum_uuid, images } = this.props
    const { image_upload, processing } = this.state

    return (
      <React.Fragment>
        <Row className="with-seperator">
          <Col>
            <h2 className="headline">{locale.additional_images}</h2>
          </Col>
          <Col sm="4" lg="3" className="text-right col-align-bottom">
            {images.length > 1 ? (
              <SortImagesModal
                client={client}
                images={images}
                locale={locale}
                mutateReorder={mutateReorder}
                processing={processing}
                uuid={form_datum_uuid}
              />
            ) : null}
          </Col>
          <Col sm="4" lg="4" className="text-right col-align-bottom">
            <p>
              <input
                className="form-control"
                type="file"
                required
                multiple
                onChange={addFiles}
                disabled={image_upload.length}
                name="files"
                id="files"
                accept=".jpg,.jpeg,.png"
                ref={ref => (this.fileInput = ref)}
              />
              <small>{locale.message_issue_upload_image}</small>
            </p>
          </Col>
        </Row>
        <hr className="seperator seperator-small" />
        {image_upload.map((image, i) => (
          <Progress
            className="mb-3 text-white progress-bar-animated-reverse"
            bar
            animated
            color="primary"
            key={i}
            value="100"
          >
            {image.name + '" ' + locale.uploading}
          </Progress>
        ))}
      </React.Fragment>
    )
  }
}

class ImagesWithLightbox extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeIndex: 0,
      modal: false,
      new_image_uuid: null
    }
  }

  getImageUuid = uuid => {
    this.setState({ new_image_uuid: uuid })
  }

  onExiting = () => {
    this.animating = true
  }

  onExited = () => {
    this.animating = false
  }

  next = () => {
    if (this.animating) return
    const nextIndex = this.state.activeIndex === this.props.gallery_images.length - 1 ? 0 : this.state.activeIndex + 1
    this.setState({ activeIndex: nextIndex })
  }

  previous = () => {
    if (this.animating) return
    const nextIndex = this.state.activeIndex === 0 ? this.props.gallery_images.length - 1 : this.state.activeIndex - 1
    this.setState({ activeIndex: nextIndex })
  }

  goToIndex = event => {
    event.preventDefault()
    event.stopPropagation()
    let index = event.target ? parseInt(event.target.parentNode.parentNode.getAttribute('index')) : 0
    if (this.animating) return
    this.setState({ activeIndex: index })
  }

  toggleModal = event => {
    event.preventDefault()
    event.stopPropagation()
    let index = event.target ? parseInt(event.target.parentNode.getAttribute('index')) : 0

    this.setState({
      activeIndex: index,
      modal: !this.state.modal
    })
  }

  UNSAFE_componentWillReceiveProps = nextProps => {
    this.setState({ modal: nextProps.modal })
  }

  render() {
    const { gallery_images, images, locale, client, refetch, form_datum_uuid } = this.props
    const { activeIndex, new_image_uuid } = this.state

    const dots = gallery_images.map((item, index) => {
      return (
        <li key={item.thumb_src} className={index === activeIndex ? 'active' : ''}>
          <a onClick={this.goToIndex} index={index}>
            <span className="indicator-image">
              <img src={item.thumb_src} alt={locale.image + ' ' + index} />
            </span>
          </a>
        </li>
      )
    })

    // set row_index for each col element
    let row_index = 0
    const getColOrder = index => {
      if (index % 3 === 0) {
        row_index = row_index + 10
      }
      return row_index
    }

    const slides = gallery_images.map(item => {
      return (
        <CarouselItem onExiting={this.onExiting} onExited={this.onExited} key={item.src}>
          <div className="carousel-image text-center">
            <img src={item.src} alt={item.altText} />
          </div>
          <CarouselCaption captionText={checkIfNull(item.caption)} captionHeader={item.title} />
        </CarouselItem>
      )
    })

    return (
      <React.Fragment>
        <UploadImages
          form_datum_uuid={form_datum_uuid}
          getImageUuid={this.getImageUuid}
          client={client}
          locale={locale}
          images={images}
        />
        <Row>
          {images.map((image, index) => (
            <ImageCard
              cardIndex={index}
              client={client}
              form_datum_uuid={form_datum_uuid}
              goToIndex={this.goToIndex}
              key={image.uuid}
              locale={locale}
              new_image_uuid={new_image_uuid}
              refetch={refetch}
              toggleModal={this.toggleModal}
              row_index={getColOrder(index)}
              {...image}
            />
          ))}
        </Row>

        <Modal isOpen={this.state.modal} toggle={this.props.close} className={this.props.className} size="lg">
          <ModalBody>
            <div className="text-right">
              <Button className="btn-transparent btn-icon-only mb-4" color="link" onClick={this.toggleModal}>
                <FontAwesomeIcon size="lg" icon={faTimes} />
              </Button>
            </div>
            <ModalContent
              activeIndex={activeIndex}
              next={this.next}
              previous={this.previous}
              dots={dots}
              slides={slides}
            />
          </ModalBody>
        </Modal>
      </React.Fragment>
    )
  }
}

const ModalContent = ({ activeIndex, next, previous, dots, slides }) => {
  return (
    <Carousel activeIndex={activeIndex} next={next} previous={previous}>
      <ol className="carousel-indicators">{dots}</ol>
      {slides}
      <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
      <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
    </Carousel>
  )
}

class CheckboxListitem extends React.Component {
  setTooltipStates = () =>
    this.setState({
      tooltip_guestgroup_open: false
    })

  toggleTooltipGuestgroup = () =>
    this.setState({
      tooltip_guestgroup_open: !this.state.tooltip_guestgroup_open
    })

  getIconImage() {
    switch (this.props.id) {
      case 'allergic':
        return IconAllergic
        break
      case 'deaf':
        return IconDeaf
        break
      case 'generations':
        return IconGenerations
        break
      case 'mental':
        return IconMental
        break
      case 'walking':
        return IconWalking
        break
      case 'wheelchair':
        return IconWheelchair
        break
      case 'visual':
        return IconVisual
        break
      default:
        return ''
    }
  }

  UNSAFE_componentWillMount() {
    this.setTooltipStates()
  }

  render() {
    const { id, guest_state, value, changeCheckbox, locale, index, cardIndex } = this.props
    const { tooltip_guestgroup_open } = this.state

    const classes = () => {
      if (guest_state.edit_open) {
        return 'col-sm-6 list-inline-item mb-2 mr-0'
      } else {
        if (!guest_state.edit_open && value) {
          return 'list-inline-item mb-2'
        } else {
          return 'hidden'
        }
      }
    }

    return (
      <li className={classes()}>
        <span className="guestgroup-icon" id={`tooltip-badge_${id + '-' + cardIndex + index}`}>
          <img src={this.getIconImage()} height="40" width="40" alt={locale.guest_group_description[id]} />
        </span>
        <Tooltip
          delay={500}
          placement="top"
          isOpen={tooltip_guestgroup_open}
          target={`tooltip-badge_${id + '-' + cardIndex + index}`}
          toggle={this.toggleTooltipGuestgroup}
        >
          {locale.guest_group_description[id]}
        </Tooltip>

        <Label
          className={guest_state.edit_open ? 'control-label checkbox-label checkbox-badge' : 'hidden'}
          for={id + '-' + cardIndex + index}
        >
          <Input
            className="checkbox-control"
            type="checkbox"
            id={id + '-' + cardIndex + index}
            checked={value}
            onChange={changeCheckbox}
          />
          <span className="check-icon-container">
            <span className="check-icon text-primary-dark">
              <FontAwesomeIcon icon={faCheck} />
            </span>
          </span>
          <span className="label-text">{locale.guest_groups[id]}</span>
        </Label>
      </li>
    )
  }
}

class ImageCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      variables: {
        uuid: this.props.uuid,
        image: {
          guest_groups: this.props.guest_groups,
          is_public: checkIfNull(this.props.is_public),
          artist: checkIfNull(this.props.artist),
          copyright: checkIfNull(this.props.copyright),
          title_de: checkIfNull(this.props.title_de),
          title_en: checkIfNull(this.props.title_en),
          short_name_de: checkIfNull(this.props.short_name_de),
          short_name_en: checkIfNull(this.props.short_name_en),
          description_de: checkIfNull(this.props.description_de),
          description_en: checkIfNull(this.props.description_en),
          description_visual_de: checkIfNull(this.props.description_visual_de),
          description_visual_en: checkIfNull(this.props.description_visual_en)
        }
      }
    }
  }

  setCheckboxStates = () => {
    // remove __typename that was set from query
    const { __typename, ...guest_groups } = this.props.guest_groups

    this.setState(
      update(this.state, {
        variables: {
          image: {
            $merge: {
              guest_groups: guest_groups
            }
          }
        }
      })
    )
  }

  setTooltipStates = () =>
    this.setState({
      tooltip_main_image_open: false,
      tooltip_update_open: false,
      tooltip_public_open: false
    })

  setEditOptions = () =>
    this.setState({
      edit_open: false,
      edit_status: 'closed'
    })

  goUpdate = () =>
    this.setState({
      edit_open: !this.state.edit_open,
      edit_status: this.state.edit_open ? 'opened' : 'closed'
    })

  openUpdateForNewImage = () => (this.props.new_image_uuid === this.props.uuid ? this.goUpdate() : '')

  toggleTooltipPublic = () =>
    this.setState({
      tooltip_public_open: !this.state.tooltip_public_open
    })

  toggleTooltipUpdate = () =>
    this.setState({
      tooltip_update_open: !this.state.tooltip_update_open
    })

  toggleTooltipMainImage = () =>
    this.setState({
      tooltip_main_image_open: !this.state.tooltip_main_image_open
    })

  allChecked = () => {
    let all_checked = true

    Object.keys(this.state.variables.image.guest_groups).map(dataKey => {
      if (this.state.variables.image.guest_groups[dataKey] === false && all_checked) {
        all_checked = false
      }
    })

    return all_checked
  }

  changeAllCheckboxes = event => {
    let value = event.target.checked ? true : false
    this.setState(
      update(this.state, {
        variables: {
          image: {
            guest_groups: {
              $set: {
                allergic: value,
                deaf: value,
                generations: value,
                mental: value,
                walking: value,
                wheelchair: value,
                visual: value
              }
            }
          }
        }
      })
    )
  }

  changeCheckbox = event => {
    this.setState(
      update(this.state, {
        variables: {
          image: {
            guest_groups: { [event.target.id.split('-')[0]]: { $set: event.target.checked } }
          }
        }
      })
    )
  }

  changeIsPublic = event => {
    this.setState(
      update(this.state, {
        variables: {
          image: {
            [event.target.id.split('-')[0]]: { $set: event.target.checked }
          }
        }
      })
    )
  }

  change = event =>
    event.target
      ? this.setState(
          update(this.state, {
            variables: {
              image: {
                [event.target.id.split('-')[0]]: { $set: event.target.value }
              }
            }
          })
        )
      : this.setState(
          update(this.state, {
            variables: {
              image: {
                [event.id.split('-')[0]]: { $set: event.value }
              }
            }
          })
        )

  submit = () => {
    const { client } = this.props
    const { variables } = this.state

    client
      .mutate({
        mutation: updateImage,
        variables
      })
      .then(_result => this.goUpdate())
      .catch(error => this.gqlValidationError(error))
  }

  gqlValidationError = error =>
    this.setState({
      error_msg: error.graphQLErrors[0].message,
      error: true
    })

  // use functions before rendering
  UNSAFE_componentWillMount() {
    this.setCheckboxStates()
    this.setTooltipStates()
    this.setEditOptions()
  }

  componentDidMount() {
    this.openUpdateForNewImage()
  }

  // render Row Element
  render = () => {
    const image = this.props
    const { locale, uuid, path_for_middle_size, cardIndex, toggleModal, form_datum_uuid, row_index } = this.props
    const state = this.state
    const variables = this.state.variables.image
    const {
      change,
      changeCheckbox,
      changeAllCheckboxes,
      submit,
      goUpdate,
      changeIsPublic,
      allChecked,
      toggleTooltipPublic,
      toggleTooltipMainImage
    } = this

    // remove __typename that was set from query
    const { __typename, ...guest_groups } = image.guest_groups

    return (
      <Col
        sm={state.edit_open ? '12' : '4'}
        style={{ order: state.edit_open ? row_index - 1 : row_index + (cardIndex % 3) }}
      >
        <Card className={`${image.is_main_image ? 'card-main-image' : ''}`}>
          <CardHeader className="d-flex align-items-center">
            {image.is_main_image ? (
              <React.Fragment>
                <span
                  className="icon-main-image btn-transparent btn-icon-only text-primary-light"
                  color="link"
                  id={`tooltip-main-image`}
                >
                  <FontAwesomeIcon size="lg" icon={faImage} />
                </span>
                <Tooltip
                  delay={500}
                  placement="top"
                  isOpen={state.tooltip_main_image_open}
                  target={`tooltip-main-image`}
                  toggle={toggleTooltipMainImage}
                >
                  Hauptfoto
                </Tooltip>
              </React.Fragment>
            ) : null}
            <h3 className="card-title">{variables.title_de}</h3>
            <ul className="list-inline justify-content-end">
              <li className="list-inline-item">
                <a
                  download
                  href={image.path_for_original}
                  className="btn-transparent btn-icon-only"
                  color="link"
                  id={`tooltip-download_${image.uuid + '-' + cardIndex}`}
                >
                  <FontAwesomeIcon icon={faDownload} />
                </a>
                <UncontrolledTooltip
                  delay={500}
                  placement="top"
                  target={`tooltip-download_${image.uuid + '-' + cardIndex}`}
                >
                  Original herunterladen
                </UncontrolledTooltip>
              </li>
              <li className="list-inline-item">
                <Button
                  onClick={goUpdate}
                  className="btn-transparent btn-icon-only"
                  color="link"
                  id={`tooltip-update_${image.uuid + '-' + cardIndex}`}
                >
                  {state.edit_open ? <FontAwesomeIcon size="lg" icon={faTimes} /> : <FontAwesomeIcon icon={faEdit} />}
                </Button>
                <UncontrolledTooltip
                  delay={500}
                  placement="top"
                  target={`tooltip-update_${image.uuid + '-' + cardIndex}`}
                >
                  {state.edit_open ? locale.close : locale.edit}
                </UncontrolledTooltip>
              </li>
              <li className="list-inline-item">
                <CustomModal
                  children={<Delete id={image.uuid} uuid={form_datum_uuid} client={image.client} locale={locale} />}
                  toggleModalText={'Bild ' + locale.delete}
                  modal_type={'confirm'}
                  modalConfirmText={locale.confirm_delete_image_description}
                  button_type={{
                    icon_only: true,
                    icon_type: faTrashAlt,
                    color: 'link',
                    classes: 'btn-transparent btn-icon-only text-danger'
                  }}
                  modal_size={'lg'}
                />
              </li>
            </ul>
          </CardHeader>
          <div className="image-container">
            <Badge
              className="h3 badge-square text-white"
              color="info"
              id={`tooltip-public_${image.uuid + '-' + cardIndex}`}
            >
              {variables.is_public ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faUserSecret} />}
            </Badge>
            <Tooltip
              delay={500}
              placement="top"
              isOpen={state.tooltip_public_open}
              target={`tooltip-public_${uuid + '-' + cardIndex}`}
              toggle={toggleTooltipPublic}
            >
              {variables.is_public ? locale.public_image : locale.private_image}
            </Tooltip>
            <a onClick={toggleModal} index={cardIndex} title="test">
              <img className="card-img" width="100%" src={path_for_middle_size} alt={`Bild mit der ID: ${uuid}`} />
              {variables.artist !== '' || variables.copyright !== '' ? (
                <span className="image-copyright">{`${variables.artist} ${
                  variables.artist !== '' && variables.copyright !== '' ? '|' : ''
                } ${variables.copyright}`}</span>
              ) : null}
            </a>
          </div>
          <CardBody>
            {state.error ? <Alert color="danger">{state.error_msg}</Alert> : ''}
            <CardText className={state.edit_open ? 'hidden' : ''}>{variables.description_de}</CardText>
            <Form>
              <Update
                state={state}
                uuid={uuid}
                cardIndex={cardIndex}
                locale={locale}
                variables={variables}
                change={change}
              />

              <ul className={state.edit_open ? 'list-unstyled list-checkbox-badge' : 'list-inline'}>
                <li className={state.edit_open ? '' : 'hidden'}>
                  <Label className="control-label checkbox-label" for={`is_public-${uuid + '-' + cardIndex}`}>
                    <Input
                      className="checkbox-control"
                      type="checkbox"
                      id={`is_public-${uuid + '-' + cardIndex}`}
                      defaultChecked={variables.is_public}
                      onChange={changeIsPublic}
                    />
                    <span className="check-icon-container">
                      <span className="check-icon text-primary-dark">
                        <FontAwesomeIcon icon={faCheck} />
                      </span>
                    </span>
                    <span className="label-text">öffentlich</span>
                  </Label>
                </li>
                <li className={state.edit_open ? '' : 'hidden'}>
                  <Label className="control-label checkbox-label" for={`all-guestgroups-${uuid + '-' + cardIndex}`}>
                    <Input
                      className="checkbox-control"
                      type="checkbox"
                      id={`all-guestgroups-${uuid + '-' + cardIndex}`}
                      checked={allChecked()}
                      onChange={changeAllCheckboxes}
                    />
                    <span className="check-icon-container">
                      <span className="check-icon text-primary-dark">
                        <FontAwesomeIcon icon={faCheck} />
                      </span>
                    </span>
                    <span className="label-text">Alle Gästegruppen</span>
                  </Label>
                </li>
              </ul>

              <hr className={state.edit_open ? 'seperator border-light' : 'hidden'} />

              <ul className={state.edit_open ? 'list-unstyled list-checkbox-badge row mt-4' : 'list-inline'}>
                {Object.keys(guest_groups).map((guest, index) => (
                  <CheckboxListitem
                    key={guest}
                    id={guest}
                    cardIndex={cardIndex}
                    index={index}
                    edit_open={state.edit_open}
                    value={variables.guest_groups[guest]}
                    guest_state={state}
                    changeCheckbox={changeCheckbox}
                    locale={locale}
                  />
                ))}
              </ul>
              {state.edit_open ? (
                <div className="text-center d-block pt-4 mt-2 border-top border-light">
                  <Button className="btn-labeled" color="primary-light" onClick={submit}>
                    <FontAwesomeIcon className="icon-prepend" icon={faSave} />
                    <span>{locale.save}</span>
                  </Button>
                </div>
              ) : (
                ''
              )}
            </Form>
          </CardBody>
        </Card>
      </Col>
    )
  }
}

export default withApollo(
  graphql(getFormDatumImages, {
    options: props => ({
      networkPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      variables: {
        uuid: props.uuid
      }
    })
  })(Images)
)
