const TokenAuth = {
  getCredentials: () => JSON.parse(localStorage.getItem('auth')),
  getUser: () => JSON.parse(localStorage.getItem('user')),
  setUser: ({ user }) => {
    const _user = {
      salutation: user.salutation,
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      id: user.id
    }
    localStorage.setItem('user', JSON.stringify(_user))
  },
  getPermissions: () => JSON.parse(localStorage.getItem('permissions')),
  isLoggedIn: () => !!(TokenAuth.getUser() && TokenAuth.getPermissions()),
  logout: () => {
    window.localStorage.clear()
  },
  updateAuthStorage: headers => {
    // dumb HOTFIX
    // https://github.com/lynndylanhurley/devise_token_auth/issues/1159

    // instead of returning a new access-token each request u make (because the backend doesnt know when you make the next request in future)
    // device-token-auth is returning a new access-token only when a USED access-token is older than 5 seconds.
    // thats kind of stupid because a used access-token have a life time of 4 weeks m(

    // console.log('use new token ?', !!headers.get('access-token'), headers.get('access-token'));
    if (!!headers.get('access-token')) {
      // console.log("using new token");
      const auth = {
        client: headers.get('client'),
        'access-token': headers.get('access-token'),
        uid: headers.get('uid')
      }
      localStorage.setItem('auth', JSON.stringify(auth))
    } else {
      // console.log("using old token")
    }
  }
}

export { TokenAuth }
