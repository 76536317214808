import { useRef } from 'react'

import { Button, Col, Row, UncontrolledTooltip } from 'reactstrap'

import {
  faSortAlphaDownAlt,
  faSortAlphaUp,
  faSortAmountDown,
  faSortAmountUpAlt,
  faSortNumericDownAlt,
  faSortNumericUp
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Select } from 'components/modules/selects/react-select'
import { withLocale } from 'locale'
import PropTypes from 'prop-types'

import allowedSortingDirections from './allowedSortingDirections'
import allowedSortingOptions from './allowedSortingOptions'

const sortDirectionIconForType = {
  [allowedSortingOptions.UPDATED_AT]: {
    [allowedSortingDirections.ASCENDING]: faSortNumericUp,
    [allowedSortingDirections.DESCENDING]: faSortNumericDownAlt
  },
  [allowedSortingOptions.NAME]: {
    [allowedSortingDirections.ASCENDING]: faSortAlphaUp,
    [allowedSortingDirections.DESCENDING]: faSortAlphaDownAlt
  },
  [allowedSortingOptions.LICENSE_OWNER]: {
    [allowedSortingDirections.ASCENDING]: faSortAlphaUp,
    [allowedSortingDirections.DESCENDING]: faSortAlphaDownAlt
  },
  [allowedSortingOptions.WORKFLOW_STATE_CHANGED_AT]: {
    [allowedSortingDirections.ASCENDING]: faSortNumericUp,
    [allowedSortingDirections.DESCENDING]: faSortNumericDownAlt
  },
  [allowedSortingOptions.WORKFLOW_STATE]: {
    [allowedSortingDirections.ASCENDING]: faSortAmountUpAlt,
    [allowedSortingDirections.DESCENDING]: faSortAmountDown
  },
  [allowedSortingOptions.COLLECTION_DESIRED_UNTIL]: {
    [allowedSortingDirections.ASCENDING]: faSortNumericUp,
    [allowedSortingDirections.DESCENDING]: faSortNumericDownAlt
  },
  [allowedSortingOptions.COLLECTION_DATE]: {
    [allowedSortingDirections.ASCENDING]: faSortNumericUp,
    [allowedSortingDirections.DESCENDING]: faSortNumericDownAlt
  },
  [allowedSortingOptions.CERTIFIED_TO]: {
    [allowedSortingDirections.ASCENDING]: faSortNumericUp,
    [allowedSortingDirections.DESCENDING]: faSortNumericDownAlt
  }
}

const SortingSwitcher = ({ locale, type, direction, setSortingOptions }) => {
  const buttonRef = useRef()

  const typeOptions = Object.keys(allowedSortingOptions).map(key => ({
    value: allowedSortingOptions[key],
    label: locale.types[allowedSortingOptions[key]]
  }))

  const selectedTypeOption = {
    value: type,
    label: (
      <>
        <span className="text-primary-light">{locale.label}:</span> {locale.types[type]}
      </>
    )
  }

  const setSortingType = selectedOption => {
    setSortingOptions({
      type: selectedOption.value,
      direction
    })
  }

  const sortDirectionIcon = sortDirectionIconForType[type][direction]

  const setSortingDirection = () => {
    setSortingOptions({
      type,
      direction:
        direction === allowedSortingDirections.ASCENDING
          ? allowedSortingDirections.DESCENDING
          : allowedSortingDirections.ASCENDING
    })
  }

  return (
    <Row className="m-0">
      <Col className="p-0">
        <Select options={typeOptions} value={selectedTypeOption} onChange={setSortingType} />
      </Col>
      <Col xs="auto" className="pr-0">
        <Button innerRef={buttonRef} color="link" onClick={setSortingDirection} className="text-primary-light">
          <FontAwesomeIcon icon={sortDirectionIcon} />
        </Button>
        <UncontrolledTooltip placement="top" target={buttonRef} boundariesElement="viewport">
          {locale.directions[type][direction]}
        </UncontrolledTooltip>
      </Col>
    </Row>
  )
}

SortingSwitcher.propTypes = {
  locale: PropTypes.object.isRequired,
  type: PropTypes.oneOf([
    allowedSortingOptions.UPDATED_AT,
    allowedSortingOptions.NAME,
    allowedSortingOptions.LICENSE_OWNER,
    allowedSortingOptions.WORKFLOW_STATE_CHANGED_AT,
    allowedSortingOptions.WORKFLOW_STATE,
    allowedSortingOptions.COLLECTION_DESIRED_UNTIL,
    allowedSortingOptions.COLLECTION_DATE,
    allowedSortingOptions.CERTIFIED_TO
  ]).isRequired,
  direction: PropTypes.oneOf([allowedSortingDirections.ASCENDING, allowedSortingDirections.DESCENDING]).isRequired,
  setSortingOptions: PropTypes.func.isRequired
}

export default withLocale(SortingSwitcher, { key: 'facilitiesDashboard.sorting' })
