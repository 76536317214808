import { useCallback, useEffect, useRef, useState } from 'react'

import { useTimer } from 'react-timer-hook'
import { Alert, Badge, Button, ButtonGroup, UncontrolledTooltip } from 'reactstrap'

import { useMutation, useSubscription } from '@apollo/client'
import { faDownload, faTable } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { ProcessingButton } from 'components/modules/buttons/processing-button'
import generateUUID from 'helpers/guid'
import { withLocale } from 'locale'
import moment from 'moment'
import { PropTypes } from 'prop-types'

import { facilitiesExportUpdateSubscription, initializeFilteredFacilitiesExport } from './fileExporter.graphql'

import './styles.scss'

const DownloadTimeInfo = withLocale(
  ({ fileCreatedAt, locale }) => {
    const createdAt = moment(fileCreatedAt)
    const expireAt = moment(createdAt).add(2, 'hours')
    useTimer({
      expiryTimestamp: expireAt
    })

    return (
      <>
        {locale.fileCreated(createdAt)}
        <br />
        {locale.expire(expireAt)}
      </>
    )
  },
  { key: 'facilitiesExport' }
)

const ProcessingLabelWithCount = withLocale(
  ({ state, processedCount, totalCount, locale }) => {
    switch (state) {
      case 'checking':
        return locale.checking
      case 'waiting':
        return locale.waiting
      case 'processing':
        return (
          <>
            {locale.processing}{' '}
            <Badge color="success" className="text-monospace ml-2">
              {processedCount === totalCount ? locale.saving : `${processedCount}/${totalCount}`}
            </Badge>
          </>
        )
      default:
        return null
    }
  },
  { key: 'facilitiesExport' }
)

const FileExporter = ({
  locale,
  sortBy,
  sortDirection,
  queryString,
  licenseOwnerIds,
  collectorIds,
  regionIds,
  trainingStatus,
  certificationEndDateRange,
  workflowStates,
  certificationTypes
}) => {
  const exportButtonId = useCallback(`facilities-generate-export-button${generateUUID()}`, [])
  const downloadButtonRef = useRef()
  const [exportState, setExportState] = useState('checking')
  const { loading, data, error } = useSubscription(facilitiesExportUpdateSubscription)
  const [initializeExportMutation] = useMutation(initializeFilteredFacilitiesExport)

  const fileExport = data?.fileExport

  useEffect(() => {
    if (loading) {
      setExportState('checking')
    } else if (fileExport === null) {
      setExportState('missing')
    } else if (fileExport.totalCount !== null && fileExport.downloadUrl === null) {
      setExportState('processing')
    } else if (fileExport.downloadUrl !== null) {
      setExportState('existing')
    } else {
      setExportState('waiting')
    }
  }, [loading, fileExport, fileExport?.totalCount, fileExport?.downloadUrl])

  if (error) {
    console.error('Error getting export status', error)
    return (
      <Alert color="danger" className="m-0">
        Error getting export status
      </Alert>
    )
  }

  const processing = ['checking', 'waiting', 'processing'].includes(exportState)

  const progress =
    exportState !== 'waiting' && fileExport ? ((fileExport.processedCount / fileExport.totalCount) * 100).toFixed(2) : 0

  const initializeAsyncExport = () => {
    setExportState('waiting')
    initializeExportMutation({
      variables: {
        sortBy,
        sortDirection,
        queryString: queryString.length > 0 ? queryString : null,
        licenseOwnerIds: licenseOwnerIds.length > 0 ? licenseOwnerIds : null,
        collectorIds: collectorIds.length > 0 ? collectorIds : null,
        regionIds: regionIds.length > 0 ? regionIds : null,
        trainingStatus,
        certificationEndDateRange,
        workflowStates: workflowStates.length > 0 ? workflowStates : null,
        certificationTypes: certificationTypes.length > 0 ? certificationTypes : null
      }
    })
  }

  return (
    <ButtonGroup className="full-width facilities-export-button-group">
      <ProcessingButton
        id={exportButtonId}
        label={locale.generate}
        outline
        color="primary"
        icon={faTable}
        processing={processing}
        processing_label={
          <ProcessingLabelWithCount
            state={exportState}
            totalCount={fileExport?.totalCount}
            processedCount={fileExport?.processedCount}
          />
        }
        progress={progress}
        disabled={loading}
        onClick={initializeAsyncExport}
      />
      <UncontrolledTooltip placement="top" target={exportButtonId} boundariesElement="viewport">
        {locale.generateHint}
      </UncontrolledTooltip>

      {exportState !== 'waiting' && fileExport?.downloadUrl && (
        <>
          <Button
            href={fileExport.downloadUrl}
            download
            className="flex-grow-0 px-3"
            color="primary-light"
            innerRef={downloadButtonRef}
            tag="a"
            target="_blank"
          >
            <FontAwesomeIcon icon={faDownload} />
          </Button>
          <UncontrolledTooltip placement="top" target={downloadButtonRef}>
            {locale.download(fileExport.totalCount)}
            <br />
            <DownloadTimeInfo fileCreatedAt={fileExport.fileCreatedAt} />
          </UncontrolledTooltip>
        </>
      )}
    </ButtonGroup>
  )
}

FileExporter.propTypes = {
  locale: PropTypes.object.isRequired,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.string,
  queryString: PropTypes.string,
  licenseOwnerIds: PropTypes.arrayOf(PropTypes.string),
  collectorIds: PropTypes.arrayOf(PropTypes.string),
  regionIds: PropTypes.arrayOf(PropTypes.string),
  trainingStatus: PropTypes.string,
  certificationEndDateRange: PropTypes.shape({
    start: PropTypes.string.isRequired,
    end: PropTypes.string.isRequired
  }),
  workflowStates: PropTypes.arrayOf(PropTypes.string),
  certificationTypes: PropTypes.arrayOf(PropTypes.string)
}

export default withLocale(FileExporter, { key: 'facilitiesExport' })
