import { Component, Fragment } from 'react'

import { Alert, Col, Label } from 'reactstrap'

import { withApollo } from '@apollo/client/react/hoc'

import update from 'immutability-helper'

import { getFacility } from '../../facility.graphql'
import { updateFacilityPartnerGateway } from './information.graphql'

import { getPropertyFromObject } from '../../../../helper/helper-functions'
import ValidatedInformationInput from '../../../../modules/inputs/validated-information-input'
import RadioButtons from '../../../../modules/radio-switch/radio-buttons'
import { FormGroupContainer, FormGroupContainerActions } from '../partials'

class PartnerGatewayForm extends Component {
  state = {
    processing: false,
    variables: {
      uuid: this.props.facility.uuid,
      settings: (({ listed, en_available, publication_allowed, publication_comments }) => ({
        listed,
        en_available,
        publication_allowed,
        publication_comments
      }))(this.props.facility.partner_gateway_settings)
    }
  }

  onError = ({ graphQLErrors }) =>
    this.setState({
      processing: false,
      error_msg: graphQLErrors[0].message,
      errors: graphQLErrors[0].errors
    })

  onSuccess = () =>
    this.setState(
      {
        processing: false,
        error_msg: '',
        errors: {}
      },
      this.props.hideEditBoxRight()
    )

  submit = () => {
    this.setState({ processing: true })
    const {
      props: {
        client,
        facility: { uuid }
      },
      state: { variables }
    } = this

    client
      .mutate({
        mutation: updateFacilityPartnerGateway,
        variables,
        refetchQueries: [
          {
            query: getFacility,
            variables: {
              uuid,
              version_number: this.props.match.params.version_number || ''
            }
          }
        ],
        awaitRefetchQueries: true
      })
      .then(() => this.onSuccess())
      .catch(error => this.onError(error))
  }

  onChange = key => event =>
    this.setState(
      update(this.state, {
        variables: {
          settings: {
            [key]: {
              $set: event.target.value
            }
          }
        }
      })
    )

  update = () =>
    this.setState({ processing: true }, () =>
      this.props.client
        .mutate({
          mutation: updateFacilityPartnerGateway,
          variables: {
            uuid: this.props.facility.uuid,
            settings: {
              listed: true
            }
          },
          refetchQueries: [
            {
              query: getFacility,
              variables: {
                uuid: this.props.facility.uuid,
                version_number: this.props.match.params.version_number || ''
              }
            }
          ],
          awaitRefetchQueries: true
        })
        .then(() => this.onSuccess())
        .catch(error => this.onError(error))
    )

  render = () => {
    const {
      onChange,
      onChangeAvailable,
      submit,
      update,
      props: {
        locale,
        hideEditBoxRight,
        facility: { permissions }
      },
      state: { errors, error_msg, variables, processing }
    } = this
    const actions_props = { locale, hideEditBoxRight, processing, submit }
    return (
      <div className="box">
        {error_msg && <Alert color="danger">{error_msg}</Alert>}
        <FormGroupContainer
          children={
            <Fragment>
              <Col lg="5" className="text-lg-right">
                <Label className="control-label" for={'settings.listed'}>
                  {getPropertyFromObject(locale, 'settings.listed')}
                </Label>
              </Col>
              <Col lg="7">
                <RadioButtons id={'settings.listed'} variables={this.state.variables} onChange={onChange('listed')} />
              </Col>
            </Fragment>
          }
        />
        <FormGroupContainer
          children={
            <Fragment>
              <Col lg="5" className="text-lg-right">
                <Label className="control-label" for={'settings.en_available'}>
                  {getPropertyFromObject(locale, 'settings.en_available')}
                </Label>
              </Col>
              <Col lg="7">
                <RadioButtons
                  id={'settings.en_available'}
                  variables={this.state.variables}
                  onChange={onChange('en_available')}
                />
              </Col>
            </Fragment>
          }
        />
        {permissions.update_confidental_partner_gateway_settings && (
          <Fragment>
            <FormGroupContainer>
              <Col lg="5" className="text-lg-right">
                <Label className="control-label" for={'settings.publication_allowed'}>
                  {getPropertyFromObject(locale, 'settings.publication_allowed')}
                </Label>
              </Col>
              <Col lg="7">
                <RadioButtons
                  id={'settings.publication_allowed'}
                  variables={this.state.variables}
                  onChange={onChange('publication_allowed')}
                />
              </Col>
            </FormGroupContainer>
            <FormGroupContainer>
              <ValidatedInformationInput
                type="textarea"
                id="settings.publication_comments"
                {...{ errors, variables, locale, onChange: onChange('publication_comments') }}
              />
            </FormGroupContainer>
          </Fragment>
        )}
        <FormGroupContainerActions {...actions_props} />
      </div>
    )
  }
}

export default withApollo(PartnerGatewayForm)
