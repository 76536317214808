import React from 'react'

import { Button, Col, Container, FormGroup, Modal, ModalBody, Row } from 'reactstrap'

import { faImages } from '@fortawesome/free-solid-svg-icons'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class CreateSectionImagesModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      modal: false
    }
  }

  toggle = event => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    this.setState({
      modal: !this.state.modal
    })
  }

  render = () => {
    const { locale, children } = this.props
    const { modal, className } = this.state
    const { toggle } = this

    return (
      <React.Fragment>
        <Button className="px-4" outline color={'primary-light'} onClick={this.toggle}>
          <FontAwesomeIcon className="icon-prepend" icon={faImages} />
          <span>{locale.edit_images}</span>
        </Button>
        <Modal size={'lg'} centered isOpen={modal} toggle={toggle} className={className}>
          <ModalBody>
            {children}
            <FormGroup className="form-action">
              <Container>
                <Row>
                  <Col sm="12" className="text-right">
                    <a className="link-btn" onClick={toggle} href="#">
                      {locale.close}
                    </a>
                  </Col>
                </Row>
              </Container>
            </FormGroup>
          </ModalBody>
        </Modal>
      </React.Fragment>
    )
  }
}

export default CreateSectionImagesModal
