import * as Sentry from '@sentry/react'

import { removeOffline } from '.'
import db from '../../../indexed-db/db'
import { certificationUpdateMutation } from '../../views/facility/certification-workflow/sections/graphql'
import { config, syncMutation } from './sync'

const syncOnline = ({ facility, updateProgress, client }) => {
  let promises = []

  config.forEach(sync => promises.push(db.get(sync.store, facility.uuid)))

  return Promise.all(promises).then(results => {
    updateProgress({ max: results.flat().length })

    let promiseChain = Promise.resolve()
    let errors = []

    results.forEach((result, index) => {
      promiseChain = promiseChain.then(() => {
        let sync = config[index]
        return syncMutation(result || [], sync, client, updateProgress, error => errors.push(error))
      })
    })

    return promiseChain
      .then(() => {
        if (errors.length > 0) {
          console.error({ errors })
          errors.forEach(Sentry.captureException)
        }
      })
      .then(_ => {
        console.log(facility)
        let device_name = facility?.download?.device || 'unbekanntem Gerät'
        let comment = `Offline Daten synchronisiert von ${device_name}`
        client.mutate({
          mutation: certificationUpdateMutation,
          variables: {
            uuid: facility.uuid,
            comment
          }
        })
      })
      .then(() => removeOffline(facility, client))
  })
}

export default syncOnline
