import AssetDownloads from '../components/views/admin/asset-downloads'
import InputApiUsers from '../components/views/admin/input-api-users/input_api_users'
import LicenseOwners from '../components/views/admin/license-owners/license_owners'
import PartnerGatewayUsers from '../components/views/admin/partner-gateway-users/partner_gateway_users'
import { Providers } from '../components/views/admin/providers'
import Regions from '../components/views/admin/regions/regions'
import Roles from '../components/views/admin/roles/roles'
import Users from '../components/views/admin/users/users'
import WidgetFilters from '../components/views/admin/widget-filters/widget_filters'
import DataPrivacy from '../components/views/data-privacy/data-privacy'
import GraphiQl from '../components/views/debug/graphiql'
import PageNotFound from '../components/views/errors/page-not-found'
import Facilities from '../components/views/facilities/facilities.jsx'
import Facility from '../components/views/facility/facility.jsx'
import getNewFormDatumQueryComponent from '../components/views/facility/form-datum/create_new'
import PublicRequirements from '../components/views/facility/form-datum/public_requirements'
import EditFormsDatumWithRequirementQueryComponent from '../components/views/facility/form-datum/requirements'
import EditFormDatumQueryComponent from '../components/views/facility/form-datum/update_new'
import ReportingsDetails from '../components/views/facility/reportings/details'
import FacilityKinds from '../components/views/form-kit/facility-kinds/facility-kinds'
import UpdateField from '../components/views/form-kit/forms/form/field/update'
import UpdateForm from '../components/views/form-kit/forms/form/update'
import Forms from '../components/views/form-kit/forms/forms'
import SectionCreate from '../components/views/form-kit/sections/section_create'
import SectionUpdate from '../components/views/form-kit/sections/section_update'
import Sections from '../components/views/form-kit/sections/sections'
import {
  CreateShortReportVariable,
  EditShortReportVariable,
  ShortReportVariables
} from '../components/views/form-kit/short-report-variables'
import VersionClone from '../components/views/form-kit/versions/version_clone'
import VersionCreate from '../components/views/form-kit/versions/version_create'
import VersionUpdate from '../components/views/form-kit/versions/version_update'
import Versions from '../components/views/form-kit/versions/versions'
import ConfirmReset from '../components/views/login/confirm-reset-password.jsx'
import Login from '../components/views/login/login.jsx'
import Logout from '../components/views/login/logout.jsx'
import Reset from '../components/views/login/reset-password.jsx'
import CreateFacility from '../components/views/no-login/create_facility'
import Manuals from '../components/views/user/manuals/manuals'
import OnlineTraining from '../components/views/user/online_training/online_training'
import Settings from '../components/views/user/settings/settings'

const routes = [
  {
    exact: true,
    path: '/',
    component: Facilities
  },
  {
    path: '/data-privacy',
    component: DataPrivacy,
    protected: false
  },
  {
    path: '/login',
    component: Login,
    protected: false
  },
  {
    path: '/logout',
    component: Logout
  },
  {
    path: '/password/new',
    component: Reset,
    protected: false
  },
  {
    path: '/reset_password/:token',
    component: ConfirmReset,
    protected: false
  },
  {
    exact: true,
    path: '/facilities',
    component: Facilities
  },
  {
    path: '/admin/regions',
    component: Regions
  },
  {
    path: '/admin/widget-filters',
    component: WidgetFilters
  },
  {
    path: '/admin/providers',
    component: Providers
  },
  {
    path: '/admin/license-owners',
    component: LicenseOwners
  },
  {
    path: '/admin/asset-downloads/:page?',
    component: AssetDownloads
  },
  {
    path: '/admin/roles',
    component: Roles
  },
  {
    path: '/admin/users',
    component: Users
  },
  {
    path: '/admin/partner_gateway_users',
    component: PartnerGatewayUsers
  },

  {
    path: '/admin/input_api_users',
    component: InputApiUsers
  },
  {
    path: '/form-kit/versions',
    component: Versions
  },
  {
    path: '/form-kit/version/new',
    component: VersionCreate
  },
  {
    path: '/form-kit/version/clone/:id',
    component: VersionClone
  },
  {
    path: '/form-kit/version/edit/:id',
    component: VersionUpdate
  },
  {
    path: '/form-kit/sections/version/:id?',
    component: Sections
  },
  {
    path: '/form-kit/section/new/version/:id',
    component: SectionCreate
  },
  {
    path: '/form-kit/section/edit/:id',
    component: SectionUpdate
  },
  {
    path: '/form-kit/forms/:id?',
    component: Forms
  },
  {
    path: '/form-kit/create/form/:form_version_id?',
    component: UpdateForm
  },
  {
    path: '/form-kit/form/:id',
    component: UpdateForm
  },
  {
    path: '/form-kit/create/field/:form_id?',
    component: UpdateField
  },
  {
    path: '/form-kit/field/:id',
    component: UpdateField
  },
  {
    path: '/form-kit/facility-kinds',
    component: FacilityKinds
  },
  {
    path: '/form-kit/short-report-variables/new/:form_id?',
    component: CreateShortReportVariable
  },
  {
    path: '/form-kit/short-report-variables/:variable_id',
    component: EditShortReportVariable
  },
  {
    path: '/form-kit/short-report-variables',
    component: ShortReportVariables
  },
  {
    path: '/user/settings',
    component: Settings
  },
  {
    path: '/facility/:uuid/:version_number?',
    component: Facility,
    name: 'facility'
  },
  {
    path: '/offline-facility/:uuid/',
    component: Facility,
    name: 'facility'
  },
  {
    path: '/formdatum/edit/:uuid',
    component: EditFormDatumQueryComponent
  },
  {
    path: '/offline-formdatum/edit/:uuid',
    component: EditFormDatumQueryComponent
  },
  {
    path: '/reportings/:uuid/:version_number',
    component: ReportingsDetails
  },
  {
    path: '/formdatum/requirements/edit/:uuid/:guest_group/:category',
    component: EditFormsDatumWithRequirementQueryComponent
  },
  {
    path: '/formdatum/requirements/public/:uuid/:guest_group?/:category?',
    component: PublicRequirements
  },
  {
    path: '/formdatum/new/:form_id/:section_datum_uuid/:facility_uuid',
    component: getNewFormDatumQueryComponent
  },
  {
    path: '/offline-formdatum/new/:form_id/:section_datum_uuid/:facility_uuid',
    component: getNewFormDatumQueryComponent
  },
  {
    path: '/einrichtung-erstellen',
    component: CreateFacility,
    protected: false
  },
  {
    path: '/manuals',
    component: Manuals
  },
  {
    path: '/online-training',
    component: OnlineTraining
  },
  {
    path: '/debug/graphiql',
    component: GraphiQl,
    applicationLayout: false,
    protected: false
  },
  {
    component: PageNotFound
  }
]

export default routes
