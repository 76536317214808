import { Fragment } from 'react'

import PropTypes from 'prop-types'

import { InputWrapper } from '..'
import CountrySelect from '../../../../modules/selects/countries'
import StateSelect from '../../../../modules/selects/states'
import { FormGroupContainer } from '../../../../views/facility/information/partials'
import ValidatedInput from '../../../inputs/validated-input'

const AddressForm = ({ id, variables, errors, locale, onChange }) => (
  <Fragment>
    <FormGroupContainer>
      <ValidatedInput
        id={`${id}.address.street`}
        errors={errors}
        variables={variables}
        locale={locale}
        onChange={onChange}
      />
      <ValidatedInput
        id={`${id}.address.street_addition`}
        errors={errors}
        variables={variables}
        locale={locale}
        onChange={onChange}
      />
    </FormGroupContainer>
    <FormGroupContainer>
      <ValidatedInput
        id={`${id}.address.zipcode`}
        errors={errors}
        variables={variables}
        locale={locale}
        onChange={onChange}
      />
      <ValidatedInput
        id={`${id}.address.city`}
        errors={errors}
        variables={variables}
        locale={locale}
        onChange={onChange}
      />
    </FormGroupContainer>

    <FormGroupContainer>
      <InputWrapper
        id={`${id}.address.country_code`}
        label={locale.country}
        layout={{ label: { sm: 2 }, input: { sm: 4 } }}
      >
        <CountrySelect
          id={`${id}.address.country_code`}
          errors={errors}
          variables={variables}
          locale={locale}
          onChange={onChange}
        />
      </InputWrapper>
      <InputWrapper id={`${id}.address.state`} label={locale.state} layout={{ label: { sm: 2 }, input: { sm: 4 } }}>
        <StateSelect
          id={`${id}.address.state`}
          current_country={variables[id].address.country_code}
          errors={errors}
          variables={variables}
          locale={locale}
          onChange={onChange}
        />
      </InputWrapper>
    </FormGroupContainer>
  </Fragment>
)

AddressForm.propTypes = {
  id: PropTypes.string.isRequired,
  variables: PropTypes.object.isRequired,
  errors: PropTypes.object,
  locale: PropTypes.object.isRequired
}

export default AddressForm
