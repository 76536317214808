import { faMapLocationDot } from '@fortawesome/free-solid-svg-icons'

import { withLocale } from 'locale/index'
import PropTypes from 'prop-types'

import ComponentTypeBadge from '../componentTypeBadge'

const PathLengthCalculationComponentType = ({ value, locale }) => (
  <ComponentTypeBadge color="primary" tooltip={locale.tooltip} icon={faMapLocationDot}>
    {value}
  </ComponentTypeBadge>
)

PathLengthCalculationComponentType.propTypes = {
  value: PropTypes.string.isRequired,
  locale: PropTypes.shape({
    tooltip: PropTypes.string.isRequired
  }).isRequired
}

export default withLocale(PathLengthCalculationComponentType, {
  key: 'accounting_information.calculatedVolumeUnitCostsCalculationComponents.pathLength'
})
