import React, { Fragment } from 'react'

import { Alert, Col, Form } from 'reactstrap'

import { withApollo } from '@apollo/client/react/hoc'
import { faSave } from '@fortawesome/free-solid-svg-icons'

import { withLocale } from 'locale'
import PropTypes from 'prop-types'

import allowKeys from '../../../../../../helpers/allow_keys'
import valueFromString from '../../../../../../helpers/value_from_string'
import { ProcessingButton } from '../../../../../modules/buttons/processing-button'
import { FormGroupContainer } from '../../../../facility/information/partials'
import { FormFields } from '../../create'
import { getProviders, updateProvider } from '../../graphql'

class ModalContent extends React.Component {
  constructor(props) {
    super(props)

    let provider = allowKeys(props.provider, [
      'name',
      'email',
      'phone',
      'miscellaneous',
      {
        address: ['street', 'street_addition', 'zipcode', 'city']
      }
    ])

    if (provider.address) {
      provider.address.country_code = props.provider.address.country?.key
      provider.address.state = props.provider.address.state?.key
    } else {
      provider.address = {}
    }

    this.state = {
      error_msg: null,
      errors: {},
      processing: false,
      variables: {
        provider
      }
    }
  }

  static propTypes = {
    client: PropTypes.object.isRequired,
    locale: PropTypes.object.isRequired,
    provider: PropTypes.object.isRequired,
    toggleModal: PropTypes.func.isRequired,
    refetchQueries: PropTypes.arrayOf(PropTypes.object)
  }

  onChange = e => {
    let newState = this.state
    if (
      e.target.id === 'provider.address.country_code' &&
      e.target.value !== this.state.variables.provider.address.country_code
    ) {
      newState.variables.provider.address.state = null
    }
    valueFromString(newState.variables, e.target.id, e.target.value)
    this.setState(newState)
  }

  submit = e => {
    e.preventDefault()
    this.setState({
      processing: true
    })

    this.props.client
      .mutate({
        mutation: updateProvider,
        variables: {
          ...this.state.variables,
          id: this.props.provider.id
        },
        refetchQueries: this.props.refetchQueries || [{ query: getProviders }],
        awaitRefetchQueries: true
      })
      .then(() => this.props.toggleModal())
      .catch(this.onError)
  }

  onError = ({ graphQLErrors }) =>
    this.setState({
      error_msg: graphQLErrors[0].message,
      errors: graphQLErrors[0].errors,
      processing: false
    })

  render = () => {
    const {
      onChange,
      props: { locale, toggleModal },
      state: { error_msg, errors, processing, variables },
      submit
    } = this

    return (
      <Fragment>
        {error_msg && <Alert color="danger">{error_msg}</Alert>}
        <Form onSubmit={submit}>
          <fieldset disabled={processing}>
            <FormFields {...{ locale, variables, errors, onChange }} />
          </fieldset>
          <FormGroupContainer>
            <Col sm="2">
              <a className="link-btn" onClick={toggleModal} href="#">
                {locale.cancel}
              </a>
            </Col>
            <Col sm={{ size: '4', offset: '6' }} className="text-right">
              <ProcessingButton processing={processing} onClick={submit} label={locale.save} icon={faSave} />
            </Col>
          </FormGroupContainer>
        </Form>
      </Fragment>
    )
  }
}

export default withApollo(withLocale(ModalContent))
