import { format, parseISO } from 'date-fns'
import { de as dateLocaleDe } from 'date-fns/locale'
import PropTypes from 'prop-types'

const FormattedDateTime = ({ ISO8601DateTime, format: resultFormat = 'dd.MM.yyyy - HH:mm' }) => {
  if (!ISO8601DateTime) return null

  const parsedDate = parseISO(ISO8601DateTime)

  return format(parsedDate, resultFormat, { locale: dateLocaleDe })
}

FormattedDateTime.propTypes = {
  ISO8601DateTime: PropTypes.string,
  format: PropTypes.string
}

export default FormattedDateTime
