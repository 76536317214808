import React, { Fragment, createRef } from 'react'

import { Button, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, UncontrolledTooltip } from 'reactstrap'

import { withApollo } from '@apollo/client/react/hoc'
import { faPenToSquare, faSave } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { LegacySelect as Select } from 'components/modules/selects/react-select'
import { deepNestInObjectsWithKeys } from 'helpers/object'
import update from 'immutability-helper'

import { getFacility } from '../facility.graphql'
import { updateFacilityBaseDataContactPerson } from './contact-person-certification.graphql'

import { ProcessingButton } from '../../../modules/buttons/processing-button'
import ValidatedInput from '../../../modules/inputs/validated-input'

class UpdateData extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      processing: false,
      modal: false,
      variables: {
        base_data: {
          contact_person: {
            salutation: this.props?.contact_person?.salutation?.key || 'mr',
            first_name: this.props?.contact_person?.first_name || '',
            last_name: this.props?.contact_person?.last_name || '',
            email: this.props?.contact_person?.email || '',
            phone: this.props?.contact_person?.phone || ''
          }
        },
        uuid: this.props.uuid
      }
    }
    this.buttonRef = createRef()
  }

  onError = ({ graphQLErrors }) =>
    this.setState({
      processing: false,
      error_msg: graphQLErrors[0].message,
      errors: graphQLErrors[0].errors
    })
  toggle = () => this.setState({ modal: !this.state.modal, processing: false })
  submit = () => {
    this.setState({ processing: true })
    this.props.client
      .mutate({
        mutation: updateFacilityBaseDataContactPerson,
        variables: this.state.variables,
        refetchQueries: [
          {
            query: getFacility,
            variables: {
              uuid: this.state.variables.uuid,
              version_number: this.props.match.params.version_number || ''
            }
          }
        ],
        awaitRefetchQueries: true
      })
      .then(result => this.toggle())
      .catch(error => this.onError(error))
  }

  changeSalutation = salutation =>
    this.setState(
      update(this.state, {
        variables: {
          base_data: {
            contact_person: {
              salutation: {
                $set: salutation.value
              }
            }
          }
        }
      })
    )

  onChange = event => {
    const fragments = event.target.id.split('.')
    const variables = deepNestInObjectsWithKeys(event.target.value, ...fragments, '$set')

    this.setState(update(this.state, { variables }))
  }

  render() {
    const {
      buttonRef,
      changeSalutation,
      props: { locale, headline, user, isUser, isFacilityOwner, options = [] },
      onChange,
      submit,
      state: { variables, errors }
    } = this
    return (
      <Fragment>
        <Button onClick={this.toggle} color="primary" size="sm" innerRef={buttonRef}>
          <FontAwesomeIcon icon={faPenToSquare} /> {locale.facility_contacts.actions.edit_static.label}
        </Button>
        <UncontrolledTooltip placement="top" target={buttonRef} boundariesElement="viewport">
          {locale.facility_contacts.actions.edit_static.tooltip}
        </UncontrolledTooltip>
        <Modal size={'lg'} centered isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader>Daten von {headline} bearbeiten</ModalHeader>
          <ModalBody>
            <Row>
              <Col sm="2" className="text-right">
                <Label className="control-label" for="salutation">
                  {locale.salutation}
                </Label>
              </Col>
              <Col sm={4}>
                {' '}
                <Select
                  isClearable={false}
                  name="salutation"
                  value={variables.base_data.contact_person.salutation}
                  placeholder="auswählen..."
                  onChange={changeSalutation}
                  options={[
                    { value: 'mr', label: 'Herr' },
                    { value: 'mrs', label: 'Frau' }
                  ]}
                />
              </Col>
            </Row>
            <Row>
              <ValidatedInput
                locale={locale}
                onChange={onChange}
                id="base_data.contact_person.first_name"
                variables={variables}
                errors={errors}
              />
              <ValidatedInput
                label_sm={1}
                locale={locale}
                onChange={onChange}
                id="base_data.contact_person.last_name"
                variables={variables}
                errors={errors}
              />
            </Row>
            <Row>
              <ValidatedInput
                locale={locale}
                onChange={onChange}
                id="base_data.contact_person.email"
                variables={variables}
                errors={errors}
              />
              <ValidatedInput
                label_sm={1}
                locale={locale}
                onChange={onChange}
                id="base_data.contact_person.phone"
                variables={variables}
                errors={errors}
              />
            </Row>
          </ModalBody>
          <ModalFooter>
            <ProcessingButton processing={this.state.processing} onClick={submit} label={'Speichern'} icon={faSave} />
          </ModalFooter>
        </Modal>
      </Fragment>
    )
  }
}

export default withApollo(UpdateData)
