import React from 'react'

import { Alert } from 'reactstrap'

import { withApollo } from '@apollo/client/react/hoc'

import { deepNestInObjectsWithKeys } from 'helpers/object'
import update from 'immutability-helper'

import { createWidgetFilter, getWidgetFilters } from './widget_filters.graphql'

import FormGroups from './form'

const WidgetFilterCreate = ({ locale, history, client, toggleModal }) => (
  <React.Fragment>
    <WidgetFilterForm client={client} history={history} locale={locale} toggleModal={toggleModal} />
  </React.Fragment>
)

class WidgetFilterForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      variables: {
        widget_filter: {
          name_de: '',
          name_en: ''
        }
      }
    }
  }

  onChange = ({ target }) => {
    const fragments = target.id.split('.')
    const variables = deepNestInObjectsWithKeys(target.value, ...fragments, '$set')

    this.setState(update(this.state, { variables }))
  }

  submit = () => {
    const { variables } = this.state

    this.props.client
      .mutate({
        mutation: createWidgetFilter,
        variables,
        refetchQueries: [
          {
            query: getWidgetFilters
          }
        ]
      })
      .then(() => this.props.toggleModal())
      .catch(error => this.onError(error))
  }

  onError = ({ graphQLErrors }) =>
    this.setState({
      error_msg: graphQLErrors[0].message,
      errors: graphQLErrors[0].errors
    })

  render = () => (
    <React.Fragment>
      {this.state.errors && <Alert color="danger">{this.state.error_msg}</Alert>}
      <FormGroups
        toggleModal={this.props.toggleModal}
        errors={this.state.errors}
        variables={this.state.variables}
        props={this.props}
        onChange={this.onChange}
        submit={this.submit}
      />
    </React.Fragment>
  )
}

export default withApollo(WidgetFilterCreate)
