import { Fragment, useEffect, useState } from 'react'

import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import { faSync } from '@fortawesome/free-solid-svg-icons'

import { ProcessingButton } from 'components/modules/buttons/processing-button'
import { withLocale } from 'locale'
import { Workbox, messageSW } from 'workbox-window'

const initServiceworker = setUpdateAvailable => {
  let update

  if ('serviceWorker' in navigator) {
    const wb = new Workbox('/sw.js')
    let registration

    update = () => {
      wb.addEventListener('controlling', event => {
        window.location.reload()
      })

      if (registration && registration.waiting) {
        messageSW(registration.waiting, { type: 'SKIP_WAITING' })
      }
    }

    const showSkipWaitingPrompt = event => {
      setUpdateAvailable(true)
    }

    // Add an event listener to detect when the registered
    // service worker has installed but is waiting to activate.
    wb.addEventListener('waiting', showSkipWaitingPrompt)
    wb.addEventListener('externalwaiting', showSkipWaitingPrompt)

    wb.register()
      .then(r => {
        registration = r
        console.debug('Serviceworker registration succeeded')
      })
      .catch(error => {
        if (error.name == 'SecurityError' && error.message == 'The operation is insecure.') {
          console.error('Serviceworker registration failed:', error)
        } else {
          throw error
        }
      })
  }

  return update
}

export const ServiceworkerProvider = withLocale(
  ({ children, locale }) => {
    const [updateAvailable, setUpdateAvailable] = useState(false)
    const [processing, setProcessing] = useState(false)
    const [actions, setActions] = useState({})

    useEffect(() => {
      const u = initServiceworker(setUpdateAvailable)
      setActions({ update: u })
    }, [])

    if (updateAvailable) console.log('New Serviceworker Available')

    return (
      <Fragment>
        <Modal isOpen={updateAvailable && __SERVER_ENV__ !== 'development'} centered={true} size="sm" backdrop="static">
          <ModalHeader toggle={_ => setUpdateAvailable(!updateAvailable)}>{locale.updatePrompt.title}</ModalHeader>
          <ModalBody>{locale.updatePrompt.info}</ModalBody>
          <ModalFooter className="pt-2">
            <ProcessingButton
              label={locale.updatePrompt.action}
              icon={faSync}
              processing={!actions.update || processing}
              processing_label="… wird aktualisiert"
              color="warning"
              onClick={_ => {
                setProcessing(true)
                actions.update()
              }}
            />
          </ModalFooter>
        </Modal>

        {children}
      </Fragment>
    )
  },
  {
    key: 'serviceworker'
  }
)
