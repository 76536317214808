import { Fragment } from 'react'

import { components } from 'react-select'

import { LegacySelect as Select } from 'components/modules/selects/react-select'
import { withLocale } from 'locale'
import PropTypes from 'prop-types'

const Option = props => (
  <components.Option {...props}>
    <Fragment>
      {props.data.name} <small className="d-block">{props.data.email}</small>
    </Fragment>
  </components.Option>
)

const filterOption = ({ data: { name, email } }, filterString) =>
  name.toLowerCase().includes(filterString.toLowerCase()) || email.toLowerCase().includes(filterString.toLowerCase())

const ProviderSelect = ({ locale, value, providers, onChange }) => (
  <Select
    value={value}
    onChange={onChange}
    placeholder={`${locale.choose}...`}
    simpleValue
    isClearable={false}
    filterOption={filterOption}
    noOptionsMessage={_ => locale.facility.provider.none_found}
    options={providers.map(({ id, name, email }) => ({
      name,
      email,
      label: name,
      value: id
    }))}
    components={{ Option }}
  />
)

ProviderSelect.propTypes = {
  locale: PropTypes.object.isRequired,
  value: PropTypes.string,
  providers: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired
}

export default withLocale(ProviderSelect)
