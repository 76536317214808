// additional components
import React from 'react'

import { Badge, Modal, ModalBody, ModalHeader, Tooltip } from 'reactstrap'

import Guid from '../../../helpers/guid'
import FormGroup from '../../views/admin/roles/update.jsx'

// CSS
import '../../modules/modal/_modal.scss'

class UpdateRolesModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
      backdrop: 'static',
      tooltip_open: false,
      tooltip_uuid: Guid()
    }
  }

  toggleModal = event => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    this.setState({
      modal: !this.state.modal
    })
  }

  toggleTooltip = () =>
    this.setState({
      tooltip_open: !this.state.tooltip_open
    })

  changeBackdrop(event) {
    let value = event.target.value
    if (value !== 'static') {
      value = JSON.parse(value)
    }
    this.setState({ backdrop: value })
  }

  render() {
    const { locale, client, history } = this.props
    const { name, id, dsft, changeable } = this.props.role
    const { tooltip_open, modal, tooltip_uuid } = this.state
    const { toggleModal, toggleTooltip } = this

    return (
      <React.Fragment>
        <a
          className="link-btn"
          href="#"
          id={`tooltip-modal_${tooltip_uuid}`}
          onClick={e => {
            e.preventDefault()
            toggleModal(e)
          }}
        >
          {name}
        </a>
        <Tooltip
          delay={500}
          placement="top"
          isOpen={tooltip_open}
          target={`tooltip-modal_${tooltip_uuid}`}
          toggle={toggleTooltip}
        >
          {`${locale.role.singular} "${name}" ${locale.edit}`}
        </Tooltip>
        <Modal isOpen={modal} centered size={'lg'} toggle={toggleModal}>
          <ModalHeader>
            {`${locale.role.singular} "${name}" ${locale.edit}`}
            {!changeable && (
              <Badge className="ml-3" color="warning">
                Schreibgeschützt
              </Badge>
            )}
          </ModalHeader>
          <ModalBody>
            <FormGroup
              toggleModal={toggleModal}
              id={id}
              locale={locale}
              dsft={dsft}
              client={client}
              history={history}
            />
          </ModalBody>
        </Modal>
      </React.Fragment>
    )
  }
}

export default UpdateRolesModal
