import React from 'react'

import { graphql } from '@apollo/client/react/hoc'

import { LegacySelect as Select } from 'components/modules/selects/react-select'

import { getCountries } from './select-queries.graphql'

import { getPropertyFromObject } from '../../helper/helper-functions'

const CountrySelect = ({ data: { possible_countries, loading }, id, variables, onChange, errors, style }) =>
  loading ? (
    <Select
      id={id}
      style={
        getPropertyFromObject(errors, id) && {
          borderColor: '#e53012'
        }
      }
      placeholder={'auswählen'}
      isClearable={false}
      name={id}
      value={getPropertyFromObject(variables, id)}
      onChange={onChange}
      options={[]}
    />
  ) : (
    <React.Fragment>
      <Select
        id={id}
        style={
          getPropertyFromObject(errors, id) && {
            borderColor: '#e53012'
          }
        }
        placeholder={'auswählen'}
        isClearable={false}
        name={id}
        value={getPropertyFromObject(variables, id)}
        onChange={onChange}
        options={possible_countries.map(country => {
          return {
            value: country.key,
            label: country.label,
            target: { id, value: country.key }
          }
        })}
      />
      {!!getPropertyFromObject(errors, id) && (
        <div
          style={{
            width: '100%',
            marginTop: '0.25rem',
            fontSize: '80%',
            color: '#e53012'
          }}
        >
          {getPropertyFromObject(errors, id)}
        </div>
      )}
    </React.Fragment>
  )
export default graphql(getCountries, {
  options: () => ({
    notifyOnNetworkStatusChange: true
  })
})(CountrySelect)
