import { useEffect } from 'react'

import { Link } from 'react-router-dom'
import { Button, Col, Row } from 'reactstrap'

import { Query } from '@apollo/client/react/components'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { editVariable, updateVariable } from '../short_report_variables.graphql'

import { withLocale } from '../../../../../locale'
import Loading from '../../../../helper/loading/loading'
import { withPageHeader } from '../../../../modules/page'
import { Form as UpdateForm } from '../components/form'

const EditVariable = ({
  locale,
  setPageHeader,
  match: {
    params: { variable_id: id }
  }
}) => {
  useEffect(() => {
    setPageHeader(locale.short_report.variable.actions.edit)
  }, [])

  return (
    <Query
      query={editVariable}
      variables={{ id }}
      onCompleted={data => {
        setPageHeader(locale.short_report.variable.actions.edit(data.edit_facility_short_report_variable.name))
      }}
    >
      {({ loading, error, data, client }) => {
        if (loading) return <Loading />
        if (error) return error?.message || 'error'

        const variable = data.edit_facility_short_report_variable

        const backLink = variable.form ? `/form-kit/form/${variable.form.id}` : '/form-kit/short-report-variables'
        const backText = variable.form
          ? locale.short_report.variable.actions.back_to_form
          : locale.short_report.variable.actions.back_to_index

        return (
          <Row>
            <Col sm="12">
              <Button tag={Link} to={backLink} color="primary-light" outline>
                <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '1rem' }} />
                {backText}
              </Button>
            </Col>
            <Col sm="12">
              <UpdateForm
                {...{
                  facility_short_report_variable: variable,
                  client,
                  query: updateVariable
                }}
              />
            </Col>
          </Row>
        )
      }}
    </Query>
  )
}

export default withLocale(withPageHeader(EditVariable))
