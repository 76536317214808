import moment from 'moment'
import PropTypes from 'prop-types'

import { DeleteProvider, UpdateProvider } from '..'

const Detail = ({ provider, changed, isNew }) => (
  <tr className={`changed-element-indicator ${isNew || changed ? 'changed' : ''} ${isNew ? 'new' : ''}`}>
    <td>
      <UpdateProvider {...{ provider }} />
    </td>
    <td>{provider.email}</td>
    <td>{provider.facility_count}</td>
    <td>{moment(provider.created_at).format('DD.MM.YYYY - H:mm')}</td>
    <td className="text-center">
      <DeleteProvider provider={provider} />
    </td>
  </tr>
)

Detail.propTypes = {
  provider: PropTypes.object.isRequired,
  changed: PropTypes.bool,
  isNew: PropTypes.bool
}

export default Detail
