import React, { Fragment } from 'react'

import { Link } from 'react-router-dom'
import {
  Alert,
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
  UncontrolledTooltip
} from 'reactstrap'

import { Query } from '@apollo/client/react/components'
import { withApollo } from '@apollo/client/react/hoc'
import { faCheck, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { deleteForm, getForms } from './forms.graphql'

import generateUUID from '../../../../helpers/guid'
import Loading from '../../../helper/loading/loading.jsx'
import DynamicSelect from '../../../modules/selects/dynamicSelect'

const Forms = props => (
  <Query
    query={getForms}
    variables={{ form_version_id: props.match.params.id || null }}
    onCompleted={data => {
      if (!props.match.params.id) props.history.push(`/form-kit/forms/${data.form_version.id}`)
    }}
  >
    {({ loading, error, data }) => {
      if (loading || !data) return <Skeleton {...props} loading={true} />
      if (error) return <p>Error! ${error.message}</p>
      const { forms, form_versions, form_version } = data
      return (
        <Skeleton
          {...props}
          forms={forms.slice().sort((a, b) => (a.order_no > b.order_no ? 1 : b.order_no > a.order_no ? -1 : 0))}
          form_version={form_version}
          form_versions={form_versions}
        />
      )
    }}
  </Query>
)

const Skeleton = ({ locale, match, history, forms, form_versions, form_version, loading, client }) => (
  <Fragment>
    <Row className="page-header">
      <Col sm="9">
        <h1>{locale.form.plural}</h1>
      </Col>
      <Col sm="3" className="text-right">
        <Button
          className={'btn-labeled'}
          color={'primary-light'}
          onClick={() => history.push(`/form-kit/create/form/${match.params.id || '1'}`)}
          outline
        >
          <FontAwesomeIcon className={'icon-prepend'} icon={faPlus} />
          <span>{locale.form.create_label}</span>
        </Button>
      </Col>
    </Row>
    <hr className="seperator" />
    <div className="box">
      <Alert color="info" children={`Bitte wählen Sie eine Version aus, die sie bearbeiten wollen.`} />
      <Row>
        <Col sm="4" className="text-right">
          <label htmlFor="currentVersion" className="control-label ">
            Version
          </label>
        </Col>
        <Col sm="6">
          <DynamicSelect
            placeholder="wird geladen..."
            onChange={event => history.push(`/form-kit/forms/${event.value}`)}
            value={match.params.id || form_version?.id || ''}
            options={form_versions?.map(form_version => ({
              value: form_version.id,
              label: form_version.current ? `${form_version.name} - (aktuelle Version)` : form_version.name
            }))}
          />
        </Col>
      </Row>
    </div>
    <Table striped responsive bordered>
      <thead className="thead-light">
        <tr>
          <th>{locale.label}</th>
          <th>{locale.fields}</th>
          <th>{locale.form_data_count}</th>
          <th> </th>
        </tr>
      </thead>
      <tbody>
        {forms?.map((form, i) => (
          <tr key={i}>
            <td>
              <Link to={`/form-kit/form/${form.id}`}> {form.name_de}</Link>
            </td>
            <td>{form.fields_count}</td>
            <td>{form.form_data_count}</td>
            <td className="text-center">
              <DeleteModal form={form} locale={locale} client={client} match={match} />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
    {loading && <Loading />}
  </Fragment>
)

class DeleteModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
      processing: false,
      tooltip_uuid: generateUUID(),
      force: false
    }
  }

  toggle = event => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    this.setState({
      processing: false,
      force: false,
      modal: !this.state.modal
    })
  }

  submit = () => {
    this.setState({ processing: true })
    this.props.client
      .mutate({
        mutation: deleteForm,
        variables: {
          id: this.props.form.id,
          force: this.state.force
        },
        refetchQueries: [{ query: getForms, variables: { form_version_id: this.props.match.params.id } }],
        awaitRefetchQueries: true
      })
      .then(() => this.toggle())
  }

  render() {
    const { className, locale, form } = this.props
    const { modal, processing, tooltip_uuid } = this.state
    const { submit, toggle } = this

    return (
      <React.Fragment>
        <Button
          size={`sm`}
          className="btn-transparent btn-icon-only btn text-danger"
          color={'link'}
          onClick={toggle}
          id={`tooltip-modal_${tooltip_uuid}`}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </Button>
        <UncontrolledTooltip delay={500} placement="top" target={`tooltip-modal_${tooltip_uuid}`}>
          {locale.delete}
        </UncontrolledTooltip>
        <Modal centered isOpen={modal} toggle={toggle} className={className} size={'lg'}>
          <ModalHeader>Formular löschen</ModalHeader>
          <ModalBody>
            <p className="mt-3">
              Möchten Sie das Formular <strong className="text-danger">{form.name_de}</strong> wirklich löschen?
            </p>
            <Label className="control-label checkbox-label d-block mb-0" for="force">
              <Input
                className="checkbox-control"
                type="checkbox"
                id="force"
                defaultChecked={false}
                onChange={() => this.setState({ force: !this.state.force })}
              />
              <span className="check-icon-container">
                <span className="check-icon text-primary-dark">
                  <FontAwesomeIcon icon={faCheck} />
                </span>
              </span>
              <span className="label-text">
                Ja, ich möchte das Formular "{form.name_de}"{' '}
                {form.form_data_count !== 0 && `und die dazugehörigen ausgefüllten ${form.form_data_count} Formulare`}{' '}
                löschen
              </span>
            </Label>
            {processing ? <Loading loadingText={`wird gelöscht...`} size={`1x`} /> : null}
            <FormGroup className="form-action">
              <Container>
                <Row>
                  <Col sm="5">
                    <a className="link-btn" onClick={toggle} href="#">
                      {locale.cancel}
                    </a>
                  </Col>
                  <Col sm="7" className="text-right">
                    <Button
                      disabled={!this.state.force}
                      outline
                      onClick={submit}
                      className="btn-labeled px-5"
                      color="danger"
                    >
                      <FontAwesomeIcon className="icon-prepend" icon={faTrashAlt} />
                      <span>{locale.delete}</span>
                    </Button>
                  </Col>
                </Row>
              </Container>
            </FormGroup>
          </ModalBody>
        </Modal>
      </React.Fragment>
    )
  }
}

export default withApollo(Forms)
