import { faQuestion } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import PropTypes from 'prop-types'

import ComponentTypeBadge from '../componentTypeBadge'

const UnknownCalculationComponentType = ({ typeName, value }) => (
  <ComponentTypeBadge
    color="danger"
    tooltip={
      <>
        {/* NOTE: no real need to translate, as missing types should be caught in development */}
        Unknown calculation component type <code className="bg-dark px-2 rounded">{typeName}</code> with value{' '}
        <code className="bg-dark px-2 rounded">{value}</code>.
      </>
    }
  >
    <FontAwesomeIcon icon={faQuestion} />
  </ComponentTypeBadge>
)

UnknownCalculationComponentType.propTypes = {
  typeName: PropTypes.string.isRequired,
  value: PropTypes.string
}

export default UnknownCalculationComponentType
