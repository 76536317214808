import { useRef } from 'react'

import { Badge, UncontrolledTooltip } from 'reactstrap'

import PropTypes from 'prop-types'

const BadgeWithTooltip = ({ children, tooltip, ...attributes }) => {
  const badgeRef = useRef()

  return (
    <>
      <Badge {...attributes} innerRef={badgeRef}>
        {children}
      </Badge>
      <UncontrolledTooltip placement="top" autohide={false} target={badgeRef} boundariesElement="viewport">
        {tooltip}
      </UncontrolledTooltip>
    </>
  )
}

BadgeWithTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  tooltip: PropTypes.node.isRequired
}

export default BadgeWithTooltip
