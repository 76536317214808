import React from 'react'

import { Col, Input, Row, Table } from 'reactstrap'

// GraphQL
import { graphql, withApollo } from '@apollo/client/react/hoc'
// Icons
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import { getRegions } from './regions.graphql'

import { DD_MM_YYYY__HH_mm } from '../../../helper/helper-functions'
import Loading from '../../../helper/loading/loading.jsx'
import DeleteRegionModal from '../../../modules/modal/deleteRegionModal.jsx'
import Modal from '../../../modules/modal/modal.jsx'
import UpdateRegionModal from '../../../modules/modal/updateRegionModal.jsx'
// additional components
import FormCreate from './create.jsx'

class Regions extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      search_query: ''
    }
  }

  change = event => this.setState({ [event.target.id]: event.target.value })

  search = () => {
    const regions = this.props.data.regions
    const result = []
    if (this.state.search_query.length !== 0) {
      for (let i = 0; i < regions.length; i++) {
        if (regions[i].name_de && regions[i].name_de.toLowerCase().includes(this.state.search_query.toLowerCase())) {
          result.push(regions[i])
          continue
        }
        if (regions[i].name_en && regions[i].name_en.toLowerCase().includes(this.state.search_query.toLowerCase())) {
          result.push(regions[i])
        }
      }
      return result
    }
    return regions
  }

  render = () => {
    const {
      locale,
      client,
      history,
      data: { loading, error }
    } = this.props
    const { search_query } = this.state
    const regions = this.search()

    if (loading) return <Loading />
    if (error) return <p>Error</p>

    return (
      <div className="page-wrapper">
        <Row className="page-header">
          <Col sm="6">
            <h1>{locale.region.plural}</h1>
          </Col>
          <Col sm="3" className="text-right">
            <Input
              id="search_query"
              placeholder="Regionen durchsuchen..."
              value={search_query}
              onChange={this.change}
            />
          </Col>
          <Col sm="3" className="text-right">
            <Modal
              children={<FormCreate locale={locale} client={client} history={history} />}
              toggleModalText={locale.add_region}
              button_type={{ icon_only: false, icon_type: faPlus, color: 'primary-light', classes: 'btn-labeled' }}
              modal_size={'lg'}
            />
          </Col>
        </Row>
        <hr className="seperator" />
        <Table striped responsive bordered>
          <thead className="thead-light">
            <tr>
              <th>{locale.name_de}</th>
              <th>{locale.name_en}</th>
              <th>{locale.created_at}</th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {regions.map(region => (
              <Region key={region.id} region={region} client={client} locale={locale} />
            ))}
          </tbody>
        </Table>
      </div>
    )
  }
}

const Region = ({ region, history, locale, client }) => (
  <tr>
    <td>
      <UpdateRegionModal region={region} client={client} history={history} locale={locale} />
    </td>
    <td>{region?.name_en || '-'}</td>
    <td>{DD_MM_YYYY__HH_mm(region?.created_at)}</td>
    <td className="text-center">
      {region.destroyable && (
        <DeleteRegionModal id={region.id} client={client} name={region.name_de} history={history} locale={locale} />
      )}
    </td>
  </tr>
)

export default withApollo(
  graphql(getRegions, {
    options: () => ({
      notifyOnNetworkStatusChange: true
    })
  })(Regions)
)
