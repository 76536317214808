import React from 'react'

import { Col, FormFeedback, Input, Label } from 'reactstrap'

import { getPropertyFromObject } from '../../helper/helper-functions'

const ValidatedInformationInput = ({
  id,
  variables,
  errors,
  error,
  locale,
  placeholder,
  onChange,
  type,
  customLabel,
  localeKey = '',
  thinLabel = false,
  children
}) => {
  error = error || getPropertyFromObject(errors, id)

  return (
    <React.Fragment>
      <Col lg="5" className="text-lg-right">
        <Label className={`control-label${thinLabel ? ' font-weight-normal' : ''}`} for={id}>
          {customLabel || getPropertyFromObject(locale, id) || getPropertyFromObject(locale, localeKey)}
        </Label>
      </Col>
      <Col lg="7">
        {children || (
          <Input
            type={type || 'text'}
            name={id}
            id={id}
            placeholder={placeholder}
            value={getPropertyFromObject(variables, id) || ''}
            onChange={onChange}
            invalid={!!getPropertyFromObject(errors, id)}
          />
        )}
        {!!error && <FormFeedback className={error ? 'd-block' : ''}>{error}</FormFeedback>}
      </Col>
    </React.Fragment>
  )
}

export default ValidatedInformationInput
