/*
 * migration from IDB version 4 to 5
 *
 * add field help_image to all field data
 *
 * add field provider to facility
 * add field possible_providers to facility
 */

const migrate = form => ({
  ...form,
  field_data_flat: form.field_data_flat.map(field_datum => ({
    ...field_datum,
    help_image: null
  }))
})

export default migrate
