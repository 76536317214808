import { faE, faR } from '@fortawesome/free-solid-svg-icons'

import { withLocale } from 'locale/index'
import PropTypes from 'prop-types'

import ComponentTypeBadge from '../componentTypeBadge'

const CertificationRunCalculationComponentType = ({ recertification = false, locale }) => {
  const tooltip = recertification ? locale.recertificationTooltip : locale.firstCertificationTooltip
  const icon = recertification ? faR : faE

  return <ComponentTypeBadge color="success" tooltip={tooltip} icon={icon} />
}

CertificationRunCalculationComponentType.propTypes = {
  recertification: PropTypes.bool,
  locale: PropTypes.shape({
    recertificationTooltip: PropTypes.string.isRequired,
    firstCertificationTooltip: PropTypes.string.isRequired
  }).isRequired
}

export default withLocale(CertificationRunCalculationComponentType, {
  key: 'accounting_information.calculatedVolumeUnitCostsCalculationComponents.certificationRun'
})
