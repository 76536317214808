import { components as reactSelectComponents } from 'react-select'
import { Alert, Badge } from 'reactstrap'

import { useQuery } from '@apollo/client'
import { faPeopleRoof } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Select } from 'components/modules/selects/react-select'
import { withLocale } from 'locale'
import PropTypes from 'prop-types'

import { getLicenseOwnersForFacilitiesFilter } from './licenseOwnerSelect.graphql'

// Extending the default select option component to show the parent license owner if present
const LicenseOwnerOptionWithParent = props => {
  if (props.data.parentLicenseOwnerName) {
    return (
      <reactSelectComponents.Option {...props}>
        {props.data.label}
        <span className="d-block">
          <Badge color="light" className="text-wrap">
            <FontAwesomeIcon icon={faPeopleRoof} className="mr-2" />
            {props.data.parentLicenseOwnerName}
          </Badge>
        </span>
      </reactSelectComponents.Option>
    )
  } else {
    return (
      <reactSelectComponents.Option {...props}>
        <strong>{props.data.label}</strong>
      </reactSelectComponents.Option>
    )
  }
}

const LicenseOwnerSelect = ({ licenseOwnerIds, setLicenseOwnerIds, locale }) => {
  const { loading, error, data } = useQuery(getLicenseOwnersForFacilitiesFilter)

  if (error) {
    console.error('Error fetching license owners', error)
    return (
      <Alert color="danger" className="m-0">
        Error fetching license owners
      </Alert>
    )
  }

  const options =
    data?.licenseOwners?.flatMap(licenseOwner => [
      {
        value: licenseOwner.id,
        label: licenseOwner.name
      },
      ...licenseOwner.subLicenseOwners.map(subLicenseOwner => ({
        parentLicenseOwnerName: licenseOwner.name,
        value: subLicenseOwner.id,
        label: `↳ ${subLicenseOwner.name}`
      }))
    ]) || []

  options.unshift({
    value: null,
    label: locale.none
  })

  const value = licenseOwnerIds.map(licenseOwnerId => options.find(option => option.value === licenseOwnerId))

  const onChange = selectedOptions => {
    setLicenseOwnerIds(selectedOptions.map(option => option.value))
  }

  return (
    <>
      <label>{locale.label}</label>
      <Select
        isLoading={loading}
        value={value}
        onChange={onChange}
        options={options}
        isMulti
        placeholder={locale.emptyPlaceholder}
        components={{ Option: LicenseOwnerOptionWithParent }}
        menuPortalTarget={document.body}
      />
    </>
  )
}

LicenseOwnerSelect.propTypes = {
  licenseOwnerIds: PropTypes.array.isRequired,
  setLicenseOwnerIds: PropTypes.func.isRequired,
  locale: PropTypes.object.isRequired
}

export default withLocale(LicenseOwnerSelect, { key: 'facilitiesDashboard.filters.licenseOwner' })
