import { useState } from 'react'

import { sortableHandle } from 'react-sortable-hoc'
import { UncontrolledTooltip } from 'reactstrap'

import { faArrowsAlt, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import generateUUID from 'helpers/guid'
import { withLocale } from 'locale'

const DragHandle = ({ locale, className = '', loading = false, disabled = false, iconProps = {}, ...props }) => {
  const [id] = useState(`sortable-drag-handle-${generateUUID()}`)
  className = `btn-icon-only drag-handle ${className} ${disabled ? 'sorting-disabled' : ''}`.trim()
  disabled ||= loading

  return (
    <>
      <span tabIndex={0} {...{ ...props, className, disabled, id }}>
        <FontAwesomeIcon spin={loading} icon={loading ? faSpinner : iconProps.icon || faArrowsAlt} {...iconProps} />
      </span>
      {!disabled && (
        <UncontrolledTooltip placement="top" target={id} boundariesElement="viewport">
          {locale.move}
        </UncontrolledTooltip>
      )}
    </>
  )
}

export default sortableHandle(withLocale(DragHandle))
