// additional components
import React from 'react'

import { Button, Modal, ModalBody, ModalHeader, Tooltip } from 'reactstrap'

import { faEdit } from '@fortawesome/free-solid-svg-icons'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import generateUUID from '../../../helpers/guid'
import FormGroup from '../../views/form-kit/sections/section_update.jsx'

// CSS
import '../../modules/modal/_modal.scss'

class UpdateSectionModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
      backdrop: 'static',
      tooltip_open: false,
      tooltip_uuid: generateUUID()
    }
  }

  toggleModal = event => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    this.setState({
      modal: !this.state.modal
    })
  }

  toggleTooltip = () =>
    this.setState({
      tooltip_open: !this.state.tooltip_open
    })

  changeBackdrop(event) {
    let value = event.target.value
    if (value !== 'static') {
      value = JSON.parse(value)
    }
    this.setState({ backdrop: value })
  }

  render() {
    const { locale, client, history, id } = this.props
    const { tooltip_open, modal, tooltip_uuid } = this.state
    const { toggleModal, toggleTooltip } = this

    return (
      <React.Fragment>
        <Button
          size={`sm`}
          className="btn-transparent btn-icon-only btn"
          color={'link'}
          onClick={toggleModal}
          id={`tooltip-modal_${tooltip_uuid}`}
        >
          <FontAwesomeIcon icon={faEdit} />
        </Button>
        <Tooltip
          delay={500}
          placement="top"
          isOpen={tooltip_open}
          target={`tooltip-modal_${tooltip_uuid}`}
          toggle={toggleTooltip}
        >
          {modal ? locale.close : locale.edit}
        </Tooltip>
        <Modal isOpen={modal} centered size={'lg'} toggle={toggleModal}>
          <ModalHeader>{locale.edit_default_section}</ModalHeader>
          <ModalBody>
            <FormGroup toggleModal={toggleModal} id={id} locale={locale} client={client} history={history} />
          </ModalBody>
        </Modal>
      </React.Fragment>
    )
  }
}

export default UpdateSectionModal
