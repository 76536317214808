import React from 'react'

import { Link } from 'react-router-dom'

import FacilityNameWithBundleInfo from 'components/views/facilities/helper/facility_name_with_bundle_info'
import PropTypes from 'prop-types'

class FacilityLinkWithBundleInfo extends React.Component {
  static _bundleInformationPropTypes = PropTypes.shape({
    totalCount: PropTypes.number.isRequired,
    nodes: PropTypes.arrayOf(
      PropTypes.shape({
        base_data: PropTypes.shape({
          name: PropTypes.shape({
            de: PropTypes.string.isRequired
          }).isRequired
        }).isRequired,
        certification_data: PropTypes.shape({
          audit_order_number: PropTypes.string.isRequired
        }).isRequired
      })
    ).isRequired
  }).isRequired

  static propTypes = {
    facility: PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      base_data: PropTypes.shape({
        name: PropTypes.shape({
          de: PropTypes.string.isRequired
        }).isRequired
      }).isRequired,
      bundleInformation: PropTypes.shape({
        bundledBy: this._bundleInformationPropTypes,
        bundledFacilities: this._bundleInformationPropTypes
      }).isRequired
    }).isRequired
  }

  _compactBundleInformation(bundleInformation) {
    if (bundleInformation.totalCount === 0) return undefined

    return {
      name: bundleInformation.nodes[0].base_data.name.de,
      audit_order_number: bundleInformation.nodes[0].certification_data.audit_order_number
    }
  }

  render() {
    const { facility } = this.props

    return (
      <Link to={`/facility/${facility.uuid}`} className="text-truncate">
        <FacilityNameWithBundleInfo
          name={facility.base_data.name.de}
          firstBundledFacility={this._compactBundleInformation(facility.bundleInformation.bundledFacilities)}
          totalBundledFacilities={facility.bundleInformation.bundledFacilities.totalCount}
          firstBundledByFacility={this._compactBundleInformation(facility.bundleInformation.bundledBy)}
          totalBundledByFacilities={facility.bundleInformation.bundledBy.totalCount}
        />
      </Link>
    )
  }
}

export default FacilityLinkWithBundleInfo
