import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import BadgeWithTooltip from 'components/modules/badge/badge_with_tooltip'
import PropTypes from 'prop-types'

const VersionNumberBadge = ({ versionNumber, icon, ...attributes }) => (
  <BadgeWithTooltip className="m-1" pill {...attributes}>
    <FontAwesomeIcon className="icon-prepend" icon={icon} />v{versionNumber}
  </BadgeWithTooltip>
)

VersionNumberBadge.propTypes = {
  versionNumber: PropTypes.string.isRequired,
  // HACK: there is no really good way to check for an actual font awesome icon,
  // because it is only a normal js-object without specific class and we cannot
  // reuse the typescript interface definition as prop type. I also don't want
  // to replicate the entire interface definition as prop type here.
  icon: PropTypes.any.isRequired
}

export default VersionNumberBadge
