// additional components
import React from 'react'

import { Modal, ModalBody, ModalHeader, Tooltip } from 'reactstrap'

import Guid from '../../../helpers/guid'
import FormGroup from '../../views/admin/license-owners/update.jsx'

// CSS
import '../../modules/modal/_modal.scss'

class UpdateLicenseOwnerModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
      backdrop: 'static',
      tooltip_open: false,
      tooltip_uuid: Guid()
    }
  }

  toggleModal = event => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    this.setState({
      modal: !this.state.modal
    })
  }

  toggleTooltip = () =>
    this.setState({
      tooltip_open: !this.state.tooltip_open
    })

  changeBackdrop(event) {
    let value = event.target.value
    if (value !== 'static') {
      value = JSON.parse(value)
    }
    this.setState({ backdrop: value })
  }

  render() {
    const { locale, client, history, license_owner, is_sublicense_owner } = this.props
    const { name, id } = license_owner
    const { tooltip_open, modal, tooltip_uuid } = this.state
    const { toggleModal, toggleTooltip } = this

    // <Link
    //   to={`/admin/license-owner/edit/${license_owner.id}`}>{license_owner.is_sublicense_owner ? license_owner.name :
    //   <strong>{license_owner.name}</strong>}
    //   </Link>{license_owner.is_sublicense_owner}

    return (
      <React.Fragment>
        <a
          className="link-btn"
          href="#"
          id={`tooltip-modal_${tooltip_uuid}`}
          onClick={e => {
            e.preventDefault()
            toggleModal(e)
          }}
        >
          {is_sublicense_owner ? name : <strong>{name}</strong>}
        </a>
        <Tooltip
          delay={500}
          placement="top"
          isOpen={tooltip_open}
          target={`tooltip-modal_${tooltip_uuid}`}
          toggle={toggleTooltip}
        >
          {`${is_sublicense_owner ? locale.sublicense_owner : locale.license_owner.singular} "${name}" ${locale.edit}`}
        </Tooltip>
        <Modal isOpen={modal} centered size={'lg'} toggle={toggleModal}>
          <ModalHeader>{`${is_sublicense_owner ? locale.sublicense_owner : locale.license_owner.singular} "${name}" ${
            locale.edit
          }`}</ModalHeader>
          <ModalBody>
            <FormGroup
              toggleModal={toggleModal}
              id={id}
              locale={locale}
              client={client}
              history={history}
              is_sublicense_owner={is_sublicense_owner}
            />
          </ModalBody>
        </Modal>
      </React.Fragment>
    )
  }
}

export default UpdateLicenseOwnerModal
