import { useState } from 'react'

import RichTextEditor from 'react-rte'
import { Alert, Button, Col, Form, Row } from 'reactstrap'

import { useMutation } from '@apollo/client'
import { faCoins, faMinus, faPlus, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { withLocale } from 'locale/index'

import {
  createVolumeUnitAccountTransaction,
  fetchVolumeUnitAccountTransactions
} from './volumeUnitAccountTransactions.graphql'

import AmountInput from './createTransaction/amountInput'
import CommentInput from './createTransaction/commentInput'
import SignageSwitch from './createTransaction/signageSwitch'

const stripEmptyHtml = value => ['<p><br></p>', '\n'].reduce((val, substr) => val?.replace(substr, ''), value || '')

const CreateTransaction = ({ accountId, locale }) => {
  const [amountPositive, setAmountPositive] = useState(true)
  const [amount, setAmount] = useState('0')
  const [comment, setComment] = useState(RichTextEditor.createValueFromString('', 'html'))
  const [createTransaction, { loading, error }] = useMutation(createVolumeUnitAccountTransaction, {
    onError: _ => {},
    refetchQueries: [{ query: fetchVolumeUnitAccountTransactions, variables: { accountId } }],
    onCompleted: _ => {
      setAmount('0')
      setComment(RichTextEditor.createValueFromString('', 'html'))
    }
  })

  const submitForm = event => {
    event.preventDefault()

    createTransaction({
      variables: {
        input: {
          accountId,
          amount: Math.abs(parseFloat(amount) || 0) * (amountPositive ? 1 : -1) || 0,
          comment: stripEmptyHtml(comment.toString('html'))
        }
      }
    })
  }

  const generalErrorKey = error?.graphQLErrors[0]?.message
  const amountErrorKey = error?.graphQLErrors[0]?.validationErrors?.amount
  const commentErrorKey = error?.graphQLErrors[0]?.validationErrors?.comment

  return (
    <>
      <h3>{locale.heading}</h3>
      <hr className="seperator border-gray" />
      <Row>
        <Col sm={{ size: 12 }} lg={{ size: 8, offset: 2 }}>
          <Form onSubmit={submitForm}>
            {!!error && (
              <Alert color="danger">
                {locale.errors.general[generalErrorKey] || `Es gab einen Fehler: ${generalErrorKey}`}
              </Alert>
            )}
            <fieldset disabled={loading}>
              <SignageSwitch positive={amountPositive} setPositive={setAmountPositive} />
              <AmountInput
                amount={amount}
                setAmount={setAmount}
                positive={amountPositive}
                errorMessage={locale.errors.validationErrors.amount[amountErrorKey]}
              />
              <CommentInput
                errorMessage={locale.errors.validationErrors.comment[commentErrorKey]}
                {...{ comment, setComment }}
              />
              <Button
                type="submit"
                color={amountPositive ? 'success' : 'danger'}
                className="float-right"
                disabled={amount == 0 || stripEmptyHtml(comment.toString('html')) === ''}
              >
                <FontAwesomeIcon icon={amountPositive ? faPlus : faMinus} className="mx-2" />
                <FontAwesomeIcon icon={loading ? faSpinner : faCoins} pulse={loading} className="mx-2" />
                {locale.submit({ amount: Math.abs(parseFloat(amount) || 0), positive: amountPositive })}
              </Button>
            </fieldset>
          </Form>
        </Col>
      </Row>
    </>
  )
}

export default withLocale(CreateTransaction, { key: 'licenseOwners.volumeUnitAccount.createTransaction' })
