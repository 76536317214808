// CSS
import React from 'react'

import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'

import { faPlus } from '@fortawesome/free-solid-svg-icons'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// additional components
import FormGroup from '../../views/form-kit/sections/section_create.jsx'

import '../../modules/modal/_modal.scss'

class CreateSectionModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
      backdrop: 'static'
    }
  }

  toggleModal = event => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    this.setState({
      modal: !this.state.modal
    })
  }

  changeBackdrop(event) {
    let value = event.target.value
    if (value !== 'static') {
      value = JSON.parse(value)
    }
    this.setState({ backdrop: value })
  }

  render() {
    const { locale, client, history, id } = this.props
    const { modal } = this.state
    const { toggleModal } = this

    return (
      <React.Fragment>
        <Button color={'primary-light'} outline onClick={toggleModal}>
          <FontAwesomeIcon className="icon-prepend" icon={faPlus} />
          <span>{locale.create_default_section}</span>
        </Button>
        <Modal isOpen={modal} centered size={'lg'} toggle={toggleModal}>
          <ModalHeader>{locale.create_default_section}</ModalHeader>
          <ModalBody>
            <FormGroup toggleModal={toggleModal} id={id} locale={locale} client={client} history={history} />
          </ModalBody>
        </Modal>
      </React.Fragment>
    )
  }
}

export default CreateSectionModal
