import React from 'react'

import { Button, Col, Container, FormGroup, Input, Label, Row } from 'reactstrap'

import { withApollo } from '@apollo/client/react/hoc'
import { faCheck, faSave } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ValidatedInput from '../../../modules/inputs/validated-input'
import CountrySelect from '../../../modules/selects/countries'
import LicenseOwners from '../../../modules/selects/license-owners-multiselect/license-owners-multi'
import LicenseOwner from '../../../modules/selects/license-owners-select/license_owners'
import PossibleLicenseOwners from '../../../modules/selects/possible-license-owner-to-collect-for-select/possible-license-owner-to-collect-for-select'
import Roles from '../../../modules/selects/roles-select/roles'
import SalutationSelect from '../../../modules/selects/salutations.jsx'
import StateSelect from '../../../modules/selects/states'
import StatusSelect from '../../../modules/selects/statuses-select/statuses'

const FormGroups = ({
  variables,
  errors,
  onChange,
  id,
  client,
  user,
  is_create,
  changeLicenseOwner,
  changeStatus,
  changeLicenseOwnerToCollectFor,
  change,
  changeCountry,
  changeState,
  changeInfo,
  changeAddress,
  changeNotification,
  changeCollector,
  submit,
  changeSalutation,
  changeRole,
  toggleModal,
  locale,
  possible_options
}) => (
  <React.Fragment>
    <FormGroup>
      <Container>
        <Row>
          <Col sm="2" className="text-right">
            <Label className="control-label" for="salutation">
              {locale.salutation}
            </Label>
          </Col>
          <Col sm="4">
            <SalutationSelect
              id={'user.salutation'}
              errors={errors}
              variables={variables}
              locale={locale}
              onChange={onChange}
              current_country={variables.user.address.country_code}
            />
          </Col>

          <Col sm="2" className="text-right">
            <Label className="control-label" for="role_id">
              {locale.role_to_set_rights}
            </Label>
          </Col>
          <Col sm="4">
            <Roles role_id={user.role_id} changeRole={changeRole} client={client} />
          </Col>
        </Row>
      </Container>
    </FormGroup>
    <FormGroup>
      <Container>
        <Row>
          <ValidatedInput
            id={'user.first_name'}
            errors={errors}
            variables={variables}
            locale={locale}
            onChange={onChange}
          />
          <ValidatedInput
            id={'user.last_name'}
            errors={errors}
            variables={variables}
            locale={locale}
            onChange={onChange}
          />
        </Row>
      </Container>
    </FormGroup>
    <FormGroup>
      <Container>
        <Row>
          <ValidatedInput
            id={'user.institution'}
            errors={errors}
            variables={variables}
            locale={locale}
            onChange={onChange}
          />
          <ValidatedInput id={'user.phone'} errors={errors} variables={variables} locale={locale} onChange={onChange} />
        </Row>
      </Container>
    </FormGroup>
    <FormGroup>
      <Container>
        <Row>
          <ValidatedInput id={'user.email'} errors={errors} variables={variables} locale={locale} onChange={onChange} />
          <Col sm="2" className="text-right">
            <Label className="control-label" for="license_owner_id">
              {locale.user.license_owner_id}
            </Label>
          </Col>
          <Col sm="4">
            <LicenseOwner
              id="user.license_owner_id"
              changeLicenseOwner={changeLicenseOwner}
              license_owner_id={user.license_owner_id}
              withSubs
              errors={errors}
            />
          </Col>
        </Row>
      </Container>
    </FormGroup>

    <FormGroup>
      <Container>
        <Row>
          <ValidatedInput
            id={'user.address.street'}
            errors={errors}
            variables={variables}
            locale={locale}
            onChange={onChange}
          />
          <ValidatedInput
            id={'user.address.street_addition'}
            errors={errors}
            variables={variables}
            locale={locale}
            onChange={onChange}
          />
        </Row>
      </Container>
    </FormGroup>
    <FormGroup>
      <Container>
        <Row>
          <ValidatedInput
            id={'user.address.zipcode'}
            errors={errors}
            variables={variables}
            locale={locale}
            onChange={onChange}
          />
          <ValidatedInput
            id={'user.address.city'}
            errors={errors}
            variables={variables}
            locale={locale}
            onChange={onChange}
          />
        </Row>
      </Container>
    </FormGroup>
    <FormGroup>
      <Container>
        <Row>
          <Col sm="2" className="text-right">
            <Label className="control-label" for="address_country_code">
              {locale.country}
            </Label>
          </Col>
          <Col sm="4">
            <CountrySelect
              id={'user.address.country_code'}
              errors={errors}
              variables={variables}
              locale={locale}
              onChange={onChange}
            />
          </Col>
          <Col sm="2" className="text-right">
            <Label className="control-label" for="address_state">
              {locale.state}
            </Label>
          </Col>
          <Col sm="4">
            <StateSelect
              id={'user.address.state'}
              errors={errors}
              variables={variables}
              locale={locale}
              onChange={onChange}
              current_country={variables.user.address.country_code}
            />
          </Col>
        </Row>
      </Container>
    </FormGroup>
    <FormGroup>
      <Container>
        <Row>
          <ValidatedInput id={'user.notes'} errors={errors} variables={variables} locale={locale} onChange={onChange} />
          <ValidatedInput
            id={'user.info.more_info'}
            errors={errors}
            variables={variables}
            locale={locale}
            onChange={onChange}
          />
        </Row>
      </Container>
    </FormGroup>
    <FormGroup>
      <Container>
        <Row>
          <Col sm={{ size: '4', offset: '2' }}>
            <Label className="control-label checkbox-label justify-content-start" for="email_notifications">
              <Input
                className="checkbox-control"
                type="checkbox"
                id="email_notifications"
                defaultChecked={user.email_notifications}
                onChange={changeNotification}
              />
              <span className="check-icon-container">
                <span className="check-icon text-primary-dark">
                  <FontAwesomeIcon icon={faCheck} />
                </span>
              </span>
              <span className="label-text">{locale.email_notifications}</span>
            </Label>
          </Col>

          <Col sm={{ size: '4', offset: '2' }}>
            <Label className="control-label checkbox-label justify-content-start" for="collector">
              <Input
                className="checkbox-control"
                type="checkbox"
                id="collector"
                defaultChecked={user.collector}
                onChange={changeCollector}
              />
              <span className="check-icon-container">
                <span className="check-icon text-primary-dark">
                  <FontAwesomeIcon icon={faCheck} />
                </span>
              </span>
              <span className="label-text">Arbeitet als Erheber</span>
            </Label>
          </Col>
        </Row>
      </Container>
    </FormGroup>

    {user.collector ? (
      <React.Fragment>
        <FormGroup>
          <h3> Zusatzinformationen Erheber </h3>
          <hr className="seperator seperator-form border-gray" />
          <Container>
            <Row>
              <ValidatedInput
                id={'user.collector_id'}
                errors={errors}
                variables={variables}
                locale={locale}
                onChange={onChange}
              />
              <Col sm="2" className="text-right">
                <Label className="control-label" for="status">
                  {locale.status}
                </Label>
              </Col>
              <Col sm="4">
                <StatusSelect changeStatus={changeStatus} status={user.status} />
              </Col>
            </Row>
          </Container>
        </FormGroup>
        <FormGroup>
          <Container>
            <Row>
              <Col sm="2" className="text-right">
                <Label className="control-label" for="password">
                  Erhebt für
                </Label>
              </Col>
              <Col sm="10">
                {is_create ? (
                  <LicenseOwners
                    value={user.collects_for_license_owner_ids}
                    changeLicenseOwner={changeLicenseOwnerToCollectFor}
                    withSubs
                  />
                ) : (
                  <PossibleLicenseOwners
                    possible_options={possible_options}
                    value={user.collects_for_license_owner_ids}
                    id={id}
                    changeLicenseOwnerToCollectFor={changeLicenseOwnerToCollectFor}
                    id={id}
                    withSubs
                  />
                )}
              </Col>
            </Row>
          </Container>
        </FormGroup>
      </React.Fragment>
    ) : null}

    <hr className="seperator seperator-form border-gray" />
    <FormGroup>
      <Container>
        <Row>
          <ValidatedInput
            type={'password'}
            id={'user.password'}
            errors={errors}
            variables={variables}
            locale={locale}
            onChange={onChange}
            autoComplete="new-password"
          />
          <ValidatedInput
            type={'password'}
            id={'user.password_confirmation'}
            errors={errors}
            variables={variables}
            locale={locale}
            onChange={onChange}
            autoComplete="new-password"
          />
        </Row>
      </Container>
    </FormGroup>

    <FormGroup className="form-action">
      <Container>
        <Row>
          <Col sm="2">
            <a className="link-btn" onClick={toggleModal} href="#">
              {locale.cancel}
            </a>
          </Col>
          <Col sm={{ size: '4', offset: '6' }} className="text-right">
            <Button className="btn-labeled" color="primary-light" onClick={submit}>
              <FontAwesomeIcon className="icon-prepend" icon={faSave} />
              <span>{locale.save}</span>
            </Button>
          </Col>
        </Row>
      </Container>
    </FormGroup>
  </React.Fragment>
)
export default withApollo(FormGroups)
