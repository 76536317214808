import { useEffect } from 'react'

import { Link } from 'react-router-dom'
import { Button, Col, Row } from 'reactstrap'

import { Query } from '@apollo/client/react/components'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { newVariable, createVariable as query } from '../short_report_variables.graphql'

import { withLocale } from '../../../../../locale'
import Loading from '../../../../helper/loading/loading'
import { withPageHeader } from '../../../../modules/page'
import { Form as CreateForm } from '../components/form'

const CreateVariable = ({
  locale,
  setPageHeader,
  match: {
    params: { form_id }
  }
}) => {
  useEffect(() => {
    setPageHeader(locale.short_report.variable.actions.create)
  }, [])

  const backLink = form_id ? `/form-kit/form/${form_id}` : '/form-kit/short-report-variables'
  const backText = form_id
    ? locale.short_report.variable.actions.back_to_form
    : locale.short_report.variable.actions.back_to_index

  return (
    <Row>
      <Col sm="12">
        <Button tag={Link} to={backLink} color="primary-light" outline>
          <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '1rem' }} />
          {backText}
        </Button>
      </Col>
      <Col sm="12">
        <Query query={newVariable} variables={{ form_id: form_id }}>
          {({ loading, error, data, client }) => {
            if (loading) return <Loading />
            if (error) return error?.message || 'error'
            return (
              <CreateForm
                {...{ facility_short_report_variable: data.new_facility_short_report_variable, client, query, form_id }}
              />
            )
          }}
        </Query>
      </Col>
    </Row>
  )
}

export default withLocale(withPageHeader(CreateVariable))
