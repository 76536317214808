import { useQuery } from '@apollo/client'

import { Select } from 'components/modules/selects/react-select'
import { withLocale } from 'locale'
import PropTypes from 'prop-types'

import { getTrainingStatesForFacilitiesFilter } from './trainingStatusSelect.graphql'

const TrainingStatusSelect = ({ trainingStatus, setTrainingStatus, locale }) => {
  const { loading, error, data } = useQuery(getTrainingStatesForFacilitiesFilter)

  if (error) {
    console.error('Error fetching training states', error)
    return (
      <Alert color="danger" className="m-0">
        Error fetching training states
      </Alert>
    )
  }

  const options =
    data?.trainingStatusEnum?.enumValues?.map(enumValue => ({
      value: enumValue.name,
      label: locale.options[enumValue.name]
    })) || []

  const value = options.find(option => option.value === trainingStatus)

  const onChange = selectedOption => {
    if (selectedOption === null) {
      setTrainingStatus(null)
      return
    }

    setTrainingStatus(selectedOption.value)
  }

  return (
    <>
      <label>{locale.label}</label>
      <Select
        isLoading={loading}
        value={value}
        onChange={onChange}
        options={options}
        isClearable
        placeholder={locale.emptyPlaceholder}
        menuPortalTarget={document.body}
      />
    </>
  )
}

TrainingStatusSelect.propTypes = {
  trainingStatus: PropTypes.string,
  setTrainingStatus: PropTypes.func.isRequired,
  locale: PropTypes.object.isRequired
}

export default withLocale(TrainingStatusSelect, { key: 'facilitiesDashboard.filters.trainingStatus' })
