import React from 'react'

import { Col, Input, Label, Row } from 'reactstrap'

import { withApollo } from '@apollo/client/react/hoc'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { getFacility } from '../../facility.graphql'
import { updateFacilityKinds } from './information.graphql'

import { FormGroupContainerActions } from '../partials'

const FacilityKindsForm = props => (
  <div className="box">
    <Form {...props} />
  </div>
)

class Form extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      variables: {
        uuid: this.props.facility.uuid,
        base_data: {
          kind_ids: this.props.facility.base_data.kind_ids
        }
      }
    }
  }

  onSuccess = () => this.setState({ processing: false, errors: false }, this.props.hideEditBoxRight())

  changeCheckbox = kind => event => {
    let newKindIds = [...this.state.variables.base_data.kind_ids]

    if (event.target.checked) {
      newKindIds.push(kind.id)
    } else {
      newKindIds = newKindIds.filter(
        id => id != kind.id && !(kind.sub_kinds && kind.sub_kinds.map(({ id }) => id).includes(id))
      )
    }

    let newState = this.state
    newState.variables.base_data.kind_ids = newKindIds
    this.setState(newState)
  }

  submit = () => {
    this.setState({ processing: true })
    const { client } = this.props
    const { variables } = this.state
    client
      .mutate({
        mutation: updateFacilityKinds,
        variables,
        refetchQueries: [
          {
            query: getFacility,
            variables: {
              uuid: this.props.facility.uuid,
              version_number: this.props.match.params.version_number || ''
            }
          }
        ],
        awaitRefetchQueries: true
      })
      .then(() => this.onSuccess())
      .catch(error => this.onError(error))
  }

  onError = ({ graphQLErrors }) =>
    this.setState({
      processing: false,
      error_msg: graphQLErrors[0].message,
      errors: graphQLErrors[0].errors
    })

  render = () => {
    const { changeCheckbox, submit } = this
    const {
      locale,
      hideEditBoxRight,
      facility: {
        base_data: { possible_kinds }
      }
    } = this.props
    const { variables, errors, error_msg, processing } = this.state

    const actions_props = { locale, hideEditBoxRight, processing, submit }

    return (
      <React.Fragment>
        <ul className="list-unstyled">
          {possible_kinds.map(facility_kind => (
            <Facilitykind
              changeCheckbox={changeCheckbox}
              key={facility_kind.id}
              {...facility_kind}
              checked={this.state.variables.base_data.kind_ids.includes(facility_kind.id)}
              kind_ids={this.state.variables.base_data.kind_ids}
            />
          ))}
        </ul>
        <FormGroupContainerActions {...actions_props} />
      </React.Fragment>
    )
  }
}

const Facilitykind = facility_kind => (
  <li>
    <Label className="control-label checkbox-label d-block mb-0" for={facility_kind.id}>
      <Input
        className="checkbox-control"
        checked={facility_kind.checked}
        id={facility_kind.id}
        onChange={facility_kind.changeCheckbox(facility_kind)}
        type="checkbox"
      />
      <span className="check-icon-container">
        <span className="check-icon text-primary-dark">
          <FontAwesomeIcon icon={faCheck} />
        </span>
      </span>
      <span className="label-text">{facility_kind.name_de}</span>
    </Label>

    {facility_kind.sub_kinds.length > 0 ? (
      <Row className={`ml-5 pl-3 mt-3 border-left border-primary ${facility_kind.checked ? '' : 'd-none'}`}>
        {facility_kind.sub_kinds.map((subkind, index) => (
          <Subkinds
            changeCheckbox={facility_kind.changeCheckbox}
            index={index}
            key={subkind.id}
            {...subkind}
            checked={facility_kind.kind_ids.includes(subkind.id)}
            parent={facility_kind}
          />
        ))}
      </Row>
    ) : (
      ''
    )}
  </li>
)

const Subkinds = subkind => (
  <Col sm="6">
    <Label className="control-label checkbox-label d-block mb-0" for={subkind.id}>
      <Input
        className="checkbox-control"
        checked={subkind.checked}
        id={subkind.id}
        onChange={subkind.changeCheckbox(subkind)}
        type="checkbox"
      />
      <span className="check-icon-container">
        <span className="check-icon text-primary-dark">
          <FontAwesomeIcon icon={faCheck} />
        </span>
      </span>
      <span className="label-text">{subkind.name_de}</span>
    </Label>
  </Col>
)

export default withApollo(FacilityKindsForm)
