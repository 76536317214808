import React from 'react'

import { Alert, Col, Container, Form, FormGroup, Row } from 'reactstrap'

import { withApollo } from '@apollo/client/react/hoc'

import OrganizationSelect from 'components/modules/selects/organization-select'
import { HelpTooltip } from 'components/views/facility/form-datum/help-tooltip'
import { deepNestInObjectsWithKeys } from 'helpers/object'
import update from 'immutability-helper'

import { createPartnerGatewayUser, getPartnerGatewayUsers } from './partner_gateway_users.graphql'

import { ProcessingButton } from '../../../modules/buttons/processing-button'
import ValidatedInput from '../../../modules/inputs/validated-input'

class FormCreate extends React.Component {
  constructor(props) {
    super(props)
    const defaultState = {
      processing: false,
      password: '',
      variables: {
        partner_gateway_user: {
          name: '',
          email: '',
          organization_ids: []
        }
      }
    }

    this.state = defaultState
  }

  onChange = event => {
    const fragments = event.target.id.split('.')
    const variables = deepNestInObjectsWithKeys(event.target.value, ...fragments, '$set')

    this.setState(update(this.state, { variables }))
  }

  onError = ({ graphQLErrors }) =>
    this.setState({
      processing: false,
      error_msg: graphQLErrors[0].message,
      errors: graphQLErrors[0].errors
    })

  submit = () => {
    this.setState({ processing: true })
    let { variables } = this.state

    this.props.client
      .mutate({
        mutation: createPartnerGatewayUser,
        variables,
        refetchQueries: [
          {
            query: getPartnerGatewayUsers
          }
        ]
      })
      .then(result =>
        this.setState({
          processing: false,
          password: result.data.create_partner_gateway_user.password,
          error_msg: ''
        })
      )
      .catch(error => this.onError(error))
  }

  render() {
    const { locale, toggleModal } = this.props
    const { errors, error_msg, variables, processing, password } = this.state
    const { submit, onChange } = this
    return (
      <Form>
        {error_msg ? <Alert color="danger">{error_msg}</Alert> : ''}
        {password ? (
          <Alert color="info">
            Bitte notieren Sie sich das Passwort.
            <br />
            <br />
            Passwort: <strong>{password}</strong>
          </Alert>
        ) : (
          ''
        )}

        {!password && (
          <FormGroup>
            <Container>
              <Row>
                <ValidatedInput
                  id={'partner_gateway_user.name'}
                  variables={variables}
                  errors={errors}
                  onChange={onChange}
                  locale={locale}
                />
                <ValidatedInput
                  id={'partner_gateway_user.email'}
                  variables={variables}
                  errors={errors}
                  onChange={onChange}
                  locale={locale}
                />
              </Row>
              <Row>
                <ValidatedInput
                  id={'partner_gateway_user.organization_ids'}
                  errors={errors}
                  locale={locale}
                  customLabel={
                    <>
                      {locale.organizations}
                      <HelpTooltip help_text={locale.partner_gateway_user.organizations_help} />
                    </>
                  }
                >
                  <OrganizationSelect
                    id="partner_gateway_user.organization_ids"
                    change={onChange}
                    value={variables.partner_gateway_user.organization_ids}
                  />
                </ValidatedInput>
              </Row>
            </Container>
          </FormGroup>
        )}

        <FormGroup className="form-action">
          <Container>
            <Row>
              <Col sm="5">
                {!password && (
                  <a className="link-btn" onClick={toggleModal} href="#">
                    {locale.cancel}
                  </a>
                )}
              </Col>
              <Col sm="7" className="text-right">
                {password ? (
                  <ProcessingButton onClick={toggleModal} label={'schließen'} no_icon={true} processing={processing} />
                ) : (
                  <ProcessingButton onClick={submit} label={'speichern'} processing={processing} />
                )}
              </Col>
            </Row>
          </Container>
        </FormGroup>
      </Form>
    )
  }
}

export default withApollo(FormCreate)
