import { Component } from 'react'

// ReactStrap Components
import { Alert, Button, Col, Container, Form, FormGroup, Row } from 'reactstrap'

import { withApollo } from '@apollo/client/react/hoc'
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { TokenAuth } from 'helpers/token-auth'
import update from 'immutability-helper'

import { auth } from '../../../config/auth'
import ValidatedInput from '../../modules/inputs/validated-input'
import { UserDataQuery, setNewPasswordByTokenMutation } from './graphql'

// Style
import '../../modules/box/_box.scss'
import './_login.scss'

class ConfirmReset extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: false,
      success: false,
      success_msg: '',
      error_msg: '',
      variables: {
        reset_password_token: this.props.match.params.token,
        password: '',
        password_confirmation: ''
      }
    }
  }

  change = event =>
    this.setState(
      update(this.state, {
        variables: {
          [event.target.id]: {
            $set: event.target.value
          }
        }
      })
    )

  fetchLogin = (email, password) => {
    auth.options.body = JSON.stringify({ email: email, password: password })
    fetch(`${auth.uri}/sign_in`, auth.options)
      .then(response => {
        this.handleStatusCodes(response)
        this.updateAuthStorage(response)
      })
      .then(() => this.updateUserStorage())
      .then(() => this.props.history.push('/'))
  }

  handleStatusCodes = response => {
    if (response.status === 200) {
      return response
    }
    if (response.status === 401) {
      this.setState({
        error_msg: 'Email oder Passwort nicht korrekt.',
        error: true
      })
    }
  }
  updateAuthStorage = ({ headers }) => {
    const auth = {
      client: headers.get('client'),
      'access-token': headers.get('access-token'),
      uid: headers.get('uid')
    }
    localStorage.setItem('auth', JSON.stringify(auth))
  }

  updateUserStorage = () =>
    this.props.client
      .query({
        query: UserDataQuery
      })
      .then(response => {
        this.updateUser(response.data)
        this.updatePermissions(response.data)
      })

  updateUser = TokenAuth.setUser

  updatePermissions = ({ user }) => {
    let _permissions = {}
    user.permissions.map(permission => {
      _permissions[permission.key] = permission.permitted
    })
    localStorage.setItem('permissions', JSON.stringify(_permissions))
  }

  submit = event => {
    event.preventDefault()
    const { variables } = this.state
    const { history } = this.props

    this.props.client
      .mutate({ mutation: setNewPasswordByTokenMutation, variables })
      .then(response => {
        this.setState(
          {
            errors: {},
            error_msg: '',
            success: true,
            success_msg: `Hallo ${response.data.reset_password_by_token.full_name}, Ihr Passwort wurde erfolgreich geändert. Sie werden jetzt automatisch eingeloggt.`
          },
          () =>
            setTimeout(
              () => this.fetchLogin(response.data.reset_password_by_token.email, this.state.variables.password),
              4000
            )
        )
      })
      .catch(error => this.onError(error))
  }

  onError = ({ graphQLErrors }) =>
    this.setState({
      processing: false,
      error_msg: graphQLErrors[0].message,
      errors: graphQLErrors[0].errors
    })

  render = () => {
    const { errors, error_msg } = this.state
    const { password, password_confirmation } = this.state.variables
    const { locale } = this.props
    return (
      <div className="page-wrapper">
        <Row className="page-header">
          <Col>
            <h1>Neues Passwort setzen</h1>
          </Col>
        </Row>
        <hr className="seperator" />

        <Form className="box">
          {this.state.error_msg && <Alert color="danger">{this.state.error_msg}</Alert>}
          {this.state.success ? <Alert color="success">{this.state.success_msg}</Alert> : null}
          <FormGroup>
            <Container>
              <Row>
                <ValidatedInput
                  type="password"
                  id={'password'}
                  onChange={this.change}
                  locale={locale}
                  errors={errors}
                  variables={this.state.variables}
                />
              </Row>
            </Container>
          </FormGroup>
          <FormGroup>
            <Container>
              <Row>
                <ValidatedInput
                  type="password"
                  id={'password_confirmation'}
                  onChange={this.change}
                  locale={locale}
                  errors={errors}
                  variables={this.state.variables}
                />
              </Row>
            </Container>
          </FormGroup>
          <FormGroup className="form-action">
            <Container>
              <Row>
                <Col sm={{ size: 8, offset: 3 }}>
                  <Button type="submit" className="btn-labeled btn-login" color="primary-light" onClick={this.submit}>
                    <FontAwesomeIcon className="icon-prepend" icon={faSignInAlt} />
                    <span>Neues Passwort speichern</span>
                  </Button>
                </Col>
              </Row>
            </Container>
          </FormGroup>
        </Form>
      </div>
    )
  }
}

export default withApollo(ConfirmReset)
