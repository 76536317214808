import { Alert } from 'reactstrap'

import { withLocale } from 'locale'
import PropTypes from 'prop-types'

import CurrentBadge from './badges/current_badge'
import PublicVersionBadge from './badges/public_version_badge'
import ShownVersionBadge from './badges/shown_version_badge'

const VersionInfo = ({
  isCurrentVersion,
  versionNumber,
  publicVersionNumber,
  isPublished,
  publicIsCurrentVersion,
  facilityUuid,
  locale
}) => {
  const showPublicNotCurrentNotice = isCurrentVersion && isPublished && !publicIsCurrentVersion

  return (
    <>
      <div className="h3">
        <CurrentBadge isCurrentVersion={isCurrentVersion} facilityUuid={facilityUuid} />
        <ShownVersionBadge versionNumber={versionNumber} />

        {isPublished && <PublicVersionBadge publicVersionNumber={publicVersionNumber} facilityUuid={facilityUuid} />}
      </div>

      {showPublicNotCurrentNotice && <Alert color="warning">{locale.publicNotCurrentNotice}</Alert>}
    </>
  )
}

VersionInfo.propTypes = {
  isCurrentVersion: PropTypes.bool.isRequired,
  versionNumber: PropTypes.string.isRequired,
  publicVersionNumber: PropTypes.string,
  isPublished: PropTypes.bool.isRequired,
  publicIsCurrentVersion: PropTypes.bool.isRequired,
  facilityUuid: PropTypes.string.isRequired,
  locale: PropTypes.shape({ publicNotCurrentNotice: PropTypes.string.isRequired }).isRequired
}

export default withLocale(VersionInfo, { key: 'facility.version_info' })
