// NOTE: they correspond to the respective enum values of the graphql api (FacilityListSortingTypeEnum)

export const UPDATED_AT = 'UPDATED_AT'
export const NAME = 'NAME'
export const LICENSE_OWNER = 'LICENSE_OWNER'
export const WORKFLOW_STATE_CHANGED_AT = 'WORKFLOW_STATE_CHANGED_AT'
export const WORKFLOW_STATE = 'WORKFLOW_STATE'
export const COLLECTION_DESIRED_UNTIL = 'COLLECTION_DESIRED_UNTIL'
export const COLLECTION_DATE = 'COLLECTION_DATE'
export const CERTIFIED_TO = 'CERTIFIED_TO'

export default {
  UPDATED_AT,
  NAME,
  LICENSE_OWNER,
  WORKFLOW_STATE_CHANGED_AT,
  WORKFLOW_STATE,
  COLLECTION_DESIRED_UNTIL,
  COLLECTION_DATE,
  CERTIFIED_TO
}
