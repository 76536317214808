import React from 'react'

import { Col, Container, FormGroup, Input, Label, Row } from 'reactstrap'

import * as Sentry from '@sentry/react'
import { graphql, withApollo } from '@apollo/client/react/hoc'

import { LegacySelect as Select } from 'components/modules/selects/react-select'

import { getFacilitySectionData } from '../facility.graphql'
import { getSectionDatum, updateSectionDatum } from './sections.graphql'

import { ProcessingButton } from '../../../modules/buttons/processing-button'

const Update = ({ facility_uuid, hideEditBoxLeft, client, locale, data: { loading, error, section_datum }, match }) => {
  if (loading) return <p>Formular wird geladen</p>
  if (error) {
    console.error(error)
    Sentry.captureException(error)
    return null
  }
  return (
    <React.Fragment>
      <h2>
        {locale.editing} - {section_datum.name_de}
      </h2>
      <hr className="seperator" />
      <SectionForm
        facility_uuid={facility_uuid}
        {...section_datum}
        client={client}
        hideEditBoxLeft={hideEditBoxLeft}
        locale={locale}
        match={match}
      />
    </React.Fragment>
  )
}

class SectionForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name_de: this?.props?.name_de || '',
      name_en: this?.props?.name_en || '',
      selected_alternative_section_datum_uuids: this.getSelectedSectionData(this.props.alternative_section_data)
    }
  }

  UNSAFE_componentWillReceiveProps = nextProps =>
    this.setState({
      name_de: nextProps.name_de || '',
      name_en: nextProps.name_en || '',
      selected_alternative_section_datum_uuids: this.getSelectedSectionData(nextProps.alternative_section_data)
    })

  getSelectedSectionData = section_data => {
    let selected = []
    section_data.map(section_datum => selected.push(section_datum.uuid))
    return selected
  }

  getPossibleSectionDataOptions = section_data => {
    let possible_options = []
    section_data.map(section_datum =>
      section_datum.uuid !== this.props.uuid
        ? possible_options.push({
            value: section_datum.uuid,
            label: section_datum.name_de,
            id: 'selected_alternative_section_datum_uuids'
          })
        : null
    )
    return possible_options
  }

  changeSelectedAlternativeSectionDatumUuids = event => {
    let values = []
    event.map(value => values.push(value.value))
    this.setState({ selected_alternative_section_datum_uuids: values })
  }

  change = event =>
    event.target ? this.setState({ [event.target.id]: event.target.value }) : this.setState({ [event.id]: event.value })

  submit = () => {
    this.setState({ processing: true })

    this.props.client
      .mutate({
        mutation: updateSectionDatum,
        variables: {
          uuid: this.props.uuid,
          section_datum: {
            name_de: this.state.name_de,
            name_en: this.state.name_en,
            alternative_section_datum_uuids: this.state.selected_alternative_section_datum_uuids
          }
        },
        refetchQueries: [
          {
            query: getSectionDatum,
            variables: {
              uuid: this.props.uuid
            }
          },
          {
            query: getFacilitySectionData,
            variables: {
              uuid: this.props.facility_uuid,
              version_number: ''
            }
          }
        ],
        awaitRefetchQueries: true
      })
      .catch(() => {})
      .then(() => {
        this.setState({ processing: false })
        this.props.hideEditBoxLeft()
      })
  }

  cancel = () => this.props.hideEditBoxLeft()

  render = () => {
    const {
      state,
      cancel,
      change,
      changeSelectedAlternativeSectionDatumUuids,
      getPossibleSectionDataOptions,
      submit
    } = this
    const { section, possible_alternative_section_data, locale } = this.props
    return (
      <div className="box">
        <FormGroup>
          <Container>
            <Row>
              <Col lg="5" className="text-lg-right">
                <Label className="control-label" for="name_de">
                  Name (de)
                </Label>
              </Col>
              <Col lg="7">
                <Input
                  type="text"
                  name="name_de"
                  id="name_de"
                  placeholder="Teilbereich XY"
                  value={state.name_de}
                  onChange={change}
                />
              </Col>
            </Row>
            <Row>
              <Col lg="5" className="text-lg-right">
                <Label className="control-label" for="name_en">
                  Name (en)
                </Label>
              </Col>
              <Col lg="7">
                <Input
                  type="text"
                  name="name_en"
                  id="name_en"
                  placeholder="Section XY"
                  value={state.name_en}
                  onChange={change}
                />
              </Col>
            </Row>
            <Row>
              <Col lg="5" className="text-lg-right">
                <Label className="control-label" for="default_section">
                  Standard-Teilbereich
                </Label>
              </Col>
              <Col lg="7">
                <Input
                  type="text"
                  name="default_section"
                  id="default_section"
                  placeholder={section?.name_de}
                  disabled
                />
                <p>Der Standard-Teilbereich kann nur bei neuen Teilbereichen angepasst werden.</p>
              </Col>
            </Row>
            <Row>
              <Col lg="5" className="text-lg-right">
                <Label className="control-label" for="alternative_sections">
                  Alternative Teilbereiche
                </Label>
              </Col>
              <Col lg="7">
                <Select
                  isClearable={true}
                  name="alternative_sections"
                  isMulti
                  value={state.selected_alternative_section_datum_uuids}
                  onChange={changeSelectedAlternativeSectionDatumUuids}
                  options={getPossibleSectionDataOptions(possible_alternative_section_data)}
                  placeholder={'Bitte wählen Sie mögliche Alternativen'}
                />
              </Col>
            </Row>
          </Container>
        </FormGroup>
        <FormGroup className="form-action">
          <Container>
            <Row>
              <Col sm="5">
                <a
                  className="link-btn"
                  onClick={e => {
                    e.preventDefault()
                    cancel()
                  }}
                  href="#"
                >
                  {locale.cancel}
                </a>
              </Col>
              <Col sm="7" className="text-right">
                <ProcessingButton onClick={submit} label={locale.save} processing={state.processing} />
              </Col>
            </Row>
          </Container>
        </FormGroup>
      </div>
    )
  }
}

export default withApollo(
  graphql(getSectionDatum, {
    options: props => ({
      notifyOnNetworkStatusChange: true,
      variables: {
        uuid: props.section_datum_uuid
      },
      fetchPolicy: 'network-only'
    })
  })(Update)
)
