// additional components
import React from 'react'

import { Button, Col, Container, FormGroup, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'

import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// GraphQL
import gql from 'graphql-tag'

import Loading from '../../helper/loading/loading'
import { removeOffline } from '../offline'

const delete_facility = gql`
  mutation delete_facility($uuid: ID!) {
    delete_facility(uuid: $uuid) {
      message
      success
    }
  }
`

class DeleteFacilityModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      modal: false,
      processing: false
    }
  }

  toggle = event => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    this.setState({
      modal: !this.state.modal
    })
  }

  deleteFacility = () => {
    this.setState({ processing: true })
    this.props.client
      .mutate({
        mutation: delete_facility,
        variables: { uuid: this.props.facility.uuid }
      })
      .then(() => removeOffline(this.props.facility, this.props.client))
      .then(() => this.toggle())
      .then(() => this.props.history.push('/facilities'))
  }

  render() {
    const { className, name, locale, facility } = this.props
    const { modal, processing } = this.state
    const { deleteFacility, toggle } = this
    return (
      <React.Fragment>
        <Button outline color={'danger'} className={'btn-labeled px-5'} onClick={toggle}>
          <FontAwesomeIcon className="icon-prepend" icon={faTrashAlt} />
          <span>{locale.delete_facility}</span>
        </Button>
        <Modal centered isOpen={modal} toggle={toggle} className={className} size={'lg'}>
          <ModalHeader>{locale.delete_facility}</ModalHeader>
          <ModalBody>
            <p className="mt-3">
              Möchten Sie die Einrichtung <strong className="text-danger">{facility.base_data.name_de}</strong> wirklich
              löschen? Alle dazugehörigen Formulare werden unverzüglich gelöscht!
            </p>
            {processing ? <Loading loadingText={`wird gelöscht...`} size={`1x`} /> : null}
            <FormGroup className="form-action">
              <Container>
                <Row>
                  <Col sm="5">
                    <a className="link-btn" onClick={toggle} href="#">
                      {locale.cancel}
                    </a>
                  </Col>
                  <Col sm="7" className="text-right">
                    <Button outline onClick={deleteFacility} className="btn-labeled px-5" color="danger">
                      <FontAwesomeIcon className="icon-prepend" icon={faTrashAlt} />
                      <span>{locale.delete}</span>
                    </Button>
                  </Col>
                </Row>
              </Container>
            </FormGroup>
          </ModalBody>
        </Modal>
      </React.Fragment>
    )
  }
}

export default DeleteFacilityModal
