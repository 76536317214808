// additional components
import React from 'react'

import {
  Button,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from 'reactstrap'

import { faComments } from '@fortawesome/free-solid-svg-icons'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { DD_MM_YYYY__HH_mm } from '../../helper/helper-functions'

class ShowCommentsModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      modal: false
    }
  }

  toggleModal = event => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    this.setState({
      modal: !this.state.modal
    })
  }

  render() {
    const { toggleModal } = this
    const { className, locale, comments } = this.props
    const { modal } = this.state

    return (
      <React.Fragment>
        <Button
          outline
          color={'primary-light'}
          className={'px-5 btn-labeled align-self-center text-nowrap'}
          onClick={toggleModal}
          disabled={comments.length ? false : true}
        >
          {comments.length ? <FontAwesomeIcon className="icon-prepend" icon={faComments} /> : null}
          <span>{comments.length ? locale.show_all : locale.no_comments}</span>
        </Button>
        <Modal centered isOpen={modal} toggle={toggleModal} className={className} size={'lg'}>
          <ModalHeader>{locale.comments}</ModalHeader>
          <ModalBody>
            <ListGroup className="list-comments">
              {comments.map((comment, index) => (
                <ListGroupItem
                  key={index}
                  className={index > 0 ? 'ml-4-sm ml-10-lg li-comment mb-3 mt-4' : 'li-comment mb-3'}
                >
                  <ListGroupItemHeading>
                    {comment?.user?.full_name} &mdash; {DD_MM_YYYY__HH_mm(comment?.created_at)}{' '}
                    {index === 0 && comments.length > 1 ? '(Aktuellster Kommentar)' : ''}
                    {index === comments.length - 1 && comments.length > 1 ? '(Ältester Kommentar)' : ''}
                  </ListGroupItemHeading>
                  <ListGroupItemText>{comment.comment}</ListGroupItemText>
                </ListGroupItem>
              ))}
            </ListGroup>
            <div className="form-action mb-0">
              <Container>
                <Row>
                  <Col sm={{ size: '12' }} className="text-right">
                    <a className="link-btn" onClick={toggleModal} href="#">
                      {locale.close}
                    </a>
                  </Col>
                </Row>
              </Container>
            </div>
          </ModalBody>
        </Modal>
      </React.Fragment>
    )
  }
}

export default ShowCommentsModal
