// TODO: convert into a generic component under `ui/`
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import PropTypes from 'prop-types'

const EmailLink = ({ email }) => {
  if (!email) return null

  return (
    <a href={`mailto:${email}`}>
      <FontAwesomeIcon icon={faEnvelope} className="icon-prepend" />
      {email}
    </a>
  )
}

EmailLink.propTypes = {
  email: PropTypes.string
}

export default EmailLink
