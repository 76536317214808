import { Button, Col, Row } from 'reactstrap'

import { faFilter, faRotateLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { withLocale } from 'locale'
import PropTypes from 'prop-types'

import CertificationEndDateRangeInput from './certificationEndDateRangeInput'
import CertificationTypesSelect from './certificationTypesSelect'
import CollectorSelect from './collectorSelect'
import LicenseOwnerSelect from './licenseOwnerSelect'
import RegionSelect from './regionSelect'
import TrainingStatusSelect from './trainingStatusSelect'
import WorkflowStateSelect from './workflowStateSelect'

const FacilitiesFilterContent = ({ toggle, resetFilter, setFilter, filter, locale }) => {
  const setLicenseOwnerIds = licenseOwnerIds => {
    setFilter({ ...filter, licenseOwnerIds })
  }

  const setCollectorIds = collectorIds => {
    setFilter({ ...filter, collectorIds })
  }

  const setRegionIds = regionIds => {
    setFilter({ ...filter, regionIds })
  }

  const setTrainingStatus = trainingStatus => {
    setFilter({ ...filter, trainingStatus })
  }

  const setWorkflowStates = workflowStates => {
    setFilter({ ...filter, workflowStates })
  }

  const setCertificationTypes = certificationTypes => {
    setFilter({ ...filter, certificationTypes })
  }

  const setCertificationEndDateRange = ({ start, end }) => {
    if (!start || !end) {
      setFilter({ ...filter, certificationEndDateRange: null })
      return
    }

    setFilter({ ...filter, certificationEndDateRange: { start, end } })
  }

  return (
    <>
      <Row className="mt-0">
        <Col>
          <LicenseOwnerSelect licenseOwnerIds={filter.licenseOwnerIds} setLicenseOwnerIds={setLicenseOwnerIds} />
        </Col>
      </Row>
      <Row>
        <Col>
          <CollectorSelect collectorIds={filter.collectorIds} setCollectorIds={setCollectorIds} />
        </Col>
      </Row>
      <Row>
        <Col>
          <RegionSelect regionIds={filter.regionIds} setRegionIds={setRegionIds} />
        </Col>
      </Row>
      <Row>
        <Col>
          <TrainingStatusSelect trainingStatus={filter.trainingStatus} setTrainingStatus={setTrainingStatus} />
        </Col>
      </Row>
      <Row>
        <Col>
          <WorkflowStateSelect workflowStates={filter.workflowStates} setWorkflowStates={setWorkflowStates} />
        </Col>
      </Row>
      <Row>
        <Col>
          <CertificationTypesSelect
            certificationTypes={filter.certificationTypes}
            setCertificationTypes={setCertificationTypes}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <CertificationEndDateRangeInput
            certificationEndDateRange={filter.certificationEndDateRange}
            setCertificationEndDateRange={setCertificationEndDateRange}
          />
        </Col>
      </Row>
      <Row className="mb-0">
        <Col>
          <Button
            color="link"
            onClick={() => {
              resetFilter()
              toggle()
            }}
            className="text-nowrap text-primary-light"
          >
            <FontAwesomeIcon icon={faRotateLeft} className="icon-prepend" />
            {locale.reset}
          </Button>
        </Col>
        <Col className="text-right">
          <Button color="primary" onClick={toggle} className="text-nowrap">
            <FontAwesomeIcon icon={faFilter} className="icon-prepend" />
            {locale.filter}
          </Button>
        </Col>
      </Row>
    </>
  )
}

FacilitiesFilterContent.propTypes = {
  toggle: PropTypes.func.isRequired,
  resetFilter: PropTypes.func.isRequired,
  setFilter: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    licenseOwnerIds: PropTypes.array.isRequired,
    collectorIds: PropTypes.array.isRequired,
    regionIds: PropTypes.array.isRequired,
    trainingStatus: PropTypes.string,
    certificationEndDateRange: PropTypes.shape({
      start: PropTypes.string,
      end: PropTypes.string
    }),
    workflowStates: PropTypes.array.isRequired
  }).isRequired,
  locale: PropTypes.object.isRequired
}

export default withLocale(FacilitiesFilterContent, { key: 'facilitiesDashboard.filters' })
