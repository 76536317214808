import gql from 'graphql-tag'

export const rolesQuery = gql`
  query roles_query {
    roles {
      id
      name
    }
  }
`
