import { Query } from '@apollo/client/react/components'

import { getVariables } from '../short_report_variables.graphql'

import Loading from '../../../../helper/loading/loading'
import RenderedTable from './variablesTable/RenderedTable'

const VariablesTable = _ => (
  <Query query={getVariables}>
    {({ loading, error, data }) => {
      if (loading) return <Loading />
      if (error) return error?.message || 'error'

      return <RenderedTable variables={data.facility_short_report_variables} />
    }}
  </Query>
)

export default VariablesTable
