import { useRef } from 'react'

import { Link } from 'react-router-dom'
import { UncontrolledTooltip } from 'reactstrap'

import { faArrowUpRightFromSquare, faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import PropTypes from 'prop-types'

const ActionButtons = ({ facilityUuid }) => {
  const openRef = useRef()
  const openInNewTabRef = useRef()

  return (
    <>
      <Link to={`/facility/${facilityUuid}`} ref={openRef} className="p-3">
        <FontAwesomeIcon icon={faEye} />
      </Link>
      <UncontrolledTooltip target={openRef} placement="top">
        Details anzeigen
      </UncontrolledTooltip>
      <Link to={`/facility/${facilityUuid}`} target="_blank" ref={openInNewTabRef} className="p-3">
        <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
      </Link>
      <UncontrolledTooltip target={openInNewTabRef} placement="top">
        Details in neuem Tab anzeigen
      </UncontrolledTooltip>
    </>
  )
}

ActionButtons.propTypes = {
  facilityUuid: PropTypes.string.isRequired
}

export default ActionButtons
