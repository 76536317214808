import { useState } from 'react'

import { Collapse } from 'reactstrap'

import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import PropTypes from 'prop-types'

// TODO: remove from accordion and put into general "Collapse" component
const AccordionCollapse = ({ title, children, emphasized = false, className }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const collapseIcon = isOpen ? faChevronUp : faChevronDown

  let titleElement, TitleContainerType, titleContainerProps

  if (typeof title === 'string') {
    titleElement = (
      <>
        <span className="accordion__head__icon">
          <FontAwesomeIcon icon={collapseIcon} />
        </span>
        <span className="accordion__head__text">{title}</span>
      </>
    )
    TitleContainerType = 'button'
    titleContainerProps = { onClick: toggle, type: 'button' }
  } else {
    titleElement = title
    TitleContainerType = 'div'
  }

  return (
    <div className={`accordion  ${emphasized && 'emphasized-accordion'} ${className}`}>
      <TitleContainerType className="accordion__head px-5" {...titleContainerProps}>
        {titleElement}
      </TitleContainerType>
      <Collapse className="accordion__content" isOpen={isOpen}>
        {children}
      </Collapse>
    </div>
  )
}

AccordionCollapse.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  children: PropTypes.node,
  emphasized: PropTypes.bool,
  className: PropTypes.string
}

export default AccordionCollapse
