import React from 'react'

import { graphql, withApollo } from '@apollo/client/react/hoc'

import { LegacySelect as Select } from 'components/modules/selects/react-select'

import { getStates } from './select-queries.graphql'

import { getPropertyFromObject } from '../../helper/helper-functions'

const StateSelect = ({ data: { possible_states, loading }, id, variables, onChange, errors }) =>
  loading ? (
    <Select
      id={id}
      style={
        errors && {
          borderColor: '#e53012'
        }
      }
      placeholder={'auswählen'}
      isClearable={false}
      name={id}
      value={getPropertyFromObject(variables, id)}
      onChange={onChange}
      options={[]}
    />
  ) : (
    <React.Fragment>
      <Select
        id={id}
        style={
          getPropertyFromObject(errors, id) && {
            borderColor: '#e53012'
          }
        }
        placeholder={'auswählen'}
        isClearable={false}
        name={id}
        value={getPropertyFromObject(variables, id)}
        onChange={onChange}
        options={possible_states.map(state => {
          return {
            value: state.key,
            label: state.label,
            target: { id, value: state.key }
          }
        })}
      />
      {!!getPropertyFromObject(errors, id) && (
        <div
          style={{
            width: '100%',
            marginTop: '0.25rem',
            fontSize: '80%',
            color: '#e53012'
          }}
        >
          {getPropertyFromObject(errors, id)}
        </div>
      )}
    </React.Fragment>
  )

export default withApollo(
  graphql(getStates, {
    options: ({ current_country }) => ({
      notifyOnNetworkStatusChange: true,
      variables: {
        country_code: current_country || 'DE'
      }
    })
  })(StateSelect)
)
