import gql from 'graphql-tag'

export const query = gql`
  query user($id: ID!) {
    user(id: $id) {
      possible_license_owners_to_collect_for {
        id
        name
        sublicense_owners {
          id
          name
        }
      }
    }
  }
`
