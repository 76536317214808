import { useState } from 'react'

import { Alert, UncontrolledTooltip } from 'reactstrap'

import { withApollo } from '@apollo/client/react/hoc'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'

import { ProcessingButton } from 'components/modules/buttons/processing-button'
import { withLocale } from 'locale/'

import {
  getBundledFacilities,
  removeBundledFacility as removeBundledFacilityMutation
} from '../bundle_information.graphql'

import FacilityCollection from '../facility_collection'

const RemoveBundledFacilities = ({ facility, locale, client, setSelectOptionsCacheKey }) => {
  const [processing, setProcessing] = useState(null)
  const [error, setError] = useState({})

  const removeBundledFacility = uuid => _ => {
    setProcessing(uuid)
    client
      .mutate({
        mutation: removeBundledFacilityMutation,
        variables: {
          input: {
            uuid: facility.uuid,
            bundledFacilityUuid: uuid
          }
        },
        refetchQueries: [
          {
            query: getBundledFacilities,
            variables: { uuid: facility.uuid }
          }
        ],
        awaitRefetchQueries: true
      })
      .then(result => {
        if (result?.data?.removeBundledFacility?.success) {
          setError({
            ...error,
            [uuid]: null
          })
          setSelectOptionsCacheKey(Date.now())
        } else {
          setError({
            ...error,
            [uuid]: JSON.parse(result?.data?.removeBundledFacility?.message || [])[0] || 'There was an error'
          })
        }
        setProcessing(null)
      })
  }

  const FacilityActions = ({ facility: bundledFacility }) => {
    const id = `delete-bundled-facility-${bundledFacility.uuid}`

    return (
      <>
        {error[bundledFacility.uuid] && (
          <Alert
            color="danger"
            toggle={_ => setError({ ...error, [bundledFacility.uuid]: null })}
            className="mb-0 mr-3"
          >
            {error[bundledFacility.uuid]}
          </Alert>
        )}
        <ProcessingButton
          color="danger"
          size="sm"
          className="px-3"
          id={id}
          label=""
          processing={processing === bundledFacility.uuid}
          disabled={processing !== null}
          processing_label=""
          icon={faTrashAlt}
          onClick={removeBundledFacility(bundledFacility.uuid)}
        />
        <UncontrolledTooltip placement="left" target={id}>
          {locale.facility.bundleInformation.removeBundledFacility}
        </UncontrolledTooltip>
      </>
    )
  }

  return (
    <FacilityCollection
      type="bundledFacilities"
      uuid={facility.uuid}
      actions={bundledFacility => <FacilityActions facility={bundledFacility} />}
    />
  )
}

export default withApollo(withLocale(RemoveBundledFacilities))
