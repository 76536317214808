import { Col, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap'

import { faCoins } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { withLocale } from 'locale/index'

import TransactionsExport from '../transactionsExport'

const TransactionsTableHeader = ({ balance, exportBaseUrl, locale }) => (
  <Row className="my-4">
    <Col sm="12" lg="6">
      <InputGroup size="lg">
        <InputGroupAddon addonType="prepend">{locale.balance}</InputGroupAddon>
        <InputGroupText className={`px-4 border-left ${balance < 0 ? 'bg-danger text-white' : 'bg-success'}`}>
          <FontAwesomeIcon icon={faCoins} className="mr-3" />
          <span className={`h3 m-0 ${balance < 0 ? 'text-white' : ''}`}>{balance}</span>
        </InputGroupText>
      </InputGroup>
    </Col>
    <Col sm="12" lg="6" className="align-self-lg-end py-0 mt-lg-0">
      <TransactionsExport exportBaseUrl={exportBaseUrl} />
    </Col>
  </Row>
)

export default withLocale(TransactionsTableHeader, { key: 'licenseOwners.volumeUnitAccount' })
