import { Query } from '@apollo/client/react/components'
import { withApollo } from '@apollo/client/react/hoc'

import { getNewFormDatum } from './form-datum.graphql'

import Loading from '../../../helper/loading/loading'
import FormDatum from './form_datum'

const getNewFormDatumQueryComponent = props => (
  <Query
    fetchPolicy="network-only"
    query={getNewFormDatum}
    variables={{
      form_id: props.match.params.form_id,
      facility_uuid: props.match.params.facility_uuid,
      section_datum_uuids: props.match.params.section_datum_uuid
    }}
  >
    {({ loading, error, data }) => {
      if (loading || !data) return <Loading />
      if (error) return `Error! ${error.message}`
      return <FormDatum {...props} {...data} isCreate={true} />
    }}
  </Query>
)

export default withApollo(getNewFormDatumQueryComponent)
