import React from 'react'

import { Button, Col, Container, Form, FormGroup, Row } from 'reactstrap'

import { withApollo } from '@apollo/client/react/hoc'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { deleteFacilityKind, getFacilityKinds } from './facility_kinds.graphql'

class FormDelete extends React.Component {
  submit = () =>
    this.props.client
      .mutate({
        mutation: deleteFacilityKind,
        variables: {
          id: this.props.facility_kind.id
        },
        refetchQueries: [
          {
            query: getFacilityKinds
          }
        ]
      })
      .then(result => this.props.toggleModal())

  render() {
    const { locale, toggleModal } = this.props
    const { submit } = this

    return (
      <Form>
        <FormGroup>
          <Container>
            <p>
              Möchten Sie den Angebotstyp{' '}
              <span className="text-danger">
                <strong>{this.props.facility_kind.name_de}</strong>
              </span>{' '}
              wirklich löschen?
            </p>
          </Container>
        </FormGroup>
        <FormGroup className="form-action">
          <Container>
            <Row>
              <Col sm="5">
                <a className="link-btn" onClick={toggleModal} href="#">
                  {locale.cancel} ?
                </a>
              </Col>
              <Col sm="7" className="text-right">
                <Button className="btn-labeled" outline color="danger" onClick={submit}>
                  <FontAwesomeIcon className={'icon-prepend'} icon={faTrashAlt} /> <span>{locale.delete}</span>
                </Button>
              </Col>
            </Row>
          </Container>
        </FormGroup>
      </Form>
    )
  }
}

export default withApollo(FormDelete)
