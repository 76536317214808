import React from 'react'

import { graphql, withApollo } from '@apollo/client/react/hoc'

import { LegacySelect as Select } from 'components/modules/selects/react-select'

import { getFacilityKinds } from '../../views/form-kit/facility-kinds/facility_kinds.graphql'

import { getPropertyFromObject } from '../../helper/helper-functions'

const FacilityKindParentSelect = ({ data: { facility_kinds, loading }, id, variables, onChange, errors, style }) => {
  if (loading) {
    return (
      <Select
        id={id}
        style={
          getPropertyFromObject(errors, id) && {
            borderColor: '#e53012'
          }
        }
        placeholder={'auswählen'}
        isClearable={false}
        name={id}
        value={getPropertyFromObject(variables, id)}
        onChange={onChange}
        options={[]}
      />
    )
  } else {
    let options = []
    facility_kinds.map(facility_kind => {
      options.push({
        value: facility_kind.id,
        label: facility_kind.name_de,
        target: { id, value: facility_kind.id }
      })
    })

    return (
      <React.Fragment>
        <Select
          id={id}
          style={
            getPropertyFromObject(errors, id) && {
              borderColor: '#e53012'
            }
          }
          placeholder={'auswählen'}
          isClearable={false}
          name={id}
          value={getPropertyFromObject(variables, id)}
          onChange={onChange}
          options={options}
        />
        {!!getPropertyFromObject(errors, id) && (
          <div
            style={{
              width: '100%',
              marginTop: '0.25rem',
              fontSize: '80%',
              color: '#e53012'
            }}
          >
            {getPropertyFromObject(errors, id)}
          </div>
        )}
      </React.Fragment>
    )
  }
}
export default withApollo(
  graphql(getFacilityKinds, {
    options: () => ({
      notifyOnNetworkStatusChange: true
    })
  })(FacilityKindParentSelect)
)
