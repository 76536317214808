import { Col, Row } from 'reactstrap'

import PropTypes from 'prop-types'

import { FacilityKindCheckbox } from './facilityKindCheckbox'

const FacilityKindCheckboxGroup = ({ facilityKinds, selectedFacilityKindIds, changeFacilityKindIds }) => (
  <Row>
    {facilityKinds.map(({ id, name_de }) => (
      <Col lg={6} key={id} className="mb-3">
        <FacilityKindCheckbox
          {...{
            id,
            name_de,
            selectedFacilityKindIds,
            changeFacilityKindIds
          }}
        />
      </Col>
    ))}
  </Row>
)

FacilityKindCheckboxGroup.propTypes = {
  facilityKinds: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name_de: PropTypes.string.isRequired
    })
  ).isRequired,
  selectedFacilityKindIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  changeFacilityKindIds: PropTypes.func.isRequired
}

export { FacilityKindCheckboxGroup }
