import React from 'react'

import { LegacySelect as Select } from 'components/modules/selects/react-select'

class PossibleForms extends React.Component {
  options = () => {
    const { possible_forms } = this.props.facility
    let options = []

    possible_forms
      .slice()
      .sort(this.formSort)
      .map(possible_form =>
        options.push({
          value: possible_form.id,
          label: `${possible_form.sheet_number} ${possible_form.name_de} ${possible_form.in_use ? '' : '(deaktiviert)'}`
        })
      )

    return options
  }

  formSort = (a, b) => {
    if (a.in_use === b.in_use) {
      if (a.sheet_number === b.sheet_number) {
        return a.name_de.localeCompare(b.name_de, navigator.languages[0] || navigator.language)
      }
      return a.sheet_number.localeCompare(b.sheet_number, navigator.languages[0] || navigator.language, {
        numeric: true
      })
    }
    return a.in_use ? -1 : 1
  }

  render = () => {
    const { form_id, section_datum_uuid, history, facility } = this.props
    return (
      <Select
        isClearable={false}
        placeholder={`Auswahl`}
        name="form_id"
        value={form_id}
        onChange={event => {
          history.location.pathname.includes('offline-facility')
            ? history.push(`/offline-formdatum/new/${event.value}/${section_datum_uuid}/${facility.uuid}`)
            : history.push(`/formdatum/new/${event.value}/${section_datum_uuid}/${facility.uuid}`)
        }}
        options={this.options()}
      />
    )
  }
}

export default PossibleForms
