import { faGlobe } from '@fortawesome/free-solid-svg-icons'

import { withLocale } from 'locale'
import PropTypes from 'prop-types'

import VersionNumberBadge from './version_number_badge'

const PublicVersionBadge = ({ publicVersionNumber, facilityUuid, locale }) => (
  <VersionNumberBadge
    versionNumber={publicVersionNumber}
    icon={faGlobe}
    tooltip={locale.publicVersionHelp(facilityUuid, publicVersionNumber)}
  />
)

PublicVersionBadge.propTypes = {
  publicVersionNumber: PropTypes.string.isRequired,
  facilityUuid: PropTypes.string.isRequired,
  locale: PropTypes.shape({ publicVersionHelp: PropTypes.func.isRequired }).isRequired
}

export default withLocale(PublicVersionBadge, { key: 'facility.version_info' })
