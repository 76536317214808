// Images
import { Component } from 'react'

import { Link as ReactRouterLink } from 'react-router-dom'
// ReactStrap Components
import { NavbarBrand, Tooltip } from 'reactstrap'

import LogoImage from '../../assets/reisen-fuer-alle.png'

// CSS
import './_logo.scss'

const Link = props => <ReactRouterLink {...props} />

class Logo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tooltipOpen: false
    }
  }

  toggle = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    })
  }

  render() {
    return (
      <NavbarBrand tag={Link} to="/" id="Logo">
        <img src={LogoImage} alt="Reisen für Alle" />
        <Tooltip placement="right" isOpen={this.state.tooltipOpen} target="Logo" toggle={this.toggle}>
          Zur Startseite
        </Tooltip>
      </NavbarBrand>
    )
  }
}

export default Logo
