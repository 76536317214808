import { faPlus } from '@fortawesome/free-solid-svg-icons'

import { withLocale } from 'locale'
import PropTypes from 'prop-types'

import { getFacility } from '../../facility.graphql'

import Modal from '../../../../../components/modules/modal/modal'
import { CreateProvider } from '../../../admin/providers'

const CreateModal = ({ locale, facility: { uuid } }) => (
  <Modal
    toggleModalText={locale.facility.provider.create}
    button_type={{ icon_only: false, icon_type: faPlus, color: 'primary', outlined: false, classes: 'btn-sm mx-2' }}
    modal_size={'lg'}
  >
    <CreateProvider
      facilityUuid={uuid}
      refetchQueries={[{ query: getFacility, variables: { uuid: uuid, version_number: '' } }]}
    />
  </Modal>
)

CreateModal.propTypes = {
  locale: PropTypes.object.isRequired,
  facility: PropTypes.object.isRequired
}

export default withLocale(CreateModal)
