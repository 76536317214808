import React from 'react'

import { Button, Col, Container, FormGroup, Row } from 'reactstrap'

import { faSave } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ValidatedInput from '../../../modules/inputs/validated-input'

const FormGroups = ({ errors, variables, onChange, submit, toggleModal, props: { locale } }) => (
  <React.Fragment>
    <FormGroup>
      <Container>
        <Row>
          <ValidatedInput
            id={'region.name_de'}
            errors={errors}
            variables={variables}
            onChange={onChange}
            locale={locale}
          />
          <ValidatedInput
            id={'region.name_en'}
            errors={errors}
            variables={variables}
            onChange={onChange}
            locale={locale}
          />
        </Row>
      </Container>
    </FormGroup>
    <FormGroup className="form-action">
      <Container>
        <Row>
          <Col sm="2">
            <a className="link-btn" onClick={toggleModal} href="#">
              {locale.cancel}
            </a>
          </Col>
          <Col sm={{ size: '4', offset: '6' }} className="text-right">
            <Button className="btn-labeled" color="primary-light" onClick={submit}>
              <FontAwesomeIcon className="icon-prepend" icon={faSave} />
              <span>{locale.save}</span>
            </Button>
          </Col>
        </Row>
      </Container>
    </FormGroup>
  </React.Fragment>
)
export default FormGroups
