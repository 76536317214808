import RichTextEditor from 'react-rte'
import { FormFeedback } from 'reactstrap'

import { InputWrapper } from 'components/modules/form/components'
import { withLocale } from 'locale'

const toolbarConfig = {
  display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
  INLINE_STYLE_BUTTONS: [
    { label: 'Fett', style: 'BOLD' },
    { label: 'Kursiv', style: 'ITALIC' },
    { label: 'Durchgestrichen', style: 'STRIKETHROUGH' },
    { label: 'Unterstrichen', style: 'UNDERLINE' }
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: 'Text', style: 'unstyled' },
    { label: 'Überschrift H3', style: 'header-three' },
    { label: 'Überschrift H4', style: 'header-four' },
    { label: 'Überschrift H5', style: 'header-five' }
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: 'Liste (unsortiert)', style: 'unordered-list-item' },
    { label: 'Liste (sortiert)', style: 'ordered-list-item' }
  ]
}

const CustomFeedback = ({ error }) =>
  !!error ? (
    <FormFeedback valid={!error} style={{ display: 'block' }}>
      {error}
    </FormFeedback>
  ) : null

const TextTemplateForm = ({ locale, value, onChange, error }) => {
  return (
    <InputWrapper layout={{ label: { sm: 0 }, input: { sm: 12 } }}>
      <RichTextEditor
        toolbarConfig={toolbarConfig}
        value={value || RichTextEditor.createEmptyValue()}
        onChange={onChange}
        className={error ? 'border-danger' : ''}
      />
      <CustomFeedback error={error} />
    </InputWrapper>
  )
}

export default withLocale(TextTemplateForm, { key: 'short_report.variable' })
