import gql from 'graphql-tag'

export const certificationUpdateMutation = gql`
  mutation($uuid: ID!, $comment: String!) {
    comment_facility_event(uuid: $uuid, comment: $comment) {
      uuid
      version_number
      workflow_history {
        new_status
        updated_at
        comments {
          comment
          created_at
          user {
            full_name
          }
        }
      }
    }
  }
`
