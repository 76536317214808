import React, { Fragment } from 'react'

import { components } from 'react-select'
import { FormText, UncontrolledTooltip } from 'reactstrap'

import { LegacySelect as Select } from 'components/modules/selects/react-select'

import { getPropertyFromObject } from '../../helper/helper-functions'

const Option = props => {
  const {
    data: { hint },
    innerProps: { id }
  } = props

  return (
    <Fragment>
      <components.Option {...props} />
      {hint ? (
        <UncontrolledTooltip target={id} placement="bottom" delay={0} boundariesElement={window}>
          {hint}
        </UncontrolledTooltip>
      ) : null}
    </Fragment>
  )
}

const DynamicSelect = ({
  form_text = '',
  disabled = false,
  placeholder = 'auswählen',
  value = null,
  simpleValue = false,
  isClearable = false,
  isMulti = false,
  id = 'missing',
  variables = { missing: '1' },
  onChange = () => alert('add onChange function'),
  errors = {},
  options = [],
  selectOpts = {}
}) => (
  <React.Fragment>
    <Select
      isDisabled={disabled}
      isMulti={isMulti}
      simpleValue={simpleValue}
      id={id}
      noOptionsMessage={_ => 'Keine Einträge vorhanden'}
      style={
        getPropertyFromObject(errors, id) && {
          borderColor: '#e53012'
        }
      }
      placeholder={placeholder}
      isClearable={isClearable}
      name={id}
      value={value || getPropertyFromObject(variables, id)}
      onChange={e => onChange(e, id)}
      options={options}
      closeMenuOnSelect={!isMulti}
      components={{ Option }}
      {...selectOpts}
    />
    {!!form_text && <FormText>{form_text}</FormText>}
    {!!getPropertyFromObject(errors, id) && (
      <div
        style={{
          width: '100%',
          marginTop: '0.25rem',
          fontSize: '80%',
          color: '#e53012'
        }}
      >
        {getPropertyFromObject(errors, id)}
      </div>
    )}
  </React.Fragment>
)

export default DynamicSelect
