import { createRoot } from 'react-dom/client'

import * as Sentry from '@sentry/react'
import { ApolloClient, ApolloProvider } from '@apollo/client'
import { createConsumer } from '@rails/actioncable'
import { ExtraErrorData as ExtraErrorDataIntegration, Offline as OfflineIntegration } from '@sentry/integrations'
import SentryRRWeb from '@sentry/rrweb'
import { Integrations as TracingIntegrations } from '@sentry/tracing'

import { ApolloLink } from 'apollo-link'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
import { SentryLink, excludeGraphQLFetch } from 'apollo-link-sentry'
import { createUploadLink } from 'apollo-upload-client'
import apolloCache from 'config/apollo_cache'
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink'
import { createBrowserHistory } from 'history'

import App from './components/app/app.jsx'
import { graphql_uri } from './config/graphql'
import { TokenAuth } from './helpers/token-auth'

const history = createBrowserHistory()

Sentry.init({
  dsn: ['rfa-production', 'rfa-qs', 'rfa-dev'].includes(__SERVER_ENV__) || __FORCE_SENTRY__ ? __SENTRY_DSN__ : null,
  environment: __SERVER_ENV__,
  attachStacktrace: true,
  integrations: [
    new ExtraErrorDataIntegration(),
    new OfflineIntegration(),
    new TracingIntegrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
    }),
    new SentryRRWeb()
  ],

  tracesSampleRate: 0,
  beforeSend(event) {
    event.user = {
      id: TokenAuth.getUser()?.id
    }

    return event
  },
  beforeBreadcrumb: excludeGraphQLFetch
})

const sentryLink = new SentryLink({
  uri: graphql_uri,
  attachBreadcrumbs: {
    includeQuery: true,
    includeVariables: true,
    includeFetchResult: true,
    includeError: true
  }
})

const uploadLink = createUploadLink({
  uri: graphql_uri,
  fetchOptions: {
    onProgress: progress => {
      console.log(progress)
    }
  }
})

const authLink = setContext(() => {
  const headers = TokenAuth.getCredentials()
  return { headers }
})

const handleErrors = onError(e => {
  const { graphQLErrors, networkError } = e

  if (graphQLErrors)
    graphQLErrors.map(error => {
      console.warn('[GraphQL error]', error.message, error)
    })

  if (networkError) {
    console.warn('[Network error]', e)
    if (networkError.statusCode === 401 && window.location.pathname !== '/login' && !(navigator && !navigator.onLine)) {
      TokenAuth.logout()
      window.routeHistory.replace({
        pathname: '/login',
        state: {
          afterSignInPath: window.routeHistory.location.pathname
        }
      })
    }
  }
})

const afterwareLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    const context = operation.getContext()
    const {
      response: { headers }
    } = context
    if (headers) {
      const uid = headers.get('uid')
      if (uid) {
        TokenAuth.updateAuthStorage(headers)
      }
    }

    return response
  })
})

const cableUrl = () => {
  const credentials = TokenAuth.getCredentials()
  if (!credentials) return __CABLE_URL__

  const params = Object.keys(credentials)
    .map(key => `${key}=${encodeURIComponent(credentials[key])}`)
    .join('&')

  return `${__CABLE_URL__}?${params}`
}

const hasSubscriptionOperation = ({ query: { definitions } }) => {
  return definitions.some(({ kind, operation }) => kind === 'OperationDefinition' && operation === 'subscription')
}

const baseLink = ApolloLink.from([sentryLink, handleErrors, afterwareLink, authLink, uploadLink])

// TODO: Maybe include some reconnection logic. Can we even do this easily without a dedicated apollo wrapper component?
const createSubscriptionLink = () =>
  ApolloLink.split(
    hasSubscriptionOperation,
    new ActionCableLink({ cable: createConsumer(cableUrl), channelName: 'FrontendApiChannel' }),
    baseLink
  )

const client = new ApolloClient({
  connectToDevTools: true,
  link: createSubscriptionLink(),
  cache: apolloCache
})

const renderApp = () => {
  const root = createRoot(document.getElementById('app-root'))

  root.render(
    <ApolloProvider client={client}>
      <App history={history} />
    </ApolloProvider>
  )
}

renderApp()
