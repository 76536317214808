import React from 'react'

import { Alert } from 'reactstrap'

import { withApollo } from '@apollo/client/react/hoc'

import { deepNestInObjectsWithKeys } from 'helpers/object'
import update from 'immutability-helper'

import { createUser, getUsers } from './users.graphql'

import FormGroups from './form'

const UserCreate = ({ locale, history, client, toggleModal }) => (
  <UserForm client={client} history={history} locale={locale} toggleModal={toggleModal} />
)

class UserForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      variables: {
        user: {
          address: {
            street: '',
            street_addition: '',
            zipcode: '',
            city: '',
            state: null,
            country_code: 'DE'
          },
          notes: '',
          info: {
            more_info: ''
          },
          phone: '',
          collects_for_license_owner_ids: [],
          email: '',
          email_notifications: true,
          first_name: '',
          last_name: '',
          salutation: 'mr',
          collector_id: '',
          institution: '',
          license_owner_id: '',
          role_id: 5, //5 is default - collector role
          password: '',
          password_confirmation: '',
          collector: false,
          status: 'not_certified'
        }
      }
    }
  }

  onError = ({ graphQLErrors }) =>
    this.setState({
      error_msg: graphQLErrors[0].message,
      errors: graphQLErrors[0].errors
    })

  changeNotification = () =>
    this.setState(
      update(this.state, {
        variables: {
          user: {
            email_notifications: {
              $set: !this.state.variables.user.email_notifications
            }
          }
        }
      })
    )

  changeCollector = () =>
    this.setState(
      update(this.state, {
        variables: {
          user: {
            collector: {
              $set: !this.state.variables.user.collector
            }
          }
        }
      })
    )

  changeAddress = event => {
    this.setState(
      update(this.state, {
        variables: {
          user: {
            address: {
              [event.target.id]: {
                $set: event.target.value
              }
            }
          }
        }
      })
    )
  }
  changeInfo = event => {
    this.setState(
      update(this.state, {
        variables: {
          user: {
            info: {
              [event.target.id]: {
                $set: event.target.value
              }
            }
          }
        }
      })
    )
  }
  submit = () => {
    let { variables } = this.state

    //api expects array but select is giving us stringlist
    if (!Array.isArray(variables.user.collects_for_license_owner_ids)) {
      variables.user.collects_for_license_owner_ids = variables.user.collects_for_license_owner_ids
        .split(',')
        .filter(item => item !== '')
    }

    //no collector
    if (!variables.user.collector) {
      variables.user.collector_id = ''
      variables.user.status = 'not_certified'
      variables.user.collects_for_license_owner_ids = []
    }

    this.props.client
      .mutate({
        mutation: createUser,
        variables,
        refetchQueries: [
          {
            query: getUsers
          }
        ]
      })
      .then(() => this.props.toggleModal())
      .catch(error => this.onError(error))
  }

  change = event => {
    this.setState(
      update(this.state, {
        variables: {
          user: {
            [event.target.id]: {
              $set: event.target.value
            }
          }
        }
      })
    )
  }
  changeSalutation = salutation => {
    this.setState(
      update(this.state, {
        variables: {
          user: {
            salutation: {
              $set: salutation.value
            }
          }
        }
      })
    )
  }
  changeLicenseOwner = license_owner => {
    this.setState(
      update(this.state, {
        variables: {
          user: {
            license_owner_id: {
              $set: license_owner.value
            }
          }
        }
      })
    )
  }
  changeState = state => {
    this.setState(
      update(this.state, {
        variables: {
          user: {
            address: {
              state: {
                $set: state.value === '' ? null : state.value
              }
            }
          }
        }
      })
    )
  }
  changeStatus = status => {
    this.setState(
      update(this.state, {
        variables: {
          user: {
            status: {
              $set: status.value
            }
          }
        }
      })
    )
  }
  changeCountry = country => {
    this.setState(
      update(this.state, {
        variables: {
          user: {
            address: {
              country_code: {
                $set: country.value
              }
            }
          }
        }
      })
    )
  }
  changeLicenseOwnerToCollectFor = id_string => {
    this.setState(
      update(this.state, {
        variables: {
          user: {
            collects_for_license_owner_ids: {
              $set: id_string
            }
          }
        }
      })
    )
  }
  changeRole = role => {
    this.setState(
      update(this.state, {
        variables: {
          user: {
            ['role_id']: {
              $set: role.value
            }
          }
        }
      })
    )
  }

  onChange = event => {
    const fragments = event.target.id.split('.')
    const variables = deepNestInObjectsWithKeys(event.target.value, ...fragments, '$set')

    this.setState(update(this.state, { variables }))
  }

  onError = ({ graphQLErrors }) =>
    this.setState({
      error_msg: graphQLErrors[0].message,
      errors: graphQLErrors[0].errors
    })

  render = () => (
    <form autoComplete="off">
      {this.state.errors && <Alert color="danger">{this.state.error_msg}</Alert>}
      <FormGroups
        change={this.change}
        changeAddress={this.changeAddress}
        changeCollector={this.changeCollector}
        changeCountry={this.changeCountry}
        changeInfo={this.changeInfo}
        changeLicenseOwner={this.changeLicenseOwner}
        changeLicenseOwnerToCollectFor={this.changeLicenseOwnerToCollectFor}
        changeNotification={this.changeNotification}
        changeRole={this.changeRole}
        changeSalutation={this.changeSalutation}
        changeState={this.changeState}
        changeStatus={this.changeStatus}
        errors={this.state.errors}
        variables={this.state.variables}
        onChange={this.onChange}
        is_create={true}
        submit={this.submit}
        user={this.state.variables.user}
        locale={this.props.locale}
        toggleModal={this.props.toggleModal}
      />
    </form>
  )
}

export default withApollo(UserCreate)
