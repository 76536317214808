import PropTypes from 'prop-types'

import { FACILITY_SIZE, FIRST_CERTIFICATION, FORM_COUNT, PATH_LENGTH, RECERTIFICATION } from './componentTypes'
import CertificationRun from './componentTypes/certificationRun'
import FacilitySize from './componentTypes/facilitySize'
import FormCount from './componentTypes/formCount'
import PathLength from './componentTypes/pathLength'
import Unknown from './componentTypes/unknown'

const CalculationComponent = ({ type, value }) => {
  switch (type) {
    case FACILITY_SIZE:
      return <FacilitySize value={value} />
    case PATH_LENGTH:
      return <PathLength value={value} />
    case FORM_COUNT:
      return <FormCount value={value} />
    case RECERTIFICATION:
      return <CertificationRun recertification />
    case FIRST_CERTIFICATION:
      return <CertificationRun />
    default:
      return <Unknown typeName={type} value={value} />
  }
}

CalculationComponent.propTypes = {
  type: PropTypes.oneOf([FACILITY_SIZE, PATH_LENGTH, FORM_COUNT, RECERTIFICATION, FIRST_CERTIFICATION]).isRequired,
  value: PropTypes.string
}

export default CalculationComponent
