import { Component } from 'react'

import { Route, Router, Switch } from 'react-router-dom'

import { withApollo } from '@apollo/client/react/hoc'
// NOTE: these mantine styles are needed for all mantine components. At time of
// implementation this is only the month-range-picker for the dashboard filter.
// But we have to include them here, otherwise they override our default
// bootstrap styles. This has to be BEFORE `import './app.scss'`.
import '@mantine/core/styles.css'
import '@mantine/dates/styles.css'

import moment from 'moment'

import { heartbeat } from './app.graphql'

import routes from '../../config/router.js'
import { TokenAuth } from '../../helpers/token-auth'
import { de } from '../../locale/locale'
import ProtectedComponent from '../views/login/protected-component'
import ApplicationLayout from './applicationLayout'
import { ServiceworkerProvider } from './serviceworker_provider'

import './app.scss'

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      online: false,
      locale: de,
      loggedIn: this.isLoggedIn(),
      offlineMode: true
    }
  }

  isLoggedIn = () => TokenAuth.getUser() !== null
  setLoggedIn = () => {
    if (TokenAuth.getUser() !== null) {
      this.setState({ loggedIn: true })
    } else {
      TokenAuth.logout()
      this.setState({ loggedIn: false })
    }
  }

  UNSAFE_componentWillMount = () => {
    document.title = __PAGE_TITLE__
    this.setLoggedIn()
    this.checkOnlineStatus()
  }

  checkOnlineStatus = () =>
    navigator.onLine
      ? this.props.client
          .query({
            query: heartbeat,
            fetchPolicy: 'network-only'
          })
          .then(() => this.updateOffline({ online: true, offlineMode: false }))
          .catch(({ networkError }) => {
            if (networkError && networkError.statusCode === 200) {
              this.updateOffline({ online: false, offlineMode: false })
            }
          })
      : this.updateOffline({ online: false, offlineMode: true })

  updateOffline = ({ online, offlineMode }) => {
    if (online != this.state.online || offlineMode != this.state.offlineMode) {
      this.setState({ online, offlineMode })
      console.log('updated offline state')
    }
  }

  render = () => {
    window.addEventListener('online', this.checkOnlineStatus)
    window.addEventListener('offline', this.checkOnlineStatus)

    moment.locale(this.state.locale.code)

    return (
      <ServiceworkerProvider>
        <Router history={this.props.history}>
          <Switch>
            {routes.map((route, i) => (
              <RouteWithSubRoutes
                key={i}
                {...route}
                locale={this.state.locale}
                offlineMode={this.state.offlineMode || (route.path && route.path.includes('offline'))}
                online={this.state.online}
              />
            ))}
          </Switch>
        </Router>
      </ServiceworkerProvider>
    )
  }
}

const RouteWithSubRoutes = route => (
  <Route
    path={route.path}
    render={props => {
      props = {
        ...props,
        routes: route.routes,
        locale: route.locale,
        online: route.online,
        offlineMode: route.offlineMode
      }
      window.routeHistory = props.history

      const getContent = _ => {
        if (route.protected === null || route.protected === false || !route.online) {
          return <route.component {...props} />
        } else {
          return (
            <ProtectedComponent {...props}>
              <route.component {...props} />
            </ProtectedComponent>
          )
        }
      }

      if (route.applicationLayout === false) {
        return getContent()
      }

      return <ApplicationLayout online={props.online}>{getContent()}</ApplicationLayout>
    }}
  />
)

export default withApollo(App)
