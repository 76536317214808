import { useEffect, useState } from 'react'

import { Link } from 'react-router-dom'
import { Alert, Badge, Col, ListGroup, ListGroupItem } from 'reactstrap'

import { useQuery } from '@apollo/client'
import { faCube, faCubes, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Loading from 'components/helper/loading/loading'
import { ProcessingButton } from 'components/modules/buttons/processing-button'
import FacilityNameWithBundleInfo from 'components/views/facilities/helper/facility_name_with_bundle_info'
import capitalize from 'helpers/capitalize'
import { withLocale } from 'locale'

import { getBundledByFacilities, getBundledFacilities } from './bundle_information.graphql'

const List = ({ type, uuid, locale, setCount, actions }) => {
  const query = type === 'bundledFacilities' ? getBundledFacilities : getBundledByFacilities

  const { data, loading, fetchMore, error } = useQuery(query, {
    variables: { uuid },
    notifyOnNetworkStatusChange: true
  })

  useEffect(() => {
    setCount(data?.facility?.bundleInformation?.[type]?.totalCount)
  }, [data?.facility?.bundleInformation?.[type]?.totalCount])

  if (loading && !data?.facility?.bundleInformation?.[type]?.edges?.length > 0) return <Loading />
  if (error) return `Errors! ${error}`

  const { edges, pageInfo } = data.facility.bundleInformation[type]

  if (edges.length === 0)
    return <Alert color="primary">{locale.facility.bundleInformation[`no${capitalize(type)}`]}</Alert>

  const facilities = edges.map(edge => edge.node)

  const loadMore = _ => {
    if (pageInfo.hasNextPage) {
      fetchMore({
        variables: {
          uuid,
          cursor: pageInfo.endCursor
        }
      })
    }
  }

  return (
    <>
      <ListGroup>
        {facilities.map(facility => (
          <ListGroupItem key={facility.uuid} className="d-flex justify-content-between align-items-center">
            <Link to={`/facility/${facility.uuid}`}>
              <Badge color="light" className="mr-2 align-middle">
                {facility.certification_data.audit_order_number}
              </Badge>
              <FacilityNameWithBundleInfo
                name={facility.base_data.name.de}
                firstBundledFacility={
                  facility.bundleInformation.bundledFacilities.nodes.slice(0, 1).map(bundledFacility => ({
                    name: bundledFacility.base_data.name.de,
                    audit_order_number: bundledFacility.certification_data.audit_order_number
                  }))[0]
                }
                totalBundledFacilities={facility.bundleInformation.bundledFacilities.totalCount}
                firstBundledByFacility={
                  facility.bundleInformation.bundledBy.nodes.slice(0, 1).map(bundledByFacility => ({
                    name: bundledByFacility.base_data.name.de,
                    audit_order_number: bundledByFacility.certification_data.audit_order_number
                  }))[0]
                }
                totalBundledByFacilities={facility.bundleInformation.bundledBy.totalCount}
              />
            </Link>
            {actions && actions(facility)}
          </ListGroupItem>
        ))}
      </ListGroup>
      {pageInfo.hasNextPage && (
        <ProcessingButton
          label={locale.show_more}
          icon={faPlus}
          processing={loading}
          processing_label={locale.show_more}
          onClick={loadMore}
          block
          className="mt-3"
          color="light"
        />
      )}
    </>
  )
}

const FacilityCollection = ({ type, uuid, locale, actions, ...props }) => {
  const [count, setCount] = useState(null)

  return (
    <Col {...props}>
      <h3 className="mt-0">
        {locale.facility.bundleInformation[type]}
        {count !== null && count > 0 && (
          <Badge color="primary" className="ml-3 mb-2">
            <FontAwesomeIcon icon={type === 'bundledBy' ? faCube : faCubes} className="mr-2" />
            {count}
          </Badge>
        )}
      </h3>
      <List {...{ type, uuid, locale, setCount, actions }} />
    </Col>
  )
}

export default withLocale(FacilityCollection)
