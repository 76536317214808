import React from 'react'

import { Alert, Button, Col, Container, FormGroup, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'

import { Query } from '@apollo/client/react/components'
import { withApollo } from '@apollo/client/react/hoc'
import { faPlus, faSave } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { deepNestInObjectsWithKeys } from 'helpers/object'
import update from 'immutability-helper'

import { getFacility, getFacilitySectionData } from '../facility.graphql'
import { createSectionDatum, newSectionDatum } from './sections.graphql'

import generateUUID from '../../../../helpers/guid'
import Loading from '../../../helper/loading/loading'
import ValidatedInput from '../../../modules/inputs/validated-input'
import DynamicSelect from '../../../modules/selects/dynamicSelect'

class CreateModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      modal: false,
      processing: false,
      variables: {
        section_datum: {
          uuid: generateUUID(),
          facility_uuid: this.props.facility_uuid,
          name_de: '',
          name_en: '',
          comment: '',
          section_id: '',
          alternative_section_datum_uuids: ''
        }
      }
    }
  }

  toggle = event => {
    event.preventDefault()
    this.setState({ modal: !this.state.modal, errors: {}, error_msg: '' })
  }

  clean = () =>
    this.setState({
      modal: false,
      processing: false,
      error_msg: '',
      variables: {
        section_datum: {
          uuid: generateUUID(),
          facility_uuid: this.props.facility_uuid,
          name_de: '',
          name_en: '',
          comment: '',
          section_id: '',
          alternative_section_datum_uuids: ''
        }
      }
    })

  save = () => {
    const { variables } = this.state
    this.setState({ processing: true })
    this.props.client
      .mutate({
        mutation: createSectionDatum,
        variables: {
          section_datum: {
            uuid: generateUUID(),
            facility_uuid: variables.section_datum.facility_uuid,
            name_de: variables.section_datum.name_de,
            name_en: variables.section_datum.name_en,
            section_id: variables.section_datum.section_id,
            alternative_section_datum_uuids: variables.section_datum.alternative_section_datum_uuids.split(',')
          }
        },
        refetchQueries: [
          {
            query: getFacilitySectionData,
            variables: {
              uuid: this.props.facility_uuid,
              version_number: this.props.match.params.version_number || ''
            }
          },
          {
            query: newSectionDatum,
            variables: { facility_uuid: this.props.facility_uuid },
            networkPolicy: 'network-only'
          },
          {
            query: getFacility,
            variables: { uuid: this.props.facility_uuid, version_number: this.props.match.params.version_number || '' },
            networkPolicy: 'network-only'
          }
        ],

        awaitRefetchQueries: true
      })
      .then(() => this.clean())
      .catch(this.onError)
  }

  onError = ({ graphQLErrors }) =>
    this.setState({
      processing: false,
      error_msg: graphQLErrors[0].message,
      errors: graphQLErrors[0].errors
    })

  changeAlternatives = event => {
    this.setState(
      update(this.state, {
        variables: {
          section_datum: {
            alternative_section_datum_uuids: {
              $set: event
            }
          }
        }
      })
    )
  }

  onChange = event => {
    const fragments = event?.target?.id?.split('.')
    const variables = deepNestInObjectsWithKeys(event.target.value, ...fragments, '$set')

    this.setState(update(this.state, { variables }))
  }

  render = () => {
    const {
      state: { variables, errors, processing, modal, className, error_msg },
      props: { locale, facility_uuid, new_section_datum },
      onChange,
      toggle,
      save,
      changeAlternatives
    } = this

    return (
      <React.Fragment>
        <Button id="addSection" className="px-4" outline color={'primary-light'} onClick={this.toggle}>
          <FontAwesomeIcon className="icon-prepend" icon={faPlus} />
          <span>{locale.add_section}</span>
        </Button>
        <Modal size={'lg'} centered isOpen={modal} toggle={toggle} className={className}>
          <ModalHeader>{locale.add_section}</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Container>
                {error_msg && <Alert color="danger">{error_msg}</Alert>}

                <Row>
                  <ValidatedInput
                    label_sm={4}
                    input_sm={7}
                    id="section_datum.name_de"
                    errors={errors}
                    onChange={onChange}
                    locale={locale}
                    variables={variables}
                  />
                </Row>
                <Row>
                  <ValidatedInput
                    label_sm={4}
                    input_sm={7}
                    id="section_datum.name_en"
                    errors={errors}
                    locale={locale}
                    onChange={onChange}
                    variables={variables}
                  />
                </Row>
                <Row>
                  <Col sm="4" className="text-right">
                    <Label className="control-label" for="default_section">
                      Standard-Teilbereich
                    </Label>
                  </Col>
                  <Col sm="7">
                    <DynamicSelect
                      id="section_datum.section_id"
                      errors={errors}
                      locale={locale}
                      onChange={onChange}
                      variables={variables}
                      options={new_section_datum.possible_sections.map(possible_section => {
                        return {
                          value: possible_section.id,
                          label: possible_section.name_de,
                          target: {
                            id: 'section_datum.section_id',
                            value: possible_section.id
                          }
                        }
                      })}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="4" className="text-right">
                    <Label className="control-label" for="alternative_sections">
                      Alternative Teilbereiche
                    </Label>
                  </Col>
                  <Col sm="7">
                    <DynamicSelect
                      isMulti={true}
                      locale={locale}
                      simpleValue
                      id="section_datum.alternative_section_datum_uuids"
                      errors={errors}
                      onChange={changeAlternatives}
                      variables={variables}
                      options={new_section_datum.possible_alternative_section_data.map(
                        possible_alternative_section_datum => ({
                          value: possible_alternative_section_datum.uuid,
                          label: possible_alternative_section_datum.name_de,
                          target: {
                            id: 'section_datum.alternative_section_datum_uuids',
                            value: possible_alternative_section_datum.uuid
                          }
                        })
                      )}
                    />
                  </Col>
                </Row>
              </Container>
            </FormGroup>
            {processing ? <Loading loadingText={`wird erstellt...`} size={`1x`} /> : null}
            <FormGroup className="form-action">
              <Container>
                <Row>
                  <Col sm="5">
                    <a className="link-btn" onClick={toggle} href="#">
                      {locale.cancel}
                    </a>
                  </Col>
                  <Col sm="7" className="text-right">
                    <Button className="btn-labeled" color="primary-light" onClick={save}>
                      <FontAwesomeIcon className="icon-prepend" icon={faSave} />
                      <span>{locale.save}</span>
                    </Button>
                  </Col>
                </Row>
              </Container>
            </FormGroup>
          </ModalBody>
        </Modal>
      </React.Fragment>
    )
  }
}

const Create = ({ locale, facility_uuid, client, match }) => (
  <Query query={newSectionDatum} variables={{ facility_uuid }} fetchPolicy="network-only">
    {({ loading, error, data }) => {
      if (loading)
        return (
          <Button className="px-4" outline color={'primary-light'}>
            <FontAwesomeIcon className="icon-prepend" icon={faPlus} />
            <span>{locale.add_section}</span>
          </Button>
        )
      if (error) return <p>Error</p>
      const { new_section_datum } = data

      return (
        <CreateModal
          locale={locale}
          client={client}
          facility_uuid={facility_uuid}
          new_section_datum={new_section_datum}
          match={match}
        />
      )
    }}
  </Query>
)

export default withApollo(Create)
