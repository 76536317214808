import { useEffect, useState } from 'react'

import { Alert, Fade } from 'reactstrap'

import { useQuery } from '@apollo/client'

import CursorBasedPagination from 'components/ui/genericComponents/cursorBasedPagination/cursorBasedPagination'
import { omit } from 'lodash'
import PropTypes from 'prop-types'

import { getFilteredAndPagedFacilities } from './listFacilities.graphql'

import FacilitiesTable from './facilitiesTable'
import LoadingOverlay from './loadingOverlay'

import './filteredFacilitiesFromApi.scss'

// TODO: we currently use this component as entrypoint, but the namespace is "facilitiesTable".
// We should probably refactor the naming and imports or restructure the components to have more integration character
const FilteredFacilitiesFromApi = ({
  perPage,
  sortBy,
  sortDirection,
  queryString,
  licenseOwnerIds,
  collectorIds,
  regionIds,
  trainingStatus,
  certificationEndDateRange,
  workflowStates,
  certificationTypes
}) => {
  // caching the query variables to avoid unnecessary re-renders
  const [queryVariables, setQueryVariables] = useState({
    first: perPage,
    sortBy,
    sortDirection,
    queryString: queryString.length > 0 ? queryString : null,
    licenseOwnerIds: licenseOwnerIds.length > 0 ? licenseOwnerIds : null,
    collectorIds: collectorIds.length > 0 ? collectorIds : null,
    regionIds: regionIds.length > 0 ? regionIds : null,
    trainingStatus,
    certificationEndDateRange,
    workflowStates: workflowStates.length > 0 ? workflowStates : null,
    certificationTypes: certificationTypes.length > 0 ? certificationTypes : null
  })

  useEffect(() => {
    setQueryVariables({
      first: perPage,
      sortBy,
      sortDirection,
      queryString: queryString.length > 0 ? queryString : null,
      licenseOwnerIds: licenseOwnerIds.length > 0 ? licenseOwnerIds : null,
      collectorIds: collectorIds.length > 0 ? collectorIds : null,
      regionIds: regionIds.length > 0 ? regionIds : null,
      trainingStatus,
      certificationEndDateRange,
      workflowStates: workflowStates.length > 0 ? workflowStates : null,
      certificationTypes: certificationTypes.length > 0 ? certificationTypes : null
    })
  }, [
    sortBy,
    sortDirection,
    queryString,
    licenseOwnerIds,
    collectorIds,
    regionIds,
    trainingStatus,
    certificationEndDateRange?.start,
    certificationEndDateRange?.end,
    workflowStates,
    certificationTypes,
    perPage
  ])

  const { loading, error, data, previousData } = useQuery(getFilteredAndPagedFacilities, {
    variables: queryVariables,
    fetchPolicy: 'network-only'
  })

  if (error) return <Alert color="danger">{`${error}`}</Alert>

  const connectionResult = loading ? previousData?.facilities : data.facilities

  if (!connectionResult) return <LoadingOverlay loading={true} />

  const paginationProps = {
    pageInfo: connectionResult.pageInfo,
    totalCount: connectionResult.totalCount,
    perPage,
    getItemsFromCursor: newPagingVariables =>
      setQueryVariables({ ...omit(queryVariables, 'first', 'last', 'before', 'after'), ...newPagingVariables })
  }

  return (
    <div className="facilitiesTable__ResultContainer">
      <LoadingOverlay loading={loading} />

      <Fade in={!loading} timeout={50}>
        <CursorBasedPagination {...paginationProps} />
        <FacilitiesTable facilities={connectionResult.nodes} />
        <CursorBasedPagination {...paginationProps} />
      </Fade>
    </div>
  )
}

FilteredFacilitiesFromApi.propTypes = {
  perPage: PropTypes.number.isRequired,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.string,
  queryString: PropTypes.string,
  licenseOwnerIds: PropTypes.arrayOf(PropTypes.string),
  collectorIds: PropTypes.arrayOf(PropTypes.string),
  regionIds: PropTypes.arrayOf(PropTypes.string),
  trainingStatus: PropTypes.string,
  certificationEndDateRange: PropTypes.shape({
    start: PropTypes.string.isRequired,
    end: PropTypes.string.isRequired
  }),
  workflowStates: PropTypes.arrayOf(PropTypes.string),
  certificationTypes: PropTypes.arrayOf(PropTypes.string)
}

export default FilteredFacilitiesFromApi
