import React, { Fragment, createRef } from 'react'

import { components } from 'react-select'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledTooltip } from 'reactstrap'

import { withApollo } from '@apollo/client/react/hoc'
import { faSave, faUserMinus, faUserTag } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { LegacySelect as Select } from 'components/modules/selects/react-select'
import update from 'immutability-helper'
import { withLocale } from 'locale/index'

import { getFacility } from '../facility.graphql'
import {
  changeFacilityCollector,
  changeFacilityContactUser,
  changeFacilityDsftUser,
  changeFacilityLicenseOwnerUser,
  changeFacilitySecondaryCollector
} from './contact-person-certification.graphql'

import { ProcessingButton } from '../../../modules/buttons/processing-button'

const Option = props => (
  <components.Option {...props}>
    <Fragment>
      {props.data.first_name} {props.data.last_name} <small className="d-block">{props.data.email}</small>
    </Fragment>
  </components.Option>
)

class Update extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      processing: false,
      modal: false,
      variables: {
        uuid: this.props.uuid,
        [this.props.id]: this.props?.user?.id || null
      }
    }
    this.linkButtonRef = createRef()
    this.unlinkButtonRef = createRef()
  }

  toggle = () => this.setState({ modal: !this.state.modal, processing: false })

  submit = () => {
    return this.setState({ processing: true }, _ => {
      let mutation
      switch (this.props.id) {
        case 'contact_user_id':
          mutation = changeFacilityContactUser
          break
        case 'collector_id':
          mutation = changeFacilityCollector
          break
        case 'secondary_collector_id':
          mutation = changeFacilitySecondaryCollector
          break
        case 'dsft_user_id':
          mutation = changeFacilityDsftUser
          break
        case 'license_owner_user_id':
          mutation = changeFacilityLicenseOwnerUser
          break
        default:
          break
      }

      return this.props.client
        .mutate({
          mutation,
          variables: this.state.variables,
          refetchQueries: [
            {
              query: getFacility,
              variables: {
                uuid: this.state.variables.uuid,
                version_number: this.props.match.params.version_number || ''
              }
            }
          ],
          awaitRefetchQueries: true
        })
        .then(_ => this.setState({ modal: false, processing: false }))
    })
  }

  unlink = () => {
    this.onChange(null).then(_ => this.submit())
  }

  onChange = id =>
    new Promise(resolve =>
      this.setState(
        update(this.state, {
          variables: {
            [this.props.id]: {
              $set: id
            }
          }
        }),
        resolve
      )
    )

  filterOption = ({ data: { first_name, last_name, email } }, filterString) =>
    `${first_name} ${last_name}`.toLowerCase().includes(filterString.toLowerCase()) ||
    email.toLowerCase().includes(filterString.toLowerCase())

  render() {
    const {
      linkButtonRef,
      unlinkButtonRef,
      props: { locale, headline, user, options = [], unlinkingAllowed = false },
      onChange,
      submit,
      filterOption,
      unlink
    } = this

    const currentValue = this.state.variables[this.props.id]

    return (
      <Fragment>
        {unlinkingAllowed && user && (
          <>
            <Button
              onClick={unlink}
              color="primary"
              size="sm"
              innerRef={unlinkButtonRef}
              className="mr-1"
              disabled={this.state.processing}
            >
              <FontAwesomeIcon icon={faUserMinus} /> {locale.facility_contacts.actions.unlink.label}
            </Button>
            <UncontrolledTooltip placement="top" target={unlinkButtonRef} boundariesElement="viewport">
              {locale.facility_contacts.actions.unlink.tooltip}
            </UncontrolledTooltip>
          </>
        )}
        <Button onClick={this.toggle} color="primary" size="sm" innerRef={linkButtonRef}>
          <FontAwesomeIcon icon={faUserTag} /> {locale.facility_contacts.actions.select.label}
        </Button>
        <UncontrolledTooltip placement="top" target={linkButtonRef} boundariesElement="viewport">
          {locale.facility_contacts.actions.select.tooltip}
        </UncontrolledTooltip>
        <Modal size={'sm'} centered isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader>{headline} bearbeiten</ModalHeader>
          <ModalBody>
            <Select
              value={currentValue}
              onChange={onChange}
              placeholder={'auswählen...'}
              simpleValue
              isClearable={false}
              noOptionsMessage={_ => 'Keine Benutzer gefunden.'}
              filterOption={filterOption}
              components={{ Option }}
              options={options.map(option => ({
                ...option,
                label: `${option.first_name} ${option.last_name}`,
                value: option.id
              }))}
            />
          </ModalBody>
          <ModalFooter>
            <ProcessingButton processing={this.state.processing} onClick={submit} label={'Speichern'} icon={faSave} />
          </ModalFooter>
        </Modal>
      </Fragment>
    )
  }
}

export default withLocale(withApollo(Update))
