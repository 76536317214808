// GraphQL
import React from 'react'

import { Button, ButtonGroup, Col, FormFeedback, Input, InputGroup, Label, Row } from 'reactstrap'

import { withApollo } from '@apollo/client/react/hoc'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import FieldDatum from '../field_datum'
import { HelpTooltip } from '../help-tooltip'
import Requirements from './Requirements'

const Checkbox = ({
  requirements_with_status,
  depth,
  help_text,
  help_image,
  field_number,
  uuid,
  changeFieldValue,
  parent_field_id,
  name,
  enabled,
  processing,
  value_de,
  field_id,
  embedded_form_datum,
  linked_forms,
  embedded,
  i,
  errors
}) => (
  <React.Fragment>
    <Row
      className={
        depth > 0
          ? `mt-0 mb-0${enabled ? ' ' : ' hidden '}parent-${parent_field_id || 'none'}`
          : `${enabled ? '' : 'hidden '}parent-${parent_field_id || 'none'}`
      }
    >
      <Col
        sm={{ size: 10 - depth, offset: depth }}
        lg={{ size: 6, offset: depth }}
        className={depth > 0 ? 'pt-3 pb-3 pl-4 border-left border-secondary' : 'pl-4'}
      >
        <Label
          className={`control-label align-items-center justify-content-start ${errors?.value_de ? 'text-danger' : ''}`}
          for={uuid}
        >
          {field_number && <span className="text-primary-light">{field_number}&nbsp;&mdash;&nbsp;</span>}
          {name}
          <HelpTooltip help_text={help_text} help_image={help_image} uuid={uuid} />
        </Label>
        <Requirements requirements_with_status={requirements_with_status} />
      </Col>
      <Col sm={2} className={depth > 0 ? 'pt-3 pb-3' : ''}>
        <InputGroup className="align-items-center justify-content-sm-start">
          <ButtonGroup className="full-width">
            <Button
              disabled={processing}
              color="white"
              onClick={() => changeFieldValue({ value_de: '1', uuid, field_id, embedded, i })}
              active={value_de === '1'}
            >
              Ja
            </Button>{' '}
            <Button
              disabled={processing}
              color="white"
              onClick={() => changeFieldValue({ value_de: '0', uuid, field_id, embedded, i })}
              active={value_de === '0'}
            >
              Nein
            </Button>{' '}
          </ButtonGroup>
        </InputGroup>
      </Col>
      {errors?.value_de && (
        <Col sm={{ size: 2, offset: 10 }} lg={{ size: 2, offset: 6 + depth }}>
          <FormFeedback valid={false} className="d-block">
            {errors?.value_de}
          </FormFeedback>
        </Col>
      )}
    </Row>
    {value_de === '1' && embedded_form_datum && (
      <div className="box">
        <h3>Eingebettetes Formular: {embedded_form_datum.form_name}</h3>
        {embedded_form_datum.field_data_flat.map((props, x) => {
          props = {
            ...props,
            changeFieldValue,
            embedded: true,
            i,
            errors: errors?.embedded_form_datum?.field_data?.[x]
          }

          return <FieldDatum key={x} {...props} />
        })}
      </div>
    )}
    {value_de === '1' && linked_forms.length > 0 && (
      <div className="box">
        <h3>Bitte wählen Sie die zugehörigen Formulare aus.</h3>
        {linked_forms?.map((linked_form, i) => (
          <Row key={i} className={depth > 0 ? 'mt-0 mb-0' : ''}>
            <Col
              sm={{ size: 12 - depth, offset: depth }}
              lg={{ size: 3, offset: depth }}
              className={depth > 0 ? 'pt-3 pb-3-lg pl-4 border-left border-secondary' : 'pl-4'}
            ></Col>
            <Col
              sm={{ size: 6, offset: depth }}
              lg={{ size: 4, offset: 0 }}
              className={depth > 0 ? 'pb-3 pt-3-lg pl-4 border-left-md-only border-secondary' : ''}
            >
              <React.Fragment>
                <strong>
                  {linked_form?.form?.sheet_number} {linked_form?.form?.name_de}
                </strong>
                {linked_form?.linked_form_data?.length > 0 ? (
                  linked_form?.linked_form_data?.map((linked_form_datum, j) => (
                    <Label
                      key={j}
                      className="control-label checkbox-label justify-content-start"
                      for={linked_form_datum?.form_datum?.uuid}
                    >
                      <Input
                        className="checkbox-control"
                        type="checkbox"
                        id={linked_form_datum?.form_datum?.uuid}
                        defaultChecked={linked_form_datum?.linked}
                        onChange={e =>
                          changeFieldValue({
                            type: 'linked_form',
                            value_de: linked_form_datum?.form_datum?.uuid,
                            uuid,
                            field_id,
                            i,
                            j
                          })
                        }
                      />
                      <span className="check-icon-container">
                        <span className="check-icon text-primary-dark">
                          <FontAwesomeIcon icon={faCheck} />
                        </span>
                      </span>
                      <p>{linked_form_datum?.form_datum?.name_de}</p>
                    </Label>
                  ))
                ) : (
                  <p>
                    Es gibt an dieser Einrichtung noch kein Formular "{linked_form?.form?.sheet_number}{' '}
                    {linked_form?.form?.name_de}"
                  </p>
                )}
              </React.Fragment>
            </Col>
          </Row>
        ))}
      </div>
    )}
  </React.Fragment>
)

export default withApollo(Checkbox)
