import migrate_2_3 from './migrate_2_3'
import migrate_3_4 from './migrate_3_4'
import migrate_4_20200110134640 from './migrate_4_20200110134640'
import migrate_20200110134640_20200306170946 from './migrate_20200110134640_20200306170946'
import migrate_20200306170946_20200506103821 from './migrate_20200306170946_20200506103821'
import migrate_20200506103821_20200720163715 from './migrate_20200506103821_20200720163715'
import migrate_20200506103821_20200826143945 from './migrate_20200506103821_20200826143945'
import migrate_20211012145711_addBundleInformation from './migrate_20211012145711_addBundleInformation'
import migrate_20230526081233_addMetaDescriptionToBaseData from './migrate_20230526081233_addMetaDescriptionToBaseData'
import migrate_20240716150345_addFacilitySizeAndVolumeUnitInformation from './migrate_20240716150345_addFacilitySizeAndVolumeUnitInformation'

export default {
  migrate_2_3,
  migrate_3_4,
  migrate_4_20200110134640,
  migrate_20200110134640_20200306170946,
  migrate_20200306170946_20200506103821,
  migrate_20200506103821_20200720163715,
  migrate_20200506103821_20200826143945,
  migrate_20211012145711_addBundleInformation,
  migrate_20230526081233_addMetaDescriptionToBaseData,
  migrate_20240716150345_addFacilitySizeAndVolumeUnitInformation
}
