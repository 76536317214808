import gql from 'graphql-tag'

export const salutationsQuery = gql`
  query salutations_query {
    possible_salutations {
      key
      label
    }
  }
`
