// additional components
import React from 'react'

import { Badge, Col, Input, Row, Table, UncontrolledTooltip } from 'reactstrap'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons'

import { getPartnerGatewayUsers } from './partner_gateway_users.graphql'

import Loading from '../../../helper/loading/loading.jsx'
import Modal from '../../../modules/modal/modal.jsx'
import Create from './create.jsx'
import Delete from './delete.jsx'
import Update from './update.jsx'

const PartnerGatewayUsers = ({ data: { error, loading, partner_gateway_users }, locale, client, history }) =>
  loading ? (
    <Loading />
  ) : (
    <PartnerGatewayUserList
      partner_gateway_users={partner_gateway_users}
      locale={locale}
      client={client}
      history={history}
    />
  )

class PartnerGatewayUserList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      search_query: ''
    }
  }

  search = () => {
    const partner_gateway_users = this.props.partner_gateway_users
    const result = []
    if (this.state.search_query.length !== 0) {
      for (let i = 0; i < partner_gateway_users.length; i++) {
        if (
          partner_gateway_users[i].name &&
          partner_gateway_users[i].name.toLowerCase().includes(this.state.search_query.toLowerCase())
        ) {
          result.push(partner_gateway_users[i])
          continue
        }
        if (
          partner_gateway_users[i].email &&
          partner_gateway_users[i].email.toLowerCase().includes(this.state.search_query.toLowerCase())
        ) {
          result.push(partner_gateway_users[i])
        }
      }
      return result
    }
    return partner_gateway_users
  }

  change = event => this.setState({ [event.target.id]: event.target.value })

  render = () => {
    const { locale, client, history } = this.props
    const { search_query } = this.state
    const partner_gateway_users = this.search()

    return (
      <div className="page-wrapper">
        <Row className="page-header">
          <Col sm="6">
            <h1>{locale.partner_gateway_user.plural}</h1>
          </Col>
          <Col sm="3" className="text-right">
            <Input
              id="search_query"
              placeholder={locale.partner_gateway_user.search_placeholder}
              value={search_query}
              onChange={this.change}
            />
          </Col>
          <Col sm="3" className="text-right">
            <Modal
              children={<Create locale={locale} client={client} />}
              toggleModalText={locale.partner_gateway_user.create}
              button_type={{ icon_only: false, icon_type: faPlus, color: 'primary-light', classes: 'btn-labeled' }}
              modal_size={'lg'}
            />
          </Col>
        </Row>
        <hr className="seperator" />
        <Table striped responsive bordered>
          <thead className="thead-light">
            <tr>
              <th>{locale.partner_gateway_user.name}</th>
              <th>{locale.partner_gateway_user.email}</th>
              <th>{locale.organizations}</th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {partner_gateway_users.map((partner_gateway_user, i) => (
              <PartnerGatewayUserListItem key={i} partner_gateway_user={partner_gateway_user} locale={locale} />
            ))}
          </tbody>
        </Table>
      </div>
    )
  }
}

const PartnerGatewayUserListItem = ({ partner_gateway_user, locale, client }) => (
  <tr>
    <td>
      <Update partner_gateway_user={partner_gateway_user} locale={locale} />
    </td>
    <td>{partner_gateway_user.email}</td>
    <td>
      {partner_gateway_user.organizations.map(({ short_name, id, name }) => (
        <>
          <UncontrolledTooltip placement="top" target={`organization_badge_${id}`}>
            {name}
          </UncontrolledTooltip>
          <Badge key={id} color="primary" id={`organization_badge_${id}`} className="mr-2">
            {short_name}
          </Badge>
        </>
      ))}
    </td>
    <td>
      <Modal
        toggleModalText={`${locale.partner_gateway_user.delete}`}
        children={<Delete partner_gateway_user={partner_gateway_user} locale={locale} />}
        button_type={{
          icon_only: true,
          icon_type: faTrashAlt,
          color: 'primary-light',
          classes: 'btn-transparent btn-icon-only text-danger'
        }}
        modal_size={'lg'}
      />
    </td>
  </tr>
)

export default withApollo(
  graphql(getPartnerGatewayUsers, {
    options: () => ({
      notifyOnNetworkStatusChange: true
    })
  })(PartnerGatewayUsers)
)
