import React from 'react'

import { graphql, withApollo } from '@apollo/client/react/hoc'

import { LegacySelect as Select } from 'components/modules/selects/react-select'

import { regionsQuery } from './graphql'

class Regions extends React.Component {
  regionsOptions = () => {
    const { regions } = this.props.data
    let options = []
    regions.map(region => options.push({ value: region.id, label: region.name_de }))
    return options
  }

  render = () => {
    const { error, loading } = this.props.data
    const { region_ids, changeRegions } = this.props

    if (loading)
      return (
        <Select
          isClearable={false}
          name="license_owner.region_ids"
          id="license_owner.region_ids"
          value={region_ids}
          onChange={changeRegions}
          options={[]}
        />
      )
    if (error) return <p>Error</p>
    return (
      <Select
        isClearable={false}
        isMulti
        simpleValue
        name="license_owner.region_ids"
        id="license_owner.region_ids"
        placeholder="auswählen..."
        value={region_ids}
        onChange={changeRegions}
        options={this.regionsOptions()}
      />
    )
  }
}

export default withApollo(
  graphql(regionsQuery, {
    options: () => ({
      notifyOnNetworkStatusChange: true
    })
  })(Regions)
)
