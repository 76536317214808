import { orderBy } from 'natural-orderby'

import * as migrations from './migrations'

/*
* This will update each individual entry in each store.
* Modify the entries depending on the store in the corresponding switch statements.
* Write individual migration functions for each new idb version.
* Create those functions in ./migrations/[storeName]/migrate_[storeName]_[oldVersion]_[newVersion].js

* You just have to add fields if your graphql changes. Outdated fields will just not be read by graphql.
* If you dont add new graphql fields to the corresponding objects in the database, graphql will throw an
* error when receiving offline data from the idb because of missing fields.
* Most of the times some kind of dummy data is enough for it to just not throw an error. You don't have to get data from the action GQL API.
* Leave the changes from older version in the migration, as some users might have an older idb version than the previous one.
*/

export const migrateStoreEntry = ({ store, key, index }) => oldValue => {
  let newValue = oldValue
  if (store.name in migrations) {
    const storeMigrations = migrations[store.name]
    // sorts migrations ascending so oldest ones get called first
    // only migrations between the old and new version get called
    // calls every migration consecutively with the result of the previous one or the old value if it's the first
    // migrations have to return the new object that should be stored
    let migrationsToRun = orderBy(Object.keys(storeMigrations)).filter(migrationName => {
      const migrationVersion = migrationVersionFromName(migrationName)
      return !store.existingMigrations.includes(`${store.name}_${migrationVersion}`)
    })
    migrationsToRun.forEach(migrationName => {
      console.info('[MIGRATION]', `running migration ${migrationName} for ${store.name}`)
      newValue = storeMigrations[migrationName](newValue, { index, key })
      store.migrationStore.put(new Date(), `${store.name}_${migrationVersionFromName(migrationName)}`)
    })
  }
  store.objectStore.put(newValue, key)
}

const migrationVersionFromName = name => parseInt(name.split('_')[1])
