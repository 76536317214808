import gql from 'graphql-tag'

export const query = gql`
  query users($id: ID!) {
    license_owner(id: $id) {
      id
      possible_default_users {
        full_name
        id
      }
    }
  }
`
