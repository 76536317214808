import { Badge, Table } from 'reactstrap'

import { useQuery } from '@apollo/client'
import { faCoins, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { DD_MM_YYYY__HH_mm } from 'components/helper/helper-functions'
import Loading from 'components/helper/loading/loading'
import { ProcessingButton } from 'components/modules/buttons/processing-button'
import { withLocale } from 'locale'

import { fetchVolumeUnitAccountTransactions } from './volumeUnitAccountTransactions.graphql'

import TransactionsTableHeader from './transactionsTable/transactionsTableHeader'

import './volumeUnitAccountManagement.scss'

const AccountingVolumeUnitsTransactionTable = ({ locale, generalLocale, accountId }) => {
  const { loading, error, data, fetchMore } = useQuery(fetchVolumeUnitAccountTransactions, {
    variables: { accountId },
    notifyOnNetworkStatusChange: true
  })

  if (loading && !data?.volumeUnitAccount?.transactions?.edges?.length > 0) return <Loading />
  if (error) return `Errors! ${error}`

  const account = data.volumeUnitAccount
  const pageInfo = account.transactions.pageInfo

  const loadMore = _ => {
    if (pageInfo.hasNextPage) {
      fetchMore({ variables: { accountId, cursor: pageInfo.endCursor } })
    }
  }

  return (
    <>
      <h3>
        {locale.history}
        <Badge className="ml-3 float-right">
          {account.transactions.totalCount}{' '}
          {account.transactions.totalCount === 1 ? locale.transaction.singular : locale.transaction.plural}
        </Badge>
      </h3>
      <hr className="seperator border-gray" />
      <TransactionsTableHeader balance={account.balance} exportBaseUrl={account.downloadExportUrl} />
      <Table bordered>
        <thead className="thead-light">
          <tr>
            <th>{locale.dateTime}</th>
            <th>{locale.transactionAmount}</th>
            <th>{locale.comment}</th>
            <th>{locale.creator}</th>
          </tr>
        </thead>
        <tbody>
          {account.transactions.edges.map(({ node: transaction }, index) => (
            <TransactionTableRow
              createdAt={transaction.createdAt}
              amount={transaction.amount}
              comment={transaction.comment}
              creatorName={transaction.creator.full_name}
              key={index}
            />
          ))}
        </tbody>
      </Table>
      {pageInfo.hasNextPage && (
        <ProcessingButton
          label={generalLocale.showMore}
          icon={faPlus}
          processing={loading}
          processing_label={locale.createTransaction.loadingMore}
          onClick={loadMore}
          block
          className="mt-1"
          color="light"
        />
      )}
    </>
  )
}

const TransactionTableRow = ({ createdAt, amount, comment, creatorName }) => (
  <tr>
    <td>{DD_MM_YYYY__HH_mm(createdAt)}</td>
    <td>
      <Badge color={amount < 0 ? 'danger' : 'success'} className="py-2 px-3">
        <FontAwesomeIcon icon={faCoins} className="mr-3" />
        {amount}
      </Badge>
    </td>
    <td
      dangerouslySetInnerHTML={{ __html: comment }}
      className="volumeUnitAccountManagement__transactionsTabe__commentWrapper"
    />
    <td>{creatorName}</td>
  </tr>
)

export default withLocale(
  withLocale(AccountingVolumeUnitsTransactionTable, { key: 'licenseOwners.volumeUnitAccount' }),
  { attributeName: 'generalLocale' }
)
