import React, { Component, Fragment } from 'react'

import { Link } from 'react-router-dom'
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
  UncontrolledTooltip
} from 'reactstrap'

import { Query } from '@apollo/client/react/components'
import { withApollo } from '@apollo/client/react/hoc'
import {
  faCheck,
  faCheckCircle,
  faLevelUpAlt,
  faPlus,
  faTimesCircle,
  faTrashAlt
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { deepNestInObjectsWithKeys } from 'helpers/object'
import update from 'immutability-helper'

import { createForm, deleteField, getForm, getNewForm, updateForm } from '../forms.graphql'

import generateUUID from '../../../../../helpers/guid'
import allergicSVG from '../../../../assets/guestgroup-icons/allergien-grau.svg'
import generationsSVG from '../../../../assets/guestgroup-icons/bequem-grau.svg'
import visualSVG from '../../../../assets/guestgroup-icons/blind-grau.svg'
import walkingSVG from '../../../../assets/guestgroup-icons/gehbehindert-grau.svg'
import deafSVG from '../../../../assets/guestgroup-icons/gehoerlos-grau.svg'
import mentalSVG from '../../../../assets/guestgroup-icons/kognitiv-grau.svg'
import wheelchairSVG from '../../../../assets/guestgroup-icons/rollstuhlfahrer-grau.svg'
import { getPropertyFromObject } from '../../../../helper/helper-functions'
import Loading from '../../../../helper/loading/loading.jsx'
import { ProcessingButton } from '../../../../modules/buttons/processing-button'
import ValidatedInput from '../../../../modules/inputs/validated-input'
import RadioButtons from '../../../../modules/radio-switch/radio-buttons'
import DynamicSelect from '../../../../modules/selects/dynamicSelect'

const UpdateForm = props => (
  <Query
    fetchPolicy="network-only"
    query={props.match.params.id ? getForm : getNewForm}
    variables={
      props.match.params.id ? { id: props.match.params.id } : { form_version_id: props.match.params.form_version_id }
    }
  >
    {({ loading, error, data }) => {
      if (loading || !data) return <Loading />
      if (error) return <p>Error! {error.message}</p>
      if (props.match.params.id) {
        return <Skeleton {...props} form_versions={data.form_versions} form={data.form} />
      } else {
        return <Skeleton {...props} form_versions={data.form_versions} form={data.new_form} create={true} />
      }
    }}
  </Query>
)

const Skeleton = props => (
  <div className="page-wrapper">
    <FormForm {...props} />
    {!props.create && (
      <Fragment>
        <Fields {...props} />
      </Fragment>
    )}
    {props.loading && <Loading />}
  </div>
)

class FormForm extends Component {
  constructor(props) {
    super(props)
    const { form } = this.props
    this.state = {
      error_msg: '',
      errors: {},
      processing: false,
      variables: {
        id: form.id || 'create',
        form: {
          name_de: form.name_de || '',
          name_en: form.name_en || '',
          in_use: form.in_use,
          form_version_id: form.form_version_id,
          sheet_number: form.sheet_number || '',
          order_no: form.order_no
        }
      }
    }
  }

  submit = () => {
    const {
      state: {
        variables,
        variables: { form }
      }
    } = this

    if (this.props.create) {
      this.setState({ processing: true })
      this.props.client
        .mutate({
          mutation: createForm,
          variables: { form }
        })
        .then(result => this.props.history.push(`/form-kit/form/${result.data.create_form.id}`))
        .catch(errors => this.onError(errors))
    } else {
      this.setState({ processing: true })
      this.props.client
        .mutate({
          mutation: updateForm,
          variables,
          refetchQueries: [{ query: getForm, variables: { id: variables.id } }],
          awaitRefetchQueries: true
        })
        .then(result => this.onSuccess(result))
        .catch(errors => this.onError(errors))
    }
  }

  onError = ({ graphQLErrors }) =>
    this.setState({
      processing: false,
      error_msg: graphQLErrors[0].message,
      errors: graphQLErrors[0].errors
    })

  onSuccess = result =>
    this.setState({
      errors: {},
      error_msg: '',
      processing: false
    })

  onChange = event => {
    const depth = event.target.id.split('.').length
    const fragments = event.target.id.split('.')
    const variables = deepNestInObjectsWithKeys(event.target.value, ...fragments, '$set')

    this.setState(update(this.state, { variables }))
  }

  render = () => {
    const {
      onChange,
      submit,
      props: { locale, form_versions, form, history },
      state: { variables, errors }
    } = this
    const props = { locale, variables, errors, onChange }

    return (
      <Fragment>
        <Row className="page-header">
          <Col sm="12">
            <h1>{this.props.create ? locale.form.create_label : locale.form.update_label}</h1>
          </Col>
        </Row>
        <hr className="seperator" />
        <div className="box">
          {this.state.error_msg ? <Alert color="danger">{this.state.error_msg}</Alert> : ''}
          <Form>
            <FormGroup>
              <Row>
                <ValidatedInput id="form.name_de" {...props} />
                <ValidatedInput id="form.name_en" {...props} />
              </Row>
              <Row>
                <ValidatedInput id="form.sheet_number" {...props} />
                <Col sm={2} className="text-right">
                  <Label className="control-label" for={'form.order_no'}>
                    {getPropertyFromObject(locale, 'form.order_no')}
                  </Label>
                </Col>
                <Col sm={4}>
                  <DynamicSelect
                    id="form.order_no"
                    {...props}
                    options={form?.possible_order_entries?.map(possible_order_entry => ({
                      value: parseInt(possible_order_entry.order_no, 10),
                      label: possible_order_entry.name,
                      target: {
                        id: 'form.order_no',
                        value: parseInt(possible_order_entry.order_no, 10)
                      }
                    }))}
                  />
                </Col>{' '}
              </Row>
              <Row>
                <Col sm={2} className="text-right">
                  <Label className="control-label" for={'form.in_use'}>
                    {getPropertyFromObject(locale, 'form.in_use')}
                  </Label>
                </Col>
                <Col sm={4}>
                  <RadioButtons id="form.in_use" {...props} />
                </Col>
                <Col sm={2} className="text-right">
                  <Label className="control-label" for={'form.form_version_id'}>
                    {getPropertyFromObject(locale, 'form.form_version_id')}
                  </Label>
                </Col>
                <Col sm={4}>
                  <DynamicSelect
                    id="form.form_version_id"
                    {...props}
                    options={form_versions?.map(form_version => ({
                      value: form_version.id,
                      label: form_version.name,
                      target: { id: 'form.form_version_id', value: form_version.id }
                    }))}
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup className="form-action">
              <Container>
                <Row>
                  <Col sm="5">
                    <a
                      className="link-btn"
                      href="#"
                      onClick={e => {
                        e.preventDefault()
                        history.push('/form-kit/forms')
                      }}
                    >
                      {locale.cancel}
                    </a>
                  </Col>
                  <Col sm="7" className="text-right">
                    <ProcessingButton
                      onClick={submit}
                      label={'speichern'}
                      no_icon={true}
                      processing={this.state.processing}
                    />
                  </Col>
                </Row>
              </Container>
            </FormGroup>
          </Form>
        </div>
      </Fragment>
    )
  }
}

const Fields = ({ form, loading, locale, create, history, match, client }) => (
  <Fragment>
    <Row className="page-header">
      <Col sm="9">
        <h1>{locale.field.plural}</h1>
      </Col>
      <Col sm="3" className="text-right">
        <Button
          disabled={create}
          className={'btn-labeled'}
          color={'primary-light'}
          onClick={() => history.push(`/form-kit/create/field/${match.params.id}`)}
          outline
        >
          <FontAwesomeIcon className={'icon-prepend'} icon={faPlus} />
          <span>{locale.field.create_label}</span>
        </Button>
      </Col>
    </Row>
    <hr className="seperator" />
    <Table size={'lg'} responsive striped bordered>
      <thead className="thead-light">
        <tr>
          <th>Name</th>
          <th>Fragenr.</th>
          {/*<th>Typ</th>*/}
          <th>
            Verlinkte
            <br />
            Formulare
          </th>
          <th>Anforderungen</th>
          <th>
            im
            <br />
            Bericht
          </th>
          <th>
            <GuestGroupIcon icon={wheelchairSVG} alt="Gehbehinderung und Rollstuhlfahrer" />
          </th>
          <th>
            <GuestGroupIcon icon={walkingSVG} alt="Gehbehinderung" />
          </th>
          <th>
            <GuestGroupIcon icon={visualSVG} alt="Sehbehinderung und Blinde" />
          </th>
          <th>
            <GuestGroupIcon icon={deafSVG} alt="Hörbehinderung und Gehörlose" />
          </th>
          <th>
            <GuestGroupIcon icon={mentalSVG} alt="Kognitive Beeinträchtigungen" />
          </th>
          <th>
            <GuestGroupIcon icon={allergicSVG} alt="Lebensmittelunverträglichkeiten" />
          </th>
          <th>
            <GuestGroupIcon icon={generationsSVG} alt="Für alle Generationen" />
          </th>
          <th> </th>
        </tr>
      </thead>
      <tbody>
        {!loading &&
          form?.fields?.map((field, i) => (
            <TableRow key={i} field={field} i={i} client={client} match={match} locale={locale} depth={0} />
          ))}
      </tbody>
    </Table>
  </Fragment>
)

const TableRow = ({ field, i, locale, client, match, depth }) => (
  <Fragment>
    <tr>
      <td>
        <Link to={`/form-kit/field/${field.id}`}>
          {String.fromCharCode(8195).repeat(depth)}
          {depth > 0 && <FontAwesomeIcon icon={faLevelUpAlt} rotation={90} />}{' '}
          {depth > 0 && String.fromCharCode(160).repeat(2)}
          {field.name}
        </Link>
      </td>
      <td className="text-center">{field.field_number || '-'}</td>
      <td>{field.linked_forms.filter(item => item.chosen).length || '-'}</td>
      <td>{field.requirements.length || '-'}</td>
      <td className="text-center">
        <CheckOrCrossIcon boolean={field.show_in_audit_report} />
      </td>
      <td className="text-center">
        <CheckOrCrossIcon boolean={field.relevant_for_guest_groups.wheelchair} />
      </td>
      <td className="text-center">
        <CheckOrCrossIcon boolean={field.relevant_for_guest_groups.walking} />
      </td>
      <td className="text-center">
        <CheckOrCrossIcon boolean={field.relevant_for_guest_groups.visual} />
      </td>
      <td className="text-center">
        <CheckOrCrossIcon boolean={field.relevant_for_guest_groups.deaf} />
      </td>
      <td className="text-center">
        <CheckOrCrossIcon boolean={field.relevant_for_guest_groups.mental} />
      </td>
      <td className="text-center">
        <CheckOrCrossIcon boolean={field.relevant_for_guest_groups.allergic} />
      </td>
      <td className="text-center">
        <CheckOrCrossIcon boolean={field.relevant_for_guest_groups.generations} />
      </td>
      <td className="text-center">
        <DeleteModal field={field} locale={locale} client={client} match={match} />
      </td>
    </tr>
    {field?.children?.map((field, i) => (
      <TableRow key={i} field={field} i={i} client={client} match={match} locale={locale} depth={depth + 1} />
    ))}
  </Fragment>
)

const CheckOrCrossIcon = ({ boolean }) =>
  boolean ? (
    <FontAwesomeIcon icon={faCheckCircle} color={'green'} />
  ) : (
    <FontAwesomeIcon icon={faTimesCircle} color={'orange'} />
  )
const GuestGroupIcon = ({ icon, alt }) => {
  const uuid = generateUUID()
  return (
    <Fragment>
      <img id={`tooltip_${uuid}`} height={40} alt={`Gästegruppe: ${alt}`} src={icon} />
      <UncontrolledTooltip delay={400} target={`tooltip_${uuid}`}>
        {alt}
      </UncontrolledTooltip>
    </Fragment>
  )
}

class DeleteModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
      processing: false,
      tooltip_uuid: generateUUID(),
      force: false
    }
  }

  toggle = event => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    this.setState({
      processing: false,
      force: false,
      modal: !this.state.modal
    })
  }

  submit = () => {
    this.setState({ processing: true })
    this.props.client
      .mutate({
        mutation: deleteField,
        variables: {
          id: this.props.field.id,
          force: this.state.force
        },
        refetchQueries: [{ query: getForm, variables: { id: this.props.match.params.id } }],
        awaitRefetchQueries: true
      })
      .then(() => this.toggle())
  }

  render() {
    const { className, locale, field } = this.props
    const { modal, processing, tooltip_uuid } = this.state
    const { submit, toggle } = this

    return (
      <React.Fragment>
        <Button
          size={`sm`}
          className="btn-transparent btn-icon-only btn text-danger"
          color={'link'}
          onClick={toggle}
          id={`tooltip-modal_${tooltip_uuid}`}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </Button>
        <UncontrolledTooltip delay={500} placement="top" target={`tooltip-modal_${tooltip_uuid}`}>
          {locale.delete}
        </UncontrolledTooltip>
        <Modal centered isOpen={modal} toggle={toggle} className={className} size={'lg'}>
          <ModalHeader>Formular löschen</ModalHeader>
          <ModalBody>
            <p className="mt-3">
              Möchten Sie das Feld <strong className="text-danger">{field.name}</strong> wirklich löschen?
            </p>
            <Label className="control-label checkbox-label d-block mb-0" for="force">
              <Input
                className="checkbox-control"
                type="checkbox"
                id="force"
                defaultChecked={false}
                onChange={() => this.setState({ force: !this.state.force })}
              />
              <span className="check-icon-container">
                <span className="check-icon text-primary-dark">
                  <FontAwesomeIcon icon={faCheck} />
                </span>
              </span>
              <span className="label-text">
                Ja, ich möchte das Feld "{field.name}"{' '}
                {field.field_data_count !== 0 && `und die dazugehörigen ausgefüllten ${field.field_data_count} Felder`}{' '}
                löschen
              </span>
            </Label>
            {processing ? <Loading loadingText={`wird gelöscht...`} size={`1x`} /> : null}
            <FormGroup className="form-action">
              <Container>
                <Row>
                  <Col sm="5">
                    <a className="link-btn" onClick={toggle} href="#">
                      {locale.cancel}
                    </a>
                  </Col>
                  <Col sm="7" className="text-right">
                    <Button
                      disabled={!this.state.force}
                      outline
                      onClick={submit}
                      className="btn-labeled px-5"
                      color="danger"
                    >
                      <FontAwesomeIcon className="icon-prepend" icon={faTrashAlt} />
                      <span>{locale.delete}</span>
                    </Button>
                  </Col>
                </Row>
              </Container>
            </FormGroup>
          </ModalBody>
        </Modal>
      </React.Fragment>
    )
  }
}

export default withApollo(UpdateForm)
