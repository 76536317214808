import { Col, Input, Label } from 'reactstrap'

import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { getPropertyFromObject } from '../../helper/helper-functions'

const LegacyCheckbox = ({ onChange, variables, id, locale, sm_label, sm_input }) => (
  <>
    <Col sm={sm_label || '2'} className="text-right">
      <Label className="control-label" for={id}>
        {getPropertyFromObject(locale, id)}
      </Label>
    </Col>
    <Col sm={sm_input || '4'}>
      <Label className="control-label checkbox-label justify-content-start" for={id}>
        <Input
          className="checkbox-control"
          type="checkbox"
          id={id}
          defaultChecked={getPropertyFromObject(variables, id)}
          onChange={onChange}
        />
        <span className="check-icon-container">
          <span className="check-icon text-primary-dark">
            <FontAwesomeIcon icon={faCheck} />
          </span>
        </span>
      </Label>
    </Col>
  </>
)

export default LegacyCheckbox
