import React, { Fragment } from 'react'

import { Alert, Button, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import { faCheck, faSave } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { LegacySelect as Select } from 'components/modules/selects/react-select'
import { deepNestInObjectsWithKeys } from 'helpers/object'
import update from 'immutability-helper'

import { createFacility, newFacility } from './../facilities.graphql'

import { getPropertyFromObject } from '../../../helper/helper-functions'
import Loading from '../../../helper/loading/loading'
import ValidatedInput from '../../../modules/inputs/validated-input'
import { ProviderSelect } from '../../facility/provider/update'

const Create = ({ data: { loading, error, new_facility }, locale, client, history, toggleModal }) =>
  error ? (
    console.log(error)
  ) : loading ? (
    <Loading />
  ) : (
    <FormCreate
      possible_license_owners={new_facility.possible_license_owners}
      license_owner_id={new_facility.license_owner?.id || ''}
      facilityKinds={new_facility.base_data.possible_kinds}
      providers={new_facility.possible_providers}
      locale={locale}
      client={client}
      history={history}
      toggleModal={toggleModal}
      permissions={new_facility.permissions}
    />
  )

class FormCreate extends React.Component {
  constructor(props) {
    super(props)

    let kind_idOptions = []

    this.props.facilityKinds.map(kind => {
      let subkind_options = []

      if (kind.sub_kinds.length) {
        kind.sub_kinds.map(subkind => {
          subkind_options.push({
            id: subkind.id,
            label: subkind.name_de,
            value: false
          })
        })
      }

      kind_idOptions.push({
        id: kind.id,
        label: kind.name_de,
        value: false,
        subkind_options
      })
    })

    const defaultState = {
      kind_idOptions,
      variables: {
        facility: {
          name_de: '',
          description_de: '',
          license_owner_id: props?.license_owner_id || '',
          provider_id: null,
          kind_ids: []
        }
      }
    }

    this.state = defaultState
  }

  onChange = event => {
    const fragments = event.target.id.split('.')
    const variables = deepNestInObjectsWithKeys(event.target.value, ...fragments, '$set')

    this.setState(update(this.state, { variables }))
  }

  changeProvider = value => {
    this.setState(
      update(this.state, {
        variables: {
          facility: {
            provider_id: {
              $set: value
            }
          }
        }
      })
    )
  }

  onError = error => {
    console.error(error)
    this.setState({
      error_msg: error.graphQLErrors[0].message,
      errors: error.graphQLErrors[0].errors
    })
  }

  submit = () =>
    this.props.client
      .mutate({
        mutation: createFacility,
        variables: this.state.variables
      })
      .then(result => this.props.history.push(`/facility/${result.data.create_facility.uuid}`))
      .catch(error => this.onError(error))

  toggleSubkinds = event => {
    let sub_list = document.getElementById(`subkind-${event.target.id}`)

    if (sub_list !== null) {
      sub_list.classList.contains('d-none') ? sub_list.classList.remove('d-none') : sub_list.classList.add('d-none')
    }
  }

  change = event =>
    event.target ? this.setState({ [event.target.id]: event.target.value }) : this.setState({ [event.id]: event.value })

  changeCheckbox = event => {
    event.stopPropagation()
    this.toggleSubkinds(event)

    let add_kind_ids = this.state.variables.facility.kind_ids

    if (event.target.checked && this.state.variables.facility.kind_ids.indexOf(event.target.id) === -1) {
      add_kind_ids.push(event.target.id)

      this.setState(
        update(this.state, {
          variables: {
            facility: {
              kind_ids: { $set: add_kind_ids }
            }
          }
        })
      )
    } else {
      let kind_index = add_kind_ids.indexOf(event.target.id)

      if (kind_index !== -1) {
        add_kind_ids.splice(kind_index, 1)
      }

      this.setState(
        update(this.state, {
          variables: {
            facility: {
              kind_ids: { $set: add_kind_ids }
            }
          }
        })
      )
    }
  }

  license_owner_options = () => {
    let options = [
      {
        value: '',
        label: 'Kein Lizenznehmer',
        target: { id: 'facility.license_owner_id', value: '' }
      }
    ]

    this.props.possible_license_owners.map(possible_license_owner => {
      options.push({
        value: possible_license_owner.id,
        label: possible_license_owner.name,
        target: { id: 'facility.license_owner_id', value: possible_license_owner.id }
      })
      possible_license_owner.sublicense_owners.map(sub_license_owner => {
        options.push({
          value: sub_license_owner.id,
          label: `↳  ${sub_license_owner.name}`,
          target: { id: 'facility.license_owner_id', value: sub_license_owner.id }
        })
      })
    })

    return options
  }

  render() {
    const { locale, toggleModal, possible_license_owners, providers, permissions } = this.props
    const { onChange, submit, changeCheckbox, toggleSubkinds, changeProvider } = this
    const { errors, error_msg, variables } = this.state

    return (
      <Form>
        {errors ? <Alert color="danger">{error_msg}</Alert> : ''}
        <FormGroup>
          <Container>
            <Row>
              <ValidatedInput
                id={'facility.name_de'}
                variables={variables}
                errors={errors}
                onChange={onChange}
                locale={locale}
              />

              <Col sm="2" className="text-right">
                <Label className="control-label" for="license_owner_id">
                  {locale.license_owner.singular}
                </Label>
              </Col>
              <Col sm="4">
                <Select
                  id="facility.license_owner_id"
                  placeholder={'optional'}
                  isClearable={false}
                  name="facility.license_owner_id"
                  value={getPropertyFromObject(variables, 'facility.license_owner_id')}
                  onChange={onChange}
                  options={this.license_owner_options()}
                />
              </Col>
            </Row>
            <Row>
              <ValidatedInput
                type={'textarea'}
                id={'facility.description_de'}
                variables={variables}
                errors={errors}
                onChange={onChange}
                locale={locale}
              />
              {permissions.create_with_provider && (
                <Fragment>
                  <Col sm="2">
                    <Label className="control-label" for="provider_id">
                      {locale.provider.singular}
                    </Label>
                  </Col>
                  <Col sm="4">
                    <ProviderSelect
                      value={variables.facility.provider_id}
                      providers={providers}
                      onChange={changeProvider}
                    />
                  </Col>
                </Fragment>
              )}
            </Row>
            <Row>
              <Col sm="2" className="text-right">
                <p className="control-label">{locale.facility_kind.plural}</p>
              </Col>
              <Col sm="10">
                <ul className="list-unstyled">
                  {this.state.kind_idOptions.map(facility_kind => (
                    <Facilitykind
                      key={facility_kind.id}
                      {...facility_kind}
                      changeCheckbox={changeCheckbox}
                      toggleSubkinds={toggleSubkinds}
                    />
                  ))}
                </ul>
              </Col>
            </Row>
          </Container>
        </FormGroup>
        <FormGroup className="form-action">
          <Container>
            <Row>
              <Col sm="5">
                <a className="link-btn" onClick={toggleModal} href="#">
                  {locale.cancel}
                </a>
              </Col>
              <Col sm="7" className="text-right">
                <Button className="btn-labeled" color="primary-light" onClick={submit}>
                  <FontAwesomeIcon className="icon-prepend" icon={faSave} />
                  <span>{locale.save}</span>
                </Button>
              </Col>
            </Row>
          </Container>
        </FormGroup>
      </Form>
    )
  }
}

export const Facilitykind = facility_kind => (
  <li>
    <Label className="control-label checkbox-label d-block mb-0" for={facility_kind.id}>
      <Input
        className="checkbox-control"
        type="checkbox"
        id={facility_kind.id}
        defaultChecked={facility_kind.value}
        onChange={facility_kind.changeCheckbox}
      />
      <span className="check-icon-container">
        <span className="check-icon text-primary-dark">
          <FontAwesomeIcon icon={faCheck} />
        </span>
      </span>
      <span className="label-text">{facility_kind.label}</span>
    </Label>

    {facility_kind.subkind_options.length ? (
      <Row className="d-none ml-5 pl-3 mt-3 border-left border-primary" id={`subkind-${facility_kind.id}`}>
        {facility_kind.subkind_options.map((subkind, index) => (
          <Subkinds key={subkind.id} {...subkind} index={index} changeCheckbox={facility_kind.changeCheckbox} />
        ))}
      </Row>
    ) : (
      ''
    )}
  </li>
)

const Subkinds = subkind => (
  <Col sm="6">
    <Label className="control-label checkbox-label d-block mb-0" for={subkind.id}>
      <Input
        className="checkbox-control"
        type="checkbox"
        id={subkind.id}
        defaultChecked={subkind.value}
        onChange={subkind.changeCheckbox}
      />
      <span className="check-icon-container">
        <span className="check-icon text-primary-dark">
          <FontAwesomeIcon icon={faCheck} />
        </span>
      </span>
      <span className="label-text">{subkind.label}</span>
    </Label>
  </Col>
)

export default withApollo(
  graphql(newFacility, {
    options: () => ({
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true
    })
  })(Create)
)
