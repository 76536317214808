import React, { Fragment } from 'react'

import { Alert, Badge, Button, Collapse, Table, Tooltip } from 'reactstrap'

import { faAngleDown, faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { CalculationComponent } from 'components/ui/calculatedVolumeUnits/calculationComponent'
import moment from 'moment'

import { TableField } from '../partials'

class AccountingInformation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      status: 'closed'
    }
  }

  toggle = () => this.setState({ isOpen: !this.state.isOpen })
  toggleTooltip = () => this.setState({ tooltip_open: !this.state.tooltip_open })
  onEntering = () => this.setState({ status: 'opened' })
  onExiting = () => this.setState({ status: 'closed' })

  render = () => {
    const {
      toggle,
      toggleTooltip,
      onEntering,
      onExiting,
      state: { status, isOpen, tooltip_open },
      props: { facility, locale, editContentRight }
    } = this
    const { accounting_information, permissions, calculatedFacilitySize, calculatedVolumeUnitCosts } = facility

    return (
      <React.Fragment>
        {/* TODO: container should be refactored to `src/components/ui/genericComponents/collapseWithHeader/collapseWithHeader.jsx` */}
        <div className="collapse-container">
          <div className={`collapse-header btn clearfix ${status}`} onClick={toggle}>
            <h3 className="collapse-headline">
              <span className="collapse-headline-text">{locale.accounting_information.singular}</span>
              <span className="collapse-icon icon-append">
                <FontAwesomeIcon size="2x" icon={faAngleDown} />
              </span>
            </h3>
            {permissions?.update_accounting_information ? (
              <div className="list-actions">
                <Button
                  className="btn-transparent btn-icon-only"
                  color={'link'}
                  onClick={editContentRight}
                  id={`accounting_information`}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
                <Tooltip
                  delay={500}
                  placement="top"
                  isOpen={tooltip_open}
                  target={`accounting_information`}
                  toggle={toggleTooltip}
                >
                  {locale.edit}
                </Tooltip>
              </div>
            ) : null}
          </div>
          <Collapse className="collapse-content" isOpen={isOpen} onEntering={onEntering} onExiting={onExiting}>
            <Table striped responsive bordered>
              <tbody>
                <tr>
                  <th>{locale.accounting_information.calculatedFacilitySize}</th>
                  <td>
                    <Badge pill color="info">
                      {calculatedFacilitySize}
                    </Badge>
                    <Badge className="ml-2" pill>
                      {facility.form_data_count}{' '}
                      {facility.form_data_count === 1 ? locale.form.singular : locale.form.plural}
                    </Badge>
                  </td>
                </tr>
                <tr>
                  <th>{locale.accounting_information.calculatedVolumeUnitCosts}</th>
                  <td>
                    {calculatedVolumeUnitCosts.value ? (
                      <Fragment>
                        {calculatedVolumeUnitCosts.value}{' '}
                        {calculatedVolumeUnitCosts.value === 1
                          ? locale.volumeUnits.singular
                          : locale.volumeUnits.plural}
                      </Fragment>
                    ) : (
                      <Alert color="warning" className="m-0">
                        {locale.accounting_information.volumeUnitCostCannotBeCalculatedHint}
                      </Alert>
                    )}
                    <div>
                      {calculatedVolumeUnitCosts.calculationComponents.map(({ type, value }) => (
                        <CalculationComponent key={`${type}:${value}`} {...{ type, value }} />
                      ))}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>{locale.accounting_information.licence_owner_billed}</th>
                  <td>
                    {accounting_information.licence_owner_billed_at ? (
                      <Fragment>
                        <strong>{locale.accounting_information.bill_date}</strong>:{' '}
                        {moment(accounting_information.licence_owner_billed_at).format('ll')}
                        <br />
                        <strong>{locale.accounting_information.bill_number}</strong>:{' '}
                        {accounting_information.licence_owner_bill_number}
                        <br />
                        <strong>{locale.accounting_information.billing_type}</strong>:{' '}
                        {accounting_information.licence_owner_billing_type?.name[locale.code]}
                        <br />
                        <strong>{locale.accounting_information.licence_owner_billing_details}</strong>: <br />
                        {accounting_information.licence_owner_billing_details ? (
                          <p className="mb-0 mt-3">{accounting_information.licence_owner_billing_details}</p>
                        ) : null}
                      </Fragment>
                    ) : (
                      locale.no
                    )}
                  </td>
                </tr>
                <tr>
                  <th>{locale.accounting_information.facility_billed}</th>
                  <td>
                    {accounting_information.facility_billed_at ? (
                      <Fragment>
                        <strong>{locale.accounting_information.bill_date}</strong>:{' '}
                        {moment(accounting_information.facility_billed_at).format('ll')}
                        <br />
                        <strong>{locale.accounting_information.bill_number}</strong>:{' '}
                        {accounting_information.facility_bill_number}
                        <br />
                      </Fragment>
                    ) : (
                      locale.no
                    )}
                  </td>
                </tr>
                <tr>
                  <th>{locale.accounting_information.collector_billed}</th>
                  <td>
                    {accounting_information.collector_billed_at ? (
                      <Fragment>
                        <strong>{locale.accounting_information.bill_date}</strong>:{' '}
                        {moment(accounting_information.collector_billed_at).format('ll')}
                        <br />
                        <strong>{locale.accounting_information.bill_number}</strong>:{' '}
                        {accounting_information.collector_bill_number}
                        <br />
                      </Fragment>
                    ) : (
                      locale.no
                    )}
                  </td>
                </tr>
                <tr>
                  <th>{locale.accounting_information.sticker_sent}</th>
                  <td>
                    {accounting_information.sticker_sent_at
                      ? moment(accounting_information.sticker_sent_at).format('ll')
                      : locale.no}
                  </td>
                </tr>
                <tr>
                  <th>{locale.accounting_information.sign_sent}</th>
                  <td>
                    {accounting_information.sign_sent_at
                      ? moment(accounting_information.sign_sent_at).format('ll')
                      : locale.no}
                  </td>
                </tr>
                <TableField label={locale.accounting_information.comment} value={accounting_information.comment} />
              </tbody>
            </Table>
          </Collapse>
        </div>
      </React.Fragment>
    )
  }
}

export default AccountingInformation
