import { Fragment, useState } from 'react'

import { Button, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledTooltip } from 'reactstrap'

import { withApollo } from '@apollo/client/react/hoc'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { ProcessingButton } from 'components/modules/buttons/processing-button'
import { withLocale } from 'locale'

import { deleteVariable, getVariables } from '../short_report_variables.graphql'

const DeleteVariable = ({ variable, locale, cancelText, client }) => {
  const [open, setOpen] = useState(false)
  const [processing, setProcessing] = useState(false)

  const toggleOpen = () => (processing ? null : setOpen(!open))

  const remove = () => {
    setProcessing(true)
    client
      .mutate({
        mutation: deleteVariable,
        variables: { id: variable.id },
        refetchQueries: [getVariables],
        awaitRefetchQueries: true
      })
      .then(toggleOpen)
  }

  return (
    <Fragment>
      <Button
        className="btn-transparent btn-icon-only btn"
        color="link"
        id={`button-delete-variable-${variable.id}`}
        onClick={toggleOpen}
      >
        <FontAwesomeIcon icon={faTrashAlt} className="text-danger" />
      </Button>
      <UncontrolledTooltip placement="top" target={`button-delete-variable-${variable.id}`} delay={0}>
        {locale.actions.delete()}
      </UncontrolledTooltip>
      <Modal isOpen={open} toggle={toggleOpen} centered={true} size="sm">
        <ModalHeader toggle={toggleOpen}>{locale.actions.delete(variable.name)}</ModalHeader>
        <ModalBody>{locale.actions.confirm_delete}</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleOpen}>
            {cancelText}
          </Button>
          <ProcessingButton
            processing={processing}
            type="submit"
            no_icon={true}
            onClick={remove}
            label={locale.actions.delete()}
            removebutton={true}
            outline={false}
          />
        </ModalFooter>
      </Modal>
    </Fragment>
  )
}

export default withApollo(
  withLocale(withLocale(DeleteVariable, { key: 'short_report.variable' }), {
    key: 'cancel',
    attributeName: 'cancelText'
  })
)
