export default function allowKeys(object, keys) {
  if (!keys || !object || typeof object !== 'object' || !Array.isArray(keys)) return object
  return keys.reduce((obj, key) => {
    if (typeof key === 'object') {
      Object.keys(key).forEach(k => (obj[k] = allowKeys(object[k], key[k])))
    } else if (typeof key === 'string' || typeof key === 'number') {
      obj[key] = object[key]
    }
    return obj
  }, {})
}
