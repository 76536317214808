import React from 'react'

import {
  Alert,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
  Row
} from 'reactstrap'

import { faComment } from '@fortawesome/free-solid-svg-icons'

import update from 'immutability-helper'

import { getFacility } from '../../facility.graphql'

import { DD_MM_YYYY__HH_mm } from '../../../../helper/helper-functions.jsx'
import { ProcessingButton } from '../../../../modules/buttons/processing-button'
import { certificationUpdateMutation } from './graphql'

import './_comments.scss'

const CommentCertification = ({ locale, client, uuid, permission, facility }) => (
  <React.Fragment>
    <Comments locale={locale} client={client} uuid={uuid} {...facility.workflow_history[0]} />
    <AddComment locale={locale} client={client} uuid={uuid} permission={permission} {...facility} />
  </React.Fragment>
)

class AddComment extends React.Component {
  state = {
    comment_only: ''
  }

  gqlValidationError = error =>
    this.setState({
      error_msg: error.graphQLErrors[0].message,
      error: true,
      processing: false
    })

  change = event =>
    event.target
      ? this.setState(update(this.state, { [event.target.id]: { $set: event.target.value } }))
      : this.setState(update(this.state, { [event.id]: { $set: event.value } }))

  submit = () => {
    this.setState({ processing: true })
    this.state.comment_only.length &&
      this.props.client
        .mutate({
          mutation: certificationUpdateMutation,
          variables: {
            uuid: this.props.uuid,
            comment: this.state.comment_only
          },
          refetchQueries: [
            {
              query: getFacility,
              variables: {
                uuid: this.props.uuid,
                version_number: ''
              }
            }
          ],
          awaitRefetchQueries: true
        })
        .then(_ =>
          this.setState({
            comment_only: '',
            processing: false
          })
        )
        .catch(error => this.gqlValidationError(error))
  }

  render() {
    const { locale, permission } = this.props
    const { change, submit } = this
    const { comment_only, error, error_msg, processing } = this.state

    return (
      <React.Fragment>
        {permission ? (
          <React.Fragment>
            <h2>{locale.comment_certification}</h2>
            <hr className="seperator" />
            <Container>
              {error ? <Alert color="danger">{error_msg}</Alert> : ''}
              <Form>
                <FormGroup>
                  <Container>
                    <Row>
                      <Col sm="4" className="text-right">
                        <Label className="control-label" for="comment_only">
                          Kommentar
                        </Label>
                      </Col>
                      <Col sm="8">
                        <Input
                          type="textarea"
                          name="comment_only"
                          value={comment_only}
                          onChange={change}
                          id="comment_only"
                        />
                      </Col>
                    </Row>
                  </Container>
                </FormGroup>
                <FormGroup className="form-action">
                  <Container>
                    <Row>
                      <Col sm={{ size: 8, offset: 4 }}>
                        <ProcessingButton
                          label={locale.add_comment}
                          icon={faComment}
                          processing={processing}
                          onClick={submit}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={{ size: 8, offset: 4 }}>
                        <span className="text-muted">{locale.add_comment_hint}</span>
                      </Col>
                    </Row>
                  </Container>
                </FormGroup>
              </Form>
            </Container>
          </React.Fragment>
        ) : (
          ''
        )}
      </React.Fragment>
    )
  }
}

class Comments extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show_all: false
    }
  }

  toggleComments = event => {
    event.preventDefault()

    this.setState({
      show_all: !this.state.show_all
    })
  }

  render() {
    const { comments, locale } = this.props
    const { show_all } = this.state
    const { toggleComments } = this

    const subClasses = show_all ? 'ml-4-sm ml-10-lg li-comment mb-3 mt-4' : 'hidden'

    return (
      <React.Fragment>
        {comments?.length ? (
          <React.Fragment>
            <ListGroup className="list-comments">
              {comments.map((comment, index) => (
                <ListGroupItem key={index} className={index > 0 ? subClasses : 'li-comment mb-3'}>
                  <ListGroupItemHeading>
                    {comment.user ? `${comment.user.full_name}` : locale.user.system} &mdash;{' '}
                    {DD_MM_YYYY__HH_mm(comment?.created_at)}{' '}
                    {index === 0 && comments.length > 1 ? '(Aktuellster Kommentar)' : ''}
                    {index === comments.length - 1 && comments.length > 1 ? '(Ältester Kommentar)' : ''}
                  </ListGroupItemHeading>
                  <ListGroupItemText>{comment.comment}</ListGroupItemText>
                </ListGroupItem>
              ))}
            </ListGroup>
            <div className="text-right">
              {comments.length > 1 ? (
                <a
                  className="link-btn"
                  onClick={e => {
                    e.preventDefault()
                    toggleComments(e)
                  }}
                  href="#"
                >
                  {show_all ? locale.comments + ' ' + locale.close : locale.show_all_comments}
                </a>
              ) : (
                ''
              )}
            </div>
          </React.Fragment>
        ) : (
          ''
        )}
      </React.Fragment>
    )
  }
}

export default CommentCertification
