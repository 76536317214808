/*
 * migration from IDB version 3 to 4
 *
 * add field help_image to all field data
 *
 * add field provider to facility
 * add field possible_providers to facility
 */

const migrate = facility => ({
  ...facility,
  section_data: facility.section_data.map(section_datum => ({
    ...section_datum,
    form_data: addHelpImage(section_datum.form_data)
  })),
  form_data_without_section: addHelpImage(facility.form_data_without_section)
})

const addHelpImage = form_data =>
  form_data.map(form_datum => ({
    ...form_datum,
    field_data_flat: form_datum.field_data_flat.map(field_datum => ({
      ...field_datum,
      help_image: null
    }))
  }))

export default migrate
