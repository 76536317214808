const migrate = facility => ({
  ...facility,
  base_data: {
    metaDescription: {
      de: '',
      en: ''
    },
    ...facility.base_data
  }
})

export default migrate
