import { FacilityKindCheckbox } from './facilityKindCheckbox'
import { FacilityKindCheckboxGroup } from './facilityKindCheckboxGroup'

const facilityKindAccordionElementConfig = (
  { id, name_de, sub_kinds },
  selectedFacilityKindIds,
  changeFacilityKindIds
) => {
  if (sub_kinds.length > 0) {
    return {
      key: parseInt(id),
      title: name_de,
      children: (
        <FacilityKindCheckboxGroup {...{ facilityKinds: sub_kinds, selectedFacilityKindIds, changeFacilityKindIds }} />
      )
    }
  } else {
    return {
      key: parseInt(id),
      title: (
        <FacilityKindCheckbox
          {...{
            id,
            name_de,
            selectedFacilityKindIds,
            changeFacilityKindIds
          }}
        />
      )
    }
  }
}

export { facilityKindAccordionElementConfig }
