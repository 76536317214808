import React from 'react'

import { Alert } from 'reactstrap'

import { withApollo } from '@apollo/client/react/hoc'

import { deepNestInObjectsWithKeys } from 'helpers/object'
import update from 'immutability-helper'

import { getFacility } from '../../facility.graphql'
import { updateChargesList } from './information.graphql'

import { convertToEuroString } from '../../../../helper/helper-functions'
import ValidatedInformationInput from '../../../../modules/inputs/validated-information-input'
import { FormGroupContainer, FormGroupContainerActions } from '../partials'

const ChargesForm = props => (
  <div className="box">
    <Form {...props} />
  </div>
)

class Form extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      processing: false,
      variables: {
        charges_list: {
          facility_uuid: this?.props?.facility.uuid,
          certification: convertToEuroString(this?.props?.facility?.charges_list?.certification || 0),
          collection: convertToEuroString(this?.props?.facility?.charges_list?.collection || 0),
          discount: convertToEuroString(this?.props?.facility?.charges_list?.discount || 0),
          organisation: convertToEuroString(this?.props?.facility?.charges_list?.organisation || 0),
          other: convertToEuroString(this?.props?.facility?.charges_list?.other || 0)
        }
      }
    }
  }

  convertToInt = value => {
    let checkEmpty = value === '' ? '0' : Number.parseFloat(value.split(',').join('.')).toFixed(2)
    return String(value).indexOf(',') !== -1 ? parseInt(checkEmpty * 100, 10) : parseInt(checkEmpty, 10) * 100
  }

  onChange = event => {
    const fragments = event.target.id.split('.')
    const variables = deepNestInObjectsWithKeys(event.target.value, ...fragments, '$set')

    this.setState(update(this.state, { variables }))
  }

  onError = ({ graphQLErrors }) =>
    this.setState({
      processing: false,
      error_msg: graphQLErrors[0].message,
      errors: graphQLErrors[0].errors
    })

  onSuccess = () =>
    this.setState(
      {
        processing: false,
        error_msg: '',
        errors: {}
      },
      this.props.hideEditBoxRight()
    )

  submit = () => {
    this.setState({ processing: true })
    const {
      convertToInt,
      props: {
        client,
        facility: { uuid }
      },
      state: {
        variables: { charges_list }
      }
    } = this

    const charges = {
      charges_list: {
        facility_uuid: charges_list.facility_uuid,
        certification: convertToInt(charges_list.certification),
        collection: convertToInt(charges_list.collection),
        discount: convertToInt(charges_list.discount),
        organisation: convertToInt(charges_list.organisation),
        other: convertToInt(charges_list.other)
      }
    }

    client
      .mutate({
        mutation: updateChargesList,
        variables: charges,
        refetchQueries: [
          {
            query: getFacility,
            variables: {
              uuid,
              version_number: this.props.match.params.version_number || ''
            }
          }
        ],
        awaitRefetchQueries: true
      })
      .then(() => this.onSuccess())
      .catch(error => this.onError(error))
  }

  render = () => {
    const { onChange, submit } = this
    const { locale, hideEditBoxRight } = this.props
    const { variables, errors, error_msg, processing } = this.state

    const input_props = { errors, variables, locale, onChange }
    const actions_props = { locale, hideEditBoxRight, processing, submit }

    return (
      <React.Fragment>
        {error_msg && <Alert color="danger">{error_msg}</Alert>}
        <FormGroupContainer children={<ValidatedInformationInput id={'charges_list.collection'} {...input_props} />} />
        <FormGroupContainer
          children={<ValidatedInformationInput id={'charges_list.certification'} {...input_props} />}
        />
        <FormGroupContainer
          children={<ValidatedInformationInput id={'charges_list.organisation'} {...input_props} />}
        />
        <FormGroupContainer children={<ValidatedInformationInput id={'charges_list.other'} {...input_props} />} />
        <FormGroupContainer children={<ValidatedInformationInput id={'charges_list.discount'} {...input_props} />} />
        <FormGroupContainerActions {...actions_props} />
      </React.Fragment>
    )
  }
}

export default withApollo(ChargesForm)
