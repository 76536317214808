import React from 'react'

import { Button, Col, Container, FormGroup, Modal, ModalBody, ModalHeader, Row, Tooltip } from 'reactstrap'

import { faCopy } from '@fortawesome/free-solid-svg-icons'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import gql from 'graphql-tag'

// GraphQL
import {
  getFacility,
  getFacilitySectionData,
  getFormDataForSectionDatum,
  getFormDataWithoutSection
} from '../../views/facility/facility.graphql'

import generateUUID from '../../../helpers/guid'
// additional components
import Loading from '../../helper/loading/loading'

const duplicate_form_datum = gql`
  mutation duplicate_form_datum($uuid: ID!) {
    duplicate_form_datum(uuid: $uuid) {
      uuid
    }
  }
`

class CopyFormDatumModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      modal: false,
      processing: false,
      tooltip_uuid: generateUUID()
    }
  }

  clean = () =>
    this.setState({
      modal: false,
      processing: false
    })

  toggle = event => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    this.setState({
      modal: !this.state.modal
    })
  }

  toggleTooltip = () =>
    this.setState({
      tooltip_open: !this.state.tooltip_open
    })

  dublicate = () => {
    this.setState({ processing: true })

    let refetch = this.props.section_datum_uuid
      ? {
          query: getFormDataForSectionDatum,
          variables: { uuid: this.props.section_datum_uuid }
        }
      : {
          query: getFormDataWithoutSection,
          variables: { uuid: this.props.facility_uuid, version_number: '' }
        }

    this.props.client
      .mutate({
        mutation: duplicate_form_datum,
        variables: { uuid: this.props.form_datum_uuid },
        refetchQueries: [
          refetch,
          {
            query: getFacilitySectionData,
            variables: { uuid: this.props.facility_uuid, version_number: '' }
          },
          {
            query: getFacility,
            variables: { uuid: this.props.facility_uuid, version_number: '' }
          }
        ],
        awaitRefetchQueries: true
      })
      .then(() => this.clean())
  }

  render() {
    const { dublicate, toggle, toggleTooltip } = this
    const { className, name, locale } = this.props
    const { modal, processing, tooltip_uuid, tooltip_open } = this.state

    return (
      <React.Fragment>
        <Button
          className="btn-transparent btn-icon-only"
          color={'link'}
          onClick={toggle}
          id={`tooltip-modal_${tooltip_uuid}`}
        >
          <FontAwesomeIcon className="text-info" icon={faCopy} />
        </Button>
        <Tooltip
          delay={500}
          placement="top"
          isOpen={tooltip_open}
          target={`tooltip-modal_${tooltip_uuid}`}
          toggle={toggleTooltip}
        >
          {locale.dublicate}
        </Tooltip>
        <Modal centered isOpen={modal} toggle={toggle} className={className} size={'lg'}>
          <ModalHeader>
            {locale.form.singular} {locale.dublicate}
          </ModalHeader>
          <ModalBody>
            Möchten Sie das Formular <strong className="text-primary-light">{name}</strong> wirklich kopieren?
            {processing ? <Loading loadingText={`Kopie wird erstellt...`} size={`1x`} /> : null}
            <FormGroup className="form-action">
              <Container>
                <Row>
                  <Col sm="5">
                    <a className="link-btn" onClick={toggle} href="#">
                      {locale.cancel}
                    </a>
                  </Col>
                  <Col sm="7" className="text-right">
                    <Button className="btn-labeled" color="primary-light" onClick={dublicate}>
                      <FontAwesomeIcon className="icon-prepend" icon={faCopy} />
                      <span>{locale.dublicate}</span>
                    </Button>
                  </Col>
                </Row>
              </Container>
            </FormGroup>
          </ModalBody>
        </Modal>
      </React.Fragment>
    )
  }
}

export default CopyFormDatumModal
