import { useState } from 'react'

import { Controller, useForm } from 'react-hook-form'
import { components } from 'react-select'
import { withAsyncPaginate } from 'react-select-async-paginate'
import { Alert, Badge, Col } from 'reactstrap'

import { withApollo } from '@apollo/client/react/hoc'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import { ProcessingButton } from 'components/modules/buttons/processing-button'
import ValidatedInformationInput from 'components/modules/inputs/validated-information-input'
import { Select } from 'components/modules/selects/react-select'
import FacilityNameWithBundleInfo from 'components/views/facilities/helper/facility_name_with_bundle_info'
import { withLocale } from 'locale/'

import { addBundledFacilities, getBundleableFacilities, getBundledFacilities } from '../bundle_information.graphql'

import { FormGroupContainer } from '../../../partials'

const AsyncSelect = withAsyncPaginate(Select)

const CustomOption = props => (
  <components.Option {...props}>
    <Badge color="light" className="mr-2 align-middle">
      {props.data.facility.certification_data.audit_order_number}
    </Badge>
    <FacilityNameWithBundleInfo
      name={props.data.facility.base_data.name.de}
      firstBundledFacility={
        props.data.facility.bundleInformation.bundledFacilities.nodes.slice(0, 1).map(bundledFacility => ({
          name: bundledFacility.base_data.name.de,
          audit_order_number: bundledFacility.certification_data.audit_order_number
        }))[0]
      }
      totalBundledFacilities={props.data.facility.bundleInformation.bundledFacilities.totalCount}
      firstBundledByFacility={
        props.data.facility.bundleInformation.bundledBy.nodes.slice(0, 1).map(bundledByFacility => ({
          name: bundledByFacility.base_data.name.de,
          audit_order_number: bundledByFacility.certification_data.audit_order_number
        }))[0]
      }
      totalBundledByFacilities={props.data.facility.bundleInformation.bundledBy.totalCount}
      inheritColor={props.isFocused}
    />
  </components.Option>
)

const CustomMultiValueLabel = props => (
  <components.MultiValueLabel {...props}>
    <FacilityNameWithBundleInfo
      name={props.data.facility.base_data.name.de}
      firstBundledFacility={
        props.data.facility.bundleInformation.bundledFacilities.nodes.slice(0, 1).map(bundledFacility => ({
          name: bundledFacility.base_data.name.de,
          audit_order_number: bundledFacility.certification_data.audit_order_number
        }))[0]
      }
      totalBundledFacilities={props.data.facility.bundleInformation.bundledFacilities.totalCount}
      firstBundledByFacility={
        props.data.facility.bundleInformation.bundledBy.nodes.slice(0, 1).map(bundledByFacility => ({
          name: bundledByFacility.base_data.name.de,
          audit_order_number: bundledByFacility.certification_data.audit_order_number
        }))[0]
      }
      totalBundledByFacilities={props.data.facility.bundleInformation.bundledBy.totalCount}
      inheritColor={true}
    />
  </components.MultiValueLabel>
)

const mapToOptions = data =>
  data?.bundleableFacilities?.edges?.map(({ node: facility }) => ({
    value: facility.uuid,
    label: facility.base_data.name.de,
    facility
  })) || []

const AddBundledFacilities = ({ locale, facility, client, selectOptionsCacheKey, setSelectOptionsCacheKey }) => {
  const defaultValues = { bundledFacilityUuids: [] }
  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { errors }
  } = useForm({ defaultValues })
  const [processing, setProcessing] = useState()
  const [message, setMessage] = useState(null)

  const loadOptions = (search, _prevOptions, additional) =>
    client
      .query({
        query: getBundleableFacilities,
        variables: {
          uuid: facility.uuid,
          search,
          cursor: additional?.pageInfo?.endCursor
        },
        fetchPolicy: 'network-only'
      })
      .then(({ data }) => ({
        options: mapToOptions(data),
        hasMore: data.bundleableFacilities.pageInfo.hasNextPage,
        additional: {
          pageInfo: data.bundleableFacilities.pageInfo
        }
      }))

  const submit = data => {
    setProcessing(true)
    setMessage(null)
    client
      .mutate({
        mutation: addBundledFacilities,
        variables: {
          input: {
            uuid: facility.uuid,
            bundledFacilityUuids: data.bundledFacilityUuids.map(({ value }) => value)
          }
        },
        refetchQueries: [
          {
            query: getBundledFacilities,
            variables: { uuid: facility.uuid }
          }
        ],
        awaitRefetchQueries: true
      })
      .then(result => {
        if (result?.data?.addBundledFacilities?.success) {
          setMessage({
            type: 'success',
            text: locale.facility.bundleInformation.addBundledFacilitiesSuccess(data.bundledFacilityUuids.length)
          })
          reset(defaultValues)
          setSelectOptionsCacheKey(Date.now())
        } else {
          setError('bundledFacilityUuids', {
            type: 'manual',
            message: result?.data?.addBundledFacilities?.message || 'There was an error'
          })
        }
        setProcessing(false)
      })
  }

  return (
    <>
      {message && (
        <FormGroupContainer>
          <Col sm={12}>
            <Alert color={message.type} toggle={_ => setMessage(null)} className="m-0">
              {message.text}
            </Alert>
          </Col>
        </FormGroupContainer>
      )}
      <FormGroupContainer>
        <ValidatedInformationInput
          id="facility.bundleInformation.addBundledFacilities"
          {...{ locale }}
          error={errors.bundledFacilityUuids?.message}
        >
          <Controller
            name="bundledFacilityUuids"
            control={control}
            render={({ field }) => (
              <AsyncSelect
                key={selectOptionsCacheKey}
                selectRef={field.ref}
                isMulti
                loadOptions={loadOptions}
                components={{ Option: CustomOption, MultiValueLabel: CustomMultiValueLabel }}
                isDisabled={processing}
                cacheOptions={false}
                {...field}
                ref={undefined}
              />
            )}
          />
        </ValidatedInformationInput>
      </FormGroupContainer>

      <FormGroupContainer>
        <Col sm={12} className="text-right">
          <ProcessingButton label={locale.add} icon={faPlus} processing={processing} onClick={handleSubmit(submit)} />
        </Col>
      </FormGroupContainer>
    </>
  )
}

export default withApollo(withLocale(AddBundledFacilities))
