import React from 'react'

import { Button, Col, Container, FormGroup, Row } from 'reactstrap'

// GraphQL
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
// additional components
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { versionDeleteMutation } from './graphql'

const Delete = ({ client, id, toggleModal }) => {
  return (
    <React.Fragment>
      <DeleteForm toggleModal={toggleModal} client={client} id={id} />
    </React.Fragment>
  )
}

class DeleteForm extends React.Component {
  constructor(props) {
    super(props)
  }

  submit = event => {
    event.preventDefault()
    event.stopPropagation()
    this.props.client
      .mutate({
        mutation: versionDeleteMutation,
        variables: {
          id: this.props.id
        }
      })
      .then(result => {
        this.cancel()
        window.location.reload()
      })
  }
  cancel = () => this.props.toggleModal()
  render = () => {
    const { cancel, submit } = this
    return (
      <FormGroup className="form-action">
        <Container>
          <Row>
            <Col sm="5" className="text-right">
              <a className="link-btn" onClick={cancel} href="#">
                abbrechen
              </a>
            </Col>
            <Col sm="7">
              <Button onClick={submit} className="btn-labeled" color="primary-light">
                <FontAwesomeIcon className="icon-prepend" icon={faTrashAlt} />
                <span>löschen</span>
              </Button>
            </Col>
          </Row>
        </Container>
      </FormGroup>
    )
  }
}

export default Delete
