import React from 'react'

import { graphql, withApollo } from '@apollo/client/react/hoc'

import { LegacySelect as Select } from 'components/modules/selects/react-select'

import { query } from './graphql'

class PossibleLicenseOwnersToCollectFor extends React.Component {
  options = () => {
    const { possible_options } = this.props

    let options = [{ value: null, label: 'Kein Lizenznehmer ausgewählt' }]

    possible_options.map(license_owner => {
      options.push({
        value: license_owner.id,
        label: license_owner?.name
      })
      if (license_owner.sublicense_owners && this.props.withSubs) {
        license_owner.sublicense_owners.map(sublicense_owner => {
          options.push({
            value: sublicense_owner.id,
            label: String.fromCharCode(8627) + ' ' + sublicense_owner?.name
          })
        })
      }
    })
    return options
  }

  render = () => {
    const { error, loading } = this.props.data
    const { value, changeLicenseOwnerToCollectFor } = this.props

    if (loading)
      return (
        <Select
          isClearable={false}
          name="default_license_owner"
          value={value}
          onChange={changeLicenseOwnerToCollectFor}
          options={[]}
        />
      )
    if (error) {
      return <p>Error</p>
    }
    return (
      <Select
        name="default_license_owner"
        value={value}
        isMulti
        simpleValue
        onChange={changeLicenseOwnerToCollectFor}
        options={this.options()}
      />
    )
  }
}

export default withApollo(
  graphql(query, {
    options: props => ({
      notifyOnNetworkStatusChange: true,
      variables: {
        id: 264 //still hardcoded because to api support :(
      }
    })
  })(PossibleLicenseOwnersToCollectFor)
)
