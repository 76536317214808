import { faAngleLeft, faAngleRight, faCalendarAlt, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MantineProvider, createTheme } from '@mantine/core'
import { MonthPickerInput } from '@mantine/dates'

import globalThemeCSSVariables from 'components/app/_export.scss'
import { lastDayOfMonth, startOfMonth } from 'date-fns'
import 'dayjs/locale/de'
import PropTypes from 'prop-types'

const mantineTheme = createTheme({
  scale: 1.6, // because we scaled the global font size to 62.5%, every mantine component gets super small otherwise.
  colors: {
    // NOTE: this theme expects an array of 10 color shades, but we don't really have that. For now this works pretty ok.
    rfaBlue: [
      globalThemeCSSVariables['theme-colors-light'],
      globalThemeCSSVariables['theme-colors-light'],
      globalThemeCSSVariables['theme-colors-light'],
      globalThemeCSSVariables['theme-colors-primary-light'],
      globalThemeCSSVariables['theme-colors-primary-light'],
      globalThemeCSSVariables['theme-colors-primary'],
      globalThemeCSSVariables['theme-colors-primary-dark'],
      globalThemeCSSVariables['theme-colors-primary-dark'],
      globalThemeCSSVariables['theme-colors-dark'],
      globalThemeCSSVariables['theme-colors-dark']
    ]
  },
  primaryColor: 'rfaBlue',
  primaryShade: 5,
  black: globalThemeCSSVariables['theme-colors-text-dark'],
  fontFamily: 'inherit',
  lineHeight: 'inherit',
  fontSizes: {
    xs: 'inherit',
    sm: 'inherit',
    md: 'inherit',
    lg: 'inherit',
    xl: 'inherit'
  }
})

// NOTE: highlights the current month
const getMonthControlProps = date => {
  if (date.getMonth() === new Date().getMonth() && date.getFullYear() === new Date().getFullYear()) {
    return {
      style: {
        fontWeight: 700,
        color: 'var(--mantine-color-rfaBlue-4)'
      }
    }
  }

  return {}
}

const MantineMonthRangePicker = ({
  numberOfColumns,
  placeholder,
  value,
  valueFormatter,
  valueFormat,
  onChange,
  ...mantineProps
}) => {
  const changeWithValueConvertedToFullMonthRange = ([start, end]) => {
    const startOfStartMonth = start ? startOfMonth(start) : null
    const endOfEndMonth = end ? lastDayOfMonth(end) : null

    onChange([startOfStartMonth, endOfEndMonth])
  }

  const classNames = {
    root: 'form-control p-0',
    ...mantineProps.classNames
  }

  return (
    <MantineProvider theme={mantineTheme}>
      <MonthPickerInput
        leftSection={<FontAwesomeIcon icon={faCalendarAlt} />}
        clearButtonProps={{ icon: <FontAwesomeIcon icon={faXmark} /> }}
        previousIcon={<FontAwesomeIcon icon={faAngleLeft} className="text-muted" />}
        nextIcon={<FontAwesomeIcon icon={faAngleRight} className="text-muted" />}
        type="range"
        locale="de"
        clearable
        allowSingleDateInRange
        numberOfColumns={numberOfColumns}
        placeholder={placeholder}
        value={value}
        valueFormatter={valueFormatter}
        valueFormat={valueFormat}
        variant="unstyled"
        getMonthControlProps={getMonthControlProps}
        onChange={changeWithValueConvertedToFullMonthRange}
        popoverProps={{
          keepMounted: true,
          shadow: 'md',
          withinPortal: true,
          position: 'top-end',
          zIndex: 9999
        }}
        {...mantineProps}
        classNames={classNames}
      />
    </MantineProvider>
  )
}

MantineMonthRangePicker.propTypes = {
  numberOfColumns: PropTypes.number,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.instanceOf(Date)]),
  valueFormatter: PropTypes.func,
  valueFormat: PropTypes.string,
  onChange: PropTypes.func
}

export default MantineMonthRangePicker
