// additional components
import React from 'react'

import { Alert, Col, Container, Form, FormGroup, Modal, ModalBody, ModalHeader, Row, Tooltip } from 'reactstrap'

import { withApollo } from '@apollo/client/react/hoc'

import { deepNestInObjectsWithKeys } from 'helpers/object'
import update from 'immutability-helper'

import { getInputApiUsers, updateInputApiUser } from './input_api_users.graphql'

import Guid from '../../../../helpers/guid'
import { ProcessingButton } from '../../../modules/buttons/processing-button'
import ValidatedInput from '../../../modules/inputs/validated-input'

// CSS
import '../../../modules/modal/_modal.scss'

class Update extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
      backdrop: 'static',
      tooltip_open: false,
      tooltip_uuid: Guid(),
      variables: {
        id: this.props.input_api_user.id,
        input_api_user: {
          name: this.props.input_api_user.name || '',
          email: this.props.input_api_user.email || ''
        }
      }
    }
  }

  onChange = event => {
    const fragments = event.target.id.split('.')
    const variables = deepNestInObjectsWithKeys(event.target.value, ...fragments, '$set')

    this.setState(update(this.state, { variables }))
  }

  onError = ({ graphQLErrors }) =>
    this.setState({
      processing: false,
      error_msg: graphQLErrors[0].message,
      errors: graphQLErrors[0].errors
    })

  submit = () => {
    this.setState({ processing: true })
    let { variables } = this.state

    this.props.client
      .mutate({
        mutation: updateInputApiUser,
        variables,
        refetchQueries: [
          {
            query: getInputApiUsers
          }
        ]
      })
      .then(result =>
        this.setState({
          processing: false,
          error_msg: ''
        })
      )
      .then(_ => this.toggleModal())
      .catch(error => this.onError(error))
  }

  toggleModal = event => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    this.setState({
      modal: !this.state.modal
    })
  }

  toggleTooltip = () =>
    this.setState({
      tooltip_open: !this.state.tooltip_open
    })

  render() {
    const { locale, client, history, input_api_user } = this.props
    const { tooltip_open, modal, tooltip_uuid, error_msg, errors, variables, processing } = this.state
    const { toggleModal, toggleTooltip, submit, onChange } = this

    return (
      <React.Fragment>
        <a
          className="link-btn"
          href="#"
          id={`tooltip-modal_${tooltip_uuid}`}
          onClick={e => {
            e.preventDefault()
            toggleModal(e)
          }}
        >
          {input_api_user.name}
        </a>
        <Tooltip
          delay={500}
          placement="top"
          isOpen={tooltip_open}
          target={`tooltip-modal_${tooltip_uuid}`}
          toggle={toggleTooltip}
        >
          {`${locale.input_api_user.singular} "${input_api_user.name}" ${locale.edit}`}
        </Tooltip>
        <Modal isOpen={modal} centered size={'lg'} toggle={toggleModal}>
          <ModalHeader>{`${locale.input_api_user.singular} "${input_api_user.name}" ${locale.edit}`}</ModalHeader>
          <ModalBody>
            <Form>
              {error_msg ? <Alert color="danger">{error_msg}</Alert> : ''}

              <FormGroup>
                <Container>
                  <Row>
                    <ValidatedInput
                      id={'input_api_user.name'}
                      variables={variables}
                      errors={errors}
                      onChange={onChange}
                      locale={locale}
                    />
                    <ValidatedInput
                      id={'input_api_user.email'}
                      variables={variables}
                      errors={errors}
                      onChange={onChange}
                      locale={locale}
                    />
                  </Row>
                </Container>
              </FormGroup>

              <FormGroup className="form-action">
                <Container>
                  <Row>
                    <Col sm="5">
                      <a className="link-btn" onClick={toggleModal} href="#">
                        {locale.cancel}
                      </a>
                    </Col>
                    <Col sm="7" className="text-right">
                      <ProcessingButton onClick={submit} label={'speichern'} processing={processing} />
                    </Col>
                  </Row>
                </Container>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
      </React.Fragment>
    )
  }
}

export default withApollo(Update)
