import { Button, ButtonGroup } from 'reactstrap'

import { getPropertyFromObject } from '../../helper/helper-functions'

const RadioButtons = ({ id, variables, onChange }) => (
  <ButtonGroup>
    <Button
      color={'white'}
      onClick={() => onChange({ target: { id, value: true } })}
      active={getPropertyFromObject(variables, id) === true}
    >
      Ja
    </Button>
    <Button
      color={'white'}
      onClick={() => onChange({ target: { id, value: false } })}
      active={getPropertyFromObject(variables, id) !== true}
    >
      Nein
    </Button>
  </ButtonGroup>
)
export default RadioButtons
