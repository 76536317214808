import { useEffect, useRef, useState } from 'react'

import { animateScroll } from 'react-scroll'
import { Alert, Button, Fade } from 'reactstrap'

import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { withLocale } from 'locale'
import PropTypes from 'prop-types'

import TableHead from './tableHead'
import TableRowForFacility from './tableRowForFacility'

import './facilitiesTable.scss'

const FacilitiesTable = ({ locale, facilities }) => {
  if (facilities.length === 0) return <Alert color="warning">{locale.noneFound}</Alert>

  const [scrollPosition, setScrollPosition] = useState(0)
  const scrollRef = useRef(null)
  const [scrollbarHeight, setScrollbarHeight] = useState(0)

  const updateScrollbarHeight = () => {
    if (scrollRef.current) {
      setScrollbarHeight(scrollRef.current.offsetHeight - scrollRef.current.clientHeight)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', updateScrollbarHeight)

    return () => {
      window.removeEventListener('resize', updateScrollbarHeight)
    }
  }, [])

  useEffect(() => {
    updateScrollbarHeight()
  }, [scrollRef])

  const scrolledToBeginning = scrollPosition <= 0
  // NOTE: in some browsers the maximum scroll position is a few pixels off the
  // actual container end, resulting in a wrong calculation. It's not entirely
  // clear, if this has to do with display size, browser rendering, viewport
  // size or something else. To compensate, a small 2px buffer is added resulting in
  // the button to vanish a few pixels early.
  const scrolledToEnd =
    scrollRef.current && scrollRef.current.scrollWidth - scrollRef.current.clientWidth <= scrollPosition + 2

  const showLeftScrollButton = !scrolledToBeginning
  const showRightScrollButton = !scrolledToEnd

  const handleScroll = () => {
    if (scrollRef.current) {
      const scrollLeft = scrollRef.current.scrollLeft
      setScrollPosition(scrollLeft)
    }
  }

  const scroll = direction => () => {
    const directionFactor = {
      left: -1,
      right: 1
    }[direction]

    if (scrollRef.current) {
      animateScroll.scrollMore(scrollRef.current.clientWidth * 0.5 * directionFactor, {
        duration: 200,
        containerId: 'facilitiesTable__scrollContainer',
        horizontal: true
      })
    }
  }

  // TODO: all this scrolling behaviour might be better implemented in a custom wrapper component
  return (
    <div className="facilitiesTable__scrollContainer">
      {/* NOTE: we cannot use the native reactstrap Table component, because with it we don't have access to the scrolling container ref. */}
      <div ref={scrollRef} className="table-responsive" onScroll={handleScroll} id="facilitiesTable__scrollContainer">
        <Fade in={showLeftScrollButton} unmountOnExit>
          <Button
            color="link"
            className="facilitiesTable__scrollButton facilitiesTable__scrollButton-left shadow"
            onClick={scroll('left')}
            style={{ bottom: `${scrollbarHeight}px` }} // NOTE: this is needed, so the button is not covering the scrollbar
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </Button>
        </Fade>

        <table className="table table-bordered table-striped table-hover" style={{ whiteSpace: 'nowrap' }}>
          <TableHead />
          <tbody>
            {facilities.map(facility => (
              <TableRowForFacility key={facility.uuid} facility={facility} />
            ))}
          </tbody>
        </table>

        <Fade in={showRightScrollButton} unmountOnExit>
          <Button
            color="link"
            className="facilitiesTable__scrollButton facilitiesTable__scrollButton-right shadow"
            onClick={scroll('right')}
            style={{ bottom: `${scrollbarHeight}px` }} // NOTE: this is needed, so the button is not covering the scrollbar
          >
            <FontAwesomeIcon icon={faAngleRight} />
          </Button>
        </Fade>
      </div>
    </div>
  )
}

FacilitiesTable.propTypes = {
  facilities: PropTypes.arrayOf(TableRowForFacility.propTypes.facility).isRequired,
  locale: PropTypes.object.isRequired
}

export default withLocale(FacilitiesTable, { key: 'facility' })
