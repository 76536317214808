import Accordion from 'components/ui/accordion/accordion'
import Checkbox from 'components/ui/checkbox/checkbox'
import { withLocale } from 'locale'
import PropTypes from 'prop-types'

import { facilityKindAccordionElementConfig } from './facilityKindAccordionElementConfig.jsx'

const FacilityKindsSelectionAsAccordion = ({
  locale,
  facilityKinds,
  selectedFacilityKindIds,
  changeFacilityKindIds
}) => {
  const accordionElements = [...facilityKinds]
    .sort((a, b) => a.name_de.localeCompare(b.name_de))
    .map(facilityKind =>
      facilityKindAccordionElementConfig(facilityKind, selectedFacilityKindIds, changeFacilityKindIds)
    )

  const facilityKindLeafIds = facilityKinds.reduce((acc, { id, sub_kinds }) => {
    if (sub_kinds.length === 0) {
      acc.push(id)
    } else {
      sub_kinds.forEach(({ id }) => acc.push(id))
    }
    return acc
  }, [])

  const anySelected = selectedFacilityKindIds.length > 0
  const allSelected = selectedFacilityKindIds.length === facilityKindLeafIds.length

  const changeAll = _ => {
    if (anySelected) {
      changeFacilityKindIds([])
    } else {
      changeFacilityKindIds(facilityKindLeafIds)
    }
  }

  const changeAllElement = {
    key: 'all',
    title: (
      <Checkbox
        checked={anySelected}
        onChange={changeAll}
        allItemsChecked={allSelected}
        label={locale.selectAllFacilityKindIds}
      />
    )
  }

  return <Accordion elements={[changeAllElement, ...accordionElements]} />
}

FacilityKindsSelectionAsAccordion.propTypes = {
  locale: PropTypes.object.isRequired,
  facilityKinds: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name_de: PropTypes.string.isRequired,
      sub_kinds: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name_de: PropTypes.string.isRequired
        })
      ).isRequired
    })
  ).isRequired,
  selectedFacilityKindIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  changeFacilityKindIds: PropTypes.func.isRequired
}

export default withLocale(FacilityKindsSelectionAsAccordion, { key: 'short_report.variable' })
