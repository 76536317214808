import { getPropertyFromObject } from 'components/helper/helper-functions'
import { withLocale } from 'locale'
import PropTypes from 'prop-types'

import { TableField } from '.'

const TranslatedTableField = withLocale(({ field, translationKey, locale }) =>
  Object.keys(field)
    .filter(key => key !== '__typename')
    .map(localeKey => {
      const value = field[localeKey]
      const translation = getPropertyFromObject(locale, translationKey)
      const label = `${translation} (${localeKey})`

      return <TableField key={`${translationKey}_${localeKey}`} {...{ value, label }} />
    })
)

TranslatedTableField.propTypes = {
  field: PropTypes.objectOf(PropTypes.string).isRequired,
  translationKey: PropTypes.string.isRequired
}

export { TranslatedTableField }
