import React from 'react'

import { Button, ButtonGroup, Col, FormFeedback, InputGroup, Label, Row } from 'reactstrap'

import { HelpTooltip } from '../help-tooltip'
import Requirements from './Requirements'

const CheckboxNA = ({
  requirements_with_status,
  depth,
  help_text,
  help_image,
  field_number,
  uuid,
  changeFieldValue,
  parent_field_id,
  name,
  enabled,
  processing,
  value_de,
  field_id,
  embedded,
  i,
  errors
}) => (
  <React.Fragment>
    <Row
      className={
        depth > 0
          ? `mt-0 mb-0${enabled ? ' ' : ' hidden '}parent-${parent_field_id || 'none'}`
          : `${enabled ? '' : 'hidden '}parent-${parent_field_id || 'none'}`
      }
    >
      <Col
        sm={{ size: 10 - depth, offset: depth }}
        lg={{ size: 6, offset: depth }}
        className={depth > 0 ? 'pt-3 pb-3 pl-4 border-left border-secondary' : 'pl-4'}
      >
        <Label
          className={`control-label align-items-center justify-content-start ${errors?.value_de ? 'text-danger' : ''}`}
          for={uuid}
        >
          {field_number && <span className="text-primary-light">{field_number}&nbsp;&mdash;&nbsp;</span>}
          {name}
          <HelpTooltip help_text={help_text} help_image={help_image} uuid={uuid} />
        </Label>
        <Requirements requirements_with_status={requirements_with_status} />
      </Col>
      <Col sm={2} className={depth > 0 ? 'pt-3 pb-3' : ''}>
        <InputGroup className="align-items-center justify-content-sm-start">
          <ButtonGroup className="full-width">
            <Button
              disabled={processing}
              color="white"
              onClick={() => changeFieldValue({ value_de: '1', uuid, field_id, embedded, i })}
              active={value_de === '1'}
            >
              Ja
            </Button>{' '}
            <Button
              disabled={processing}
              color="white"
              onClick={() => changeFieldValue({ value_de: '0', uuid, field_id, embedded, i })}
              active={value_de === '0'}
            >
              Nein
            </Button>{' '}
            <Button
              disabled={processing}
              color="white"
              onClick={() => changeFieldValue({ value_de: '2', uuid, field_id, embedded, i })}
              active={value_de === '2'}
            >
              Nicht anwendbar
            </Button>{' '}
          </ButtonGroup>
        </InputGroup>
      </Col>
      {errors?.value_de && (
        <Col sm={{ size: 2, offset: 10 }} lg={{ size: 2, offset: 6 + depth }}>
          <FormFeedback valid={false} className="d-block">
            {errors?.value_de}
          </FormFeedback>
        </Col>
      )}
    </Row>
  </React.Fragment>
)

export default CheckboxNA
