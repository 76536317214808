import React from 'react'

import { UncontrolledTooltip } from 'reactstrap'

import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const HelpTooltip = ({ help_image, help_text, uuid }) =>
  (help_image || help_text) && (
    <React.Fragment>
      {' '}
      <span className="tooltip-help" id={`tooltip_${uuid}`}>
        <FontAwesomeIcon icon={faQuestionCircle} />
      </span>
      <UncontrolledTooltip className="tooltip-wrapper-help" placement="right" target={`tooltip_${uuid}`}>
        {help_image && <img src={help_image.path_for_middle_size} height={170} width={170} />}
        {help_text && <p>{help_text}</p>}
      </UncontrolledTooltip>
    </React.Fragment>
  )
