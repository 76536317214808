import { Alert } from 'reactstrap'

import { useQuery } from '@apollo/client'

import { Select } from 'components/modules/selects/react-select'
import { withLocale } from 'locale'
import PropTypes from 'prop-types'

import { getWorkflowStatesForFacilitiesFilter } from './workflowStateSelect.graphql'

const WorkflowStatusSelect = ({ workflowStates, setWorkflowStates, certificationStateTranslations, locale }) => {
  const { loading, error, data } = useQuery(getWorkflowStatesForFacilitiesFilter)

  if (error) {
    console.error('Error fetching workflow states', error)
    return (
      <Alert color="danger" className="m-0">
        Error fetching workflow states
      </Alert>
    )
  }

  const options =
    data?.workflowStateEnum?.enumValues?.map(enumValue => ({
      value: enumValue.name,
      label: certificationStateTranslations[enumValue.name]
    })) || []

  const value = workflowStates.map(workflowState => options.find(option => option.value === workflowState))

  const onChange = selectedOptions => {
    setWorkflowStates(selectedOptions.map(option => option.value))
  }

  return (
    <>
      <label>{locale.label}</label>
      <Select
        isLoading={loading}
        value={value}
        onChange={onChange}
        options={options}
        isMulti
        placeholder={locale.emptyPlaceholder}
        menuPortalTarget={document.body}
      />
    </>
  )
}

WorkflowStatusSelect.propTypes = {
  workflowStates: PropTypes.array.isRequired,
  setWorkflowStates: PropTypes.func.isRequired,
  certificationStateTranslations: PropTypes.object.isRequired,
  locale: PropTypes.object.isRequired
}

export default withLocale(withLocale(WorkflowStatusSelect, { key: 'facilitiesDashboard.filters.workflowStates' }), {
  key: 'certification_states',
  attributeName: 'certificationStateTranslations'
})
