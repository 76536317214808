import { Query } from '@apollo/client/react/components'

import Loading from 'components/helper/loading/loading'
import AccordionCollapse from 'components/ui/accordion/accordionCollapse'
import { withLocale } from 'locale'
import PropTypes from 'prop-types'

import getAvailableFacilityKindsForShortReportVariable from './facility_kinds.graphql'

import FacilityKindsSelectionAsAccordion from './facilityKindSelectionAsAccordion'

const FacilityKindSelection = ({ locale, selectedFacilityKindIds, changeFacilityKindIds }) => {
  return (
    <AccordionCollapse title={locale.selectFacilityKindIds} emphasized className="my-0">
      <Query query={getAvailableFacilityKindsForShortReportVariable}>
        {({ loading, error, data }) => {
          if (loading) return <Loading />
          if (error) return error?.message || 'error'
          return (
            <FacilityKindsSelectionAsAccordion
              facilityKinds={data.facility_kinds}
              {...{ selectedFacilityKindIds, changeFacilityKindIds }}
            />
          )
        }}
      </Query>
    </AccordionCollapse>
  )
}

FacilityKindSelection.propTypes = {
  locale: PropTypes.object.isRequired,
  selectedFacilityKindIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  changeFacilityKindIds: PropTypes.func.isRequired
}

export default withLocale(FacilityKindSelection, { key: 'short_report.variable' })
