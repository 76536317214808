import * as Sentry from '@sentry/react'

import { cacheNames } from 'workbox-core'

import {
  downloadFormDataForSectionDatum as downloadFormDataForSectionDatumQuery,
  downloadFormDataWithoutSection as downloadFormDataWithoutSectionQuery,
  makeOfflineAvailable as makeOfflineAvailableQuery
} from '../../views/facility/facility.graphql'
import { getNewFormDatum } from '../../views/facility/form-datum/form-datum.graphql'

import { db } from '../../../service-worker/helpers'
import { certificationUpdateMutation } from '../../views/facility/certification-workflow/sections/graphql'

const pageSize = 5

/**
 * functions to be run in sequence to store offline information
 */
const storeFunctions = [
  ({ offline_facility }) => db.set('Facilities', offline_facility.uuid, offline_facility),
  ({ offline_facility, client, updateProgress }) => {
    let promiseChain = Promise.resolve()

    offline_facility.possible_forms.forEach(
      ({ id: form_id }) =>
        (promiseChain = promiseChain.then(_ => {
          console.time(`new_form_${form_id}`)
          return client
            .query({
              query: getNewFormDatum,
              variables: {
                form_id
              },
              fetchPolicy: 'network-only'
            })
            .then(({ data: { new_form_datum: form_datum } }) => db.set('Forms', form_datum.form_id, form_datum))
            .then(_ => updateProgress({ increment: 1 }))
            .then(_ => console.info(`Downloaded new form datum for form with ID ${form_id}`))
            .then(_ => console.timeEnd(`new_form_${form_id}`))
            .catch(e => {
              console.error(e)
              Sentry.captureException(e)
            })
        }))
    )

    return promiseChain
  },
  ({ offline_facility }) => db.set('SyncCreateFormDatum', offline_facility.uuid, []),
  ({ offline_facility }) => db.set('SyncUpdateFormDatum', offline_facility.uuid, []),
  ({ offline_facility }) => db.set('SyncDeleteFormDatum', offline_facility.uuid, []),
  ({ offline_facility }) => db.set('SyncCreateSectionDatum', offline_facility.uuid, []),
  ({ offline_facility }) => db.set('SyncUpdateSectionDatum', offline_facility.uuid, []),
  ({ offline_facility }) => db.set('SyncDeleteSectionDatum', offline_facility.uuid, []),
  ({ offline_facility }) => db.set('SyncReorderSectionDatum', offline_facility.uuid, []),
  ({ offline_facility }) => db.set('SyncCreateImage', offline_facility.uuid, []),
  ({ offline_facility }) => db.set('SyncUpdateImage', offline_facility.uuid, []),
  ({ offline_facility }) => db.set('SyncDeleteImage', offline_facility.uuid, []),
  ({ offline_facility }) => db.set('SyncReorderImage', offline_facility.uuid, []),
  ({ offline_facility }) => cacheImages(offline_facility)
]

/**
 * stores a Facility in the IndexedDB and prepares other collections (Sync Collections etc)
 */
const makeOfflineAvailable = ({ facility, client, updateProgress }) =>
  downloadFacility(facility, client, updateProgress).then(offline_facility => {
    let promiseChain = Promise.resolve()

    storeFunctions.forEach(storeFunction => {
      promiseChain = promiseChain
        .then(_ => storeFunction({ offline_facility, client, updateProgress }))
        .then(_ => updateProgress({ increment: 1 }))
    })

    return promiseChain
      .then(_ => {
        let device_name = offline_facility.download.device
        let comment = `Heruntergeladen für offline Erhebung auf ${device_name}`
        client.mutate({
          mutation: certificationUpdateMutation,
          variables: {
            uuid: facility.uuid,
            comment
          }
        })
      })
      .then(_ => console.log('stored facility offline'))
  })

const downloadFacility = (facility, client, updateProgress) =>
  client
    .query({
      query: makeOfflineAvailableQuery,
      variables: {
        uuid: facility.uuid
      },
      fetchPolicy: 'network-only'
    })
    .then(
      ({
        data: {
          offline_facility: { facility: offline_facility, download }
        }
      }) => {
        offline_facility = { ...offline_facility }
        updateProgress({
          max:
            storeFunctions.length +
            offline_facility.possible_forms.length +
            offline_facility.form_data_without_section_count / pageSize +
            offline_facility.section_data.map(({ form_data_count }) => form_data_count).reduce((x, y) => x + y, 0) /
              pageSize
        })

        offline_facility.download = download

        return downloadFormDataWithoutSection(offline_facility, client, updateProgress).then(facility =>
          downloadFormDataForSectionDatum(facility, client, updateProgress)
        )
      }
    )

const downloadFormDataWithoutSection = async (facility, client, updateProgress) => {
  facility.form_data_without_section = []
  let offset = 0
  let result = null

  do {
    result = await client.query({
      query: downloadFormDataWithoutSectionQuery,
      variables: {
        uuid: facility.uuid,
        limit: pageSize,
        offset: offset
      },
      fetchPolicy: 'network-only'
    })

    facility.form_data_without_section = [
      ...facility.form_data_without_section,
      ...result.data.facility.form_data_without_section
    ]

    updateProgress({ increment: 1 })
    offset += pageSize
  } while (result.data.facility.form_data_without_section.length > 0)

  return facility
}

const downloadFormDataForSectionDatum = async (facility, client, updateProgress) => {
  facility.section_data = facility.section_data.map(sd => ({ ...sd }))
  for (let [index, section_datum] of facility.section_data.entries()) {
    let offset = 0
    let result = null

    facility.section_data[index].form_data = []

    do {
      result = await client.query({
        query: downloadFormDataForSectionDatumQuery,
        variables: {
          uuid: section_datum.uuid,
          limit: pageSize,
          offset: offset
        },
        fetchPolicy: 'network-only'
      })

      facility.section_data[index].form_data = facility.section_data[index].form_data.concat(
        result.data.section_datum.form_data
      )

      updateProgress({ increment: 1 })
      offset += pageSize
    } while (result.data.section_datum.form_data.length > 0)
  }

  return facility
}

const cacheImages = facility => {
  let filterFunc = image =>
    Object.keys(image)
      .map(key => {
        if (key.includes('path_for_')) return image[key]
        return null
      })
      .filter(el => el)

  let facilityImages = facility.images.flatMap(filterFunc)

  let sectionImages = facility.section_data.flatMap(section_datum => section_datum.images.flatMap(filterFunc))

  let formImages = facility.section_data
    .flatMap(section_datum => section_datum.form_data)
    .concat(facility.form_data_without_section)
    .flatMap(form_datum => form_datum.images.flatMap(filterFunc))

  let images = facilityImages.concat(sectionImages).concat(formImages)

  return caches
    .open(cacheNames.precache)
    .then(async cache => {
      await images.forEach(async image => {
        cache = await cache.add(image)
      })
      return cache
    })
    .then(cache => {
      return cache
    })
}

export default makeOfflineAvailable
