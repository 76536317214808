import React, { Fragment } from 'react'

import {
  Button,
  Col,
  Container,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from 'reactstrap'

import { withApollo } from '@apollo/client/react/hoc'
import { faSave, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { LegacySelect as Select } from 'components/modules/selects/react-select'

import { getFacility } from '../../facility.graphql'
import {
  createFacilityThirdPartyId,
  deleteFacilityThirdPartyId,
  updateFacilityThirdPartyId
} from './information.graphql'

import { ProcessingButton } from '../../../../modules/buttons/processing-button'
import { FormGroupContainer, FormGroupContainerActions } from '../partials'

const ThirdPartyIdsForm = props => (
  <div className="box">
    <Form {...props} />
  </div>
)

class Form extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      processing: false,
      error_message: '',
      third_party_ids:
        props.facility.third_party_ids.reduce((map, item) => {
          map[item.id] = {
            processing: false,
            errors: {},
            ...item
          }
          return map
        }, {}) || {},
      create: {
        key: '',
        value: '',
        errors: {},
        processing: false
      }
    }
  }

  updateSingleThirdPartyId = (id, attributes) =>
    this.setState({
      third_party_ids: {
        ...this.state.third_party_ids,
        [id]: {
          ...this.state.third_party_ids[id],
          ...attributes
        }
      }
    })

  onCreateError = id => ({ graphQLErrors }) =>
    this.updateSingleThirdPartyId(id, {
      errors: graphQLErrors[0].errors
    })

  onFieldChange = id => e => this.updateSingleThirdPartyId(id, { value: e.target.value })

  submit = () => {
    this.setState({ processing: true })
    let promises = []
    Object.values(this.state.third_party_ids).forEach(item =>
      promises.push(
        this.props.client
          .mutate({
            mutation: updateFacilityThirdPartyId,
            variables: (({ id, value }) => ({ id, third_party_id: { value } }))(item)
          })
          .then(() => this.updateSingleThirdPartyId(item.id, { errors: {} }))
          .catch(this.onCreateError(item.id))
      )
    )
    Promise.all(promises)
      .then(() => {
        if (!Object.values(this.state.third_party_ids).some(({ errors }) => Object.keys(errors).length > 0)) {
          return this.props.client
            .query({
              query: getFacility,
              variables: {
                uuid: this.props.facility.uuid,
                version_number: ''
              },
              fetchPolicy: 'network-only'
            })
            .then(() => this.props.hideEditBoxRight())
        }
      })
      .then(() => this.setState({ processing: false }))
  }

  deleteThirdPartyId = id => {
    this.updateSingleThirdPartyId(id, { processing: true })
    this.props.client
      .mutate({
        mutation: deleteFacilityThirdPartyId,
        variables: { id },
        refetchQueries: [{ query: getFacility, variables: { uuid: this.props.facility.uuid, version_number: '' } }],
        awaitRefetchQueries: true
      })
      .then(() => {
        let third_party_ids = this.state.third_party_ids
        delete third_party_ids[id]
        this.setState({
          third_party_ids
        })
      })
  }

  addThirdPartyId = data =>
    this.setState({
      third_party_ids: {
        ...this.state.third_party_ids,
        [data.id]: {
          processing: false,
          errors: {},
          ...data
        }
      }
    })

  createThirdPartyId = () => {
    this.updateCreate({ processing: true })
    this.props.client
      .mutate({
        mutation: createFacilityThirdPartyId,
        variables: {
          facility_uuid: this.props.facility.uuid,
          third_party_id: (({ key, value }) => ({ key, value }))(this.state.create)
        },
        refetchQueries: [{ query: getFacility, variables: { uuid: this.props.facility.uuid, version_number: '' } }],
        awaitRefetchQueries: true
      })
      .then(result => {
        this.updateCreate({ errors: {}, key: '', value: '' })
        this.addThirdPartyId(result.data.create_facility_third_party_id)
      })
      .catch(({ graphQLErrors }) => this.updateCreate({ errors: graphQLErrors[0].errors }))
      .then(() => this.updateCreate({ processing: false }))
  }

  updateCreate = attributes => {
    this.setState({
      create: {
        ...this.state.create,
        ...attributes
      }
    })
  }

  options = () =>
    this.props.facility.possible_third_party_id_types
      .filter(
        type =>
          !Object.values(this.state.third_party_ids)
            .map(id => id.key)
            .includes(type.key)
      )
      .map(type => ({ value: type.key, label: type.name.de }))

  render = () => {
    let { third_party_ids, processing, create } = this.state
    let { locale, hideEditBoxRight, facility } = this.props
    let { onFieldChange, submit, deleteThirdPartyId, createThirdPartyId, updateCreate } = this
    let options = this.options()

    let actions_props = { locale, hideEditBoxRight, processing, submit }

    return (
      <Fragment>
        <fieldset disabled={create.processing}>
          {options.length > 0 && (
            <FormGroupContainer>
              <Col sm={12}>
                <Label className="control-label justify-content-start">{locale.third_party_ids.create}</Label>
              </Col>
              <Col sm={12} lg={4}>
                <Select
                  id="new_third_party_id_key"
                  name="new_third_party_id_key"
                  placeholder={locale.third_party_ids.key}
                  options={options}
                  onChange={obj => updateCreate({ key: obj?.value })}
                  value={create.key}
                  disabled={create.processing}
                  className={create.errors?.third_party_id?.key ? 'invalid' : null}
                />
                {create.errors?.third_party_id?.key && (
                  <FormFeedback className="d-block">{create.errors?.third_party_id?.key}</FormFeedback>
                )}
              </Col>
              <Col sm={12} lg={8}>
                <Input
                  id="new_third_party_id_value"
                  name="new_third_party_id_value"
                  placeholder={locale.third_party_ids.value}
                  value={create.value}
                  onChange={e => updateCreate({ value: e.target.value })}
                  invalid={!!create.errors?.third_party_id?.value}
                />
                {create.errors?.third_party_id?.value && (
                  <FormFeedback>{create.errors?.third_party_id?.value}</FormFeedback>
                )}
              </Col>
              <Col sm={12} className="mt-3 text-right">
                <ProcessingButton
                  onClick={createThirdPartyId}
                  processing={create.processing}
                  label={locale.create}
                  icon={faSave}
                />
              </Col>
            </FormGroupContainer>
          )}
        </fieldset>
        <fieldset disabled={processing}>
          {Object.values(third_party_ids).map(item => (
            <FormGroupContainer key={item.id} id={`third_party_ids.${item.id}`}>
              <ThirdPartyIdEdit
                locale={locale}
                item={{
                  ...item,
                  label: facility.possible_third_party_id_types.filter(type => type.key === item.key)[0].name.de
                }}
                onFieldChange={onFieldChange}
                deleteThirdPartyId={deleteThirdPartyId}
              />
            </FormGroupContainer>
          ))}
        </fieldset>
        <FormGroupContainerActions {...actions_props} />
      </Fragment>
    )
  }
}

const ThirdPartyIdEdit = ({ locale, item, onFieldChange, deleteThirdPartyId }) => (
  <Fragment>
    <Col lg="3">
      <Label className="control-label" for={`third_party_id_input.${item.id}`}>
        {item.label}
      </Label>
    </Col>
    <Col lg="9" className="third-party-id-form">
      <div className="mr-3">
        <Input
          id={`third_party_id_input.${item.id}`}
          type="text"
          name="value"
          placeholder={locale.third_party_ids.value}
          value={item.value}
          onChange={onFieldChange(item.id)}
          disabled={item.processing}
          invalid={Object.keys(item.errors).length > 0}
        />
        {Object.keys(item.errors?.third_party_id || {}).length > 0 && (
          <FormFeedback>{item.errors?.third_party_id?.value}</FormFeedback>
        )}
      </div>
      <DeleteModal locale={locale} item={item} deleteThirdPartyId={deleteThirdPartyId} />
    </Col>
  </Fragment>
)

class DeleteModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      modalOpen: false
    }
  }

  toggle = e => {
    if (e) {
      e.preventDefault()
    }
    this.setState({
      modalOpen: !this.state.modalOpen
    })
  }

  submitDelete = () => {
    this.toggle()
    this.props.deleteThirdPartyId(this.props.item.id)
  }

  render = () => {
    let { toggle, submitDelete } = this
    let { locale, item } = this.props
    let { modalOpen } = this.state

    return (
      <Fragment>
        <ProcessingButton
          removebutton={true}
          onClick={toggle}
          processing={item.processing}
          label={locale.delete}
          icon={faTrashAlt}
        />

        <Modal centered isOpen={modalOpen} toggle={toggle} size="lg">
          <ModalHeader>
            {locale.third_party_ids.singular} "{item.label}" {locale.delete}
          </ModalHeader>
          <ModalBody>
            <p>
              Möchten Sie die Drittanbieter ID löschen? Der Betrieb ist dann nicht mehr darüber im Partner Gateway
              auffindbar.
            </p>
            <FormGroup className="form-action">
              <Container>
                <Row>
                  <Col sm="5">
                    <a className="link-btn" onClick={toggle} href="#">
                      {locale.cancel}
                    </a>
                  </Col>
                  <Col sm="7" className="text-right">
                    <Button outline onClick={submitDelete} className="btn-labeled px-5" color="danger">
                      <FontAwesomeIcon className="icon-prepend" icon={faTrashAlt} />
                      <span>{locale.delete}</span>
                    </Button>
                  </Col>
                </Row>
              </Container>
            </FormGroup>
          </ModalBody>
        </Modal>
      </Fragment>
    )
  }
}

export default withApollo(ThirdPartyIdsForm)
