import PropTypes from 'prop-types'

import AccordionCollapse from './accordionCollapse'

import './accordion.scss'

const Accordion = ({ elements }) => {
  const collapses = elements.map(({ key, ...accordionElement }) => (
    <AccordionCollapse {...accordionElement} key={key} />
  ))

  return <>{collapses}</>
}

Accordion.propTypes = {
  elements: PropTypes.arrayOf(
    PropTypes.exact({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      ...AccordionCollapse.propTypes
    })
  ).isRequired
}

export default Accordion
