import React, { Fragment } from 'react'

import { Button, Col, Container, FormGroup, Row } from 'reactstrap'

import { withApollo } from '@apollo/client/react/hoc'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { withLocale } from 'locale'
import PropTypes from 'prop-types'

import Loading from '../../../../../helper/loading/loading'
import { deleteProvider, getProviders } from '../../graphql'

class ModalContent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false
    }
  }

  static propTypes = {
    locale: PropTypes.object.isRequired,
    provider: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired,
    toggleModal: PropTypes.func.isRequired
  }

  destroy = () => {
    this.setState({ loading: true })
    this.props.client.mutate({
      mutation: deleteProvider,
      variables: {
        id: this.props.provider.id
      },
      refetchQueries: [{ query: getProviders }],
      awaitRefetchQueries: true
    })
  }

  render = () => {
    const {
      destroy,
      props: { locale, provider, toggleModal },
      state: { loading }
    } = this

    return (
      <Fragment>
        <p>{locale.provider.delete.confirm(provider.name, provider.facility_count)}</p>
        {loading && <Loading loadingText={locale.provider.delete.loading(provider.name)} />}
        <FormGroup className="form-action">
          <Container>
            <Row>
              <Col sm="5">
                <a className="link-btn" onClick={toggleModal} href="#">
                  {locale.cancel}
                </a>
              </Col>
              <Col sm="7" className="text-right">
                <Button outline onClick={destroy} className="btn-labeled px-5" color="danger">
                  <FontAwesomeIcon className="icon-prepend" icon={faTrashAlt} />
                  <span>{locale.delete}</span>
                </Button>
              </Col>
            </Row>
          </Container>
        </FormGroup>
      </Fragment>
    )
  }
}

export default withApollo(withLocale(ModalContent))
