const moveSectionDatum = ({ section_data, uuid, position }) => {
  section_data = section_data.slice().sort((a, b) => a.position - b.position)

  const sectionDatum = section_data.filter(({ uuid: sd_uuid }) => sd_uuid === uuid)[0]
  const targetPositionSectionDatum = section_data.filter(({ position: p }) => p >= position)[0]

  const sectionDatumIndex = section_data.indexOf(sectionDatum)
  const targetPositionIndex = (_ => {
    const i = section_data.indexOf(targetPositionSectionDatum)
    return i >= 0 ? i : section_data.length
  })()

  section_data.splice(sectionDatumIndex, 1)
  section_data.splice(targetPositionIndex, 0, sectionDatum)

  const reordered_section_data = section_data.map((section_datum, index) => ({
    ...section_datum,
    position: index + 1
  }))

  return reordered_section_data
}

export default moveSectionDatum
