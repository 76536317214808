export default {
  fields: {
    facilities: {
      keyArgs: ['working_list', 'search_query'],
      merge(existing, incoming) {
        return incoming
      }
    },
    manuals: {
      merge(existing, incoming) {
        return incoming
      }
    },
    forms: {
      merge(existing, incoming) {
        return incoming
      }
    },
    facility_kinds: {
      merge(existing, incoming) {
        return incoming
      }
    },
    facility_short_report_variables: {
      merge(existing, incoming) {
        return incoming
      }
    },
    regions: {
      merge(existing, incoming) {
        return incoming
      }
    },
    widget_filters: {
      merge(existing, incoming) {
        return incoming
      }
    },
    providers: {
      merge(existing, incoming) {
        return incoming
      }
    },
    license_owners: {
      merge(existing, incoming) {
        return incoming
      }
    },
    users: {
      merge(existing, incoming) {
        return incoming
      }
    },
    partner_gateway_users: {
      merge(existing, incoming) {
        return incoming
      }
    }
  }
}
