import React from 'react'

import { graphql, withApollo } from '@apollo/client/react/hoc'

import { LegacySelect as Select } from 'components/modules/selects/react-select'

import { getSalutations } from './select-queries.graphql'

import { getPropertyFromObject } from '../../helper/helper-functions'

const SalutationSelect = ({ data: { possible_salutations, loading }, id, variables, onChange, errors, style }) =>
  loading ? (
    <Select
      id={id}
      style={
        errors && {
          borderColor: '#e53012'
        }
      }
      placeholder={'auswählen'}
      isClearable={false}
      name={id}
      value={getPropertyFromObject(variables, id)}
      onChange={onChange}
      options={[]}
    />
  ) : (
    <React.Fragment>
      <Select
        id={id}
        style={
          getPropertyFromObject(errors, id) && {
            borderColor: '#e53012'
          }
        }
        placeholder={'auswählen'}
        isClearable={false}
        name={id}
        value={getPropertyFromObject(variables, id)}
        onChange={onChange}
        options={possible_salutations.map(salutation => {
          return {
            value: salutation.key,
            label: salutation.label,
            target: { id, value: salutation.key }
          }
        })}
      />
      {!!getPropertyFromObject(errors, id) && (
        <div
          style={{
            width: '100%',
            marginTop: '0.25rem',
            fontSize: '80%',
            color: '#e53012'
          }}
        >
          {getPropertyFromObject(errors, id)}
        </div>
      )}
    </React.Fragment>
  )

export default withApollo(
  graphql(getSalutations, {
    options: () => ({
      notifyOnNetworkStatusChange: true
    })
  })(SalutationSelect)
)
