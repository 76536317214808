import React from 'react'

import { faBan, faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './_radio-switch.scss'

class RadioSwitch extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      first_item: { ...this.props.items[0], value: false, hint: undefined },
      checked: this.props.items[0].checked
    }
  }
  render = () => (
    <div className="switch-check clearfix">
      <RadioSwitchItem
        item={this.state.first_item}
        is_checked={!this.state.checked}
        index={0}
        change={this.props.change}
      />
      {this.props.items.map((item, index) => (
        <RadioSwitchItem
          key={index}
          item={item}
          is_checked={this.state.checked}
          index={++index}
          change={this.props.change}
        />
      ))}
    </div>
  )
}

const RadioSwitchItem = ({ item, is_checked, index, change }) => {
  return (
    <React.Fragment>
      <input
        onChange={change}
        className="switch-input"
        value={item.value}
        id={`${item.key}-${index}`}
        type="radio"
        name={item.key}
        readOnly
        defaultChecked={is_checked}
      />
      <label className="switch-label switch-block" htmlFor={`${item.key}-${index}`}>
        <span className="switch-icon radio-icon">
          <FontAwesomeIcon icon={index === 0 ? faBan : faCheck} />
        </span>
        <span className={`label-text ${index === 0 ? 'sr-only' : ''}`}>
          {item.label ? item.label : 'nicht gesetzt'}
        </span>
      </label>
    </React.Fragment>
  )
}
export default RadioSwitch
