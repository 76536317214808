import { Fragment } from 'react'

import { Link } from 'react-router-dom'
import { Button, Col, Container, FormGroup, Row, Table } from 'reactstrap'

import { Query } from '@apollo/client/react/components'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { getFormDatumWithRequirements } from './form-datum.graphql'

import Loading from '../../../helper/loading/loading'

const PublicRequirements = ({
  locale,
  history,
  match: {
    params: { uuid, category, guest_group }
  }
}) => (
  <Query
    fetchPolicy="network-only"
    query={getFormDatumWithRequirements}
    variables={{ form_datum_uuid: uuid, category, guest_group }}
  >
    {({ loading, error, data }) => {
      if (error) return <p>error</p>
      if (loading || !data) return <Loading />
      const { form_datum } = data
      return (
        <div className="page-wrapper">
          <Row className="page-header">
            <Col sm="9">
              <h1>
                {locale.public_report.singular} – Teilergebnis "{form_datum.name_de}"
              </h1>
              <Link
                to={`/facility/${form_datum.facility.uuid}/${
                  form_datum.facility.is_current_version ? '' : form_datum.facility.version_number
                }`}
              >
                {form_datum.facility.base_data.name_de}
              </Link>
            </Col>
            <Col sm="3" className="text-right">
              {form_datum.changeable && (
                <Button
                  outline
                  color="primary"
                  onClick={() => history.push(`/formdatum/requirements/edit/${uuid}/${guest_group}/${category}`)}
                >
                  Prüfbericht bearbeiten
                </Button>
              )}
            </Col>
          </Row>
          <hr className="seperator" />
          <div className="box">
            <br />
            <h3>Übersicht</h3>
            <hr className="seperator" />
            <InformationTable locale={locale} category={category} guest_group={guest_group} form_datum={form_datum} />
            <br />
            <h3>Prüfbericht</h3>
            <hr className="seperator" />
            <RequirementsTable locale={locale} field_data={form_datum.field_data_flat} guestGroup={guest_group} />
          </div>
          <FormGroup className="form-action fixed-bottom bg-white">
            <Container>
              <Row>
                <Col sm="2">
                  <Link
                    to={`/reportings/${form_datum.facility.uuid}/${form_datum.facility.version_number}`}
                    className="link-btn"
                  >
                    <FontAwesomeIcon className="icon-prepend" icon={faArrowLeft} />
                    <span>zurück</span>
                  </Link>
                </Col>
              </Row>
            </Container>
          </FormGroup>
        </div>
      )
    }}
  </Query>
)
const InformationTable = ({ locale, category, guest_group, form_datum }) => (
  <Table bordered>
    <tbody>
      <InformationTableRow information={form_datum.form_name} translation={locale.form.name} />
      {!!guest_group ? (
        <Fragment>
          <InformationTableRow
            information={locale.public_requirements.guest_group[guest_group]}
            translation={locale.public_requirements.guest_group.singular}
          />
          <InformationTableRow information={category} translation={locale.public_requirements.category} />
        </Fragment>
      ) : null}
    </tbody>
  </Table>
)
const InformationTableRow = ({ information, translation, image }) => (
  <tr>
    <th scope="row">{translation}</th>
    <td>{image || information}</td>
  </tr>
)
const RequirementsTable = ({ locale, field_data, guestGroup }) => (
  <Table striped bordered>
    <thead>
      <tr>
        <th>#</th>
        <th>Prüfpunkt</th>
        <th>Festgestellter Wert</th>
        {!!guestGroup ? <th>Anforderung</th> : null}
      </tr>
    </thead>
    <tbody>
      {field_data
        .filter(field_datum => field_datum.enabled)
        .map((field_datum, i) => (
          <RequirementsTableRow key={i} field_datum={field_datum} locale={locale} {...{ guestGroup }} />
        ))}
    </tbody>
  </Table>
)

const RequirementsTableRow = ({
  field_datum: {
    field_number,
    name,
    value_de,
    unit_de,
    requirements_with_status,
    processed_value_de,
    embedded_form_datum,
    linked_forms,
    children
  },
  locale,
  guestGroup
}) => (
  <Fragment>
    <tr>
      <td>
        <strong>{field_number}</strong>
      </td>
      <td>{name}</td>
      <td>{processed_value_de}</td>
      {!!guestGroup ? (
        <td>
          {requirements_with_status && (
            <ParseResultingString
              resulting_string={requirements_with_status[0]?.requirement?.resulting_string || ''}
              calculated_result_key={requirements_with_status[0]?.calculated_result_key || ''}
              locale={locale}
            />
          )}
        </td>
      ) : null}
    </tr>
    {value_de === '1' &&
      embedded_form_datum &&
      embedded_form_datum.field_data_flat
        .filter(field_datum => field_datum.enabled)
        .map((field_datum, i) => <RequirementsTableRow key={i} field_datum={field_datum} locale={locale} />)}
  </Fragment>
)

const ParseResultingString = ({ resulting_string, calculated_result_key, locale }) => {
  if (calculated_result_key)
    switch (calculated_result_key) {
      case 'none':
        return (
          <p className={'text-success'}>
            <strong>
              {locale.public_requirements[calculated_result_key]}
              <br />
              {resulting_string}
            </strong>
          </p>
        )
      case 'not_relevant':
        return (
          <p className={'text-success'}>
            <strong>
              {locale.public_requirements[calculated_result_key]}
              <br />
              {resulting_string}
            </strong>
          </p>
        )
      case 'passed':
        return (
          <p className={'text-success'}>
            <strong>
              {locale.public_requirements[calculated_result_key]}
              <br />
              {resulting_string}
            </strong>
          </p>
        )
      case 'failed':
        return (
          <p className="text-danger">
            <strong>
              {locale.public_requirements[calculated_result_key]}
              <br />
              {resulting_string}
            </strong>
          </p>
        )
      case 'missing':
        return (
          <p className={'text-warning'}>
            <strong>
              {locale.public_requirements[calculated_result_key]}
              <br />
              {resulting_string}
            </strong>
          </p>
        )
      case 'calculating':
        return (
          <p className={'text-warning'}>
            <strong>{locale.public_requirements[calculated_result_key]}</strong>
          </p>
        )
      default:
        return <p className={'text-danger'}>resulting_string: "{calculated_result_key}" is unknown.</p>
    }
  return ''
}
export default PublicRequirements
