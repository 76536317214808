import React, { Fragment } from 'react'

import { graphql, withApollo } from '@apollo/client/react/hoc'

import { getPropertyFromObject } from 'components/helper/helper-functions'
import { LegacySelect as Select } from 'components/modules/selects/react-select'

import { licenseOwnerQuery } from './graphql'

class LicenseOwners extends React.Component {
  license_ownerOptions = () => {
    const { license_owners } = this.props.data
    let license_ownerOptions = [{ value: '', label: 'Kein Lizenznehmer ausgewählt' }]
    license_owners.map(license_owner => {
      license_ownerOptions.push({
        value: license_owner.id,
        label: license_owner?.name
      })
      if (license_owner.sublicense_owners && this.props.withSubs) {
        license_owner.sublicense_owners.map(sublicense_owner => {
          license_ownerOptions.push({
            value: sublicense_owner.id,
            label: String.fromCharCode(8627) + ' ' + sublicense_owner?.name,
            id: 'license_owner_id'
          })
        })
      }
    })
    return license_ownerOptions
  }

  render = () => {
    const { error, loading } = this.props.data
    const { license_owner_id, changeLicenseOwner, errors, id } = this.props

    const hasErrors = typeof errors == 'object' && !!getPropertyFromObject(errors, id)

    if (loading)
      return (
        <Select
          isClearable={false}
          name="license_owner.master"
          id="license_owner.master"
          value={license_owner_id}
          onChange={changeLicenseOwner}
          options={[]}
        />
      )
    if (error) return <p>Error</p>
    return (
      <Fragment>
        <Select
          isClearable={false}
          style={
            (hasErrors && {
              borderColor: '#e53012'
            }) ||
            null
          }
          name="license_owner.master"
          id="license_owner.master"
          value={license_owner_id}
          onChange={changeLicenseOwner}
          options={this.license_ownerOptions()}
        />
        {hasErrors && (
          <div
            style={{
              width: '100%',
              marginTop: '0.25rem',
              fontSize: '80%',
              color: '#e53012'
            }}
          >
            {getPropertyFromObject(errors, id)}
          </div>
        )}
      </Fragment>
    )
  }
}

export default withApollo(
  graphql(licenseOwnerQuery, {
    options: () => ({
      notifyOnNetworkStatusChange: true
    })
  })(LicenseOwners)
)
