import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'

import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { withLocale } from 'locale'
import PropTypes from 'prop-types'

import ActiveFilterBadge from './activeFilterBadge'

const FilterModal = ({ isOpen, toggle, activeFilterCount, children, locale }) => (
  <>
    <Button color="link" onClick={toggle} className="text-nowrap position-relative text-primary-light">
      <FontAwesomeIcon icon={faFilter} className="icon-prepend" />
      {locale.filter}
      <ActiveFilterBadge activeFilterCount={activeFilterCount} />
    </Button>

    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>{locale.filter}</ModalHeader>
      <ModalBody>{children}</ModalBody>
    </Modal>
  </>
)

FilterModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  activeFilterCount: PropTypes.number.isRequired,
  children: PropTypes.node,
  locale: PropTypes.object.isRequired
}

export default withLocale(FilterModal, { key: 'facilitiesDashboard.filters' })
