import { SortableContainer } from 'react-sortable-hoc'

const { default: VariableRow } = require('./VariableRow')

const SortableBody = ({ variables, form, loading, tableRef }) => (
  <tbody ref={tableRef}>
    {variables.map((variable, index) => (
      <VariableRow {...{ variable, form, index, loading, key: variable.id }} />
    ))}
  </tbody>
)

export default SortableContainer(SortableBody)
