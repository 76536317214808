import { useState } from 'react'

import 'dayjs/locale/de'
import PropTypes from 'prop-types'

import InputTextValue from './inputTextValue'
import MantineMonthRangePicker from './mantineMonthRangePicker'

const defaultValueFormatter = ({ date, locale: dateLocaleKey }) => {
  // so the placeholder is shown when no value selected
  if (!date[0] && !date[1]) {
    return null
  }

  return <InputTextValue date={date} dateLocaleKey={dateLocaleKey} />
}

const MonthRangePicker = ({
  numberOfColumns,
  placeholder,
  value: initialValue = null,
  valueFormatter: valueFormatterOverride,
  valueFormat,
  onChange: commitChange,
  strictRange = false,
  ...mantineProps
}) => {
  const valueFormatter = valueFormatterOverride || defaultValueFormatter

  const [value, setValue] = useState(initialValue ? [initialValue.start, initialValue.end] : [null, null])

  const onChange = ([start, end]) => {
    setValue([start, end])
    if ((start === null && end === null) || !strictRange || (start !== null && end !== null)) {
      commitChange({
        start,
        end
      })
    }
  }

  return (
    <MantineMonthRangePicker
      {...{ numberOfColumns, placeholder, value, valueFormatter, valueFormat, onChange, ...mantineProps }}
    />
  )
}

MonthRangePicker.propTypes = {
  numberOfColumns: PropTypes.number,
  placeholder: PropTypes.string,
  value: PropTypes.shape({
    start: PropTypes.instanceOf(Date).isRequired,
    end: PropTypes.instanceOf(Date).isRequired
  }),
  valueFormatter: PropTypes.func,
  valueFormat: PropTypes.string,
  onChange: PropTypes.func,
  strictRange: PropTypes.bool // if true, the onChange callback will only be called when both start and end are set
}

export default MonthRangePicker
