import * as Sentry from '@sentry/react'

import { deleteFacilityDownload } from '../../../views/facility/facility.graphql'

const deleteDownload = (download, facility_uuid, client) =>
  client
    .mutate({
      mutation: deleteFacilityDownload,
      variables: { id: download.id }
    })
    .catch(error => {
      console.error(error)
      Sentry.captureException(error)
    })

export default deleteDownload
