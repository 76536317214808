import { Query } from '@apollo/client/react/components'
import { withApollo } from '@apollo/client/react/hoc'

import { getFormDatumWithRequirements } from './form-datum.graphql'

import Loading from '../../../helper/loading/loading'
import FormDatum from './form_datum'

const EditFormsDatumWithRequirementQueryComponent = props => (
  <Query
    query={getFormDatumWithRequirements}
    variables={{
      form_datum_uuid: props.match.params.uuid,
      category: props.match.params.category,
      guest_group: props.match.params.guest_group
    }}
  >
    {({ loading, error, data }) => {
      if (loading || !data) return <Loading />
      if (error) return `Error! ${error.message}`
      return <FormDatum {...props} {...data} isUpdate={true} isRequirement={true} />
    }}
  </Query>
)

export default withApollo(EditFormsDatumWithRequirementQueryComponent)
