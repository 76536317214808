const DataPrivacy = () => (
  <div className="box">
    <p>
      <h2>Datenschutzbestimmungen für die Reisen für Alle Datenbank „pflege.reisen-fuer-alle.de“</h2>
    </p>
    <p>
      <h4>
        1. Name und Kontaktdaten des für die Verarbeitung Verantwortlichen sowie des betrieblichen
        Datenschutzbeauftragten
      </h4>
    </p>
    <p>Diese Datenschutz-Information gilt für die Datenverarbeitung durch:</p>
    <p>
      Verantwortlicher: Deutsches Seminar für Tourismus (DSFT) Berlin e. V., Vorstand und Geschäftsführer: Rolf
      Schrader,{' '}
    </p>
    <p>Trautenaustr. 23, 10717 Berlin (Wilmersdorf)</p>
    <p>Telefon: +49 (0)30 / 23 55 19 - 0</p>
    <p>Fax: +49 (0)30 / 23 55 19 - 25</p>
    <p>E-Mail: info[at]dsft-berlin.de,</p>
    <p>
      Die betriebliche Datenschutzbeauftragte ist unter der o.g. Anschrift, zu Hd. Frau Christine Garbe, beziehungsweise
      unter{' '}
    </p>
    <p>
      <a href="mailto:christine.garbe@dsft-berlin.de">
        <u>christine.garbe(at)dsft-berlin.de</u>
      </a>
    </p>
    <p>erreichbar.</p>
    <p>
      <h4>2. Datenspeicherung</h4>
    </p>
    <p>
      (1)
      <br />
      Mit der Nutzung der Reisen für alle Online-Datenbank willigt der Nutzer ein, dass die personenbezogene Daten
      erhoben und gespeichert werden. Es werden nur die für die Abwicklung der Zertifizierung notwendigen Daten erhoben.
    </p>
    <p>
      (2) <br />
      Folgende personenbezogene Daten werden erhoben und gespeichert:{' '}
    </p>
    <p>
      Name, Vorname, Titel, Position im Unternehmen, Adresse des Unternehmens, berufliche E-Mail-Adresse, berufliche
      Telefonnummer{' '}
    </p>
    <p>Diese Daten werden von folgenden Personengruppen erfasst:</p>
    <p>
      Zertifizierte Erheber im System „Reisen für Alle“, Prüfer der Prüfstelle „Reisen für Alle“, Ansprechpartner der
      Lizenznehmer „Reisen für Alle“ sowie Ansprechpartner der im Zertifizierungsprozess befindlichen Betriebe
    </p>
    <p>
      Personenbezogene Daten der Erheber, Lizenznehmer, Prüfstelle und Unternehmen werden ein Jahr nach Ablauf der
      Zertifizierung bzw. nach Auslaufen des Lizenzvertrages aus der Datenbank gelöscht.{' '}
    </p>
    <p>
      (3) <br />
      Folgende unternehmensbezogene Daten werden erhoben und gespeichert:
    </p>
    <ul>
      <li>
        <em>Unternehmensdaten:</em> Firmierung, Adresse, Branche
        <br />
      </li>
      <li>
        <em>Aktionsdaten:</em> Aktivitäten im Verlauf der Zertifizierung (Kaufprozesse, Historie des
        Zertifizierungsverlaufes)
        <br />
      </li>
      <li>
        <em>Inhaltsdaten:</em> Ergebnisse der Erhebung (z.B. Türmaße), Prüfberichte, die auf Grundlage der Daten
        erstellt und gespeichert werden, Fotos.
        <br />
      </li>
      <li>
        <em>Upload-Daten: </em>Fotos von Einrichtungen des im Zertifizierungsprozess befindlichen Betriebs, die der
        Erheber in der Datenbank als Nachweis speichert.
        <br />
      </li>
    </ul>
    <p>
      (4)
      <br />
      Folgende technische Daten werden erhoben:{' '}
    </p>
    <ul>
      <li>
        Verwendete(r) Browsertyp/-version
        <br />
      </li>
      <li>
        Verwendetes Betriebssystem
        <br />
      </li>
      <li>
        Datum, Uhrzeit des Zugangs
        <br />
      </li>
      <li>
        Namen heruntergeladener/hochgeladener Dateien
        <br />
      </li>
      <li>
        IP-Adresse des Clientrechners
        <br />
      </li>
      <li>
        Protokollierung von Funktionsaufrufen
        <br />
      </li>
    </ul>
    <p>Diese technischen Informationen werden ohne jeden Bezug zu personenbezogenen Daten gespeichert. </p>
    <p>
      (5)
      <br />
      Die Reisen für Alle Datenbank verwendet Cookies (kleine Dateien, die mithilfe des Browsers auf der Festplatte
      gespeichert werden), um Unternehmen die Nutzung (z. B. Log-In, Navigation) zu erleichtern. Cookies enthalten keine
      personenbezogenen Informationen, weshalb die Privatsphäre in jedem Fall geschützt bleibt.{' '}
    </p>
    <p>Die „Reisen für Alle Datenbank“ verwendet sowohl</p>
    <ul>
      <li>
        Cookies, die nur für die Dauer einer Sitzung gespeichert werden (sog. Session-Cookies) als auch
        <br />
      </li>
      <li>
        Cookies, die über die Dauer einer Sitzung hinaus gespeichert bleiben.
        <br />
      </li>
    </ul>
    <p>
      Session-Cookies werden automatisch gelöscht, sobald der Browser geschlossen wird. Cookies können jederzeit über
      die entsprechende Browser-Funktionalität gelöscht werden.{' '}
    </p>
    <p>
      <h4>3. Einsicht in die Daten und Löschung</h4>
    </p>
    <p>(6)</p>
    <p>
      Die personenbezogenen Daten der Erheber können im Falle einer Beauftragung durch die sie beauftragenden
      Lizenznehmer, die im Zertifizierungsprozess befindlichen Betriebe sowie die Mitarbeiter der Prüfstelle eingesehen
      werden.
    </p>
    <p>
      Die personenbezogenen Daten der Erheber werden ein Jahr nach Auslaufen der Gültigkeit des Zertifikats als Erheber
      aus der Datenbank gelöscht.
    </p>
    <p>(7)</p>
    <p>
      Die personenbezogenen Daten der Lizenznehmer können durch die im Zertifizierungsprozess befindlichen Betriebe des
      jeweiligen Bundeslandes, die beauftragten Erheber und die Mitarbeiter der Prüfstelle eingesehen werden.
    </p>
    <p>
      Die personenbezogenen Daten der Lizenznehmer werden ein Jahr nach Auslaufen des Lizenzvertrages aus der Datenbank
      gelöscht.{' '}
    </p>
    <p>(8)</p>
    <p>
      Die personenbezogenen Daten der im Zertifizierungsprozess befindlichen Betriebe können durch die Lizenznehmer des
      jeweiligen Bundeslandes, die beauftragten Erheber und die Mitarbeiter der Prüfstelle eingesehen werden.
    </p>
    <p>
      Die personenbezogenen Daten der zertifizierten Betriebe werden ein Jahr nach Auslaufen der Gültigkeit des
      Zertifikats „Reisen für Alle“ gelöscht.{' '}
    </p>
    <p>
      Die Unternehmensdaten der zertifizierten Betriebe werden ebenfalls ein Jahr nach Auslaufen der Zertifizierung
      gelöscht, wenn sich der Betrieb bis dahin nicht zur Re-Zertifizierung angemeldet hat.
    </p>
    <p>
      (9) <br />
      Hier schreiben, was konkret webit macht. Außerdem wo die Daten gehostet werden und wo der Server steht.{' '}
    </p>
    <p>Das DSFT hat Auftragsverarbeitungsverträge mit den oben genannten Dienstleistern abgeschlossen. </p>
    <p>
      <h4>4. Datenauswertung </h4>
    </p>
    <p>
      (10)
      <br />
      Die im Zusammenhang mit Zertifizierung erhobenen Daten (Branche, Sitz des Unternehmens, Datum der Zertifizierung,
      Zertifizierungsstufe) werden durch das DSFT und die Lizenznehmer ausgewertet, um die Entwicklung der Initiative zu
      verfolgen. Diese Daten werden für die Berichterstattung und Öffentlichkeitsarbeit genutzt.
    </p>
    <p>
      <h4>5. Betroffenenrechte</h4>
    </p>
    <p>Sie haben das Recht:</p>
    <ul>
      <li>
        gemäß Art. 15 DSGVO Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten zu verlangen. Insbesondere
        können Sie Auskunft über die Verarbeitungszwecke, die Kategorie der personenbezogenen Daten, die Kategorien von
        Empfängern, gegenüber denen Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer, das Bestehen
        eines Rechts auf Berichtigung, Löschung, Einschränkung der Verarbeitung oder Widerspruch, das Bestehen eines
        Beschwerderechts, die Herkunft ihrer Daten, sofern diese nicht bei uns erhoben wurden, sowie über das Bestehen
        einer automatisierten Entscheidungsfindung einschließlich Profiling und ggf. aussagekräftigen Informationen zu
        deren Einzelheiten verlangen;
        <br />
      </li>
      <li>
        gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer bei uns gespeicherten
        personenbezogenen Daten zu verlangen;
        <br />
      </li>
      <li>
        gemäß Art. 17 DSGVO die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen, soweit nicht
        die Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer
        rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder
        Verteidigung von Rechtsansprüchen erforderlich ist;
        <br />
      </li>
      <li>
        gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, soweit die
        Richtigkeit der Daten von Ihnen bestritten wird, die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung
        ablehnen und wir die Daten nicht mehr benötigen, Sie jedoch diese zur Geltendmachung, Ausübung oder Verteidigung
        von Rechtsansprüchen benötigen oder Sie gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt haben;
        <br />
      </li>
      <li>
        gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten,
        gängigen und maschinenlesebaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu
        verlangen;
        <br />
      </li>
      <li>
        gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung jederzeit gegenüber uns zu widerrufen. Dies hat zur
        Folge, dass wir die Datenverarbeitung, die auf dieser Einwilligung beruhte, für die Zukunft nicht mehr
        fortführen dürfen und
        <br />
      </li>
      <li>
        gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde zu beschweren. In der Regel können Sie sich hierfür an die
        Aufsichtsbehörde Ihres üblichen Aufenthaltsortes oder Arbeitsplatzes oder unseres Kanzleisitzes wenden.
        <br />
      </li>
    </ul>
    <p>
      <h4>6. Widerspruchsrecht</h4>
    </p>
    <p>
      Sofern Ihre personenbezogenen Daten auf Grundlage von berechtigten Interessen gemäß Art. 6 Abs. 1 S. 1 lit. f
      DSGVO verarbeitet werden, haben Sie das Recht, gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung Ihrer
      personenbezogenen Daten einzulegen, soweit dafür Gründe vorliegen, die sich aus Ihrer besonderen Situation ergeben
      oder sich der Widerspruch gegen Direktwerbung richtet. Im letzteren Fall haben Sie ein generelles
      Widerspruchsrecht, das ohne Angabe einer besonderen Situation von uns umgesetzt wird.
    </p>
    <p>Möchten Sie von Ihrem Widerrufs- oder Widerspruchsrecht Gebrauch machen, genügt eine E-Mail an</p>
    <p>Christine.garbe(at)dsft-berlin.de</p>
    <p>
      <h4>7. Datensicherheit</h4>
    </p>
    <p>
      Wir verwenden innerhalb des Website-Besuchs das verbreitete SSL-Verfahren (Secure Socket Layer) in Verbindung mit
      der jeweils höchsten Verschlüsselungsstufe, die von Ihrem Browser unterstützt wird. In der Regel handelt es sich
      dabei um eine 256 Bit Verschlüsselung. Falls Ihr Browser keine 256-Bit Verschlüsselung unterstützt, greifen wir
      stattdessen auf 128-Bit v3 Technologie zurück. Ob eine einzelne Seite unseres Internetauftrittes verschlüsselt
      übertragen wird, erkennen Sie an der geschlossenen Darstellung des Schüssel- beziehungsweise Schloss-Symbols in
      der unteren Statusleiste Ihres Browsers.
    </p>
    <p>
      Wir bedienen uns im Übrigen geeigneter technischer und organisatorischer Sicherheitsmaßnahmen, um Ihre Daten gegen
      zufällige oder vorsätzliche Manipulationen, teilweisen oder vollständigen Verlust, Zerstörung oder gegen den
      unbefugten Zugriff Dritter zu schützen. Unsere Sicherheitsmaßnahmen werden entsprechend der technologischen
      Entwicklung fortlaufend verbessert.
    </p>
    <p>
      <h4>8. Aktualität und Änderung dieser Datenschutzerklärung</h4>
    </p>
    <p>
      Diese Datenschutzerklärung ist aktuell gültig und hat den Stand Mai 2018. Durch die Weiterentwicklung unserer
      Website und Angebote darüber oder aufgrund geänderter gesetzlicher beziehungsweise behördlicher Vorgaben kann es
      notwendig werden, diese Datenschutzerklärung zu ändern. Die jeweils aktuelle Datenschutzerklärung kann jederzeit
      auf der Website unter https://www.dsft-berlin.de/datenschutz von Ihnen abgerufen und ausgedruckt werden.
    </p>
  </div>
)

export default DataPrivacy
