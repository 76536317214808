import { Link } from 'react-router-dom'
import { SortableElement } from 'react-sortable-hoc'
import { Button, UncontrolledTooltip } from 'reactstrap'

import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import DragHandle from 'components/modules/sortable/DragHandle'
import { withLocale } from 'locale'

import { DeleteShortReportVariable } from '../..'

const VariableRow = ({ variable, form, locale, loading }) => (
  <tr>
    <td>
      <Link to={`/form-kit/short-report-variables/${variable.id}`}>{variable.name}</Link>
    </td>
    <td>{variable.description}</td>
    <td style={{ textAlign: 'center' }}>
      <Button
        className="btn-transparent btn-icon-only btn"
        color="link"
        to={`/form-kit/short-report-variables/${variable.id}`}
        tag={Link}
        id={`button-edit-variable-${variable.id}`}
      >
        <FontAwesomeIcon icon={faEdit} />
      </Button>
      <UncontrolledTooltip placement="top" target={`button-edit-variable-${variable.id}`} delay={0}>
        {locale.short_report.variable.actions.edit()}
      </UncontrolledTooltip>
      <DeleteShortReportVariable {...{ variable }} />
      <DragHandle {...{ loading }} />
    </td>
  </tr>
)

export default SortableElement(withLocale(VariableRow))
