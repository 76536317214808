import React from 'react'

import { Col, Input, Row, Table } from 'reactstrap'

// GraphQL
import { graphql, withApollo } from '@apollo/client/react/hoc'
// Icons
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import { getWidgetFilters } from './widget_filters.graphql'

import { DD_MM_YYYY__HH_mm } from '../../../helper/helper-functions'
import Loading from '../../../helper/loading/loading.jsx'
import DeleteWidgetFilterModal from '../../../modules/modal/deleteWidgetFilterModal.jsx'
import Modal from '../../../modules/modal/modal.jsx'
import UpdateWidgetFilterModal from '../../../modules/modal/updateWidgetFilterModal.jsx'
// additional components
import FormCreate from './create.jsx'

class WidgetFilters extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      search_query: ''
    }
  }

  change = event => this.setState({ [event.target.id]: event.target.value })

  search = () => {
    const widget_filters = this.props.data.widget_filters
    const result = []
    if (this.state.search_query.length !== 0) {
      for (let i = 0; i < widget_filters.length; i++) {
        if (
          widget_filters[i].name_de &&
          widget_filters[i].name_de.toLowerCase().includes(this.state.search_query.toLowerCase())
        ) {
          result.push(widget_filters[i])
          continue
        }
        if (
          widget_filters[i].name_en &&
          widget_filters[i].name_en.toLowerCase().includes(this.state.search_query.toLowerCase())
        ) {
          result.push(widget_filters[i])
        }
      }
      return result
    }
    return widget_filters
  }

  render = () => {
    const {
      locale,
      client,
      history,
      data: { loading, error }
    } = this.props
    const { search_query } = this.state
    const widget_filters = this.search()

    if (loading) return <Loading />
    if (error) return <p>Error</p>

    return (
      <div className="page-wrapper">
        <Row className="page-header">
          <Col sm="6">
            <h1>{locale.widget_filter.plural}</h1>
          </Col>
          <Col sm="3" className="text-right">
            <Input
              id="search_query"
              placeholder="Widget Filter durchsuchen..."
              value={search_query}
              onChange={this.change}
            />
          </Col>
          <Col sm="3" className="text-right">
            <Modal
              children={<FormCreate locale={locale} client={client} history={history} />}
              toggleModalText={locale.add_widget_filter}
              button_type={{ icon_only: false, icon_type: faPlus, color: 'primary-light', classes: 'btn-labeled' }}
              modal_size={'lg'}
            />
          </Col>
        </Row>
        <hr className="seperator" />
        <Table striped responsive bordered>
          <thead className="thead-light">
            <tr>
              <th>{locale.name_de}</th>
              <th>{locale.name_en}</th>
              <th>{locale.created_at}</th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {widget_filters.map(widget_filter => (
              <WidgetFilter key={widget_filter.id} widget_filter={widget_filter} client={client} locale={locale} />
            ))}
          </tbody>
        </Table>
      </div>
    )
  }
}

const WidgetFilter = ({ widget_filter, history, locale, client }) => (
  <tr>
    <td>
      <UpdateWidgetFilterModal widget_filter={widget_filter} client={client} history={history} locale={locale} />
    </td>
    <td>{widget_filter?.name_en || '-'}</td>
    <td>{DD_MM_YYYY__HH_mm(widget_filter?.created_at)}</td>
    <td className="text-center">
      {widget_filter.destroyable && (
        <DeleteWidgetFilterModal
          id={widget_filter.id}
          client={client}
          name={widget_filter.name_de}
          history={history}
          locale={locale}
        />
      )}
    </td>
  </tr>
)

export default withApollo(
  graphql(getWidgetFilters, {
    options: () => ({
      notifyOnNetworkStatusChange: true
    })
  })(WidgetFilters)
)
