import { getPropertyFromObject } from 'components/helper/helper-functions'
import ValidatedInformationInput from 'components/modules/inputs/validated-information-input'
import { withLocale } from 'locale'
import PropTypes from 'prop-types'

import { FormGroupContainer } from './form_group_container'

const TranslatedInput = withLocale(({ id, type, errors, variables, onChange, locale }) => {
  const translatableVariable = getPropertyFromObject(variables, id)
  const translatedName = getPropertyFromObject(locale, id)

  return Object.keys(translatableVariable).map(localeKey => {
    const key = `${id}.${localeKey}`

    return (
      <FormGroupContainer key={key}>
        <ValidatedInformationInput
          id={key}
          customLabel={`${translatedName} (${localeKey})`}
          {...{ errors, type, variables, onChange, locale }}
        />
      </FormGroupContainer>
    )
  })
})

TranslatedInput.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  errors: PropTypes.object,
  variables: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
}

export { TranslatedInput }
