import { graphql, withApollo } from '@apollo/client/react/hoc'

import { Select } from 'components/modules/selects/react-select'

import { getAllOrganizations } from './organizations.graphql'

const Organizations = ({ data, id, value, change }) => {
  const options =
    data.organizations?.map(({ id: org_id, name, short_name }) => ({
      value: org_id,
      label: `${name}${short_name ? ` (${short_name})` : ''}`
    })) || []

  const selected = options.filter(({ value: org_id }) => value?.includes(org_id))

  // construct a fake event for the old legacy change event to handle
  const onChange = selectedOptions => change({ target: { id: id, value: selectedOptions.map(({ value }) => value) } })

  return <Select isDisabled={data.loading} isMulti options={options} id={id} value={selected} onChange={onChange} />
}

export default withApollo(
  graphql(getAllOrganizations, {
    options: () => ({
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true
    })
  })(Organizations)
)
