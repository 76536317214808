import { useEffect } from 'react'

import { Link } from 'react-router-dom'
import { Button, Col, Row } from 'reactstrap'

import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { VariablesTable } from '.'
import { withLocale } from '../../../../locale'
import { withPageHeader } from '../../../modules/page'

const ShortReportVariables = ({ locale, setPageHeader }) => {
  useEffect(() => {
    setPageHeader(locale.short_report.variable.plural)
  }, [])

  return (
    <Row>
      <Col sm="12">
        <Button
          color="primary-light"
          outline
          className="float-right"
          tag={Link}
          to="/form-kit/short-report-variables/new"
        >
          <FontAwesomeIcon icon={faPlus} style={{ marginRight: '1rem' }} />
          {locale.short_report.variable.actions.create}
        </Button>
      </Col>
      <Col sm="12">
        <VariablesTable />
      </Col>
    </Row>
  )
}

export default withPageHeader(withLocale(ShortReportVariables))
