import React from 'react'

import { Button, Col, Container, FormGroup, Modal, ModalBody, ModalHeader, Row, Tooltip } from 'reactstrap'

import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  getFacility,
  getFacilitySectionData,
  getFormDataForSectionDatum,
  getFormDataWithoutSection
} from '../../views/facility/facility.graphql'
import { delete_form_datum } from '../../views/facility/form-datum/form-datum.graphql'

import generateUUID from '../../../helpers/guid'
import Loading from '../../helper/loading/loading'

class DeleteFormDatumModal extends React.Component {
  state = {
    modal: false,
    processing: false,
    tooltip_uuid: generateUUID()
  }

  clean = () =>
    this.setState({
      modal: false,
      processing: false
    })

  toggle = event => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    this.setState({
      modal: !this.state.modal
    })
  }

  toggleTooltip = () => this.setState({ tooltip_open: !this.state.tooltip_open })

  deleteFormDatum = () => {
    this.setState({ processing: true })

    let refetch = this.props.section_datum_uuid
      ? {
          query: getFormDataForSectionDatum,
          variables: { uuid: this.props.section_datum_uuid }
        }
      : {
          query: getFormDataWithoutSection,
          variables: { uuid: this.props.facility_uuid, version_number: '' }
        }

    this.props.client
      .mutate({
        mutation: delete_form_datum,
        variables: { uuid: this.props.form_datum_uuid },
        refetchQueries: [
          refetch,
          {
            query: getFacilitySectionData,
            variables: { uuid: this.props.facility_uuid, version_number: '' }
          },
          {
            query: getFacility,
            variables: { uuid: this.props.facility_uuid, version_number: '' }
          }
        ],
        awaitRefetchQueries: true
      })
      .then(() => this.clean())
  }

  render = () => {
    const { className, name, locale } = this.props
    const { tooltip_uuid, tooltip_open, modal, processing } = this.state
    const { deleteFormDatum, toggle, toggleTooltip } = this

    return (
      <React.Fragment>
        <Button
          size={`sm`}
          className="btn-transparent btn-icon-only btn mr-1"
          color={'link'}
          onClick={toggle}
          id={`tooltip-modal_${tooltip_uuid}`}
        >
          <FontAwesomeIcon className="text-info" icon={faTrashAlt} />
        </Button>
        <Tooltip
          delay={500}
          placement="top"
          isOpen={tooltip_open}
          target={`tooltip-modal_${tooltip_uuid}`}
          toggle={toggleTooltip}
        >
          {locale.delete}
        </Tooltip>
        <Modal centered isOpen={modal} toggle={toggle} className={className} size={'lg'}>
          <ModalHeader>Formular löschen</ModalHeader>
          <ModalBody>
            <p className="mt-3">
              Möchten Sie das Formular <strong className="text-danger">{name}</strong> wirklich löschen?
            </p>
            {processing && <Loading loadingText={`wird gelöscht...`} size={`1x`} />}
            <FormGroup className="form-action">
              <Container>
                <Row>
                  <Col sm="5">
                    <a className="link-btn" onClick={toggle} href="#">
                      {locale.cancel}
                    </a>
                  </Col>
                  <Col sm="7" className="text-right">
                    <Button outline onClick={deleteFormDatum} className="btn-labeled px-5" color="danger">
                      <FontAwesomeIcon className="icon-prepend" icon={faTrashAlt} />
                      <span>{locale.delete}</span>
                    </Button>
                  </Col>
                </Row>
              </Container>
            </FormGroup>
          </ModalBody>
        </Modal>
      </React.Fragment>
    )
  }
}

export default DeleteFormDatumModal
