import React from 'react'

import { Alert } from 'reactstrap'

import { withApollo } from '@apollo/client/react/hoc'

import OrganizationSelect from 'components/modules/selects/organization-select'
import { deepNestInObjectsWithKeys, objectWithoutKeys } from 'helpers/object'
import update from 'immutability-helper'

import { getFacility } from '../../facility.graphql'
import { updateBaseData } from './information.graphql'

import ValidatedInformationInput from '../../../../modules/inputs/validated-information-input'
import { HelpTooltip } from '../../form-datum/help-tooltip'
import { FormGroupContainer, FormGroupContainerActions } from '../partials'
import { TranslatedInput } from '../partials/translated_input'

const BaseDataForm = props => (
  <div className="box">
    <Form {...props} />
  </div>
)

class Form extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error_msg: '',
      processing: false,
      variables: {
        uuid: this?.props?.facility.uuid,
        base_data: {
          name_de: this?.props?.facility?.base_data?.name_de || '',
          name_en: this?.props?.facility?.base_data?.name_en || '',
          description_de: this?.props?.facility?.base_data?.description_de || '',
          description_en: this?.props?.facility?.base_data?.description_en || '',
          phone: this?.props?.facility?.base_data?.phone || '',
          fax: this?.props?.facility?.base_data?.fax || '',
          email: this?.props?.facility?.base_data?.email || '',
          website: this?.props?.facility?.base_data?.website || '',
          organization_ids: this?.props?.facility?.base_data?.organizations?.map(({ id }) => id) || [],
          metaDescription: objectWithoutKeys(this?.props?.facility?.base_data?.metaDescription || {}, '__typename')
        }
      }
    }
  }

  onChange = event => {
    const fragments = event.target.id.split('.')
    const variables = deepNestInObjectsWithKeys(event.target.value, ...fragments, '$set')

    this.setState(update(this.state, { variables }))
  }

  onError = ({ graphQLErrors }) =>
    this.setState({
      processing: false,
      error_msg: graphQLErrors[0].message,
      errors: graphQLErrors[0].errors
    })

  onSuccess = () =>
    this.setState(
      {
        processing: false,
        error_msg: '',
        errors: {}
      },
      this.props.hideEditBoxRight()
    )

  submit = () => {
    this.setState({ processing: true })
    const {
      props: { client },
      state: { variables }
    } = this
    client
      .mutate({
        mutation: updateBaseData,
        variables,
        refetchQueries: [
          {
            query: getFacility,
            variables: {
              uuid: this.props.facility.uuid,
              version_number: this.props.match.params.version_number || ''
            }
          }
        ],
        awaitRefetchQueries: true
      })
      .then(() => this.onSuccess())
      .catch(error => this.onError(error))
  }

  render = () => {
    const {
      onChange,
      submit,
      props: {
        locale,
        hideEditBoxRight,
        facility: { permissions }
      },
      state: { variables, errors, error_msg, processing }
    } = this

    const input_props = { errors, variables, locale, onChange }
    const actions_props = { locale, hideEditBoxRight, processing, submit }

    return (
      <React.Fragment>
        {error_msg && <Alert color="danger">{error_msg}</Alert>}
        <FormGroupContainer children={<ValidatedInformationInput id={'base_data.name_de'} {...input_props} />} />
        <FormGroupContainer children={<ValidatedInformationInput id={'base_data.name_en'} {...input_props} />} />
        <FormGroupContainer
          children={<ValidatedInformationInput type={'textarea'} id={'base_data.description_de'} {...input_props} />}
        />
        <FormGroupContainer
          children={<ValidatedInformationInput type={'textarea'} id={'base_data.description_en'} {...input_props} />}
        />
        <FormGroupContainer
          children={<ValidatedInformationInput id={'base_data.fax'} errors={errors} {...input_props} />}
        />
        <FormGroupContainer children={<ValidatedInformationInput id={'base_data.email'} {...input_props} />} />
        <FormGroupContainer children={<ValidatedInformationInput id={'base_data.phone'} {...input_props} />} />
        <FormGroupContainer children={<ValidatedInformationInput id={'base_data.website'} {...input_props} />} />

        <TranslatedInput id="base_data.metaDescription" type="textarea" {...input_props} />

        {permissions.update_confidential_base_data && (
          <FormGroupContainer>
            <ValidatedInformationInput
              id={'base_data.organization_ids'}
              {...input_props}
              customLabel={
                <>
                  {locale.base_data.organization_ids}
                  <HelpTooltip help_text={locale.base_data.organizations_help} />
                </>
              }
            >
              <OrganizationSelect
                invalid
                id="base_data.organization_ids"
                change={onChange}
                value={variables.base_data.organization_ids}
              />
            </ValidatedInformationInput>
          </FormGroupContainer>
        )}
        <FormGroupContainerActions {...actions_props} />
      </React.Fragment>
    )
  }
}

export default withApollo(BaseDataForm)
