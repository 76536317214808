// Returns an object with each key nested in a new object assigned to a key in the outer one.
// Example:
// ```js
//   deepNestInObjectsWithKeys('baz', 'foo', 'bar')
//   => { foo: { bar: 'baz' } }
// ```
const deepNestInObjectsWithKeys = (value, ...keys) =>
  [...keys].reverse().reduce((accumulator, key) => ({ [key]: accumulator }), value)

export default deepNestInObjectsWithKeys
