import React from 'react'

import { TokenAuth } from '../../../helpers/token-auth'
// additional components
import NavMenu from '../nav-menu/nav-menu.jsx'

// CSS
import './_header.scss'

class Header extends React.Component {
  constructor(props) {
    super(props)
  }
  render = () => (
    <header id="header" className="header">
      <NavMenu userData={UserCredentials()} locale={this.props.locale} online={this.props.online} />
    </header>
  )
}

const UserCredentials = () => {
  let permissions = TokenAuth.getPermissions()
  if (permissions === null) {
    permissions = {
      administrate_access_control: false,
      administrate_all_collectors: false,
      administrate_all_users: false,
      administrate_licensee_owners: false,
      administrate_regions: false,
      administrate_roles: false,
      change_all_facilities: false,
      change_certificate: false,
      construction_kit: false,
      delete_facility: false,
      show_all_facilities: false
    }
  }
  let user = TokenAuth.getUser()
  if (user === null) user = {}
  return Object.assign(user, { permissions })
}

export default Header
