// Icons
import React from 'react'

import { Button, Col, Container, FormGroup, Input, Label, Row } from 'reactstrap'

import { faSave } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// additional components
import { LegacySelect as Select } from 'components/modules/selects/react-select'

const FormGroups = ({
  possibleForms,
  section,
  change,
  changeAdditional,
  changeStandard,
  submit,
  toggleModal,
  locale
}) => {
  let standard_forms_options = []
  let additional_forms_options = []

  possibleForms.map(possibleForm => {
    standard_forms_options.push({
      value: possibleForm.id || '',
      label: possibleForm.name_de
    })
    additional_forms_options.push({
      value: possibleForm.id || '',
      label: possibleForm.name_de
    })
  })

  const {
    form_version_id,
    name_de,
    name_en,
    missing_message_de,
    missing_message_en,
    standard_form_ids,
    additional_form_ids
  } = section

  return (
    <React.Fragment>
      <FormGroup>
        <Container>
          <Row>
            <Col sm="2" className="text-right">
              <Label className="control-label" for="name_de">
                (deutsch)
              </Label>
            </Col>
            <Col sm="4">
              <Input type="text" name="name_de" id="name_de" value={name_de} onChange={change} />
            </Col>
            <Col sm="2" className="text-right">
              <Label className="control-label" for="name_en">
                (englisch)
              </Label>
            </Col>
            <Col sm="4">
              <Input type="text" name="name_en" id="name_en" value={name_en} onChange={change} />
            </Col>
          </Row>
        </Container>
        <h4>Nachrichten bei fehlendem Teilbereich</h4>
        <hr className="seperator seperator-form border-gray" />
        <Container>
          <Row>
            <Col sm="2" className="text-right">
              <Label className="control-label" for="missing_message_de">
                (deutsch)
              </Label>
            </Col>
            <Col sm="4">
              <Input
                type="textarea"
                rows={5}
                name="missing_message_de"
                id="missing_message_de"
                value={missing_message_de || ''}
                onChange={change}
              />
            </Col>
            <Col sm="2" className="text-right">
              <Label className="control-label" for="missing_message_en">
                (englisch)
              </Label>
            </Col>
            <Col sm="4">
              <Input
                type="textarea"
                rows={5}
                name="missing_message_en"
                id="missing_message_en"
                value={missing_message_en || ''}
                onChange={change}
              />
            </Col>
          </Row>
        </Container>
        <h4>Formulare</h4>
        <hr className="seperator seperator-form border-gray" />
        <Container>
          <Row>
            <Col sm="2" className="text-right">
              <Label className="control-label" for="first_name">
                Standard
              </Label>
            </Col>
            <Col sm="4">
              <Select
                isClearable={true}
                isMulti
                simpleValue
                name="status"
                value={standard_form_ids}
                onChange={changeStandard}
                options={standard_forms_options}
              />
            </Col>
            <Col sm="2" className="text-right">
              <Label className="control-label" for="last_name">
                Zusätzlich
              </Label>
            </Col>
            <Col sm="4">
              <Select
                isClearable={true}
                isMulti
                simpleValue
                name="status"
                value={additional_form_ids}
                onChange={changeAdditional}
                options={additional_forms_options}
              />
            </Col>
          </Row>
        </Container>
      </FormGroup>
      <FormGroup className="form-action">
        <Container>
          <Row>
            <Col sm="2">
              <a
                className="link-btn"
                onClick={e => {
                  e.preventDefault()
                  toggleModal(e)
                }}
                href="#"
              >
                {locale.cancel}
              </a>
            </Col>
            <Col sm={{ size: '4', offset: '6' }} className="text-right">
              <Button className="btn-labeled" color="primary-light" onClick={submit}>
                <FontAwesomeIcon className="icon-prepend" icon={faSave} />
                <span>{locale.save}</span>
              </Button>
            </Col>
          </Row>
        </Container>
      </FormGroup>
    </React.Fragment>
  )
}

export default FormGroups
