import React from 'react'

import { Col, FormFeedback, Input, InputGroup, InputGroupAddon, Label, Row } from 'reactstrap'

import { HelpTooltip } from '../help-tooltip'
import Requirements from './Requirements'

const Area = ({
  requirements_with_status,
  depth,
  help_text,
  help_image,
  field_number,
  uuid,
  changeFieldValue,
  parent_field_id,
  name,
  enabled,
  processing,
  value_de,
  value_en,
  field_id,
  unit_de,
  secondary_values,
  embedded,
  i,
  errors
}) => (
  <React.Fragment>
    <Row
      className={
        depth > 0
          ? `mt-0 mb-0${enabled ? ' ' : ' hidden '}parent-${parent_field_id || 'none'}`
          : `${enabled ? '' : 'hidden '}parent-${parent_field_id || 'none'}`
      }
    >
      <Col
        sm={{ size: 6 - depth, offset: depth }}
        lg={{ size: 3, offset: depth }}
        className={depth > 0 ? 'pt-3 pb-3-lg pl-4-md-down border-left border-secondary' : 'pl-4'}
      >
        <Label
          className={`control-label align-items-center justify-content-start ${
            errors?.value_de || errors?.secondary_values ? 'text-danger' : ''
          }`}
          for={uuid}
        >
          {field_number && <span className="text-primary-light">{field_number}&nbsp;&mdash;&nbsp;</span>}
          {name}
          <HelpTooltip help_text={help_text} help_image={help_image} uuid={uuid} />
        </Label>
        <Requirements requirements_with_status={requirements_with_status} />
      </Col>
      <Col
        sm={{ size: 6, offset: depth }}
        lg={{ size: 5, offset: 0 }}
        className={depth > 0 ? 'pb-3 pt-3-lg pl-4 border-left-md-only border-secondary' : ''}
      >
        <InputGroup>
          <Input
            disabled={processing}
            onChange={e =>
              changeFieldValue({
                value_de: e.target.value,
                uuid,
                field_id,
                embedded,
                i
              })
            }
            invalid={!!errors?.value_de}
            placeholder={unit_de.split('|')[0]?.split(';')[0]}
            value={value_de}
          />
          <InputGroupAddon addonType="append">{unit_de.split('|')[0]?.split(';')[1]}</InputGroupAddon>
          <Input
            disabled={processing}
            className="ml-2"
            onChange={e =>
              changeFieldValue({
                type: 'area',
                secondary_value: e.target.value,
                uuid,
                field_id,
                embedded,
                i
              })
            }
            invalid={!!errors?.secondary_values}
            placeholder={unit_de.split('|')[1]?.split(';')[0]}
            value={secondary_values[0]}
          />
          <InputGroupAddon addonType="append">{unit_de.split('|')[1]?.split(';')[1]}</InputGroupAddon>
        </InputGroup>
      </Col>
      {(errors?.value_de || errors?.secondary_values) && (
        <Col sm={{ size: 6, offset: depth }} lg={{ size: 5, offset: 3 + depth }}>
          <FormFeedback valid={false} className="d-inline-block w-50">
            {errors?.value_de}
          </FormFeedback>
          <FormFeedback valid={false} className="d-inline-block w-50 pl-2">
            {errors?.secondary_values}
          </FormFeedback>
        </Col>
      )}
    </Row>
  </React.Fragment>
)

export default Area
