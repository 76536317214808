import { useApolloClient } from '@apollo/client'

import { execute } from 'apollo-link'
import Header from 'components/modules/header/header'
import { GraphiQL } from 'graphiql'
import { parse } from 'graphql/language/parser'
import { withLocale } from 'locale'

import './graphiql.scss'

const GraphiQl = ({ locale, online }) => {
  const client = useApolloClient()

  const fetcher = params => {
    return execute(client.link, { ...params, query: parse(params.query) })
  }

  return (
    <>
      <Header locale={locale} online={online} />
      <GraphiQL fetcher={fetcher} />
    </>
  )
}

export default withLocale(GraphiQl)
