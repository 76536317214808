import React from 'react'

import { graphql, withApollo } from '@apollo/client/react/hoc'

import { LegacySelect as Select } from 'components/modules/selects/react-select'

import { query } from './graphql'

class DefaultDsftUsers extends React.Component {
  options = () => {
    const { possible_default_dsft_users } = this.props.data.license_owner
    let options = []
    possible_default_dsft_users.map(user =>
      options.push({
        value: user.id,
        label: user.full_name,
        id: 'default_dsft_user_id',
        target: { id: 'default_dsft_user_id', value: user.id }
      })
    )
    return options
  }

  render = () => {
    const { error, loading } = this.props.data
    const { value, change } = this.props

    if (loading)
      return <Select isClearable={false} name="default_dsft_user" value={value} onChange={change} options={[]} />
    if (error) {
      return <p>Error</p>
    }
    return <Select name="default_dsft_user" value={value} onChange={change} options={this.options()} />
  }
}

export default withApollo(
  graphql(query, {
    options: props => ({
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      variables: {
        id: props.id
      }
    })
  })(DefaultDsftUsers)
)
