import Checkbox from 'components/ui/checkbox/checkbox'
import PropTypes from 'prop-types'

const FacilityKindCheckbox = ({ id, name_de, selectedFacilityKindIds, changeFacilityKindIds }) => {
  const checked = selectedFacilityKindIds.includes(id)
  const changeMethod = event => {
    event.stopPropagation()
    if (checked) {
      changeFacilityKindIds(selectedFacilityKindIds.filter(selectedId => selectedId !== id))
    } else {
      changeFacilityKindIds([...selectedFacilityKindIds, id])
    }
  }

  return <Checkbox checked={checked} onChange={changeMethod} label={name_de} />
}

FacilityKindCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  name_de: PropTypes.string.isRequired,
  selectedFacilityKindIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  changeFacilityKindIds: PropTypes.func.isRequired
}

export { FacilityKindCheckbox }
