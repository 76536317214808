import { Fragment, useState } from 'react'

import { Form, FormFeedback, Input, InputGroup, InputGroupAddon } from 'reactstrap'

import { useApolloClient, useMutation } from '@apollo/client'
import { faSave } from '@fortawesome/free-solid-svg-icons'

import ImgCertifiedAccessibility from 'components/assets/certified-accessibility.svg'
import ImgDocumentedAccessibility from 'components/assets/documented-accessibility.svg'
import { ProcessingButton } from 'components/modules/buttons/processing-button'
import { CertificateList } from 'components/views/facility/certification-workflow/facility-certification-status'
import { withLocale } from 'locale'

import { createOrUpdateAssetDownload } from './asset_downloads.graphql'

const CombinationImages = withLocale(({ locale, combination, info }) => {
  const CertificationImage = info ? ImgDocumentedAccessibility : ImgCertifiedAccessibility

  return (
    <div className="align-items-center d-flex ml-auto mb-0 text-right">
      <img className="certificate-icon" src={CertificationImage} height="50" alt="" />
      {!info && (
        <ul className="list-inline certificate-list">
          <CertificateList locale={locale} properties={combination} />
        </ul>
      )}
    </div>
  )
})

const combinationKey = (combination, locale) => {
  const info = Object.values(combination).every(group => group === 0)

  if (info) return locale.combinationKeys['info']
  else
    return Object.keys(combination)
      .filter(group => combination[group] > 0)
      .map(group => `${locale.combinationKeys[group]}${combination[group]}`)
}

const DownloadEntry = ({ locale, data: { url, __typename, ...combination } }) => {
  const { cache } = useApolloClient()
  const info = Object.values(combination).every(group => group === 0)
  const [formUrl, updateFormUrl] = useState(url || '')
  const [error, setError] = useState(null)
  const [updateAssetDownload, { loading: processing }] = useMutation(createOrUpdateAssetDownload)

  const key = combinationKey(combination, locale)

  const update = e => {
    e.preventDefault()
    e.stopPropagation()

    updateAssetDownload({ variables: { asset_download: { url: formUrl, ...combination } } })
      .then(result => {
        setError(null)
        cache.modify({
          id: cache.identify({ __typename, ...combination }),
          fields: {
            url() {
              return result.data.create_or_update_asset_download.url
            }
          }
        })
      })
      .catch(({ graphQLErrors, networkError }) => {
        console.log(graphQLErrors, networkError)

        if (graphQLErrors) {
          setError(graphQLErrors[0]?.errors?.asset_download?.url || graphQLErrors[0]?.message)
        }

        if (networkError) {
          setError(networkError)
        }
      })
  }

  const inputId = `asset-download-url-${Object.values(combination).join('-')}`

  return (
    <tr>
      <td>
        <CombinationImages combination={combination} info={info} />
      </td>
      <td>{key}</td>
      <td className="asset-download-link-cell">
        {url && (
          <a href={url} target="_blank" title={url}>
            {url.length > 50 ? <Fragment>{url.substr(0, 49)}&hellip;</Fragment> : url}
          </a>
        )}
      </td>
      <td>
        <Form onSubmit={update}>
          <InputGroup>
            <Input
              type="text"
              name="url"
              id={inputId}
              placeholder="http(s)://..."
              value={formUrl}
              onChange={e => updateFormUrl(e.target.value)}
              invalid={error !== null}
            />
            <InputGroupAddon addonType="append">
              <ProcessingButton
                label={locale.saveUrl}
                icon={faSave}
                color="success"
                processing={processing}
                onClick={update}
              />
            </InputGroupAddon>
          </InputGroup>
          {error !== null && <FormFeedback className="d-block">{error}</FormFeedback>}
        </Form>
      </td>
    </tr>
  )
}

export default withLocale(DownloadEntry, { key: 'asset_download' })
