// TODO: convert into a generic component under `ui/`
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import PropTypes from 'prop-types'

const PhoneLink = ({ phone }) => {
  if (!phone) return null

  return (
    <a href={`phone:${phone}`}>
      <FontAwesomeIcon icon={faPhone} className="icon-prepend" />
      {phone}
    </a>
  )
}

PhoneLink.propTypes = {
  phone: PropTypes.string
}

export default PhoneLink
