import React, { Fragment } from 'react'

import { Button, Tooltip, UncontrolledTooltip } from 'reactstrap'

import { withApollo } from '@apollo/client/react/hoc'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ImgCertifiedAccessibility from '../../../assets/certified-accessibility.svg'
import ImgDocumentedAccessibility from '../../../assets/documented-accessibility.svg'
import IconVisual from '../../../assets/guestgroup-icons/blind-grau.svg'
import IconVisualInfo from '../../../assets/guestgroup-icons/blind-grau_info.svg'
import IconWalking from '../../../assets/guestgroup-icons/gehbehindert-grau.svg'
import IconWalkingInfo from '../../../assets/guestgroup-icons/gehbehindert-grau_info.svg'
import IconDeaf from '../../../assets/guestgroup-icons/gehoerlos-grau.svg'
import IconDeafInfo from '../../../assets/guestgroup-icons/gehoerlos-grau_info.svg'
import IconPartiallyDeaf from '../../../assets/guestgroup-icons/hoerbehindert-grau.svg'
import IconPartiallyDeafInfo from '../../../assets/guestgroup-icons/hoerbehindert-grau_info.svg'
import IconMental from '../../../assets/guestgroup-icons/kognitiv-grau.svg'
import IconMentalInfo from '../../../assets/guestgroup-icons/kognitiv-grau_info.svg'
import IconWheelchair from '../../../assets/guestgroup-icons/rollstuhlfahrer-grau.svg'
import IconWheelchairInfo from '../../../assets/guestgroup-icons/rollstuhlfahrer-grau_info.svg'
import IconPartiallyVisual from '../../../assets/guestgroup-icons/sehbehindert-grau.svg'
import IconPartiallyVisualInfo from '../../../assets/guestgroup-icons/sehbehindert-grau_info.svg'
import { DD_MM_YYYY } from '../../../helper/helper-functions.jsx'
import UpdateCertificationStatusModal from '../../../modules/modal/updateCertificationStatusModal'

const CertificationStatus = props => <StatusBox {...props} />

class StatusBox extends React.Component {
  render() {
    const {
      locale,
      facility,
      facility: {
        permissions: { change_certificate },
        uuid,
        certified,
        certification_data: { certificate_type, certified_from, certified_to, certificate_pdf_url }
      },
      client,
      online
    } = this.props
    const { __typename, ...properties } = facility.certification_data.properties
    return (
      <div className="align-items-center box box-small d-flex flex-row mt-0">
        <h2 className="h4 m-0 text-secondary">{locale.certification_status}</h2>
        <div className="align-items-center d-flex ml-auto mb-0 text-right">
          {certificate_pdf_url && online && <CertificateDownload url={certificate_pdf_url} />}
          <span
            className={`text-primary-dark font-weight-normal badge text-right ${
              certified ? 'badge-success' : 'badge-warning'
            }`}
          >
            {certified ? (
              <CertificationPeriod
                certified_from={DD_MM_YYYY(certified_from)}
                certified_to={DD_MM_YYYY(certified_to)}
                locale={locale}
              />
            ) : (
              certificate_type.label
            )}
          </span>

          {certificate_type.key === 'documented' && (
            <img className="certificate-icon" src={ImgDocumentedAccessibility} height="50" alt="" />
          )}
          {certificate_type.key === 'validated' && (
            <React.Fragment>
              <img className="certificate-icon" src={ImgCertifiedAccessibility} height="50" alt="" />
              <ul className="list-inline certificate-list">
                <CertificateList locale={locale} properties={properties} />
              </ul>
            </React.Fragment>
          )}
          {change_certificate && (
            <div className="list-actions ml-4 pl-2 border-left border-secondary">
              <UpdateCertificationStatusModal
                locale={locale}
                client={client}
                properties={properties}
                certificate_type={certificate_type}
                certified_from={certified_from}
                certified_to={certified_to}
                uuid={uuid}
              />
            </div>
          )}
        </div>
      </div>
    )
  }
}

const CertificationPeriod = ({ certified_from, certified_to, locale }) => (
  <span>
    {locale.from + ' ' + certified_from}
    <br />
    {locale.to + ' ' + certified_to}
  </span>
)

const CertificateDownload = ({ url }) => (
  <Fragment>
    {' '}
    <Button outline={true} color={'primary-light'} download id="tooltip-certificate_download" href={url}>
      <span className="d-none d-xl-inline d-lg-inline">Zertifikat herunterladen </span>
      <FontAwesomeIcon size="1x" icon={faDownload} />
    </Button>
    <UncontrolledTooltip
      delay={500}
      target="tooltip-certificate_download"
      children={'Zertifikat herunterladen (.pdf)'}
    />
  </Fragment>
)

export const CertificateList = ({ properties, locale }) =>
  Object.keys(properties).map((property, index) =>
    properties[property] > 0 ? (
      <CertificateListItem id={property} index={index} key={property} locale={locale} value={properties[property]} />
    ) : (
      ''
    )
  )

class CertificateListItem extends React.Component {
  setTooltipStates = () =>
    this.setState({
      tooltip_open: false
    })

  toggleTooltip = () =>
    this.setState({
      tooltip_open: !this.state.tooltip_open
    })

  getIconImage() {
    switch (this.props.id + '-' + this.props.value + '') {
      case 'deaf-1':
        return IconDeafInfo
        break
      case 'deaf-2':
        return IconDeaf
        break
      case 'partially_deaf-1':
        return IconPartiallyDeafInfo
        break
      case 'partially_deaf-2':
        return IconPartiallyDeaf
        break
      case 'mental-1':
        return IconMentalInfo
        break
      case 'mental-2':
        return IconMental
        break
      case 'visual-1':
        return IconVisualInfo
        break
      case 'visual-2':
        return IconVisual
        break
      case 'partially_visual-1':
        return IconPartiallyVisualInfo
        break
      case 'partially_visual-2':
        return IconPartiallyVisual
        break
      case 'walking-1':
        return IconWalkingInfo
        break
      case 'walking-2':
        return IconWalking
        break
      case 'wheelchair-1':
        return IconWheelchairInfo
        break
      case 'wheelchair-2':
        return IconWheelchair
        break
      default:
        return ''
    }
  }

  UNSAFE_componentWillMount() {
    this.setTooltipStates()
  }

  render() {
    const { id, index, locale, value } = this.props
    const { tooltip_open } = this.state
    const { toggleTooltip } = this

    return (
      <li className="list-inline-item">
        <span className="guestgroup-icon" id={`tooltip-certification-status_${id + '-' + index}`}>
          <img src={this.getIconImage()} width="50" alt="" />
        </span>
        <Tooltip
          delay={500}
          placement="top"
          isOpen={tooltip_open}
          target={`tooltip-certification-status_${id + '-' + index}`}
          toggle={toggleTooltip}
        >
          {locale.guest_group_category_description[id][value]}
        </Tooltip>
      </li>
    )
  }
}

export default withApollo(CertificationStatus)
