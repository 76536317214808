// additional components
import React from 'react'

import {
  Alert,
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Tooltip
} from 'reactstrap'

import { faCheck, faEdit, faSave } from '@fortawesome/free-solid-svg-icons'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { LegacySelect as Select } from 'components/modules/selects/react-select'
import gql from 'graphql-tag'
import update from 'immutability-helper'

// GraphQL
import { getFacility } from '../../views/facility/facility.graphql'

import generateUUID from '../../../helpers/guid'
// Images
import IconVisual from '../../assets/guestgroup-icons/blind-grau.svg'
import IconVisualInfo from '../../assets/guestgroup-icons/blind-grau_info.svg'
import IconWalking from '../../assets/guestgroup-icons/gehbehindert-grau.svg'
import IconWalkingInfo from '../../assets/guestgroup-icons/gehbehindert-grau_info.svg'
import IconDeaf from '../../assets/guestgroup-icons/gehoerlos-grau.svg'
import IconDeafInfo from '../../assets/guestgroup-icons/gehoerlos-grau_info.svg'
import IconPartiallyDeaf from '../../assets/guestgroup-icons/hoerbehindert-grau.svg'
import IconPartiallyDeafInfo from '../../assets/guestgroup-icons/hoerbehindert-grau_info.svg'
import IconMental from '../../assets/guestgroup-icons/kognitiv-grau.svg'
import IconMentalInfo from '../../assets/guestgroup-icons/kognitiv-grau_info.svg'
import IconWheelchair from '../../assets/guestgroup-icons/rollstuhlfahrer-grau.svg'
import IconWheelchairInfo from '../../assets/guestgroup-icons/rollstuhlfahrer-grau_info.svg'
import IconPartiallyVisual from '../../assets/guestgroup-icons/sehbehindert-grau.svg'
import IconPartiallyVisualInfo from '../../assets/guestgroup-icons/sehbehindert-grau_info.svg'

// CSS
import './_modal.scss'

const updateCertificationDataMutation = gql`
  mutation update_facility_certification_data($uuid: ID, $certification_data: FacilityCertificationDataInput!) {
    update_facility_certification_data(uuid: $uuid, certification_data: $certification_data) {
      uuid
      version_number
    }
  }
`

class UpdateCertificationStatusModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      modal: false,
      processing: false,
      tooltip_uuid: generateUUID(),
      variables: {
        uuid: this.props.uuid,
        certification_data: {
          // HACK: convert the emulated "documented" key to "validated" for this
          // form to work after "documented" has been removed as standalone
          // value and gets calculated from "validated" and no accessible guest
          // groups
          certificate_type:
            this?.props?.certificate_type?.key === 'documented' ? 'validated' : this?.props?.certificate_type?.key,
          properties: this?.props?.properties,
          certified_from: this?.props?.certified_from || '',
          certified_to: this?.props?.certified_to || ''
        }
      }
    }
  }

  toggle = event => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    this.setState({
      modal: !this.state.modal
    })
  }

  toggleTooltip = () =>
    this.setState({
      tooltip_open: !this.state.tooltip_open
    })

  gqlValidationError = error =>
    this.setState({
      error_msg: error.graphQLErrors[0].message,
      error: true
    })

  change = event => {
    this.setState(
      update(this.state, {
        variables: {
          certification_data: {
            [event.target.id]: {
              $set: event.target.value
            }
          }
        }
      })
    )
  }

  changeCertificateType = event => {
    this.setState(
      update(this.state, {
        variables: {
          certification_data: {
            certificate_type: { $set: event.target.id }
          }
        }
      })
    )
  }

  changeProperties = event => {
    this.setState(
      update(this.state, {
        variables: {
          certification_data: {
            properties: {
              [event.target.id.split('-')[0]]: { $set: parseInt(event.target.id.split('-')[1], 10) }
            }
          }
        }
      })
    )
  }

  setDates = () => {
    let certified_from = this.state.certified_from_year + '-' + this.state.certified_from_month + '-01'
    let certified_to = this.state.certified_to_year + '-' + this.state.certified_to_month + '-01'

    return {
      certified_from: certified_from,
      certified_to: certified_to
    }
  }

  save = () => {
    let { certification_data } = this.state.variables
    let dates = this.setDates()

    this.props.client
      .mutate({
        mutation: updateCertificationDataMutation,
        variables: {
          uuid: this.props.uuid,
          certification_data: {
            certificate_type: certification_data.certificate_type,
            properties: certification_data.properties,
            certified_from: dates.certified_from,
            certified_to: dates.certified_to
          }
        },
        refetchQueries: [
          {
            query: getFacility,
            variables: {
              uuid: this.props.uuid,
              version_number: ''
            }
          }
        ]
      })
      .then(() => {
        this.toggle()
        this.state.errors = {}
      })
      .catch(error => this.onError(error))
  }

  onError = ({ graphQLErrors }) =>
    this.setState({
      error_msg: graphQLErrors[0].message,
      errors: graphQLErrors[0].errors
    })

  changeSelect = event => {
    this.setState({
      [event.key]: event.value
    })

    let to_month = event.value === '01' ? '12' : parseInt(event.value, 10) - 1

    switch (event.key) {
      case 'certified_from_month':
        this.setState({
          [event.key]: event.value,
          certified_to_month: to_month < 10 ? '0' + to_month : to_month
        })

        event.value === '01'
          ? this.setState({
              [event.key]: event.value,
              certified_to_year: parseInt(this.state.certified_from_year, 10) + 2
            })
          : this.setState({
              [event.key]: event.value,
              certified_to_year: parseInt(this.state.certified_from_year, 10) + 3
            })

        break
      case 'certified_from_year':
        this.state.certified_from_month === '01'
          ? this.setState({
              [event.key]: event.value,
              certified_to_year: parseInt(event.value) + 2
            })
          : this.setState({
              [event.key]: event.value,
              certified_to_year: parseInt(event.value) + 3
            })
        break
      default:
        return null
    }
  }

  getDateValues = () => {
    let certified_from_months = [
      {
        key: 'certified_from_month',
        label: 'Monat auswählen',
        value: ''
      }
    ]
    let certified_to_months = [
      {
        key: 'certified_to_month',
        label: 'Monat auswählen',
        value: ''
      }
    ]
    let certified_from_years = [
      {
        key: 'certified_from_year',
        label: 'Jahr auswählen',
        value: ''
      }
    ]
    let certified_to_years = [
      {
        key: 'certified_to_year',
        label: 'Jahr auswählen',
        value: ''
      }
    ]
    const certified_from_year = this?.props?.certified_from
      ? new Date(this?.props?.certified_from).getFullYear()
      : Infinity
    const certified_to_year = this?.props?.certified_to ? new Date(this?.props?.certified_to).getFullYear() : 0
    const actual_year = new Date().getFullYear()
    const default_year_difference = 5
    const first_year = Math.min(actual_year - default_year_difference, certified_from_year)
    const last_year = Math.max(actual_year + default_year_difference, certified_to_year)
    const year_difference = last_year - first_year

    Object.keys(this.props.locale.date_picker.months).map(month => {
      certified_from_months.push({
        key: 'certified_from_month',
        label: this.props.locale.date_picker.months[month],
        value: month < 10 ? '0' + month : month.toString()
      })
      certified_to_months.push({
        key: 'certified_to_month',
        label: this.props.locale.date_picker.months[month],
        value: month < 10 ? '0' + month : month.toString()
      })
    })

    for (let i = 0; i <= year_difference; i++) {
      certified_from_years.push({
        key: 'certified_from_year',
        label: first_year + i,
        value: (first_year + i).toString()
      })
      certified_to_years.push({
        key: 'certified_to_year',
        label: first_year + i,
        value: (first_year + i).toString()
      })
    }

    this.setState({
      certified_from_months: certified_from_months,
      certified_from_years: certified_from_years,
      certified_to_months: certified_to_months,
      certified_to_years: certified_to_years
    })
  }

  UNSAFE_componentWillMount = () => {
    this.setState({
      certified_from_month: this?.props?.certified_from?.split('-')[1],
      certified_from_year: this?.props?.certified_from?.split('-')[0],
      certified_to_month: this?.props?.certified_to?.split('-')[1],
      certified_to_year: this?.props?.certified_to?.split('-')[0]
    })

    this.getDateValues()
  }

  render = () => {
    const { className, locale, properties } = this.props
    const { changeSelect, changeCertificateType, changeProperties, save, toggle, toggleTooltip } = this
    const {
      errors,
      error_msg,
      tooltip_uuid,
      tooltip_open,
      modal,
      certified_from_months,
      certified_from_years,
      certified_to_months,
      certified_to_years,
      certified_from_month,
      certified_from_year,
      certified_to_month,
      certified_to_year
    } = this.state

    const { certificate_type } = this.state.variables.certification_data

    let properties_array = []

    Object.keys(properties).map(property =>
      properties_array.push({
        key: property,
        label: property,
        value: properties[property]
      })
    )

    return (
      <React.Fragment>
        <Button
          size={`sm`}
          className="btn-transparent btn-icon-only btn"
          color={'link'}
          onClick={toggle}
          id={`tooltip-modal_${tooltip_uuid}`}
        >
          <FontAwesomeIcon icon={faEdit} />
        </Button>
        <Tooltip
          delay={500}
          placement="top"
          isOpen={tooltip_open}
          target={`tooltip-modal_${tooltip_uuid}`}
          toggle={toggleTooltip}
        >
          {modal ? locale.close : locale.edit}
        </Tooltip>
        <Modal size={'lg'} centered isOpen={modal} toggle={toggle} className={className}>
          <ModalHeader>{locale.edit_certification}</ModalHeader>
          <ModalBody>
            {errors !== undefined && Object.values(errors).length ? <Alert color="danger">{error_msg}</Alert> : null}
            <FormGroup>
              <Container>
                <Row className="mb-0">
                  <Col sm="4">
                    <Label className="control-label checkbox-label justify-content-start" for="missing">
                      <Input
                        className="checkbox-control"
                        name="certificate_type"
                        type="radio"
                        id="missing"
                        defaultChecked={certificate_type === 'missing' ? true : false}
                        onChange={changeCertificateType}
                      />
                      <span className="check-icon-container">
                        <span className="check-icon text-primary-dark">
                          <FontAwesomeIcon icon={faCheck} />
                        </span>
                      </span>
                      <span className="label-text">{locale.certificate_type['missing']}</span>
                    </Label>
                  </Col>
                  <Col sm="4">
                    <Label className="control-label checkbox-label justify-content-start" for="validated">
                      <Input
                        className="checkbox-control"
                        name="certificate_type"
                        defaultChecked={certificate_type === 'validated' ? true : false}
                        type="radio"
                        id="validated"
                        onChange={changeCertificateType}
                      />
                      <span className="check-icon-container">
                        <span className="check-icon text-primary-dark">
                          <FontAwesomeIcon icon={faCheck} />
                        </span>
                      </span>
                      <span className="label-text">{locale.certificate_type['validated']}</span>
                    </Label>
                  </Col>
                </Row>
                {certificate_type === 'documented' || certificate_type === 'validated' ? (
                  <React.Fragment>
                    <Row className="mb-0 pb-2 pt-5 border-top border-secondary">
                      <Col sm="3" lg="2" className="text-right">
                        <p className="control-label justify-content-start">{locale.certified_from}&nbsp;:</p>
                      </Col>
                      <Col sm="3">
                        <Label className="control-label sr-only" for="certified_from_month">
                          {locale.certified_from}
                        </Label>
                        <Select
                          isClearable={false}
                          id="certified_from_month"
                          name="certified_from_month"
                          onChange={changeSelect}
                          value={certified_from_month?.toString()}
                          options={certified_from_months}
                          placeholder="Monat auswählen"
                        />
                      </Col>
                      <Col sm="3">
                        <Label className="control-label sr-only" for="certified_from_year">
                          {locale.certified_from}
                        </Label>
                        <Select
                          isClearable={false}
                          id="certified_from_year"
                          name="certified_from_year"
                          onChange={changeSelect}
                          value={certified_from_year}
                          options={certified_from_years}
                          placeholder="Jahr auswählen"
                        />
                      </Col>
                    </Row>
                    <Row className={`mt-0 pt-2 ${certificate_type === 'validated' ? 'mb-0 pb-5' : 'mb-2 pb-3'}`}>
                      <Col sm="3" lg="2" className="text-right">
                        <p className="control-label justify-content-start">{locale.certified_to}&nbsp;:</p>
                      </Col>
                      <Col sm="3">
                        <Label className="control-label sr-only" for="certified_to_month">
                          {locale.certified_to}
                        </Label>
                        <Select
                          style={
                            this.state?.errors?.certification_data?.certified_to && {
                              borderColor: '#e53012'
                            }
                          }
                          isClearable={false}
                          id="certified_to_month"
                          name="certified_to_month"
                          onChange={changeSelect}
                          value={certified_to_month?.toString()}
                          options={certified_to_months}
                          placeholder="Monat auswählen"
                        />
                        {this.state?.errors?.certification_data?.certified_to && (
                          <p
                            style={{
                              width: '100%',
                              marginTop: '0.25rem',
                              fontSize: '80%',
                              color: '#e53012'
                            }}
                          >
                            {this.state?.errors?.certification_data?.certified_to}
                          </p>
                        )}
                      </Col>
                      <Col sm="3">
                        <Label className="control-label sr-only" for="certified_to_year">
                          {locale.certified_to}
                        </Label>
                        <Select
                          style={
                            this.state?.errors?.certification_data?.certified_to && {
                              borderColor: '#e53012'
                            }
                          }
                          isClearable={false}
                          id="certified_to_year"
                          name="certified_to_year"
                          onChange={changeSelect}
                          value={certified_to_year?.toString()}
                          options={certified_to_years}
                          placeholder="Jahr auswählen"
                        />
                      </Col>
                    </Row>
                  </React.Fragment>
                ) : (
                  ''
                )}
                {certificate_type === 'validated' ? (
                  <React.Fragment>
                    {properties_array.map((property, index) => (
                      <CheckboxItem
                        changeProperties={changeProperties}
                        index={index}
                        key={property.key}
                        locale={locale}
                        {...property}
                      />
                    ))}
                  </React.Fragment>
                ) : (
                  ''
                )}
              </Container>
            </FormGroup>
            <FormGroup className="form-action">
              <Container>
                <Row>
                  <Col sm="5">
                    <a className="link-btn" onClick={toggle} href="#">
                      abbrechen
                    </a>
                  </Col>
                  <Col sm="7" className="text-right">
                    <Button onClick={save} className="btn-labeled" color="primary-light">
                      <FontAwesomeIcon className="icon-prepend" icon={faSave} />
                      <span>speichern</span>
                    </Button>
                  </Col>
                </Row>
              </Container>
            </FormGroup>
          </ModalBody>
        </Modal>
      </React.Fragment>
    )
  }
}

class CheckboxItem extends React.Component {
  getIconImage(label, value) {
    switch (label + '-' + value + '') {
      case 'deaf-1':
        return IconDeafInfo
        break
      case 'deaf-2':
        return IconDeaf
        break
      case 'partially_deaf-1':
        return IconPartiallyDeafInfo
        break
      case 'partially_deaf-2':
        return IconPartiallyDeaf
        break
      case 'mental-1':
        return IconMentalInfo
        break
      case 'mental-2':
        return IconMental
        break
      case 'visual-1':
        return IconVisualInfo
        break
      case 'visual-2':
        return IconVisual
        break
      case 'partially_visual-1':
        return IconPartiallyVisualInfo
        break
      case 'partially_visual-2':
        return IconPartiallyVisual
        break
      case 'walking-1':
        return IconWalkingInfo
        break
      case 'walking-2':
        return IconWalking
        break
      case 'wheelchair-1':
        return IconWheelchairInfo
        break
      case 'wheelchair-2':
        return IconWheelchair
        break
      default:
        return ''
    }
  }

  render() {
    const { label, changeProperties, locale, value, index } = this.props
    const { getIconImage } = this
    const row_classes = 'mb-0 mt-0 pb-4'

    return (
      <Row className={index === 0 ? `pt-4 ${row_classes}` : `${row_classes}`}>
        <Col sm="3">
          <p className="control-label justify-content-start">{locale.guest_group_categories[label]}&nbsp;:</p>
        </Col>
        <Col sm="3" className="text-center">
          <Label className="control-label checkbox-label justify-content-start" for={`${label}-0`}>
            <Input
              className="checkbox-control"
              name={label}
              type="radio"
              id={`${label}-0`}
              defaultChecked={value === 0 ? true : false}
              onChange={changeProperties}
            />
            <span className="check-icon-container">
              <span className="check-icon text-primary-dark">
                <FontAwesomeIcon icon={faCheck} />
              </span>
            </span>
            <span className="label-text">{locale.certification_level[0]}</span>
          </Label>
        </Col>
        {label !== 'mental' ? (
          <Col sm="3">
            <div className="checkbox-badge-container">
              <span className="checkbox-badge-icon">
                <img
                  src={getIconImage(label, 1)}
                  height="40"
                  width="40"
                  alt={locale.guest_group_category_description[label][1]}
                />
              </span>
              <Label className="control-label checkbox-label checkbox-badge justify-content-start" for={`${label}-1`}>
                <Input
                  className="checkbox-control"
                  name={label}
                  defaultChecked={value === 1 ? true : false}
                  type="radio"
                  id={`${label}-1`}
                  onChange={changeProperties}
                />
                <span className="check-icon-container">
                  <span className="check-icon text-primary-dark">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                </span>
                <span className="label-text">{locale.certification_level[1]}</span>
              </Label>
            </div>
          </Col>
        ) : null}
        <Col sm="3">
          <div className="checkbox-badge-container">
            <span className="checkbox-badge-icon">
              <img
                src={getIconImage(label, 2)}
                height="40"
                width="40"
                alt={locale.guest_group_category_description[label][2]}
              />
            </span>
            <Label className="control-label checkbox-label checkbox-badge justify-content-start" for={`${label}-2`}>
              <Input
                className="checkbox-control"
                name={label}
                defaultChecked={value === 2 ? true : false}
                type="radio"
                id={`${label}-2`}
                onChange={changeProperties}
              />
              <span className="check-icon-container">
                <span className="check-icon text-primary-dark">
                  <FontAwesomeIcon icon={faCheck} />
                </span>
              </span>
              <span className="label-text">{locale.certification_level[2]}</span>
            </Label>
          </div>
        </Col>
      </Row>
    )
  }
}

export default UpdateCertificationStatusModal
