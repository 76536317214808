// additional components
import React from 'react'

import { Link } from 'react-router-dom'
import { Alert, Button, Col, Row, Table, Tooltip } from 'reactstrap'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import { faCheckSquare, faClone, faEdit, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Loading from '../../../helper/loading/loading.jsx'
import Modal from '../../../modules/modal/modal.jsx'
import Delete from './delete.jsx'
// GraphQL
import { makeCurrentVersionMutation, versionsQuery } from './graphql'

const Versions = ({ locale, history, client, data: { loading, error, form_versions } }) => {
  if (loading) return <Loading />
  if (error) return <p>Error</p>
  return (
    <div className="page-wrapper">
      <Row className="page-header">
        <Col sm="12">
          <h1>Versionen</h1>
        </Col>
      </Row>
      <hr className="seperator" />
      <div className="box">
        <Alert color="info" children={`Bitte wählen Sie eine Version aus, die sie bearbeiten wollen.`} />
        <Row>
          <Col sm={{ size: '5', offset: '7' }} className="text-right">
            <Link className="btn-labeled btn btn-primary-light" to="/form-kit/version/new">
              <FontAwesomeIcon className="icon-prepend" icon={faPlus} />
              <span>Neue Version anlegen</span>
            </Link>
          </Col>
        </Row>
      </div>
      <Table striped responsive bordered>
        <thead className="thead-light">
          <tr>
            <th>{locale.label}</th>
            <th>{locale.comment}</th>
            <th>{locale.form.plural}</th>
            <th>{locale.fields}</th>
            <th> </th>
          </tr>
        </thead>
        <tbody>
          {form_versions.map(form_version => (
            <Version key={form_version.id} {...form_version} locale={locale} history={history} client={client} />
          ))}
        </tbody>
      </Table>
    </div>
  )
}

class Version extends React.Component {
  constructor(props) {
    super(props)
  }

  setTooltipStates = () =>
    this.setState({
      tooltip_clone_open: false,
      tooltip_update_open: false,
      tooltip_makeCurrent_open: false
    })

  toggleTooltipClone = () =>
    this.setState({
      tooltip_clone_open: !this.state.tooltip_clone_open
    })
  toggleTooltipUpdate = () =>
    this.setState({
      tooltip_update_open: !this.state.tooltip_update_open
    })
  toggleTooltipMakeCurrent = () =>
    this.setState({
      tooltip_makeCurrent_open: !this.state.tooltip_makeCurrent_open
    })

  goDelete = () => alert(`delete version id:${this.props.id} ?`)
  goClone = () => this.props.history.push(`/form-kit/version/clone/${this.props.id}`)
  goUpdate = () => this.props.history.push(`/form-kit/version/edit/${this.props.id}`)
  makeCurrent = () =>
    this.props.client
      .mutate({
        mutation: makeCurrentVersionMutation,
        variables: {
          id: this.props.id
        }
      })
      .then(result => window.location.reload()) //TODO: make without reload via parent state update

  UNSAFE_componentWillMount() {
    this.setTooltipStates()
  }

  render = () => {
    const form_version = this.props
    return (
      <tr className={form_version.current ? 'bg-info text-white' : ''}>
        <th>
          {form_version.name} {form_version.current ? '(aktuelle Version)' : ''}
        </th>
        <td>{form_version.comment}</td>
        <td>{form_version.forms_count}</td>
        <td>{form_version.fields_count}</td>
        <td className="text-right">
          <Button
            onClick={this.goUpdate}
            className={`btn-transparent btn-icon-only ${form_version.current ? 'text-white' : ''}`}
            color="link"
            id={`tooltip-update_${this.props.id}`}
          >
            <FontAwesomeIcon icon={faEdit} />
          </Button>
          <Tooltip
            placement="top"
            isOpen={this.state.tooltip_update_open}
            target={`tooltip-update_${this.props.id}`}
            toggle={this.toggleTooltipUpdate}
          >
            {this.props.locale.edit}
          </Tooltip>

          {form_version.current ? (
            ''
          ) : (
            <React.Fragment>
              <Button
                onClick={this.makeCurrent}
                className={`btn-transparent btn-icon-only ${form_version.current ? 'text-white' : ''}`}
                color="link"
                id={`tooltip-makeCurrent_${this.props.id}`}
              >
                <FontAwesomeIcon icon={faCheckSquare} />
              </Button>
              <Tooltip
                placement="top"
                isOpen={this.state.tooltip_makeCurrent_open}
                target={`tooltip-makeCurrent_${this.props.id}`}
                toggle={this.toggleTooltipMakeCurrent}
              >
                {this.props.locale.makeCurrentVersion}
              </Tooltip>
            </React.Fragment>
          )}

          <Button
            onMouseOver={this.toggleTooltipClone}
            onClick={this.goClone}
            className={`btn-transparent btn-icon-only ${form_version.current ? 'text-white' : ''}`}
            color="link"
            id={`tooltip-clone_${this.props.id}`}
          >
            <FontAwesomeIcon icon={faClone} />
          </Button>
          <Tooltip
            placement="top"
            isOpen={this.state.tooltip_clone_open}
            target={`tooltip-clone_${this.props.id}`}
            toggle={this.toggleTooltipClone}
          >
            {this.props.locale.clone}
          </Tooltip>

          <Modal
            children={<Delete id={this.props.id} client={this.props.client} />}
            toggleModalText={'Version ' + form_version.name + this.props.locale.delete}
            modal_type={'confirm'}
            modalConfirmText={this.props.locale.confirm_delete_version_description}
            button_type={{
              icon_only: true,
              icon_type: faTrashAlt,
              color: 'link',
              classes: 'btn-transparent btn-icon-only text-danger'
            }}
            modal_size={'sm'}
          />
        </td>
      </tr>
    )
  }
}

export default withApollo(
  graphql(versionsQuery, {
    options: props => ({
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true
    })
  })(Versions)
)
