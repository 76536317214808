import React, { Fragment } from 'react'

import { Link } from 'react-router-dom'
import { Button, Col, Row, Table, UncontrolledTooltip } from 'reactstrap'

import { Query } from '@apollo/client/react/components'
import { withApollo } from '@apollo/client/react/hoc'
import { faBackward, faCommentAlt, faExchangeAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { getReportings } from '../facility.graphql'

import Loading from '../../../helper/loading/loading.jsx'
import { EmptyReportings, GuestGroupImage, ReportingResultIcon } from './helper'

const ReportingsDetails = props => (
  <Query
    query={getReportings}
    fetchPolicy="network-only"
    variables={{
      uuid: props.match.params.uuid,
      version_number: props.match.params.version_number || ''
    }}
  >
    {({ loading, error, data }) => {
      if (loading || !data)
        return (
          <Fragment>
            <br />
            <br />
            <br />
            <br />
            <Loading />
          </Fragment>
        )
      if (error) return `Error! ${error.message}`
      return (
        <div className="page-wrapper">
          <Row className="page-header">
            <Col sm="12" lg="6">
              <h1>{data.facility.base_data.name_de}</h1>
            </Col>
            <Col sm="12" lg="6" className="text-right facility-actions">
              <Button
                outline
                color={'primary-light'}
                className={'px-5 btn-labeled'}
                onClick={() =>
                  props.history.push(
                    `/facility/${data.facility.uuid}/${
                      data.facility.is_current_version ? '' : data.facility.version_number
                    }`
                  )
                }
              >
                <FontAwesomeIcon className="icon-prepend" icon={faBackward} />
                <span>Zur Einrichtung</span>
              </Button>
            </Col>
          </Row>
          <hr className="seperator" />
          <Table hover bordered responsive>
            <colgroup>
              <col />
              <col width="60px" />
              <col width="60px" />
              <col width="60px" />
              <col width="60px" />
              <col width="60px" />
              <col width="60px" />
              <col width="60px" />
              <col width="60px" />
              <col width="60px" />
              <col width="60px" />
              <col width="60px" />
              <col width="60px" />
              <col width="60px" />
            </colgroup>
            <thead>
              <TableHead facility={data.facility} {...props} />
            </thead>
            <tbody>
              <SectionRow facility={data.facility} {...props} />
            </tbody>
          </Table>
        </div>
      )
    }}
  </Query>
)

const TableHead = props => (
  <tr>
    <th>{String.fromCharCode(8194)}</th>
    {props.facility.reportings.map((reporting, index) => {
      return (
        <th key={'table-head-item' + index}>
          <GuestGroupImage
            guest_group={reporting.guest_group}
            category={reporting.category}
            locale={props.locale}
            uuid={props.facility.uuid + '-table-head'}
          />
        </th>
      )
    })}
  </tr>
)

const SectionRow = props => (
  <Fragment>
    {props.facility.section_data.map((section_data_item, index) => (
      <Fragment key={'section_data-' + index}>
        <tr className="bg-light">
          <SectionRowLabel section_data={section_data_item} />
          <ReportingRow
            changeable={section_data_item.form_data[0]?.changeable}
            reportings={section_data_item.reportings}
            locale={props.locale}
          />
        </tr>
        <FormRow form_data={section_data_item.form_data} locale={props.locale} />
      </Fragment>
    ))}
    {props.facility.form_data_without_section.length > 0 && (
      <Fragment>
        <tr className="bg-light">
          <td className="text-left">
            <h3>Formulare ohne Teilbereichszuordnung</h3>
          </td>
          <EmptyReportings />
        </tr>
        <FormRow form_data={props.facility.form_data_without_section} locale={props.locale} />
      </Fragment>
    )}
  </Fragment>
)

const SectionRowLabel = props => (
  <td className="text-left">
    <h3>
      {props.section_data.name_de}
      {String.fromCharCode(8194)}
      {props.section_data.comment && String.fromCharCode(8194) && (
        <IconWithTooltip
          id={`comment_${props.section_data.uuid}`}
          tooltip_text={props.section_data.comment}
          icon={faCommentAlt}
        />
      )}
      {props.section_data.alternative_section_data.length > 0 && (
        <IconWithTooltip
          id={'alternative_section_data' + props.section_data.uuid}
          tooltip_text={`Alternative zu: ${props.section_data.alternative_section_data
            .map(alternative_section_datum => alternative_section_datum.name_de)
            .join(', ')}`}
          icon={faExchangeAlt}
        />
      )}
    </h3>
  </td>
)

const FormRow = props =>
  props.form_data.map((form_data_item, index) => {
    if (form_data_item.reportings && form_data_item.reportings.length > 0) {
      return (
        <tr key={'form_data-' + index}>
          <FormRowLabel form_data={form_data_item} />
          <ReportingRow
            changeable={form_data_item?.changeable}
            form_datum_uuid={form_data_item.uuid}
            id={'form-reporting-' + '-' + index}
            reportings={form_data_item.reportings}
            locale={props.locale}
          />
        </tr>
      )
    } else {
      return (
        <tr>
          <FormRowLabel form_data={form_data_item} />
          <EmptyReportings />
        </tr>
      )
    }
  })

const FormRowLabel = props => (
  <td className="text-left">
    {props.form_data.changeable ? (
      <Link to={`/formdatum/edit/${props.form_data.uuid}`}>{props.form_data.name_de}</Link>
    ) : (
      <span>{props.form_data.name_de}</span>
    )}
    {String.fromCharCode(8194)}
    {props.form_data.comment && String.fromCharCode(8194) && (
      <IconWithTooltip
        id={'comment_' + props.form_data.uuid}
        tooltip_text={props.form_data.comment}
        icon={faCommentAlt}
      />
    )}
    {String.fromCharCode(8194)}

    {props.form_data.alternative_form_data.length > 0 && (
      <IconWithTooltip
        id={'alternative_' + props.form_data.uuid}
        tooltip_text={`Alternative zu: ${props.form_data.alternative_form_data
          .map(alternative_form_datum => alternative_form_datum.name_de)
          .join(', ')}`}
        icon={faExchangeAlt}
      />
    )}
  </td>
)

const IconWithTooltip = props => (
  <React.Fragment>
    <span id={props.id}>
      <FontAwesomeIcon icon={props.icon} />
    </span>
    <UncontrolledTooltip delay={500} placement="top" target={props.id} children={props.tooltip_text} />
  </React.Fragment>
)

const ReportingRow = props =>
  props.reportings.map((report, index) => {
    let result_icon = ''

    if (props.form_uuid) {
      result_icon = (
        <Link to={`/formdatum/edit/${props.form_uuid}`}>
          <ReportingResultIcon calculated_result={report.calculated_result} />
        </Link>
      )
    } else {
      result_icon = <ReportingResultIcon calculated_result={report.calculated_result} />
    }
    if (props.form_datum_uuid) {
      if (props.changeable) {
        return (
          <td key={props.id + '-' + index}>
            <Link
              to={`/formdatum/requirements/edit/${props.form_datum_uuid}/${props.reportings[index].guest_group.key}/${props.reportings[index].category}`}
            >
              {result_icon}
            </Link>
          </td>
        )
      }
      return (
        <td key={props.id + '-' + index}>
          <Link
            to={`/formdatum/requirements/public/${props.form_datum_uuid}/${props.reportings[index].guest_group.key}/${props.reportings[index].category}`}
          >
            {result_icon}
          </Link>
        </td>
      )
    } else {
      return <td key={props.id + '-' + index}>{result_icon}</td>
    }
  })

export default withApollo(ReportingsDetails)
