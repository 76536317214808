import { Col, FormGroup, Input, Label, Row } from 'reactstrap'

const Update = ({ state, variables, locale, uuid, cardIndex, change }) => (
  <Row className={state.edit_open ? '' : 'hidden'}>
    <Col sm="6">
      <FormGroup>
        <Label className="control-label justify-content-start" for={`title_de-${uuid + '-' + cardIndex}`}>
          {locale.title} (de)
        </Label>
        <Input
          type="text"
          className="form-control"
          name={`title_de-${uuid + '-' + cardIndex}`}
          id={`title_de-${uuid + '-' + cardIndex}`}
          placeholder={variables.title_de}
          value={variables.title_de}
          onChange={change}
        />
      </FormGroup>

      <FormGroup>
        <Label className="control-label justify-content-start" for={`short_name_de-${uuid + '-' + cardIndex}`}>
          {locale.short_name} (de)
        </Label>
        <Input
          type="text"
          className="form-control"
          name={`short_name_de-${uuid + '-' + cardIndex}`}
          id={`short_name_de-${uuid + '-' + cardIndex}`}
          placeholder={variables.short_name_de}
          value={variables.short_name_de}
          onChange={change}
        />
      </FormGroup>

      <FormGroup>
        <Label className="control-label justify-content-start" for={`description_de-${uuid + '-' + cardIndex}`}>
          {locale.image_description} (de)
        </Label>
        <textarea
          className="form-control"
          name={`description_de-${uuid + '-' + cardIndex}`}
          id={`description_de-${uuid + '-' + cardIndex}`}
          placeholder={variables.description_de}
          value={variables.description_de}
          onChange={change}
        ></textarea>
      </FormGroup>

      <FormGroup>
        <Label className="control-label justify-content-start" for={`description_visual_de-${uuid + '-' + cardIndex}`}>
          {locale.image_description_visual} (de)
        </Label>
        <textarea
          className="form-control"
          name={`description_visual_de-${uuid + '-' + cardIndex}`}
          id={`description_visual_de-${uuid + '-' + cardIndex}`}
          placeholder={variables.description_visual_de}
          value={variables.description_visual_de}
          onChange={change}
        ></textarea>
      </FormGroup>

      <FormGroup>
        <Label className="control-label justify-content-start" for={`artist-${uuid + '-' + cardIndex}`}>
          {locale.artist}
        </Label>
        <Input
          type="text"
          className="form-control"
          name={`artist-${uuid + '-' + cardIndex}`}
          id={`artist-${uuid + '-' + cardIndex}`}
          placeholder={variables.artist}
          value={variables.artist}
          onChange={change}
        />
      </FormGroup>
    </Col>
    <Col sm="6">
      <FormGroup>
        <Label className="control-label justify-content-start" for={`title_en-${uuid + '-' + cardIndex}`}>
          {locale.title} (en)
        </Label>
        <Input
          type="text"
          className="form-control"
          name={`title_en-${uuid + '-' + cardIndex}`}
          id={`title_en-${uuid + '-' + cardIndex}`}
          placeholder={variables.title_en}
          value={variables.title_en}
          onChange={change}
        />
      </FormGroup>

      <FormGroup>
        <Label className="control-label justify-content-start" for={`short_name_en-${uuid + '-' + cardIndex}`}>
          {locale.short_name} (en)
        </Label>
        <Input
          type="text"
          className="form-control"
          name={`short_name_en-${uuid + '-' + cardIndex}`}
          id={`short_name_en-${uuid + '-' + cardIndex}`}
          placeholder={variables.short_name_en}
          value={variables.short_name_en}
          onChange={change}
        />
      </FormGroup>

      <FormGroup>
        <Label className="control-label justify-content-start" for={`description_en-${uuid + '-' + cardIndex}`}>
          {locale.image_description} (en)
        </Label>
        <textarea
          className="form-control"
          name={`description_en-${uuid + '-' + cardIndex}`}
          id={`description_en-${uuid + '-' + cardIndex}`}
          placeholder={variables.description_en}
          value={variables.description_en}
          onChange={change}
        ></textarea>
      </FormGroup>

      <FormGroup>
        <Label className="control-label justify-content-start" for={`description_visual_en-${uuid + '-' + cardIndex}`}>
          {locale.image_description_visual} (en)
        </Label>
        <textarea
          className="form-control"
          name={`description_visual_en-${uuid + '-' + cardIndex}`}
          id={`description_visual_en-${uuid + '-' + cardIndex}`}
          placeholder={variables.description_visual_en}
          value={variables.description_visual_en}
          onChange={change}
        ></textarea>
      </FormGroup>

      <FormGroup>
        <Label className="control-label justify-content-start" for={`copyright-${uuid + '-' + cardIndex}`}>
          {locale.copyright}
        </Label>
        <Input
          type="text"
          className="form-control"
          name={`copyright-${uuid + '-' + cardIndex}`}
          id={`copyright-${uuid + '-' + cardIndex}`}
          placeholder={variables.copyright}
          value={variables.copyright}
          onChange={change}
        />
      </FormGroup>
    </Col>
  </Row>
)

export default Update
