// Images
import { Badge } from 'reactstrap'

// ReactStrap Components
// import {
//   Nav,
//   NavItem,
//   NavLink
// } from 'reactstrap';
// CSS
import './_footer.scss'

const Footer = () => (
  <footer className="footer" role="contentinfo">
    <div className="footer-top">
      <div className="container clearfix">
        <p className="float-right"></p>
      </div>
    </div>
    <div className="footer-bottom">
      <div className="container">
        <span className="copyright">© {new Date().getFullYear()} Reisen für Alle</span>
        <Badge style={{ marginLeft: '20px' }} color="primary" href="https://www.reisen-fuer-alle.de/impressum_225.html">
          Impressum
        </Badge>
        <Badge style={{ marginLeft: '20px' }} color="primary" href="https://www.reisen-fuer-alle.de/kontakt_227.html">
          Kontakt
        </Badge>
        <Badge style={{ marginLeft: '20px' }} color="primary" href="/data-privacy">
          Datenschutz
        </Badge>
      </div>
    </div>
  </footer>
)

export default Footer
