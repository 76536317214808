import { useState } from 'react'

import { Input, Label } from 'reactstrap'

import { faCheck, faMinus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import generateUUID from 'helpers/guid'
import PropTypes from 'prop-types'

const Checkbox = ({ label, onChange, checked = false, allItemsChecked = true }) => {
  const [id] = useState(generateUUID())

  return (
    <Label className="control-label checkbox-label justify-content-start" for={id}>
      <Input className="checkbox-control" type="checkbox" {...{ id, onChange, checked }} />
      <span className="check-icon-container">
        <span className="check-icon text-primary-dark">
          <FontAwesomeIcon icon={allItemsChecked ? faCheck : faMinus} />
        </span>
      </span>
      {label}
    </Label>
  )
}

Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  allItemsChecked: PropTypes.bool // if all of the multiple checkboxes are checked
}

export default Checkbox
