import React from 'react'

import { Tooltip } from 'reactstrap'

import { faBolt, faCheck, faClock, faMinus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import IconVisual from '../../../assets/guestgroup-icons/blind-grau.svg'
import IconVisualInfo from '../../../assets/guestgroup-icons/blind-grau_info.svg'
import IconWalking from '../../../assets/guestgroup-icons/gehbehindert-grau.svg'
import IconWalkingInfo from '../../../assets/guestgroup-icons/gehbehindert-grau_info.svg'
import IconDeaf from '../../../assets/guestgroup-icons/gehoerlos-grau.svg'
import IconDeafInfo from '../../../assets/guestgroup-icons/gehoerlos-grau_info.svg'
import IconPartiallyDeaf from '../../../assets/guestgroup-icons/hoerbehindert-grau.svg'
import IconPartiallyDeafInfo from '../../../assets/guestgroup-icons/hoerbehindert-grau_info.svg'
import IconMental from '../../../assets/guestgroup-icons/kognitiv-grau.svg'
import IconWheelchair from '../../../assets/guestgroup-icons/rollstuhlfahrer-grau.svg'
import IconWheelchairInfo from '../../../assets/guestgroup-icons/rollstuhlfahrer-grau_info.svg'
import IconPartiallyVisual from '../../../assets/guestgroup-icons/sehbehindert-grau.svg'
import IconPartiallyVisualInfo from '../../../assets/guestgroup-icons/sehbehindert-grau_info.svg'

export class ReportingResultIcon extends React.Component {
  UNSAFE_componentWillMount = () => {
    this.setState({
      tooltipOpen: false
    })
  }

  toogleTooltip = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    })
  }

  render = () => {
    let result = ''
    let color = ''

    switch (this.props.calculated_result) {
      case -3:
        result = faClock
        color = 'text-info'
        break
      case -2:
        result = faBolt
        color = 'text-warning'
        break
      case -1:
        result = faMinus
        color = 'text-danger'
        break
      case 0:
      case 2:
        result = ''
        break
      case 1:
        result = faCheck
        color = 'text-info'
        break
    }

    return (
      <React.Fragment>
        {result && (
          <span className="icon-reporting-result" id={this.props.id}>
            {result && <FontAwesomeIcon icon={result} className={color} />}
          </span>
        )}
      </React.Fragment>
    )
  }
}

export class GuestGroupImage extends React.Component {
  UNSAFE_componentWillMount = () => {
    this.setState({
      tooltipOpen: false
    })
  }

  toogleTooltip = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    })
  }

  render = () => {
    let category = parseInt(this.props.category)
    let tooltip_text = this.props.locale['guest_group_category_description'][this.props.guest_group.key][category]
    let image_id = 'group-' + this.props.guest_group.key + '-' + category + this.props.uuid
    let image_source = ''
    let width = this.props.width ? this.props.width : 40
    let height = width

    switch (this.props.guest_group.key) {
      case 'allergic':
        image_source = category === 1 ? IconAllergicInfo : IconAllergic
        break
      case 'deaf':
        image_source = category === 1 ? IconDeafInfo : IconDeaf
        break
      case 'partially_deaf':
        image_source = category === 1 ? IconPartiallyDeafInfo : IconPartiallyDeaf
        break
      case 'generations':
        image_source = category === 1 ? IconGenerationsInfo : IconGenerations
        break
      case 'visual':
        image_source = category === 1 ? IconVisualInfo : IconVisual
        break
      case 'partially_visual':
        image_source = category === 1 ? IconPartiallyVisualInfo : IconPartiallyVisual
        break
      case 'mental':
        image_source = IconMental
        break
      case 'walking':
        image_source = category === 1 ? IconWalkingInfo : IconWalking
        break
      case 'wheelchair':
        image_source = category === 1 ? IconWheelchairInfo : IconWheelchair
        break
      case 'visual':
        image_source = category === 1 ? IconVisualInfo : IconVisual
        break
    }

    return (
      <React.Fragment>
        <img src={image_source} height={height} width={width} alt={tooltip_text} id={image_id} />
        <Tooltip
          delay={500}
          placement="top"
          isOpen={this.state.tooltipOpen}
          target={image_id}
          toggle={this.toogleTooltip}
        >
          {tooltip_text}
        </Tooltip>
      </React.Fragment>
    )
  }
}

export const EmptyReportings = () => {
  return (
    <React.Fragment>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
    </React.Fragment>
  )
}
