import { forwardRef } from 'react'

import { getPropertyFromObject } from 'components/helper/helper-functions'

import { de } from './locale'

const withLocale = (WrappedComponent, config = {}) => {
  const attributeName = config.attributeName || 'locale'
  const locale = config.key ? getPropertyFromObject(de, config.key) : de

  const componentWithInjectedLocale = forwardRef((props, ref) => (
    <WrappedComponent {...{ ...props, [attributeName]: locale, ref }} />
  ))

  if (WrappedComponent.propTypes) {
    // NOTE: this ensures, the resulting component has the same prop types, with the exception of the injected locale.
    const { [attributeName]: _unusedLocalePropType, ...wrappedPropTypesWithoutLocale } = WrappedComponent.propTypes
    componentWithInjectedLocale.propTypes = wrappedPropTypesWithoutLocale
  }

  return componentWithInjectedLocale
}

export default withLocale
