export default {
  keyFields: ['uuid'],
  fields: {
    possible_alternative_section_data: {
      merge(existing, incoming) {
        return incoming
      }
    },
    form_data: {
      merge(existing, incoming) {
        return incoming
      }
    },
    images: {
      merge(existing, incoming) {
        return incoming
      }
    }
  }
}
