import { format, parseISO } from 'date-fns'

const parse_datetime = utc_time => {
  const time = parseISO(utc_time)
  return isNaN(time.valueOf()) ? new Date(0) : time
}

export const DD_MM_YYYY = utc_time => (utc_time ? `${format(parse_datetime(utc_time), 'dd.MM.yyyy')}` : '-')

export const DD_MM_YYYY__HH_mm = utc_time =>
  utc_time ? `${format(parse_datetime(utc_time), 'dd.MM.yyyy - HH:mm')} Uhr` : '-'

export function convertToEuroString(value) {
  return String(Number.parseFloat(value / 100).toFixed(2))
    .split('.')
    .join(',')
}

/**
 * @deprecated will be deleted soon, use safe-navigation operator + OR operator instead to catch Errors (eg. user?.address?.street || '')
 */

export function checkIfNull(value) {
  return value !== null && value !== undefined ? value : ''
}

export const isValidEmail = email => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const getPropertyFromObject = (object, id) => {
  id = id.replace(/\[(\w+)\]/g, '.$1')
  id = id.replace(/^\./, '')
  let a = id.split('.')
  for (let i = 0, n = a.length; i < n; ++i) {
    let k = a[i]
    if (typeof object === 'object' && k in object) {
      object = object[k]
    } else {
      return
    }
  }
  return object
}
