import { Fragment, useEffect, useState } from 'react'

import { CopyToClipboard } from 'react-copy-to-clipboard'
import RichTextEditor from 'react-rte'
import { Alert, Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'

import { withApollo } from '@apollo/client/react/hoc'

import Loading from 'components/helper/loading/loading'
import { withLocale } from 'locale/'

import { generateShortReport } from '../short_reports.graphql'

const Generate = ({ locale, change, client, facility_uuid, guest_group_key }) => {
  const [modal, setModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [html, setHtml] = useState(null)
  const [copied, setCopied] = useState(false)

  const toggle = () => setModal(!modal)

  const generate = () => {
    setLoading(true)
    setError(null)
    client
      .mutate({
        mutation: generateShortReport,
        variables: {
          facility_uuid,
          guest_group_key
        }
      })
      .then(({ data: { generate_facility_short_report } }) => {
        setLoading(false)
        setHtml(generate_facility_short_report)
      })
      .catch(() => {
        setLoading(false)
        setError(locale.error)
      })
  }

  const apply = () => {
    setModal(false)
    change(html)
  }

  const copy = () => {
    setCopied(true)
    setTimeout(() => setCopied(false), 3000)
  }

  useEffect(() => {
    if (modal && html === null) {
      generate()
    }
  }, [modal])

  return (
    <Fragment>
      <Button className="mr-3" onClick={toggle} outline>
        {locale.button}
      </Button>

      <Modal isOpen={modal} toggle={toggle} centered size="lg">
        <ModalHeader toggle={toggle}>{locale.heading}</ModalHeader>
        <ModalBody>
          {loading ? (
            <Loading loadingText={locale.loading} />
          ) : (
            <Fragment>
              {error ? (
                <Alert color="danger">{error}</Alert>
              ) : (
                <Fragment>
                  <Row className="mt-0">
                    <Col sm={12}>
                      <h3>{locale.report}</h3>
                    </Col>
                    <Col sm={12}>
                      <p>{locale.info}</p>
                      <p>{locale.hint}</p>
                    </Col>
                    <Col sm={12}>
                      <RichTextEditor value={RichTextEditor.createValueFromString(html, 'html')} disabled />
                    </Col>
                  </Row>
                </Fragment>
              )}
              <Row>
                <Col sm={12}>
                  <Button color="primary-light" onClick={generate}>
                    {locale.regenerate}
                  </Button>
                </Col>
              </Row>
            </Fragment>
          )}
        </ModalBody>
        {!loading && !error && (
          <ModalFooter>
            <CopyToClipboard text={html} options={{ format: 'text/html' }} onCopy={copy}>
              <Button color={copied ? 'success' : 'primary-light'} outline={!copied}>
                {copied ? locale.copied : locale.copy}
              </Button>
            </CopyToClipboard>
            <Button color="warning" onClick={apply}>
              {locale.apply}
            </Button>
          </ModalFooter>
        )}
      </Modal>
    </Fragment>
  )
}

export default withApollo(withLocale(Generate, { key: 'short_report.generation' }))
