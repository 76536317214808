import React from 'react'

import { Col, Container, FormGroup, Label, Row } from 'reactstrap'

import { graphql, withApollo } from '@apollo/client/react/hoc'

import { deepNestInObjectsWithKeys } from 'helpers/object'
import { TokenAuth } from 'helpers/token-auth'
import update from 'immutability-helper'

import { getUsers } from '../../../views/admin/users/users.graphql'
import { getOwnUser, updateOwnUser } from './settings.graphql'

import { getPropertyFromObject } from '../../../helper/helper-functions'
import Loading from '../../../helper/loading/loading'
import { ProcessingButton } from '../../../modules/buttons/processing-button'
import LegacyCheckbox from '../../../modules/inputs/legacyCheckbox'
import ValidatedInput from '../../../modules/inputs/validated-input'
import CountrySelect from '../../../modules/selects/countries'
import DynamicSelect from '../../../modules/selects/dynamicSelect'
import StateSelect from '../../../modules/selects/states'
import { UserDataQuery } from '../../login/graphql'

const Settings = ({ locale, data: { user, error, loading }, client }) => (
  <div className="page-wrapper">
    <Row className="page-header">
      <Col sm="6">
        <h1>{locale.settings.plural}</h1>
      </Col>
    </Row>
    <div className="box">
      {loading ? (
        <Loading loadingText={locale.settings.loading} />
      ) : (
        user && <SettingsForm user={user} locale={locale} client={client} />
      )}
    </div>
  </div>
)

class SettingsForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = this.defaultState
  }

  defaultState = {
    processing: false,
    error_msg: {},
    errors: {},
    variables: {
      user: {
        email: this.props.user.email,
        email_notifications: this.props.user.email_notifications,
        first_name: this.props.user.first_name,
        last_name: this.props.user.last_name,
        salutation: this.props?.user?.salutation?.key || 'mr',
        address: {
          street: this.props.user.address.street,
          street_addition: this.props.user.address.street_addition,
          zipcode: this.props.user.address.zipcode,
          city: this.props.user.address.city,
          state: this.props?.user?.address?.state?.key || '',
          country_code: this.props?.user?.address?.country?.key || 'DE'
        },
        phone: this.props?.user?.phone || '',
        institution: this.props?.user?.institution || '',
        current_password: '',
        password: '',
        password_confirmation: ''
      }
    }
  }

  removeErrors = () =>
    this.setState(
      update(this.state, {
        processing: {
          $set: false
        },
        errors: {
          $set: {}
        },
        error_msg: {
          $set: {}
        },
        variables: {
          user: {
            current_password: {
              $set: ''
            },
            password: {
              $set: ''
            },
            password_confirmation: {
              $set: ''
            }
          }
        }
      })
    )

  onChange = event => {
    const fragments = event.target.id.split('.')
    const variables = deepNestInObjectsWithKeys(event.target.value, ...fragments, '$set')

    this.setState(update(this.state, { variables }))
  }

  changeNotification = () =>
    this.setState(
      update(this.state, {
        variables: { user: { email_notifications: { $set: !this.state.variables.user.email_notifications } } }
      })
    )

  onError = ({ graphQLErrors }) =>
    this.setState({
      processing: false,
      error_msg: graphQLErrors[0].message,
      errors: graphQLErrors[0].errors
    })

  submit = () => {
    this.setState({ processing: true })
    const { variables } = this.state
    this.props.client
      .mutate({
        mutation: updateOwnUser,
        variables,
        refetchQueries: [
          {
            query: getUsers
          }
        ]
      })
      .then(() => this.removeErrors())
      .then(() => this.updateUserStorage())
      .then(() => window.location.replace(window.location.protocol + '//' + window.location.host + '/' + 'facilities'))
      .catch(error => this.onError(error))
  }

  updateUserStorage = () =>
    this.props.client
      .query({
        query: UserDataQuery
      })
      .then(response => {
        this.updateUser(response.data)
        this.updatePermissions(response.data)
      })

  updateUser = TokenAuth.setUser

  updatePermissions = ({ user }) => {
    let _permissions = {}
    user.permissions.map(permission => {
      _permissions[permission.key] = permission.permitted
    })
    localStorage.setItem('permissions', JSON.stringify(_permissions))
  }
  render = () => {
    const { errors, variables } = this.state
    const { cancel, submit, onChange, changeNotification } = this
    const { locale } = this.props

    return (
      <React.Fragment>
        <FormGroup>
          <Container>
            <Row>
              <Col sm={'2'} className="text-right">
                <Label className="control-label" for={'user.salutation'}>
                  {getPropertyFromObject(locale, 'user.salutation')}
                </Label>
              </Col>
              <Col sm={'4'}>
                <DynamicSelect
                  id={'user.salutation'}
                  errors={errors}
                  variables={variables}
                  onChange={onChange}
                  options={this.props.user.possible_salutations.map(possible_salutation => ({
                    value: possible_salutation.key,
                    label: possible_salutation.label,
                    target: {
                      id: 'user.salutation',
                      value: possible_salutation.key
                    }
                  }))}
                />
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <ValidatedInput
                id={'user.first_name'}
                errors={errors}
                variables={variables}
                onChange={onChange}
                locale={locale}
              />
              <ValidatedInput
                id={'user.last_name'}
                errors={errors}
                variables={variables}
                onChange={onChange}
                locale={locale}
              />
            </Row>
          </Container>
        </FormGroup>
        <FormGroup>
          <Container>
            <Row>
              <ValidatedInput
                id={'user.email'}
                errors={errors}
                variables={variables}
                onChange={onChange}
                locale={locale}
              />
              <LegacyCheckbox
                id={'user.email_notifications'}
                errors={errors}
                variables={variables}
                onChange={changeNotification}
                locale={locale}
              />
            </Row>
          </Container>
        </FormGroup>
        <FormGroup>
          <Container>
            <Row>
              <ValidatedInput
                id={'user.phone'}
                errors={errors}
                variables={variables}
                onChange={onChange}
                locale={locale}
              />
              <ValidatedInput
                id={'user.institution'}
                errors={errors}
                variables={variables}
                onChange={onChange}
                locale={locale}
              />
            </Row>
          </Container>
        </FormGroup>
        <FormGroup>
          <Container>
            <Row>
              <ValidatedInput
                id={'user.address.street'}
                errors={errors}
                variables={variables}
                onChange={onChange}
                locale={locale}
              />
              <ValidatedInput
                id={'user.address.street_addition'}
                errors={errors}
                variables={variables}
                onChange={onChange}
                locale={locale}
              />
            </Row>
          </Container>
        </FormGroup>
        <FormGroup>
          <Container>
            <Row>
              <ValidatedInput
                id={'user.address.zipcode'}
                errors={errors}
                variables={variables}
                onChange={onChange}
                locale={locale}
              />
              <ValidatedInput
                id={'user.address.city'}
                errors={errors}
                variables={variables}
                onChange={onChange}
                locale={locale}
              />
            </Row>
          </Container>
        </FormGroup>
        <FormGroup>
          <Container>
            <Row>
              <Col sm="2" className="text-right">
                <Label className="control-label" for="state">
                  {locale.country}
                </Label>
              </Col>
              <Col sm="4">
                <CountrySelect
                  id={`user.address.country_code`}
                  variables={variables}
                  onChange={onChange}
                  errors={errors}
                />
              </Col>
              <Col sm="2" className="text-right">
                <Label className="control-label" for="state">
                  {locale.state}
                </Label>
              </Col>
              <Col sm="4">
                <StateSelect
                  current_country={variables.user.address.country_code}
                  id={`user.address.state`}
                  variables={variables}
                  onChange={onChange}
                  errors={errors}
                />
              </Col>
            </Row>
          </Container>
        </FormGroup>
        <FormGroup>
          <Container>
            <Row>
              <ValidatedInput
                type={'password'}
                id={'user.password'}
                errors={errors}
                variables={variables}
                onChange={onChange}
                locale={locale}
              />
              <ValidatedInput
                type={'password'}
                id={'user.password_confirmation'}
                errors={errors}
                variables={variables}
                onChange={onChange}
                locale={locale}
              />
            </Row>
          </Container>
        </FormGroup>
        <FormGroup>
          <Container>
            <Row>
              <ValidatedInput
                type={'password'}
                id={'user.current_password'}
                errors={errors}
                variables={variables}
                onChange={onChange}
                locale={locale}
              />
            </Row>
          </Container>
        </FormGroup>
        <FormGroup className="form-action">
          <Container>
            <Row>
              <Col sm="2"></Col>
              <Col sm={{ size: '4', offset: '6' }} className="text-right">
                <ProcessingButton processing={this.state.processing} onClick={submit} label="Speichern" />
              </Col>
            </Row>
          </Container>
        </FormGroup>
      </React.Fragment>
    )
  }
}

export default withApollo(
  graphql(getOwnUser, {
    options: () => ({
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true
    })
  })(Settings)
)
