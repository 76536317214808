import { Fragment } from 'react'

import PropTypes from 'prop-types'

import { AddressForm } from '../../../../../modules/form/components'
import ValidatedInput from '../../../../../modules/inputs/validated-input'
import { FormGroupContainer } from '../../../../facility/information/partials'

const FormFields = ({ variables, locale, errors, onChange }) => {
  const inputProps = { variables, locale, errors, onChange }
  return (
    <Fragment>
      <FormGroupContainer>
        <ValidatedInput id="provider.name" {...inputProps} />
        <ValidatedInput id="provider.email" type="email" {...inputProps} />
      </FormGroupContainer>
      <FormGroupContainer>
        <ValidatedInput id="provider.phone" type="tel" {...inputProps} />
      </FormGroupContainer>

      <AddressForm id="provider" {...inputProps} />

      <FormGroupContainer>
        <ValidatedInput id="provider.miscellaneous" type="textarea" input_sm="10" rows="7" {...inputProps} />
      </FormGroupContainer>
    </Fragment>
  )
}

FormFields.propTypes = {
  variables: PropTypes.object.isRequired,
  locale: PropTypes.object.isRequired,
  errors: PropTypes.object,
  onChange: PropTypes.func.isRequired
}

export default FormFields
