// additional components
import React from 'react'

import ReactDOM from 'react-dom'
import { Button, Modal, ModalBody, ModalHeader, Tooltip } from 'reactstrap'

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Guid from '../../../helpers/guid'

// CSS
import '../../modules/modal/_modal.scss'

class ModalContent extends React.Component {
  constructor(props) {
    super(props)
    this.content = document.createElement('div')
  }

  componentDidMount() {
    document.getElementById('modal-root').appendChild(this.content)
  }

  componentWillUnmount() {
    document.getElementById('modal-root').removeChild(this.content)
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.content)
  }
}

class EditingModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
      backdrop: 'static',
      tooltip_open: false,
      tooltip_uuid: Guid()
    }
  }

  toggleModal = event => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    this.setState({
      modal: !this.state.modal
    })
  }

  toggleTooltip = () =>
    this.setState({
      tooltip_open: !this.state.tooltip_open
    })

  changeBackdrop(event) {
    let value = event.target.value
    if (value !== 'static') {
      value = JSON.parse(value)
    }
    this.setState({ backdrop: value })
  }

  render() {
    let childrenWithProps = React.Children.map(this.props.children, child =>
      React.cloneElement(child, {
        toggleModal: this.toggleModal
      })
    )

    const { button_type, toggleModalText, modal_type, modalConfirmText, modal_size } = this.props
    const { tooltip_open, modal, tooltip_uuid } = this.state
    const { toggleModal, toggleTooltip } = this

    const outlined = button_type.icon_only ? false : true

    return (
      <React.Fragment>
        <Button
          className={button_type.classes}
          color={button_type.color}
          onClick={toggleModal}
          id={`tooltip-modal_${tooltip_uuid}`}
          outline={typeof button_type?.outlined !== 'undefined' ? button_type?.outlined : outlined}
        >
          {button_type.icon_type && (
            <FontAwesomeIcon className={button_type.icon_only ? '' : 'icon-prepend'} icon={button_type.icon_type} />
          )}
          {button_type.icon_only ? '' : <span>{button_type.label || toggleModalText}</span>}
        </Button>
        {button_type.icon_only ? (
          <Tooltip
            delay={500}
            placement="top"
            isOpen={tooltip_open}
            target={`tooltip-modal_${tooltip_uuid}`}
            toggle={toggleTooltip}
          >
            {toggleModalText}
          </Tooltip>
        ) : (
          ''
        )}
        <ModalContent>
          <Modal isOpen={modal} centered size={modal_size} toggle={toggleModal}>
            <ModalHeader>{toggleModalText}</ModalHeader>
            <ModalBody>
              {modal_type === 'confirm' ? <p>{modalConfirmText}</p> : ''}
              {childrenWithProps}
            </ModalBody>
          </Modal>
        </ModalContent>
      </React.Fragment>
    )
  }
}

export default EditingModal
