import React from 'react'

import { graphql, withApollo } from '@apollo/client/react/hoc'

import { LegacySelect as Select } from 'components/modules/selects/react-select'

import licenseOwnerQuery from './Queries.graphql'

class LicenseOwners extends React.Component {
  license_ownerOptions = () => {
    const { license_owners } = this.props.data

    let license_ownerOptions = []

    license_owners.map(license_owner => {
      license_ownerOptions.push({ value: license_owner.id, label: license_owner?.name })
      if (license_owner.sublicense_owners && this.props.withSubs) {
        license_owner.sublicense_owners.map(sublicense_owner => {
          license_ownerOptions.push({
            value: sublicense_owner.id,
            label: String.fromCharCode(8627) + ' ' + sublicense_owner?.name
          })
        })
      }
    })

    return license_ownerOptions
  }

  render = () => {
    const { error, loading } = this.props.data
    const { value, changeLicenseOwner } = this.props

    if (loading)
      return (
        <Select
          isClearable={false}
          name="license_owner"
          value={value}
          onChange={changeLicenseOwner}
          options={[]}
          placeholder="Lizenznehmer auswählen..."
        />
      )
    if (error) return <p>Error</p>
    return (
      <Select
        name="license_owner"
        value={value}
        isMulti
        simpleValue
        onChange={changeLicenseOwner}
        options={this.license_ownerOptions()}
        placeholder="Lizenznehmer auswählen..."
      />
    )
  }
}

export default withApollo(
  graphql(licenseOwnerQuery, {
    options: () => ({
      notifyOnNetworkStatusChange: true
    })
  })(LicenseOwners)
)
