import React, { Fragment } from 'react'

import { Alert, Container, FormGroup, Input, Label } from 'reactstrap'

import { withApollo } from '@apollo/client/react/hoc'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { getFacility } from '../../facility.graphql'
import { updateSearchCriteria } from './information.graphql'

import { FormGroupContainerActions } from './../partials'

const SearchCriteriaForm = props => (
  <div className="box">
    <Form {...props} />
  </div>
)

class Form extends React.Component {
  constructor(props) {
    super(props)
    let checked = []
    const { facility } = this.props
    facility.grouped_search_criteria.map(search_criteria_group =>
      search_criteria_group.search_criteria
        .filter(item => item.chosen)
        .map(search_criterium => checked.push(parseInt(search_criterium.id, 10)))
    )
    this.state = {
      processing: false,
      variables: {
        uuid: facility.uuid,
        search_criterium_ids: checked
      }
    }
  }

  onError = ({ graphQLErrors }) =>
    this.setState({
      processing: false,
      error_msg: graphQLErrors[0].message,
      errors: graphQLErrors[0].errors
    })

  onSuccess = () =>
    this.setState(
      {
        processing: false,
        error_msg: '',
        errors: {}
      },
      this.props.hideEditBoxRight()
    )

  submit = () => {
    this.setState({ processing: true })
    const {
      props: { client },
      state: { variables }
    } = this

    client
      .mutate({
        mutation: updateSearchCriteria,
        variables,
        refetchQueries: [
          {
            query: getFacility,
            variables: {
              uuid: this.props.facility.uuid,
              version_number: this.props.match.params.version_number || ''
            }
          }
        ],
        awaitRefetchQueries: true
      })
      .then(() => this.onSuccess())
      .catch(error => this.onError(error))
  }

  toggle = id => {
    let newState = this.state
    let _id = parseInt(id, 10)
    this.state.variables.search_criterium_ids.includes(_id)
      ? newState.variables.search_criterium_ids.splice(newState.variables.search_criterium_ids.indexOf(_id), 1) &&
        this.setState(newState)
      : newState.variables.search_criterium_ids.push(_id) && this.setState(newState)
  }

  render = () => {
    const {
      submit,
      props: { locale, hideEditBoxRight, facility },
      state: { errors, error_msg, processing }
    } = this

    const actions_props = { locale, hideEditBoxRight, processing, submit }

    return (
      <Fragment>
        {error_msg && <Alert color="danger">{error_msg}</Alert>}
        <FormGroup>
          <Container>
            {facility.grouped_search_criteria.map((search_criteria_group, i) => (
              <Fragment key={i}>
                <h5>{search_criteria_group.guest_group.label}</h5>
                {search_criteria_group.search_criteria.map((search_criterium, j) => (
                  <Fragment key={j}>
                    <Label className={`control-label checkbox-label d-block mb-0`} id={search_criterium.id} check>
                      <Input
                        className={`checkbox-control`}
                        onClick={() => this.toggle(search_criterium.id)}
                        type="checkbox"
                        defaultChecked={this.state.variables.search_criterium_ids.includes(
                          parseInt(search_criterium.id, 10)
                        )}
                      />{' '}
                      <span className="check-icon-container">
                        <span className="check-icon text-primary-dark">
                          <FontAwesomeIcon icon={faCheck} />
                        </span>
                      </span>
                      <span className="label-text">{search_criterium.name}</span>
                    </Label>
                    <br />
                  </Fragment>
                ))}
              </Fragment>
            ))}
          </Container>
        </FormGroup>
        <FormGroupContainerActions {...actions_props} />
      </Fragment>
    )
  }
}

export default withApollo(SearchCriteriaForm)
