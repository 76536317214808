import { withLocale } from 'locale'
import PropTypes from 'prop-types'

const TableHead = ({ locale }) => (
  <thead className="thead-light">
    <tr>
      <th></th>
      <th>{locale.licenseOwner}</th>
      <th>{locale.certificateState}</th>
      <th>{locale.auditOrderNumber}</th>
      <th>{locale.name}</th>
      <th>{locale.city}</th>
      <th>{locale.regions}</th>
      <th>{locale.bundles}</th>
      <th>{locale.forms}</th>
      <th>{locale.contactName}</th>
      <th>{locale.contactEmail}</th>
      <th>{locale.contactPhone}</th>
      <th>{locale.collectorName}</th>
      <th>{locale.collectionDesiredUntil}</th>
      <th>{locale.collectionDate}</th>
      <th>{locale.trainingState}</th>
      <th>{locale.certifiedTo}</th>
      <th>{locale.certificate}</th>
    </tr>
  </thead>
)

TableHead.propTypes = {
  locale: PropTypes.object.isRequired
}

export default withLocale(TableHead, { key: 'facilitiesDashboard.facilitiesTable' })
